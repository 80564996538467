import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ServicerSPV } from '@app/models/ServicerSPV.model';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalGenericComponent } from '@app/confirmation-modal-generic/confirmation-modal-generic.component';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-servicer-spv-edit',
  templateUrl: './servicer-spv-edit.component.html',
  styleUrls: ['./servicer-spv-edit.component.scss']
})
export class ServicerSpvEditComponent implements OnInit {
  get email() {
    return this.servicerSPVCreationForm.get('email');
  }

  get firstName() {
    return this.servicerSPVCreationForm.get('firstName');
  }

  get lastName() {
    return this.servicerSPVCreationForm.get('lastName');
  }

  servicerSPVCreationForm!: FormGroup;
  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;
  entity: ServicerSPV;
  id: string;
  private sub: any;
  private maxToday: any;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private backendAuthService: BackendAuthService
  ) {
    this.maxToday = new Date();
    this.createForm();

    this.entity = new ServicerSPV();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id; // (+) converts string 'id' to a number
      console.log(params.id);
      this.http
        .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + params.id + '"}', httpOptions)
        .subscribe(data => {
          console.log(data);
          this.entity.firstName = data.name;
          this.entity.lastName = data.lastName;
          this.entity.email = data.email;
          this.entity.telefono = data.telefono;
          this.entity.fiscal_code = data.fiscal_code;
          this.entity.data_nascita = data.data_nascita;

          this.entity.ragioneSociale = data.ragioneSociale;
          this.entity.indirizzo = data.indirizzo;
          this.entity.cap = data.cap;
          this.entity.city = data.city;
          this.entity.province = data.province;
          this.entity.country = data.country;

          this.entity.piva = data.piva;
          this.entity.rea = data.rea;
          this.entity.abi = data.abi;
          this.entity.pec = data.pec;

          this.entity.data_assunzione = this.datePipe.transform(data.data_assunzione, 'yyyy-MM-dd');
          this.entity.data_contratto = this.datePipe.transform(data.data_contratto, 'yyyy-MM-dd');

          this.servicerSPVCreationForm.setValue({
            firstName: this.entity.firstName,
            lastName: this.entity.lastName,
            telefono: this.entity.telefono,
            fiscal_code: this.entity.fiscal_code,
            data_nascita: this.entity.data_nascita,
            ragioneSociale: this.entity.ragioneSociale,
            indirizzo: this.entity.indirizzo,
            cap: this.entity.cap,
            city: this.entity.city,
            province: this.entity.province,
            country: this.entity.country,
            piva: this.entity.piva,
            rea: this.entity.rea,
            abi: this.entity.abi,
            pec: this.entity.pec,

            data_assunzione: this.entity.data_assunzione,
            data_contratto: this.entity.data_contratto
          });
          console.log('se1=>', this.servicerSPVCreationForm);
        });
    });

    this.firebaseService.createPageLog('User Visit Page - UPDATE Servicer SPV');
  }

  ngOnInit() {}

  createForm() {
    this.servicerSPVCreationForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      telefono: new FormControl(''),
      fiscal_code: new FormControl('', [Validators.required]),
      data_nascita: new FormControl('', [Validators.required]),
      data_assunzione: new FormControl(''),
      data_contratto: new FormControl(''),
      ragioneSociale: new FormControl('', [Validators.required]),
      indirizzo: new FormControl('', [Validators.required]),
      cap: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      province: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      piva: new FormControl('', [Validators.required]),
      rea: new FormControl('', [Validators.required]),
      abi: new FormControl('', [Validators.required]),
      pec: new FormControl('', [Validators.required])
    });
  }

  submit() {
    // console.log('se=>',this.servicerSPVCreationForm);
    const modalRef = this.modalService.open(ConfirmationModalGenericComponent, {
      size: 'md',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.title = 'Attenzione';
    modalRef.componentInstance.text = 'Confermi di voler aggiornare i dati?';
    modalRef.componentInstance.loading.subscribe((data: boolean) => {
      console.log(data);
      if (data) {
        modalRef.close();

        const objectToSave = this.firebaseService.updateServicerSPV(this.servicerSPVCreationForm);
        // console.log(objectToSave);
        this.firebaseService.updateUserDocAfterCreate(this.id, objectToSave);

        this.router.navigate(['/home/servicer-spv/' + this.id + '/detail']);
      }
    });
  }

  goBack() {
    this.router.navigate(['/home/servicer-spv/' + this.id + '/detail']);
  }
}
