import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { Utilities } from '@app/utilities/Utilities';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { DatePipe } from '@angular/common';
import { MailerService } from '@app/mailer/mailer.service';

import * as bcrypt from 'bcryptjs';
import { environment } from '@env/environment.prod';

@Component({
  selector: 'app-new-investor',
  templateUrl: './new-investor.component.html',
  styleUrls: ['./new-investor.component.scss']
})
export class NewInvestorComponent implements OnInit {
  products: string[] = Utilities.productNames;
  investorCreationForm!: FormGroup;
  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private lemonwayServicer: LemonwayService,
    private mailerService: MailerService
  ) {
    this.createForm();
    this.firebaseService.createPageLog('User Visit Page new-investor');
  }

  ngOnInit() {}

  submit() {
    this.isSubmitted = true;
    if (
      this.investorCreationForm.value.email == '' ||
      this.investorCreationForm.value.password == '' ||
      this.investorCreationForm.value.phone_number == '' ||
      this.investorCreationForm.value.originatorSolicitorSurname == '' ||
      this.investorCreationForm.value.originatorSolicitorName == '' ||
      (this.investorCreationForm.value.originatorSolicitorBirthplace == '' && !this.verifyPass())
    ) {
      this.investorCreationForm.get('email').markAsTouched();
      this.investorCreationForm.get('password').markAsTouched();
      this.investorCreationForm.get('name').markAsTouched();
      this.investorCreationForm.get('investorPIva').markAsTouched();
      this.investorCreationForm.get('phone_number').markAsTouched();
      this.investorCreationForm.get('originatorSolicitorSurname').markAsTouched();
      this.investorCreationForm.get('originatorSolicitorName').markAsTouched();
      this.investorCreationForm.get('originatorSolicitorBirthplace').markAsTouched();
      this.investorCreationForm.get('investorOfficeRegisteredStreet').markAsTouched();
      this.investorCreationForm.get('investorOfficeRegisteredCity').markAsTouched();
      this.investorCreationForm.get('investorOfficeRegisteredProvince').markAsTouched();
      this.investorCreationForm.get('originatorSolicitorBirthplace').markAsTouched();
      this.investorCreationForm.get('originatorSolicitorBirthdate').markAsTouched();
    } else {
      if (this.investorCreationForm.value.privato) {
        this.investorCreationForm.value.name =
          this.investorCreationForm.value.originatorSolicitorName +
          '  ' +
          this.investorCreationForm.value.originatorSolicitorSurname;
      }
      if (this.investorCreationForm.value.fiscalCode == '' && this.investorCreationForm.value.privato) {
        alert('inserire codice fiscale');
        return;
      }

      if (this.investorCreationForm.value.investorPIva == '' && !this.investorCreationForm.value.privato) {
        alert('inserire Partita iva');
        return;
      }
      if (this.investorCreationForm.value.name == '' && !this.investorCreationForm.value.privato) {
        alert('inserire denominazione');
        return;
      }

      if (this.investorCreationForm.value.nazione == '---') {
        alert('inserire una nazione valida');
        return;
      }
      if (this.investorCreationForm.value.privato) {
        this.investorCreationForm.value.investorPIva = this.investorCreationForm.value.codiceFiscale;
      }

      if (this.investorCreationForm.value.privato) {
        this.investorCreationForm.value.viaLeg = this.investorCreationForm.value.investorOfficeRegisteredStreet;
        this.investorCreationForm.value.comuneLeg = this.investorCreationForm.value.investorOfficeRegisteredCity;
        this.investorCreationForm.value.provinciaLeg = this.investorCreationForm.value.investorOfficeRegisteredProvince;
        this.investorCreationForm.value.nazioneLeg = this.investorCreationForm.value.nazione;
      }
      const objectToSave = this.firebaseService.createInvestor(this.investorCreationForm);

      const pipe = new DatePipe('en-US');
      // console.log(pipe.transform(objectToSave.originatorSolicitorBirthdate, 'y/MM/dd'));
      if (this.investorCreationForm.value.lemonway && this.investorCreationForm.value.privato) {
        //console.log(objectToSave);
        this.lemonwayServicer
          .createaNewIndividualAccount(
            objectToSave.fiscalCode,
            objectToSave.originatorSolicitorName,
            objectToSave.originatorSolicitorSurname,
            objectToSave.stato,
            1,
            objectToSave.email,
            objectToSave.investorOfficeRegisteredStreet,
            objectToSave.legalAddressCAP,
            objectToSave.investorOfficeRegisteredCity,
            pipe.transform(objectToSave.originatorSolicitorBirthdate, 'y/MM/dd'),
            objectToSave.originatorSolicitorBirthplace,
            'M',
            objectToSave.telefono,
            objectToSave.telefono,
            false,
            false
          )
          .subscribe(data => {
            //  console.log(data);
            if (data.legalAccount) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            }
            if (data.code == 200) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else if (data.error.code == 152) {
              // utente esistente
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else {
              if (data.code == 234) {
                // formato telefono non valido
                alert('Formato telefono non valido');
              }
              try {
                if (data.error.code == 152) {
                  // utente esistente
                  objectToSave.lemonwayAccount = true;
                  objectToSave.statoLemonway = Utilities.statoLemon[3];
                }
              } catch (e) {
                // console.log('exception');
              }
            }
            try {
              alert(data.error.message);
            } catch (e) {}
          });
      }
      if (this.investorCreationForm.value.lemonway && !this.investorCreationForm.value.privato) {
        this.lemonwayServicer
          .createaNewLegalAccount(
            objectToSave.investorPIva,
            objectToSave.originatorSolicitorName,
            objectToSave.originatorSolicitorSurname,
            objectToSave.stato,
            1,
            objectToSave.email,
            objectToSave.investorOfficeRegisteredStreet,
            objectToSave.legalAddressCAP,
            objectToSave.investorOfficeRegisteredCity,
            pipe.transform(objectToSave.originatorSolicitorBirthdate, 'y/MM/dd'),
            objectToSave.originatorSolicitorBirthplace,
            'M',
            objectToSave.telefono,
            objectToSave.telefono,
            false,
            false,
            objectToSave.name,
            objectToSave.name,
            false,
            this.investorCreationForm.value.REA_CODE
          )
          .subscribe(data => {
            // console.log(data);
            if (data.legalAccount) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            }
            if (data.code == 200) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else if (data.error.code == 152) {
              // utente esistente
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else {
              if (data.code == 234) {
                // formato telefono non valido
                alert('Formato telefono non valido');
              }
              try {
                if (data.error.code == 152) {
                  // utente esistente
                  objectToSave.lemonwayAccount = true;
                  objectToSave.statoLemonway = Utilities.statoLemon[3];
                }
              } catch (e) {
                // console.log('exception');
              }
            }
            try {
              alert(data.error.message);
            } catch (e) {}
          });
      }
      if (!this.investorCreationForm.value.lemonway) {
        objectToSave.lemonwayAccount = false;
      }

      this.firebaseService
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);
          // this.mailerService.sendEmailNewUser(objectToSave.email).subscribe(() => {});

          // @@ hydra - 2023-05-01 - SEND EMAIL WITH TEMPALTE
          const params = {
            firstname: undefined,
            lastname: undefined,
            subject: undefined,
            templateName: undefined,
            to: undefined
          };

          params.to = objectToSave.email;
          params.firstname = this.investorCreationForm.value.originatorSolicitorName;
          params.lastname = this.investorCreationForm.value.originatorSolicitorSurname;
          params.subject = 'Registrazione IncassaOra';
          params.templateName = 'registrationSuccessFull';

          this.mailerService.sendEmailTemplate(params);

          // @@ hydra - 2023-01-31 - PASSWORD HASH
          try {
            const newPass = bcrypt.hashSync(objectToSave.password, environment.saltPass);
            this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
          } catch (e) {}
          // *********************************************************************************************

          this.router.navigate(['/home//users-credential']);
        })
        .catch(error => {
          // Handle Errors here.
          const errorCode = error.code;
          this.errorMessage = error.message;
        });
    }
  }

  onChangeType() {
    this.createForm();
  }

  createForm() {
    this.investorCreationForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      phone_number: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      name: new FormControl('', [Validators.required]),
      investorPIva: new FormControl('', [Validators.required]),
      investorWebSite: new FormControl(''),
      investorPec: new FormControl(''),
      // sede legale
      investorOfficeRegisteredStreet: new FormControl('', [Validators.required]),
      investorOfficeRegisteredCity: new FormControl('', [Validators.required]),
      investorOfficeRegisteredProvince: new FormControl('', [Validators.required]),
      // rappresentante legale
      originatorSolicitorSurname: new FormControl('', [Validators.required]),
      originatorSolicitorName: new FormControl('', [Validators.required]),
      originatorSolicitorBirthplace: new FormControl('', [Validators.required]),
      originatorSolicitorBirthdate: new FormControl('', [Validators.required]),
      nazione: new FormControl('---'),
      tel: new FormControl(''),
      indirizzo: new FormControl(''),
      privato: new FormControl(false),
      lemonway: new FormControl(false),
      codiceFiscale: new FormControl(''),
      REA_CODE: new FormControl(''),
      viaLeg: new FormControl(''),
      comuneLeg: new FormControl(''),
      provinciaLeg: new FormControl(''),
      nazioneLeg: new FormControl(''),
      capLegale: new FormControl(''),
      legalAddressCAP: new FormControl('')
    });
  }

  onChange(event: any) {}
  get email() {
    return this.investorCreationForm.get('email');
  }

  get password() {
    return this.investorCreationForm.get('password');
  }

  get name() {
    return this.investorCreationForm.get('name');
  }

  get investorPIva() {
    return this.investorCreationForm.get('investorPIva');
  }
  get investorOfficeRegisteredStreet() {
    return this.investorCreationForm.get('investorOfficeRegisteredStreet');
  }
  get investorOfficeRegisteredCity() {
    return this.investorCreationForm.get('investorOfficeRegisteredCity');
  }
  get investorOfficeRegisteredProvince() {
    return this.investorCreationForm.get('investorOfficeRegisteredProvince');
  }

  get phone_number() {
    return this.investorCreationForm.get('phone_number');
  }
  get originatorSolicitorSurname() {
    return this.investorCreationForm.get('originatorSolicitorSurname');
  }
  get originatorSolicitorName() {
    return this.investorCreationForm.get('originatorSolicitorName');
  }
  get originatorSolicitorBirthdate() {
    return this.investorCreationForm.get('originatorSolicitorBirthdate');
  }
  get originatorSolicitorBirthplace() {
    return this.investorCreationForm.get('originatorSolicitorBirthplace');
  }

  verifyPass() {
    console.log(this.investorCreationForm.get('password').value);
    console.log(this.investorCreationForm.get('email').value);

    if (
      this.investorCreationForm.get('password').value == this.investorCreationForm.get('email').value ||
      this.investorCreationForm.get('password').value ==
        this.investorCreationForm.get('originatorSolicitorName').value ||
      this.investorCreationForm.get('password').value ==
        this.investorCreationForm.get('originatorSolicitorSurname').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
