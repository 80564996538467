import { Utilities } from '@app/utilities/Utilities';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ClipboardService } from 'ngx-clipboard';
import { environment } from '@env/environment';
import { Wallet } from '@app/models/Wallet.model';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-wallet-list',
  templateUrl: './wallet-list.component.html',
  styleUrls: ['./wallet-list.component.scss']
})
export class WalletListComponent implements OnInit, OnDestroy {
  @Input() userType: string;

  public roles: string[];
  isCopied = false;

  public wallets: Observable<any[]>;
  public walletList: Observable<any[]>;
  walletsItems: any[];
  filteredWalletsItems: any[] = [];
  regions: string[];
  sectors: string[];
  filters = {};
  filterRegion = '';
  filterFinancing = 0;
  filterSector = '';
  filterBank = '';
  filterStatoValore = 'Attivo';
  walletCreationForm!: FormGroup;
  isSubmitted = false;
  staticAlertClosed = false;
  successMessage: string;
  autohide = true;

  page: number;
  collectionSize: number;
  pageSize: number;
  sub: Subscription;
  private _success = new Subject<string>();

  constructor(public firebase: FirebaseTranchedService, private _clipboardService: ClipboardService) {
    this.regions = Utilities.regions;
    this.roles = Utilities.userType;
    this.firebase.createPageLog('User Visit Page list-wallet');
  }

  ngOnInit() {
    setTimeout(() => (this.staticAlertClosed = true), 5000);

    this.wallets = this.firebase.getWallets();
    this.walletList = this.firebase.getWallets();
    this.page = 1;
    this.sub = this.wallets.subscribe(data => {
      this.walletsItems = data;
      this.filterForRole();

      this.applyFilters();
      this.getDaysLeft(this.filteredWalletsItems);
    });

    this.pageSize = 10000000;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getDaysLeft(array: any[]) {
    for (const wallet of array) {
      Utilities.getDaysLeft(wallet);
    }
  }

  filterForRole() {
    if (this.firebase.isInvestor()) {
      /* SBLOCCARE NEL CASO IN CUI DEVE VEDERE SOLO I PORTAFOGLI IN CUI E' ASSOCIATO
      this.walletsItems = _.filter(this.walletsItems, item =>
        this.controllo(item.listInvestitori ? item.listInvestitori : [], this.firebase.loggedUser.id)
      );
      */
    }
    if (this.firebase.isOriginator()) {
      this.walletsItems = _.filter(this.walletsItems, item => item.originatorUId === this.firebase.loggedUser.id);
    } else if (this.firebase.isCompany()) {
      this.walletsItems = _.filter(this.walletsItems, item => {
        return this.firebase.loggedUser.listWallet.includes(item.id);
      });
    }
  }

  controllo(list: string[], id: string) {
    for (let entry of list) {
      if (id == entry) {
        //    console.log('qui=>' + id + '=====' + entry);
        return true;
      }
    }
    return false;
  }

  /// filter property by equality to rule
  filterExact(property: string, rule: any) {
    if (rule == '') {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val == rule;

      this.applyFilters();
    }
  }

  /// filter numbers lower than rule
  filterGreaterThan(property: string, rule: number) {
    if (rule == 0) {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val >= rule;
      this.applyFilters();
    }
  }

  /// filter  numbers greater than rule
  filterLowerThan(property: string, rule: any) {
    if (rule == 0) {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val <= rule;
      this.applyFilters();
    }
  }

  /// filter properties that resolve to true
  filterBoolean(property: string, rule: boolean) {
    if (!rule) {
      this.removeFilter(property);
    } else {
      this.filters[property] = (val: any) => val;
      this.applyFilters();
    }
  }

  public filterStato(stato: string) {
    this.applyFilters();
  }

  removeFilter(property: string) {
    delete this.filters[property];
    this[property] = null;
    this.applyFilters();
  }

  /*
  openLanding(item: any) {
    if (item.partner != undefined) this.router.navigate(['/landing', item.id, item.partner]);
    else this.router.navigate(['/landing', item.id]);
  }
*/

  private applyFilters() {
    this.filteredWalletsItems = this.walletsItems;
    if (this.filterStatoValore === 'Attivo') {
      this.filteredWalletsItems = _.filter(this.filteredWalletsItems, item => !item.closed);
    } else {
      this.filteredWalletsItems = _.filter(this.filteredWalletsItems, item => item.closed);
    }
    this.collectionSize = this.filteredWalletsItems.length;
  }
}
