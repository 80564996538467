import { Component, Input, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Assignor } from '@app/models/Assignor.model';
import { Invoice } from '@app/models/Invoice.model';
import { Wallet } from '@app/models/Wallet.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-genera-contratto',
  templateUrl: './genera-contratto.component.html',
  styleUrls: ['./genera-contratto.component.scss']
})
export class GeneraContrattoComponent implements OnInit {
  @Input() invoice: Invoice;
  @Input() wallet: Wallet;
  @Input() cedente: Assignor;
  data: string;

  constructor(public activeModal: NgbActiveModal, public firebase: FirebaseTranchedService) {}
  ngOnInit() {
    var date = new Date();
    this.data = date.toLocaleDateString();
    //console.log(this.invoice);
    //console.log(this.wallet);
    //console.log(this.cedente);
  }

  scarica() {
    var path = document.getElementById('contenuto');
    path.style.fontSize = '12px';
    path.style.width = '600px';
    //'p', 'pt', 'letter'
    //'p', 'mm', [290, 210]
    var doc = new jsPDF('p', 'pt', 'letter');
    //doc.setFontSize(1);

    var pdfjs = document.querySelector('contenuto');

    // Convert HTML to PDF in JavaScript
    doc.html(path, {
      callback: function(doc) {
        doc.save('Contratto.pdf');
      }
    });
  }
}
