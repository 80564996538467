import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { InvestorSpvComponent } from './investor-spv.component';
import { InvestorSpvCreateComponent } from './investor-spv-create/investor-spv-create.component';
import { InvestorSpvListComponent } from './investor-spv-list/investor-spv-list.component';
import { InvestorSpvDetailComponent } from './investor-spv-detail/investor-spv-detail.component';
import { InvestorSpvEditComponent } from './investor-spv-edit/investor-spv-edit.component';

const routes: Routes = [
  {
    path: 'list',
    component: InvestorSpvListComponent
  },
  {
    path: 'create',
    component: InvestorSpvCreateComponent
  },
  {
    path: ':id/detail',
    component: InvestorSpvDetailComponent
  },
  {
    path: ':id/edit',
    component: InvestorSpvEditComponent
  },
  { path: '', redirectTo: 'list', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvestorSpvRoutingModule {}
