import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Utilities } from '@app/utilities/Utilities';
import * as xlsx from 'xlsx';
import { ShowMessageService } from '@app/core/show-message.service';
import { Assignor } from '@app/models/Assignor.model';
import { DatePipe } from '@angular/common';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { MailerService } from '@app/mailer/mailer.service';
import { environment } from '@env/environment.prod';

import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-newcompany',
  templateUrl: './newcompany.component.html',
  styleUrls: ['./newcompany.component.scss']
})
export class NewcompanyComponent implements OnInit {
  products: string[] = Utilities.productNames;
  companyCreationForm!: FormGroup;
  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;

  title: 'XlsRead';
  file: File;
  arrayBuffer: any;
  filelist: any;

  private obj: {
    status: false;
    email: '';
    phoneNumber: '';
    subject_postal_code: '';
    financial: [
      {
        subject_postal_code: '';
        subject_address: '';
        subject_fiscal_code: '';
        subject_name: '';
        subject_piva: '';
        subject_rea: '';
        subject_registration_date: '';
        company_structure_cariche: [
          {
            anagrafica_persona: {
              info_persona: {
                cognome: '';
                nome: '';
                dati_nascita: {
                  data: '';
                };
              };
              domicilio_fiscale: {
                indirizzo: '';
                comune: '';
                provincia_sigla: '';
                cap: '';
              };
            };
          }
        ];
      }
    ];
  };
  maxDataEmiss: any;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private datepipe: DatePipe,
    public showService: ShowMessageService,
    private lemonwayServicer: LemonwayService,
    private mailerService: MailerService,
    private route: ActivatedRoute
  ) {
    this.maxDataEmiss = new Date();
    this.createForm();
    this.firebaseService.createPageLog('User Visit Page new-company');

    this.arrayBuffer = [];
  }

  ngOnInit() {
    this.obj = {
      status: false,
      email: '',
      phoneNumber: '',
      subject_postal_code: '',
      financial: [
        {
          subject_postal_code: '',
          subject_address: '',
          subject_fiscal_code: '',
          subject_name: '',
          subject_piva: '',
          subject_rea: '',
          subject_registration_date: '',
          company_structure_cariche: [
            {
              anagrafica_persona: {
                info_persona: {
                  cognome: '',
                  nome: '',
                  dati_nascita: {
                    data: ''
                  }
                },
                domicilio_fiscale: {
                  indirizzo: '',
                  comune: '',
                  provincia_sigla: '',
                  cap: ''
                }
              }
            }
          ]
        }
      ]
    };

    try {
      if (JSON.parse(this.route.snapshot.paramMap.get('object')).status) {
        this.obj = JSON.parse(this.route.snapshot.paramMap.get('object'));
        this.companyCreationForm.setValue({
          assignorUsername: this.obj.email,
          codiceFiscale: this.obj.financial[0].subject_fiscal_code,
          phone_number: this.obj.phoneNumber,
          name: this.obj.financial[0].subject_name,
          pIva: this.obj.financial[0].subject_piva,
          originatorSolicitorBirthdate: this.obj.financial[0].subject_registration_date,
          REA_CODE: this.obj.financial[0].subject_rea,
          cognome: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.info_persona.cognome,
          cognomeReferenteAmm: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.info_persona
            .cognome,
          refName: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.info_persona.nome,
          refMail: this.obj.email,
          legalAddressDataNascita: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.info_persona
            .dati_nascita.data,
          viaLeg: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.domicilio_fiscale.indirizzo,
          comuneLeg: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.domicilio_fiscale.comune,
          provinciaLeg: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.domicilio_fiscale
            .provincia_sigla,
          capLegale: this.obj.financial[0].company_structure_cariche[0].anagrafica_persona.domicilio_fiscale.cap
        });
      }
    } catch (e) {}
  }

  submit() {
    this.isSubmitted = true;
    // console.log(this.companyCreationForm);
    if (
      this.companyCreationForm.value.assignorUsername == '' ||
      this.companyCreationForm.value.originatorSolicitorBirthdate == '' ||
      this.companyCreationForm.value.assignorPassword == '' ||
      (this.companyCreationForm.value.phone_number == '' && !this.verifyPass())
    ) {
      this.companyCreationForm.get('assignorUsername').markAsTouched();
      this.companyCreationForm.get('assignorPassword').markAsTouched();
      this.companyCreationForm.get('phone_number').markAsTouched();
      this.companyCreationForm.get('originatorSolicitorBirthdate').markAsTouched();
      // this.companyCreationForm.get('pIva').markAsTouched();
      //this.companyCreationForm.get('name').markAsTouched();
      //this.companyCreationForm.get('fiscalCode').markAsTouched();
    } else {
      if (this.companyCreationForm.value.codiceFiscale == '' && this.companyCreationForm.value.privato) {
        alert('inserire codice fiscale');
        return;
      }

      this.companyCreationForm.value.assignorName = this.companyCreationForm.value.refName;
      this.companyCreationForm.value.cognome = this.companyCreationForm.value.cognomeReferenteAmm;

      if (this.companyCreationForm.value.privato) {
        this.companyCreationForm.value.viaLeg = this.companyCreationForm.value.legalAddressStreet;
        this.companyCreationForm.value.comuneLeg = this.companyCreationForm.value.legalAddressCity;
        this.companyCreationForm.value.provinciaLeg = this.companyCreationForm.value.legalAddressProvince;
        this.companyCreationForm.value.nazioneLeg = this.companyCreationForm.value.nazione;
      }

      if (
        this.companyCreationForm.value.legalAddressStreet == '' ||
        this.companyCreationForm.value.legalAddressCity == '' ||
        this.companyCreationForm.value.legalAddressProvince == '' ||
        this.companyCreationForm.value.legalAddressCAP == '' ||
        this.companyCreationForm.value.nazione == '---'
      ) {
        alert('sede legale non valida');
        return;
      }
      if (this.companyCreationForm.value.privato) {
        this.companyCreationForm.value.name =
          this.companyCreationForm.value.refName + '  ' + this.companyCreationForm.value.cognomeReferenteAmm;
      }

      if (this.companyCreationForm.value.REA_CODE == '' && !this.companyCreationForm.value.privato) {
        alert('REA CODE non valido');
        return;
      }

      if (this.companyCreationForm.value.pIva == '' && !this.companyCreationForm.value.privato) {
        alert('inserire Partita iva');
        return;
      }
      if (this.companyCreationForm.value.name == '' && !this.companyCreationForm.value.privato) {
        alert('inserire denominazione');
        return;
      }

      if (this.companyCreationForm.value.privato) {
        this.companyCreationForm.value.pIva = this.companyCreationForm.value.codiceFiscale;
      }
      //  console.log(this.companyCreationForm);
      let pipe = new DatePipe('en-US');
      let objectToSave = this.firebaseService.createAssignor(this.companyCreationForm);

      if (this.companyCreationForm.value.lemonway && this.companyCreationForm.value.privato) {
        // console.log(objectToSave);
        this.lemonwayServicer
          .createaNewIndividualAccount(
            objectToSave.fiscalCode,
            objectToSave.name,
            objectToSave.surname,
            'ITA',
            2,
            objectToSave.email,
            objectToSave.legalAddressStreet,
            objectToSave.legalAddressCAP,
            objectToSave.legalAddressCity,
            pipe.transform(objectToSave.legalAddressDataNascita, 'y/MM/dd'),
            objectToSave.originatorSolicitorBirthplace,
            'M',
            objectToSave.telefono,
            objectToSave.telefono,
            false,
            false
          )
          .subscribe(data => {
            //console.log(data);
            if (data.legalAccount) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            }
            if (data.code == 200) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else if (data.error.code == 152) {
              // utente esistente
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else {
              if (data.code == 234) {
                // formato telefono non valido
                alert('Formato telefono non valido');
              }
              try {
                if (data.error.code == 152) {
                  // utente esistente
                  objectToSave.lemonwayAccount = true;
                  objectToSave.statoLemonway = Utilities.statoLemon[3];
                }
              } catch (e) {
                // console.log('exception');
              }
            }
            try {
              alert(data.error.message);
            } catch (e) {}
          });
      }
      if (this.companyCreationForm.value.lemonway && !this.companyCreationForm.value.privato) {
        this.lemonwayServicer
          .createaNewLegalAccount(
            objectToSave.pIva,
            objectToSave.refName,
            objectToSave.surname,
            'ITA',
            2,
            objectToSave.email,
            objectToSave.legalAddressStreet,
            objectToSave.legalAddressCAP,
            objectToSave.legalAddressCity,
            pipe.transform(objectToSave.legalAddressDataNascita, 'y/MM/dd'),
            objectToSave.originatorSolicitorBirthplace,
            'M',
            objectToSave.telefono,
            objectToSave.telefono,
            false,
            false,
            objectToSave.name,
            objectToSave.name,
            false,
            this.companyCreationForm.value.REA_CODE
          )
          .subscribe(data => {
            // console.log(data);
            if (data.legalAccount) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            }
            if (data.code == 200) {
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else if (data.error.code == 152) {
              // utente esistente
              objectToSave.lemonwayAccount = true;
              objectToSave.statoLemonway = Utilities.statoLemon[3];
            } else {
              if (data.code == 234) {
                // formato telefono non valido
                alert('Formato telefono non valido');
              }
              try {
                if (data.error.code == 152) {
                  // utente esistente
                  objectToSave.lemonwayAccount = true;
                  objectToSave.statoLemonway = Utilities.statoLemon[3];
                }
              } catch (e) {
                // console.log('exception');
              }
            }
            try {
              alert(data.error.message);
            } catch (e) {}
          });
      }
      if (!this.companyCreationForm.value.lemonway) {
        objectToSave.lemonwayAccount = false;
      }
      this.firebaseService
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);

          // @@ hydra - 2023-05-01 - SEND EMAIL WITH TEMPALTE
          const params = {
            firstname: undefined,
            lastname: undefined,
            subject: undefined,
            templateName: undefined,
            to: undefined
          };

          params.to = objectToSave.email;
          params.firstname = this.companyCreationForm.value.refName;
          params.lastname = this.companyCreationForm.value.cognomeReferenteAmm;
          params.subject = 'Registrazione IncassaOra';
          params.templateName = 'registrationSuccessFull';

          this.mailerService.sendEmailTemplate(params);

          // this.mailerService.sendEmailNewUser(objectToSave.email).subscribe(() => {});
          this.router.navigate(['/home//users-credential']);
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          this.errorMessage = error.message;
        });
    }
  }

  onChangeType() {
    this.createForm();
  }

  createForm() {
    this.companyCreationForm = this.formBuilder.group({
      assignorUsername: new FormControl('', [Validators.required, Validators.email]),
      phone_number: new FormControl('', [Validators.required]),
      assignorPassword: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      name: new FormControl(''),
      assignorName: new FormControl(''),
      pIva: new FormControl(''),
      investorWebSite: new FormControl(''),
      assignorPec: new FormControl(''),
      assignorType: new FormControl(''),
      cognome: new FormControl(''),
      cognomeReferenteAmm: new FormControl(''),
      legalName: new FormControl(''),
      refName: new FormControl(''),
      refMail: new FormControl(''),
      refPhone: new FormControl(''),
      indirizzo: new FormControl(''),
      privato: new FormControl(false),
      lemonway: new FormControl(false),
      codiceFiscale: new FormControl(''),
      originatorSolicitorBirthdate: new FormControl('', [Validators.required]),
      legalAddressStreet: new FormControl(''),
      legalAddressCity: new FormControl(''),
      legalAddressProvince: new FormControl(''),
      legalAddressCAP: new FormControl(''),
      REA_CODE: new FormControl(''),
      nazione: new FormControl('---'),
      viaLeg: new FormControl(''),
      comuneLeg: new FormControl(''),
      provinciaLeg: new FormControl(''),
      nazioneLeg: new FormControl(''),
      capLegale: new FormControl(''),
      iban: new FormControl(''),
      legalAddressDataNascita: new FormControl('')
    });
  }

  onChange(event: any) {}

  get assignorUsername() {
    return this.companyCreationForm.get('assignorUsername');
  }

  get assignorPassword() {
    return this.companyCreationForm.get('assignorPassword');
  }

  get name() {
    return this.companyCreationForm.get('name');
  }

  get originatorSolicitorBirthdate() {
    return this.companyCreationForm.get('originatorSolicitorBirthdate');
  }

  get pIva() {
    return this.companyCreationForm.get('pIva');
  }

  get assignorName() {
    return this.companyCreationForm.get('assignorName');
  }

  get cognome() {
    return this.companyCreationForm.get('cognome');
  }

  get phone_number() {
    return this.companyCreationForm.get('phone_number');
  }

  get codiceFiscale() {
    return this.companyCreationForm.get('codiceFiscale');
  }

  addfile(event) {
    this.file = event.target.files[0];
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(this.file);
    fileReader.onload = e => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = xlsx.read(bstr, { type: 'binary' });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      //console.log(xlsx.utils.sheet_to_json(worksheet, { raw: true }));
      this.arrayBuffer = xlsx.utils.sheet_to_json(worksheet, { raw: true });
      this.filelist = [];
      // console.log(this.arrayBuffer);
    };
  }

  salva() {
    if (this.arrayBuffer.length == 0) {
      this.showService.showCreazioneCompany = true;
      return;
    }

    for (var i = 0; i != this.arrayBuffer.length; ++i) {
      //TODO capire perchè nel momento in cui salva da un errore
      var assignor: Assignor = new Assignor();
      assignor.email = this.arrayBuffer[i].email;
      assignor.password = this.arrayBuffer[i].password;
      assignor.telefono = this.arrayBuffer[i].telefono;
      assignor.password = this.arrayBuffer[i].password;
      assignor.name = this.arrayBuffer[i].denominazione;
      assignor.pIva = this.arrayBuffer[i].partitaIva;
      assignor.fiscalCode = this.arrayBuffer[i].codicefiscale;
      assignor.roles = [Utilities.userType[3]];
      assignor.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      assignor.originatorProducts = [true, false, false];
      assignor.verified = false;
      // console.log(assignor);

      this.firebaseService
        .createUser(assignor.email, assignor.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, assignor);

          // @@ hydra - 2023-01-31 - PASSWORD HASH
          try {
            const newPass = bcrypt.hashSync(assignor.password, environment.saltPass);
            this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
          } catch (e) {}
          // *********************************************************************************************
        })
        .catch(error => {
          // Handle Errors here.
          var errorCode = error.code;
          this.errorMessage = error.message;
        });
    }
    this.router.navigate(['/home//pending-requests']);
  }

  verifyPass() {
    console.log(this.companyCreationForm.get('assignorPassword').value);
    console.log(this.companyCreationForm.get('email').value);

    if (
      this.companyCreationForm.get('assignorPassword').value == this.companyCreationForm.get('email').value ||
      this.companyCreationForm.get('assignorPassword').value == this.companyCreationForm.get('refName').value ||
      this.companyCreationForm.get('assignorPassword').value ==
        this.companyCreationForm.get('cognomeReferenteAmm').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
