import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ServicerSpvCreateComponent } from './servicer-spv-create/servicer-spv-create.component';
import { ServicerSpvListComponent } from './servicer-spv-list/servicer-spv-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { ClipboardModule } from 'ngx-clipboard';

import { DataTablesModule } from 'angular-datatables';

import { ReactiveFormsModule } from '@angular/forms';
import { ServicerSpvDetailComponent } from './servicer-spv-detail/servicer-spv-detail.component';
import { ServicerSpvEditComponent } from './servicer-spv-edit/servicer-spv-edit.component';

@NgModule({
  declarations: [
    ServicerSpvCreateComponent,
    ServicerSpvListComponent,
    ServicerSpvDetailComponent,
    ServicerSpvEditComponent
  ],
  imports: [CommonModule, NgbModule, SharedModule, ClipboardModule, ReactiveFormsModule, DataTablesModule],
  providers: [],
  entryComponents: [ServicerSpvListComponent],
  exports: [ServicerSpvListComponent, ServicerSpvCreateComponent]
})
export class ServicerSPVModule {}
