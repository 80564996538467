import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TestPdfComponent } from './test/test-pdf/test-pdf.component';
import { SendPecComponent } from '@app/send-pec/send-pec/send-pec.component';
import { ProfiloPersonaleComponent } from './profilo-personale/profilo-personale.component';

const routes: Routes = [
  // Fallback when no prior route is matched
  { path: 'test/test-pdf', component: TestPdfComponent },
  { path: 'send-pec', component: SendPecComponent },
  {
    path: 'self-evaluation',
    loadChildren: () =>
      import('./widget-self-evaluation/widget-self-evaluation.module').then(m => m.WidgetSelfEvaluationModule)
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
