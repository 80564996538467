import { Component, OnInit } from '@angular/core';
import { TigranService } from '@app/tigran/tigran.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utilities } from '@app/utilities/Utilities';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';

@Component({
  selector: 'app-tigran',
  templateUrl: './tigran.component.html',
  styleUrls: ['./tigran.component.scss']
})
export class TigranComponent implements OnInit {
  vatNumberForm!: FormGroup;
  currentVatNumber: string;
  showWarningMessage = false;
  isLoading = false;
  morescore: string;
  probdef: string;

  constructor(
    private tigran: TigranService,
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService
  ) {
    this.createForm();
  }

  ngOnInit() {}

  createForm() {
    this.vatNumberForm = this.formBuilder.group({
      vatNumber: new FormControl('', Validators.required)
    });
    this.vatNumberForm.valueChanges.subscribe(data => {
      this.currentVatNumber = data.vatNumber;
      this.showWarningMessage =
        this.currentVatNumber.length !== 0 && !Utilities.isItalianVatNumber(this.currentVatNumber);
    });
  }

  searchWithVatNumber() {
    // retrive the uuid of user from piva
    let userRet = '';
    this.firebaseService.getUserByRoleAndStringField('', 'pIva', this.vatNumber.value).subscribe(data => {
      console.log('data1=>', data);
      if (data.length > 0) {
        userRet = data[0].id;
      }

      if (userRet === '') {
        this.firebaseService
          .getUserByRoleAndStringField('', 'investorPIva', this.vatNumber.value)
          .subscribe(dataInv => {
            console.log('dataInv=>', dataInv);
            if (dataInv.length > 0) {
              userRet = dataInv[0].id;
            }

            if (userRet === '') {
              // tslint:disable-next-line:max-line-length
              this.firebaseService
                .getUserByRoleAndStringField('', 'originatorPIva', this.vatNumber.value)
                .subscribe(dataOrig => {
                  console.log('dataOrig=>', dataOrig);
                  if (dataOrig.length > 0) {
                    userRet = dataOrig[0].id;
                  }

                  if (userRet === '') {
                    alert('Errore individuazione utente');
                  }
                });
            } else {
              this.saveTigranValue(userRet);
            }
          });
      } else {
        this.saveTigranValue(userRet);
      }
    });
  }

  saveTigranValue(uuid: string) {
    if (!this.vatNumberForm.valid) {
      this.vatNumberForm.get('vatNumber').markAsTouched();
    } else {
      // we use this vat number for test 09214970965
      this.isLoading = true;
      this.tigran.entireFlow(this.currentVatNumber).subscribe(data => {
        this.isLoading = false;
        this.morescore = data['more_score'];
        this.probdef = data['more_probability_of_default'];
        this.firebaseService.createLogTigran(
          this.morescore,
          this.probdef,
          this.currentVatNumber,
          data['subject'].name,
          JSON.stringify(data),
          uuid
        );
      });
    }
  }

  get vatNumber() {
    return this.vatNumberForm.get('vatNumber');
  }
}
