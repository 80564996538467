import { Component, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { SearchForSubjectResponse } from '@app/tigran/tigran';
import { Utilities } from '@app/utilities/Utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SmsService } from '@app/sms/sms.service';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { NgbdModalConfirmComponent } from '../wallet-details/request-detail-modal/request-detail-modal.component';
import { ShowMessageService } from '@app/core/show-message.service';
import { ActivatedRoute, Router } from '@angular/router';

import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { DocumentoKey } from '@app/models/document/DocumentoKey.model';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-lemonway',
  templateUrl: './lemonway.component.html',
  styleUrls: ['./lemonway.component.scss']
})
export class LemonwayComponent implements OnInit {
  vatNumberForm!: FormGroup;
  currentVatNumber: string;
  showWarningMessage = false;
  isLoading = false;
  morescore: string;
  probdef: string;
  listaGenerale: any[];
  listaDocumenti: any[];
  userSelected: string;
  documentType: number;
  fileToUpload: any;
  uploading: boolean;
  uploadPercentage: string;
  base64: any;
  privato: boolean;
  tipoDocumento = Utilities.tipoDocumento;
  statoDocumento = Utilities.statoDocumento;
  errore: boolean = false;

  documentoCedente: DocumentoKey;
  error: string;
  type: string;
  singolUserSelected: boolean = false;
  idLoggedUser: string;
  isLoggedName: string;

  listDocument: any;
  nessunaTrans: boolean;
  data;
  pdfTable: any;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private http: HttpClient,
    private sms: SmsService,
    private route: ActivatedRoute,
    private router: Router,
    private ls: LemonwayService,
    private _modalService: NgbModal,
    public showService: ShowMessageService,
    private storage: AngularFireStorage,
    private backendAuthService: BackendAuthService
  ) {
    this.type = this.route.snapshot.paramMap.get('type');
    if (this.type == 'utente') {
      this.singolUserSelected = true;
      if (this.firebaseService.loggedUser.lemonwayAccount == true) {
        if (this.firebaseService.loggedUser.statoLemonway == Utilities.statoLemon[3]) {
          this.isLoggedName = this.firebaseService.loggedUser.name;
          this.idLoggedUser = this.firebaseService.loggedUser.id;
        } else {
          this.idLoggedUser = '0';
          this.isLoggedName = "L'utente ha lemonway attivo e ha già caricato i documenti richiesti";
        }
      } else {
        this.idLoggedUser = '0';
        this.isLoggedName = "L'utente non ha ancora un account lemonway attivo";
      }
    }
    this.documentoCedente = new DocumentoKey();
    this.createForm();
    this.listaGenerale = [];
    this.userSelected = '0';
    this.documentType = 100;
    firebaseService.getUsersByRole(Utilities.userType[5]).subscribe(data => {
      for (let entry of data) {
        if (entry.lemonwayAccount == true && entry.statoLemonway == Utilities.statoLemon[3]) {
          this.listaGenerale.push(entry);
        }
      }
    });
    firebaseService.getUsersByRole(Utilities.userType[2]).subscribe(data => {
      for (let entry of data) {
        if (entry.lemonwayAccount == true && entry.statoLemonway == Utilities.statoLemon[3]) {
          this.listaGenerale.push(entry);
        }
      }
    });
    firebaseService.getUsersByRole(Utilities.userType[3]).subscribe(data => {
      for (let entry of data) {
        if (entry.lemonwayAccount == true && entry.statoLemonway == Utilities.statoLemon[3]) {
          this.listaGenerale.push(entry);
        }
      }
    });
    // console.log(this.listaGenerale);
  }
  ngOnInit() {
    var date = new Date();
    this.data = date.toLocaleDateString();
    this.listDocument = [];
    // console.log(this.firebase.loggedUser.id);
    this.loadContract();
  }

  loadContract() {
    this.listDocument = [];
    this.nessunaTrans = true;
    console.log('contratti');
    this.firebaseService.getDocument(this.userSelected).subscribe(data => {
      this.listDocument = [];
      data.forEach(element => {
        this.listDocument.push(element);
      });
      if (this.listDocument.length == 0) {
        this.nessunaTrans = true;
      } else {
        this.nessunaTrans = false;
      }
      // console.log(this.listDocument);
    });
  }

  getDownloadURL2(trans: string) {
    // console.log(trans);
    const ref = this.storage.ref(trans);
    // console.log(ref);

    ref.getDownloadURL().subscribe(data => {
      // console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', trans);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  open() {
    this.isLoading = true;
    if (this.userSelected == '0') {
      alert("seleziona l'utente");
      return;
    }
    if (this.documentType == 100) {
      alert('seleziona il tipo di documento');
      return;
    }

    //console.log(this.fileToUpload);
    const reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onload = () => {
      this.base64 = reader.result;
    };

    this.firebaseService.getUserByID(this.userSelected).then(doc => {
      //INTERNAL SERVER ERROR COMPRENDERE LA NATURA DELL ERRORE
      if (doc.exists) {
        const user = doc.data();
        // console.log(this.base64.split(',')[1]);
        if (user.isPrivato == true) {
          this.ls
            .uploadDoc(user.fiscalCode, this.documentType, this.fileToUpload.name, this.base64.split(',')[1])
            .subscribe(data => {
              //console.log(data.uploadDocument);
              if (data.uploadDocument) {
                this.showService.showDocCaricatoConSuccesso = true;
              } else {
                this.error = data.error.message;
                this.showService.showDocNonCaricato = true;
              }
              this.isLoading = false;
              this.aggiorna();
            });
        } else {
          if (user.roles[0] == 'company') {
            //console.log('qui');
            this.ls
              .uploadDoc(user.pIva, this.documentType, this.fileToUpload.name, this.base64.split(',')[1])
              .subscribe(data => {
                //console.log(data.uploadDocument);
                if (data.uploadDocument) {
                  this.showService.showDocCaricatoConSuccesso = true;
                } else {
                  this.error = data.error.message;
                  this.showService.showDocNonCaricato = true;
                }
                this.isLoading = false;
                this.aggiorna();
              });
          }
          if (user.roles[0] == 'investor') {
            this.ls
              .uploadDoc(user.investorPIva, this.documentType, this.fileToUpload.name, this.base64.split(',')[1])
              .subscribe(data => {
                //console.log(data);
                if (data.uploadDocument) {
                  this.showService.showDocCaricatoConSuccesso = true;
                } else {
                  this.error = data.error.message;
                  this.showService.showDocNonCaricato = true;
                }
                this.isLoading = false;
                this.aggiorna();
              });
          }
          if (user.roles[0] == 'originator') {
            this.ls
              .uploadDoc(user.originatorPIva, this.documentType, this.fileToUpload.name, this.base64.split(',')[1])
              .subscribe(data => {
                //console.log(data.uploadDocument);
                if (data.uploadDocument) {
                  this.showService.showDocCaricatoConSuccesso = true;
                } else {
                  this.error = data.error.message;
                  this.showService.showDocNonCaricato = true;
                }
                this.isLoading = false;
                this.aggiorna();
              });
          }
        }
        // user.statoLemonway = Utilities.statoLemon[1];
        // this.firebaseService.modUser(user);

        this.documentoCedente.nome_file = user.name + '';
        this.documentoCedente.descrizione = this.documentType + '';
        this.documentoCedente.dataCaricamento = new Date().toUTCString();
        this.documentoCedente.investorUUID = this.userSelected;
        this.documentoCedente.modified = false;

        console.log(this.documentoCedente);

        /*const today = new Date();
        const pipe = new DatePipe('en-US');
        this.contract.dataCaricamento = pipe.transform(today, 'yyyy-MM-dd');*/
        this.firebaseService.createDocumentiKey(this.documentoCedente).then(data => {
          // console.log('RESULT=>');
          console.log(data.id);
          if (data.id) {
            this.documentoCedente.id = data.id;
            this.documentoCedente.uid = data.id;
            const filePath = this.userSelected + '-' + data.id + '-DocumentKey.pdf';
            // console.log(filePath);
            this.documentoCedente.url = filePath;
            console.log(filePath);
            this.uploadFileContratto(filePath);
          }
        });
      }
    });
  }

  uploadFileContratto(filePath: any) {
    const task = this.storage.upload(filePath, this.fileToUpload);

    console.log(task);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
        })
      )
      .subscribe(data => {
        this.firebaseService.updateDocumentiKey(this.documentoCedente).then(() => {
          // this.router.navigate(['#']);
          // alert('file caricato correttamente');
        });
      });
  }

  onChange(event: any) {
    //console.log(event);

    this.loadContract();
    this.firebaseService.getUserByID(this.userSelected).then(data => {
      if (data.exists) {
        const us = data.data();
        //console.log(us);
        if (us.roles[0] == 'investor' && !us.isPrivato) {
          this.privato = false;
          this.ls.infoDocument(us.investorPIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'company' && !us.isPrivato) {
          this.privato = false;
          this.ls.infoDocument(us.pIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'investor' && us.isPrivato) {
          this.privato = true;
          this.ls.infoDocument(us.fiscalCode).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'company' && us.isPrivato) {
          this.privato = true;
          this.ls.infoDocument(us.fiscalCode).subscribe(data => {
            //(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'originator') {
          this.privato = true;
          this.ls.infoDocument(us.originatorPIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
      }
    });
  }

  aggiorna() {
    this.firebaseService.getUserByID(this.userSelected).then(data => {
      if (data.exists) {
        const us = data.data();
        //console.log(us);
        if (us.roles[0] == 'investor' && !us.isPrivato) {
          this.privato = false;
          this.ls.infoDocument(us.investorPIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'company' && !us.isPrivato) {
          this.privato = false;
          this.ls.infoDocument(us.pIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'investor' && us.isPrivato) {
          this.privato = true;
          this.ls.infoDocument(us.fiscalCode).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'company' && us.isPrivato) {
          this.privato = true;
          this.ls.infoDocument(us.fiscalCode).subscribe(data => {
            // console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'originator') {
          this.privato = true;
          this.ls.infoDocument(us.originatorPIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
      }
    });
  }
  onChange2(event: any) {
    // console.log(event);
  }

  createForm() {
    this.vatNumberForm = this.formBuilder.group({
      vatNumber: new FormControl('', Validators.required),
      user: new FormControl('')
    });
  }
  searchWithVatNumber() {
    /*
    this.http.get<any>(`http://incassaora-laravel.test/api/p2p`).subscribe(data => {
      //TODO finire il test di Lemonway con le informazioni di interesse andare ad insereire...
      console.log(data);
    });
    */
    /*
    this.sms.sendSms("3482560482").subscribe((data) => {
      console.log(data);
      var otp=data.otp;
      this.firebaseService.createSmsLog(otp);
    });
    */
    this.firebaseService.getLog('sqS4r').subscribe(data => {
      // console.log(data[0]);
    });

    const comment = '';
    this.ls.transaction(1500, '03296450780', '123456', comment).subscribe(data => {
      // console.log(data);
    });
  }

  get vatNumber() {
    return this.vatNumberForm.get('vatNumber');
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];
  }
}

const MODALS: { [name: string]: Type<any> } = {
  focusFirst: NgbdModalConfirmComponent
};
