import { Log } from '@app/models/Log.model';

export class LogTigran extends Log {
  pIva: string;
  companyName: string;
  moreScore: string;
  probDefault: string;
  return: string;
  timestamp: firebase.firestore.FieldValue;

  constructor() {
    super();
  }
}
