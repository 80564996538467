import { ClipboardModule } from 'ngx-clipboard';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { WalletFormComponent } from './wallet-form/wallet-form.component';
import { WalletListComponent } from './wallet-list/wallet-list.component';
import { StatsComponent } from './stats/stats.component';
import { NewBankComponent } from './new-originator/new-bank.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PointReplacerPipe } from '@app/pipe/point-replacer.pipe';
import { NgbdModalConfirmCloseComponent, WalletDetailsComponent } from './wallet-details/wallet-details.component';
import { ChartsModule } from 'ng2-charts';
import { NgbdSortableHeader } from '@app/models/utils/sortable.directive';
import { UsersCredentialComponent } from './users-credential/users-credential.component';
import { RatingModalComponent } from './wallet-details/rating-modal/rating-modal.component';
import { WalletEditModalComponent } from './wallet-details/wallet-edit-modal/wallet-edit-modal.component';
import {
  RequestDetailModalComponent,
  NgbdModalConfirmComponent
} from './wallet-details/request-detail-modal/request-detail-modal.component';
import { InvoiceModalComponent } from './wallet-details/invoice-modal/invoice-modal.component';
import { ChartModalComponent } from './wallet-details/chart-modal/chart-modal.component';
import { roboModalComponent } from './wallet-details/robo-modal/robo-modal.component';
import { NewPartnerComponent } from './new-partner/new-partner.component';
import { NewInvestorComponent } from './new-investor/new-investor.component';
import { UsuryRatesComponent } from './usury-rates/usury-rates.component';
import { DeleteUsuryRateModalComponent } from '@app/home/usury-rates/delete-usury-rate/delete-usury-rate-modal.component';
import { CreateUsuryRateComponent } from './usury-rates/create-usury-rate/create-usury-rate.component';
import { TigranComponent } from './tigran/tigran.component';
import { NewServicerComponent } from './new-servicer/new-servicer.component';
import { ConfirmationModalComponent } from '@app/tigran/confirmation-modal/confirmation-modal.component';
import { PendingRequestListComponent } from './pending-request-list/pending-request-list.component';
import { ConfirmRolesModalComponent } from './confirm-roles-modal/confirm-roles-modal.component';
import { WalletListItemComponent } from './wallet-list/wallet-list-item/wallet-list-item.component';
import { WalletDetailsUpperInfoComponent } from './wallet-details/wallet-details-upper-info/wallet-details-upper-info.component';
import { LemonwayComponent } from './lemonway/lemonway.component';
import { ModalAddOriginatorComponent } from './wallet-details/modal-add-originator/modal-add-originator/modal-add-originator.component';
import { NewcompanyComponent } from './new-company/newcompany/newcompany.component';
import { ReportComponentComponent } from './report-component/report-component.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { VirtualibanUserComponent } from './virtualiban-user/virtualiban-user.component';
import { TigranReportComponent } from './tigran-report/tigran-report.component';
import { ContractComponent } from './document/contract/contract.component';
import { DocumentForInvestorComponent } from './document-for-investor/document-for-investor.component';
import { ContrattoInvestitoreComponent } from '@app/home-investor/contratto-investitore/contratto-investitore.component';
import { GeneraContrattoComponent } from './genera-contratto/genera-contratto.component';
import { ModalListaCedentiComponent } from './wallet-details/modal-lista-cedenti/modal-lista-cedenti.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ServicerSPVModule } from './servicer-spv/servicer-spv.module';
import { ConfirmationModalGenericComponent } from '@app/confirmation-modal-generic/confirmation-modal-generic.component';
import { CompanyAllowSPVComponent } from '@app/company-allow-spv/company-allow-spv.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    TranslateModule,
    CoreModule,
    ClipboardModule,
    SharedModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    ChartsModule,
    HomeRoutingModule,
    NgxChartsModule,
    NgxSliderModule,
    ServicerSPVModule
  ],
  declarations: [
    HomeComponent,
    WalletFormComponent,
    WalletListComponent,
    StatsComponent,
    NewBankComponent,
    NewInvestorComponent,
    NewPartnerComponent,
    PointReplacerPipe,
    NgbdSortableHeader,
    WalletDetailsComponent,
    UsersCredentialComponent,
    RatingModalComponent,
    WalletEditModalComponent,
    RequestDetailModalComponent,
    InvoiceModalComponent,
    ChartModalComponent,
    roboModalComponent,
    NgbdModalConfirmComponent,
    NgbdModalConfirmCloseComponent,
    UsuryRatesComponent,
    CreateUsuryRateComponent,
    TigranComponent,
    NewServicerComponent,
    PendingRequestListComponent,
    ConfirmRolesModalComponent,
    WalletListItemComponent,
    WalletDetailsUpperInfoComponent,
    LemonwayComponent,
    ModalAddOriginatorComponent,
    NewcompanyComponent,
    ReportComponentComponent,
    VirtualibanUserComponent,
    TigranReportComponent,
    ContractComponent,
    DocumentForInvestorComponent,
    GeneraContrattoComponent,
    ModalListaCedentiComponent
  ],
  providers: [DecimalPipe],
  entryComponents: [
    RatingModalComponent,
    WalletEditModalComponent,
    RequestDetailModalComponent,
    InvoiceModalComponent,
    ChartModalComponent,
    roboModalComponent,
    NgbdModalConfirmComponent,
    NgbdModalConfirmCloseComponent,
    DeleteUsuryRateModalComponent,
    ConfirmationModalComponent,
    ConfirmRolesModalComponent,
    ModalAddOriginatorComponent,
    ModalListaCedentiComponent,
    ContrattoInvestitoreComponent,
    GeneraContrattoComponent,
    ConfirmationModalGenericComponent,
    CompanyAllowSPVComponent
  ],
  exports: [HomeComponent, WalletListComponent]
})
export class HomeModule {}
