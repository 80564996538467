import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { LemonWayResponseComponent } from './lemon-way-response.component';

const routes: Routes = [
  {
    path: 'lemonway_response/:response',
    component: LemonWayResponseComponent,
    data: { title: extract('lemon_response') }
  },
  { path: 'lemonway_response', component: LemonWayResponseComponent, data: { title: extract('lemon_response') } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LemonWayRoutingModule {}
