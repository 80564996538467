import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-widget-report',
  templateUrl: './widget-report.component.html',
  styleUrls: ['./widget-report.component.scss']
})
export class WidgetReportComponent implements OnInit {
  guadagno: any;
  percentualeRendimento: any;

  private httpOptions;
  private progressivoInvestimento: any;
  private percentualePartecipazioneMedia: any;
  private numeroOperazioni: any;
  private cedentiUnici: any;

  private balance: any = 0;
  private nessunConto: boolean;

  constructor(
    public firebase: FirebaseTranchedService,
    private http: HttpClient,
    private ls: LemonwayService,
    private backendAuthService: BackendAuthService
  ) {
    this.getRendimento();
    this.getPercRendimento();
    this.getPercentualePartecipazioneMedia();
    this.getNumeroOperazioni();
    this.getCedentiUnici();
    this.getSaldo();
  }

  ngOnInit() {}

  getRendimento() {
    if (this.firebase.loggedUser.roles == 'investor') {
      const body = {
        id: this.firebase.loggedUser.id
      };

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      this.http.post<any>(`${environment.laravelServer}/getRendimento`, body, httpOptions).subscribe(respInv => {
        if (respInv) {
          this.guadagno = respInv.dati.rendimento;
        }
      });
    }
  }

  getPercRendimento() {
    if (this.firebase.loggedUser.roles == 'investor') {
      const body = {
        id: this.firebase.loggedUser.id
      };

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      this.http
        .post<any>(`${environment.laravelServer}/getPercentualeRendimento`, body, httpOptions)
        .subscribe(resp => {
          this.percentualeRendimento = resp.dati.percentuale / 100;
          this.progressivoInvestimento = resp.dati.investimento;
        });
    }
  }
  getPercentualePartecipazioneMedia() {
    if (this.firebase.loggedUser.roles == 'investor') {
      const body = {
        id: this.firebase.loggedUser.id
      };

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      this.http
        .post<any>(`${environment.laravelServer}/getPercentualePartecipazioneMedia`, body, httpOptions)
        .subscribe(resp => {
          this.percentualePartecipazioneMedia = resp.dati.partecipazioneMedia / 100;
        });
    }
  }

  getNumeroOperazioni() {
    if (this.firebase.loggedUser.roles == 'investor') {
      const body = {
        id: this.firebase.loggedUser.id
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      this.http.post<any>(`${environment.laravelServer}/getNumeroOperazioni`, body, httpOptions).subscribe(resp => {
        this.numeroOperazioni = resp.dati.operazioni;
      });
    }
  }

  getCedentiUnici() {
    if (this.firebase.loggedUser.roles == 'investor') {
      const body = {
        id: this.firebase.loggedUser.id
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      this.http.post<any>(`${environment.laravelServer}/getCedentiUnici`, body, httpOptions).subscribe(resp => {
        this.cedentiUnici = resp.dati.cedentiUnici;
      });
    }
  }

  getSaldo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.ls.infoAccount(this.firebase.loggedUser.investorPIva).subscribe(data => {
      console.log('profile data=>', data);
      this.balance = data.account.balance / 100;
      if (this.balance == null) {
        this.nessunConto = true;
      }
    });
  }
}
