export class welcomeMail {
  static getHtmlMail(mail: any) {
    return (
      `
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office"><head><meta http-equiv="X-UA-Compatible" content="IE=edge" /><meta name="viewport" content="width=device-width, initial-scale=1" /><meta http-equiv="Content-Type" content="text/html; charset=utf-8" /><meta name="x-apple-disable-message-reformatting" /><meta name="apple-mobile-web-app-capable" content="yes" /><meta name="apple-mobile-web-app-status-bar-style" content="black" /><meta name="format-detection" content="telephone=no" /><title></title><style type="text/css">
        /* Resets */
        .ReadMsgBody { width: 100%; background-color: #ebebeb;}
        .ExternalClass {width: 100%; background-color: #ebebeb;}
        .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {line-height:100%;}
        a[x-apple-data-detectors]{
        color:inherit !important;
        text-decoration:none !important;
        font-size:inherit !important;
        font-family:inherit !important;
        font-weight:inherit !important;
        line-height:inherit !important;
        }
        body {-webkit-text-size-adjust:none; -ms-text-size-adjust:none;}
        body {margin:0; padding:0;}
        .yshortcuts a {border-bottom: none !important;}
        .rnb-del-min-width{ min-width: 0 !important; }
        /* Add new outlook css start */
        .templateContainer{
        max-width:590px !important;
        width:auto !important;
        }
        /* Add new outlook css end */
        /* Image width by default for 3 columns */
        img[class="rnb-col-3-img"] {
        max-width:170px;
        }
        /* Image width by default for 2 columns */
        img[class="rnb-col-2-img"] {
        max-width:264px;
        }
        /* Image width by default for 2 columns aside small size */
        img[class="rnb-col-2-img-side-xs"] {
        max-width:180px;
        }
        /* Image width by default for 2 columns aside big size */
        img[class="rnb-col-2-img-side-xl"] {
        max-width:350px;
        }
        /* Image width by default for 1 column */
        img[class="rnb-col-1-img"] {
        max-width:550px;
        }
        /* Image width by default for header */
        img[class="rnb-header-img"] {
        max-width:590px;
        }
        /* Ckeditor line-height spacing */
        .rnb-force-col p, ul, ol{margin:0px!important;}
        .rnb-del-min-width p, ul, ol{margin:0px!important;}
        /* tmpl-2 preview */
        .rnb-tmpl-width{ width:100%!important;}
        /* tmpl-11 preview */
        .rnb-social-width{padding-right:15px!important;}
        /* tmpl-11 preview */
        .rnb-social-align{float:right!important;}
        /* Ul Li outlook extra spacing fix */
        li{mso-margin-top-alt: 0; mso-margin-bottom-alt: 0;}
        /* Outlook fix */
        table {mso-table-lspace:0pt; mso-table-rspace:0pt;}
        /* Outlook fix */
        table, tr, td {border-collapse: collapse;}
        /* Outlook fix */
        p,a,li,blockquote {mso-line-height-rule:exactly;}
        /* Outlook fix */
        .msib-right-img { mso-padding-alt: 0 !important;}
        @media only screen and (min-width:590px){
        /* mac fix width */
        .templateContainer{width:590px !important;}
        }
        @media screen and (max-width: 360px){
        /* yahoo app fix width "tmpl-2 tmpl-10 tmpl-13" in android devices */
        .rnb-yahoo-width{ width:360px !important;}
        }
        @media screen and (max-width: 380px){
        /* fix width and font size "tmpl-4 tmpl-6" in mobile preview */
        .element-img-text{ font-size:24px !important;}
        .element-img-text2{ width:230px !important;}
        .content-img-text-tmpl-6{ font-size:24px !important;}
        .content-img-text2-tmpl-6{ width:220px !important;}
        }
        @media screen and (max-width: 480px) {
        td[class="rnb-container-padding"] {
        padding-left: 10px !important;
        padding-right: 10px !important;
        }
        /* force container nav to (horizontal) blocks */
        td.rnb-force-nav {
        display: inherit;
        }
        /* fix text alignment "tmpl-11" in mobile preview */
        .rnb-social-text-left {
        width: 100%;
        text-align: center;
        margin-bottom: 15px;
        }
        .rnb-social-text-right {
        width: 100%;
        text-align: center;
        }
        }
        @media only screen and (max-width: 600px) {
        /* center the address &amp; social icons */
        .rnb-text-center {text-align:center !important;}
        /* force container columns to (horizontal) blocks */
        th.rnb-force-col {
        display: block;
        padding-right: 0 !important;
        padding-left: 0 !important;
        width:100%;
        }
        table.rnb-container {
        width: 100% !important;
        }
        table.rnb-btn-col-content {
        width: 100% !important;
        }
        table.rnb-col-3 {
        /* unset table align="left/right" */
        float: none !important;
        width: 100% !important;
        /* change left/right padding and margins to top/bottom ones */
        margin-bottom: 10px;
        padding-bottom: 10px;
        /*border-bottom: 1px solid #eee;*/
        }
        table.rnb-last-col-3 {
        /* unset table align="left/right" */
        float: none !important;
        width: 100% !important;
        }
        table.rnb-col-2 {
        /* unset table align="left/right" */
        float: none !important;
        width: 100% !important;
        /* change left/right padding and margins to top/bottom ones */
        margin-bottom: 10px;
        padding-bottom: 10px;
        /*border-bottom: 1px solid #eee;*/
        }
        table.rnb-col-2-noborder-onright {
        /* unset table align="left/right" */
        float: none !important;
        width: 100% !important;
        /* change left/right padding and margins to top/bottom ones */
        margin-bottom: 10px;
        padding-bottom: 10px;
        }
        table.rnb-col-2-noborder-onleft {
        /* unset table align="left/right" */
        float: none !important;
        width: 100% !important;
        /* change left/right padding and margins to top/bottom ones */
        margin-top: 10px;
        padding-top: 10px;
        }
        table.rnb-last-col-2 {
        /* unset table align="left/right" */
        float: none !important;
        width: 100% !important;
        }
        table.rnb-col-1 {
        /* unset table align="left/right" */
        float: none !important;
        width: 100% !important;
        }
        img.rnb-col-3-img {
        /**max-width:none !important;**/
        width:100% !important;
        }
        img.rnb-col-2-img {
        /**max-width:none !important;**/
        width:100% !important;
        }
        img.rnb-col-2-img-side-xs {
        /**max-width:none !important;**/
        width:100% !important;
        }
        img.rnb-col-2-img-side-xl {
        /**max-width:none !important;**/
        width:100% !important;
        }
        img.rnb-col-1-img {
        /**max-width:none !important;**/
        width:100% !important;
        }
        img.rnb-header-img {
        /**max-width:none !important;**/
        width:100% !important;
        margin:0 auto;
        }
        img.rnb-logo-img {
        /**max-width:none !important;**/
        width:100% !important;
        }
        td.rnb-mbl-float-none {
        float:inherit !important;
        }
        .img-block-center{text-align:center !important;}
        .logo-img-center
        {
        float:inherit !important;
        }
        /* tmpl-11 preview */
        .rnb-social-align{margin:0 auto !important; float:inherit !important;}
        /* tmpl-11 preview */
        .rnb-social-center{display:inline-block;}
        /* tmpl-11 preview */
        .social-text-spacing{margin-bottom:0px !important; padding-bottom:0px !important;}
        /* tmpl-11 preview */
        .social-text-spacing2{padding-top:15px !important;}
        /* UL bullet fixed in outlook */
        ul {mso-special-format:bullet;}
        }</style><!--[if gte mso 11]><style type="text/css">table{border-spacing: 0; }table td {border-collapse: separate;}</style><![endif]--><!--[if !mso]><!--><style type="text/css">table{border-spacing: 0;} table td {border-collapse: collapse;}</style> <!--<![endif]--><!--[if gte mso 15]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]--><!--[if gte mso 9]><xml><o:OfficeDocumentSettings><o:AllowPNG/><o:PixelsPerInch>96</o:PixelsPerInch></o:OfficeDocumentSettings></xml><![endif]--></head><body>
        <table class="main-template" style="background-color: rgb(249, 250, 252);" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#f9fafc" align="center">
        <tbody><tr>
        <td valign="top" align="center">
        <!--[if gte mso 9]>
                                <table align="center" border="0" cellspacing="0" cellpadding="0" width="590" style="width:590px;">
                                <tr>
                                <td align="center" valign="top" width="590" style="width:590px;">
                                <![endif]-->
        <table class="templateContainer" style="max-width:590px!important; width: 590px;" width="590" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td valign="top" align="center">
        <table class="rnb-del-min-width" style="min-width:590px;" name="Layout_0" id="Layout_0" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width:590px;" valign="top" align="center">
         <a href="#" name="Layout_0"></a>
        <table width="100%" height="38" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td valign="top" height="38">
        <img style="display:block; max-height:38px; max-width:20px;" alt="" src="https://img.mailinblue.com/new_images/rnb/rnb_space.gif" width="20" height="38">
        </td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table>
        </td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(255, 255, 255); border-radius: 0px;">
        <!--[if mso]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                        <tr>
                        <![endif]-->
        <!--[if mso]>
                        <td valign="top" width="590" style="width:590px;">
                        <![endif]-->
        <table class="rnb-del-min-width" style="min-width:590px;" name="Layout_1" id="Layout_1" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width:590px;" valign="top" align="center">
        <a href="#" name="Layout_1"></a>
        <table class="rnb-container" style="background-color: rgb(255, 255, 255); border-bottom: 5px solid rgb(247, 239, 89); border-radius: 0px; padding-left: 20px; padding-right: 20px; border-collapse: separate;" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff">
        <tbody><tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        <tr>
        <td class="rnb-container-padding" valign="top" align="left">
        <table width="100%" cellspacing="0" cellpadding="0" border="0" align="center">
        <tbody><tr>
        <td valign="top" align="center">
        <table class="logo-img-center" cellspacing="0" cellpadding="0" border="0" align="center">
        <tbody><tr>
        <td style="line-height: 1px;" valign="middle" align="center">
        <div style="border-top:0px None #000;border-right:0px None #000;border-bottom:0px None #000;border-left:0px None #000;display:inline-block; " cellspacing="0" cellpadding="0" border="0"><div><img alt="Giovanni Visone" style="float: left;max-width:nullpx;display:block;" class="rnb-logo-img" src="https://img.mailinblue.com/3468065/images/rnb/original/6023ab536c734173cc0b9183.png" width="null" vspace="0" hspace="0" border="0"></div></div></td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table></td>
        </tr>
        <tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table>
        <!--[if mso]>
                        </td>
                        <![endif]-->
        <!--[if mso]>
                        </tr>
                        </table>
                        <![endif]-->
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
         <table class="rnb-del-min-width" style="min-width:590px;" name="Layout_6733" id="Layout_6733" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width:590px;" valign="top" align="center">
        <a href="#" name="Layout_6733"></a>
        <table width="100%" height="38" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td valign="top" height="38">
        <img style="display:block; max-height:38px; max-width:20px;" alt="" src="https://img.mailinblue.com/new_images/rnb/rnb_space.gif" width="20" height="38">
        </td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table>
        </td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(255, 255, 255); border-radius: 0px;">
        <!--[if mso]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                        <tr>
                        <![endif]-->
        <!--[if mso]>
                        <td valign="top" width="590" style="width:590px;">
                        <![endif]-->
        <table name="Layout_2" id="Layout_2" width="100%" cellspacing="0" cellpadding="0" border="0"><tbody><tr>
        <td valign="top" align="center"><a href="#" name="Layout_2"></a>
        <table class="rnb-container" style="height: 0px; background-color: rgb(255, 255, 255); border-radius: 0px; border-collapse: separate; padding-left: 20px; padding-right: 20px;" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff"><tbody><tr>
        <td class="rnb-container-padding" style="font-size: px;font-family: ; color: ;">
        <table class="rnb-columns-container" style="margin:auto;" cellspacing="0" cellpadding="0" border="0" align="center">
        <tbody><tr>
        <th class="rnb-force-col" style="text-align: center; font-weight: normal" align="center">
        <table class="rnb-col-1" cellspacing="0" cellpadding="0" border="0" align="center">
        <tbody><tr>
        <td height="10"></td>
        </tr>
        <tr>
        <td style="font-family:Arial,Helvetica,sans-serif; color:#3c4858; text-align:center;">
        <span style="color:#3c4858;"><strong><span style="font-size:24px;">Benvenuto su Yellow Finance!</span></strong></span>
        </td>
        </tr>
        <tr>
        <td height="10"></td>
        </tr>
        </tbody></table>
        </th></tr>
        </tbody></table></td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table><!--[if mso]>
                        </td>
                        <![endif]-->
        <!--[if mso]>
                        </tr>
                        </table>
                        <![endif]-->
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(255, 255, 255); border-radius: 0px;">
        <!--[if mso]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                        <tr>
                        <![endif]-->
        <!--[if mso]>
                        <td valign="top" width="590" style="width:590px;">
                        <![endif]-->
        <table class="rnb-del-min-width" style="min-width:100%;" name="Layout_13" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" valign="top" align="center">
        <a href="#" name="Layout_13"></a>
        <table class="rnb-container" style="background-color: rgb(255, 255, 255); padding-left: 20px; padding-right: 20px; border-collapse: separate; border-radius: 0px; border-bottom: 0px none rgb(200, 200, 200);" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff">
        <tbody><tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        <tr>
        <td class="rnb-container-padding" valign="top" align="left">
        <table class="rnb-columns-container" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <th class="rnb-force-col" style="text-align: left; font-weight: normal; padding-right: 0px;" valign="top">
        <table valign="top" class="rnb-col-1" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
        <tbody><tr>
        <td style="font-size:14px; font-family:Arial,Helvetica,sans-serif, sans-serif; color:#3c4858;"><div>Ti diamo il benvenuto,</div>
        </td>
        </tr>
        </tbody></table>
        </th></tr>
        </tbody></table></td>
        </tr>
        <tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table><!--[if mso]>
                        </td>
                        <![endif]-->
        <!--[if mso]>
                        </tr>
                        </table>
                        <![endif]-->
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(255, 255, 255); border-radius: 0px;">
        <!--[if mso]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                        <tr>
                        <![endif]-->
        <!--[if mso]>
                        <td valign="top" width="590" style="width:590px;">
                        <![endif]-->
        <table class="rnb-del-min-width" style="min-width:100%;" name="Layout_14" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" valign="top" align="center">
        <a href="#" name="Layout_14"></a>
        <table class="rnb-container" style="background-color: rgb(255, 255, 255); padding-left: 20px; padding-right: 20px; border-collapse: separate; border-radius: 0px; border-bottom: 0px none rgb(200, 200, 200);" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff">
        <tbody><tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        <tr>
        <td class="rnb-container-padding" valign="top" align="left">
        <table class="rnb-columns-container" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <th class="rnb-force-col" style="text-align: left; font-weight: normal; padding-right: 0px;" valign="top">
        <table valign="top" class="rnb-col-1" width="100%" cellspacing="0" cellpadding="0" border="0" align="left">
        <tbody><tr>
        <td style="font-size:14px; font-family:Arial,Helvetica,sans-serif, sans-serif; color:#3c4858;"><div><br>
        ti sei iscritto su IncassaOra con la mail: ` +
      mail +
      `.<br>
        <br><br>
        Per accedere alla piattaforma IncassaOra®, Di seguito, il link di accesso dedicato:<br>
        &nbsp;</div>
        </td>
        </tr>
        </tbody></table>
        </th></tr>
        </tbody></table></td>
        </tr>
        <tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table><!--[if mso]>
                        </td>
                        <![endif]-->
        <!--[if mso]>
                        </tr>
                        </table>
                        <![endif]-->
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(240, 240, 96); border-radius: 0px;">
        <!--[if mso]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                        <tr>
                        <![endif]-->
        <!--[if mso]>
                        <td valign="top" width="590" style="width:590px;">
                        <![endif]-->
        <table class="rnb-del-min-width" style="min-width:590px;" name="Layout_15" id="Layout_15" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width:590px;" valign="top" align="center">
        <a href="#" name="Layout_15"></a>
        <table class="mso-button-block rnb-container" style="background-color: rgb(240, 240, 96); border-radius: 0px; padding-left: 20px; padding-right: 20px; border-collapse: separate;" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        <tr>
        <td class="rnb-container-padding" valign="top" align="left">
        <table class="rnb-columns-container" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <th class="rnb-force-col" valign="top">
        <table valign="top" class="rnb-col-1" width="550" cellspacing="0" cellpadding="0" border="0" align="center">
        <tbody><tr>
        <td valign="top">
        <table class="rnb-btn-col-content" style="margin:auto; border-collapse: separate;" cellspacing="0" cellpadding="0" border="0" align="center">
        <tbody><tr>
        <td style="font-size:18px; font-family:Arial,Helvetica,sans-serif; color:#ffffff; font-weight:normal; padding-left:20px; padding-right:20px; vertical-align: middle; background-color:#0092ff;border-radius:4px;border-top:0px None #000;border-right:0px None #000;border-bottom:0px None #000;border-left:0px None #000;" width="auto" valign="middle" height="40" bgcolor="#0092ff" align="center">
        <span style="color:#ffffff; font-weight:normal;">
        <a style="text-decoration:none; color:#ffffff; font-weight:normal;" target="_blank" href="https://www.incassaora.it/">Clicca Qui</a>
        </span>
        </td>
        </tr></tbody></table>
        </td>
        </tr>
        </tbody></table>
        </th>
        </tr>
        </tbody></table></td>
        </tr>
        <tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table>
        <!--[if mso]>
                        </td>
                        <![endif]-->
        <!--[if mso]>
                        </tr>
                        </table>
                        <![endif]-->
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
        <table class="rnb-del-min-width" style="min-width:590px;" name="Layout_" id="Layout_" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width:590px;" valign="top" align="center">
        <a href="#" name="Layout_"></a>
        <table width="100%" height="30" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td valign="top" height="30">
        <img style="display:block; max-height:30px; max-width:20px;" alt="" src="https://img.mailinblue.com/new_images/rnb/rnb_space.gif" width="20" height="30">
        </td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table>
        </td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(255, 255, 255); border-radius: 0px;">
        <!--[if mso]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                        <tr>
                        <![endif]-->

        <!--[if mso]>
                        <td valign="top" width="590" style="width:590px;">
                        <![endif]-->
        <table class="rnb-del-min-width" style="min-width:100%;" name="Layout_16" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" valign="top" align="center">
        <a href="#" name="Layout_16"></a>
        <table class="rnb-container" style="background-color: rgb(255, 255, 255); padding-left: 20px; padding-right: 20px; border-collapse: separate; border-radius: 0px; border-bottom: 0px none rgb(200, 200, 200);" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff">
        <tbody><tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        <tr>
        <td class="rnb-container-padding" valign="top" align="left">
        <table class="rnb-columns-container" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <th class="rnb-force-col" style="text-align: left; font-weight: normal; padding-right: 0px;" valign="top">
        </th></tr>
        </tbody></table></td>
        </tr>
        <tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table><!--[if mso]>
                        </td>
                        <![endif]-->
        <!--[if mso]>
                        </tr>
                        </table>
                        <![endif]-->
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(255, 255, 255);">
        <!--[if mso]>
                        <table align="center" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                        <tr>
                        <![endif]-->
        <!--[if mso]>
                        <td valign="top" width="590" style="width:590px;">
                        <![endif]-->
        <table class="rnb-del-min-width" style="min-width:100%; -webkit-backface-visibility: hidden; line-height: 10px;" name="Layout_19" id="Layout_19" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width: 590px;" valign="top" align="center">
        <a href="#" name="Layout_19"></a>
        <table class="rnb-container" style="background-color: rgb(255, 255, 255);" width="100%" cellspacing="0" cellpadding="0" border="0" bgcolor="#ffffff" align="center">
        <tbody><tr>
        <td valign="top" align="center">
        <table cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td>
        <div style="border-radius:5px; width:250;;max-width:250px !important;border-top:0px None #000;border-right:0px None #000;border-bottom:0px None #000;border-left:0px None #000;border-collapse: separate;border-radius: 0px;">
        <div><img ng-if="col.img.source != 'url'" class="rnb-header-img" alt="" style="display:block; float:left; border-radius: 5px; " src="https://img.mailinblue.com/3468065/images/rnb/original/6023aa8c5dc97e729e21886a.png" width="250" vspace="0" hspace="0" border="0"></div><div style="clear:both;"></div>
        </div></td>
        </tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table>
        </td>
        </tr></tbody></table>
        <!--[if mso]>
                        </td>
                        <![endif]-->
        <!--[if mso]>
                        </tr>
                        </table>
                        <![endif]-->
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(249, 250, 252);">
        <table class="rnb-del-min-width rnb-tmpl-width" style="min-width:590px;" name="Layout_18" id="Layout_18" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width: 590px; background-color: rgb(249, 250, 252);" valign="top" bgcolor="#f9fafc" align="center">
        <a href="#" name="Layout_18"></a>
        <table class="rnb-container" width="590" cellspacing="0" cellpadding="0" border="0" align="center">
        <tbody><tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        <tr>
        <td class="rnb-container-padding" style="font-size: 14px; font-family: Arial,Helvetica,sans-serif; color: #888888;" valign="top" align="left">
        <table class="rnb-columns-container" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <th class="rnb-force-col" style="padding-right:20px; padding-left:20px; mso-padding-alt: 0 0 0 20px; font-weight: normal;" valign="top">
        <table valign="top" class="rnb-col-2 rnb-social-text-left" style="border-bottom:0;" width="264" cellspacing="0" cellpadding="0" border="0" align="left">
        <tbody><tr>
        <td valign="top">
        <table class="rnb-btn-col-content" cellspacing="0" cellpadding="0" border="0" align="left">
        <tbody><tr>
        <td style="font-size:14px; font-family:Arial,Helvetica,sans-serif; color:#888888; line-height: 16px" class="rnb-text-center" valign="middle" align="left">
        <div><div>
        <div>Campania NewSteel presso Città della Scienza<br>
        Via Coroglio, 57/104, 80124 Napoli NA</div>
        <div>Tel: +39 081 7352503<br>
        Email: desk@fx12.it</div>
        </div>
        </div>
        </td></tr>
        </tbody></table>
        </td>
        </tr>
        </tbody></table>
        </th><th ng-if="item.text.align=='left'" class="rnb-force-col rnb-social-width" style="mso-padding-alt: 0 20px 0 0; padding-right: 15px;" valign="top">
        <table valign="top" class="rnb-last-col-2" width="246" cellspacing="0" cellpadding="0" border="0" align="right">
        <tbody><tr>
        <td valign="top">
        <table class="rnb-social-align" style="float: right;" cellspacing="0" cellpadding="0" border="0" align="right">
        <tbody><tr>
        <td class="rnb-text-center" ng-init="width=setSocialIconsBlockWidth(item)" width="85" valign="middle" align="right">
        <div class="rnb-social-center">
        <table style="float:left; display: inline-block" cellspacing="0" cellpadding="0" border="0" align="left">
        <tbody><tr>
        <td style="padding:0px 5px 5px 0px; mso-padding-alt: 0px 2px 5px 0px;" align="left">
        <span style="color:#ffffff; font-weight:normal;">
        <a target="_blank" href="https://www.facebook.com/FX12Fintechperilmezzogiorno"><img alt="Facebook" style="vertical-align:top;" target="_blank" src="https://img.mailinblue.com/new_images/rnb/theme1/rnb_ico_fb.png" vspace="0" hspace="0" border="0"></a></span>
        </td></tr></tbody></table>
        </div><div class="rnb-social-center">
        <table style="float:left; display: inline-block" cellspacing="0" cellpadding="0" border="0" align="left">
        <tbody><tr>
        <td style="padding:0px 5px 5px 0px; mso-padding-alt: 0px 2px 5px 0px;" align="left">
        <span style="color:#ffffff; font-weight:normal;">
        <a target="_blank" href="https://www.linkedin.com/company/69746193/admin/"><img alt="LinkedIn" style="vertical-align:top;" target="_blank" src="https://img.mailinblue.com/new_images/rnb/theme1/rnb_ico_in.png" vspace="0" hspace="0" border="0"></a></span>
        </td></tr></tbody></table>
        </div></td>
        </tr>
        </tbody></table>
        </td>
        </tr>
         </tbody></table>
        </th></tr>
        </tbody></table></td>
        </tr>
        <tr>
        <td style="font-size:1px; line-height:20px; mso-hide: all;" height="20">&nbsp;</td>
        </tr>
        </tbody></table>
        </td>
        </tr></tbody></table>
        </div></td>
        </tr><tr>
        <td valign="top" align="center">
        <div style="background-color: rgb(249, 250, 252);">
        <table class="rnb-del-min-width rnb-tmpl-width" style="min-width:590px;" name="Layout_6" id="Layout_6" width="100%" cellspacing="0" cellpadding="0" border="0">
        <tbody><tr>
        <td class="rnb-del-min-width" style="min-width:590px; background-color: #f9fafc; text-align: center;" valign="top" bgcolor="#f9fafc" align="center">
        <a href="#" name="Layout_6"></a>
        <table class="rnb-container" style="padding-right: 20px; padding-left: 20px; background-color: rgb(249, 250, 252);" width="590" cellspacing="0" cellpadding="0" border="0" bgcolor="#f9fafc" align="center">
        <tbody><tr>
        <td style="font-size:1px; line-height:1px; mso-hide: all;" height="10">&nbsp;</td>
        </tr>
        <tr>

        </tbody></table>
        </body></html>
        <script>
        function addEventHandler(elem,eventType,handler) {
         if (elem.addEventListener)
             elem.addEventListener (eventType,handler,false);
         else if (elem.attachEvent)
             elem.attachEvent ('on'+eventType,handler);
        }

        function cancelDefaultAction(e) {
         var evt = e ? e:window.event;
         if (evt.preventDefault) evt.preventDefault();
         evt.returnValue = false;
         return false;
        }
        var sEventType = 'click';
        var links = document.getElementsByTagName('a');
        var linksCnt = links.length;
        if (linksCnt > 0) {
            for (var i = 0; i < linksCnt; i++) {
                addEventHandler(links[i],sEventType,cancelDefaultAction);
            }
        }
        </script>
             `
    );
  }
}
