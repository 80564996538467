import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Invoice } from '@app/models/Invoice.model';
import { Originator } from '@app/models/Originator.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MailerService } from '@app/mailer/mailer.service';

import { environment } from '@env/environment';
import { firestore } from 'firebase/app';
import Timestamp = firestore.Timestamp;
import { ActivatedRoute } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'invoice-modal',
  templateUrl: './invoice-modal.component.html',
  styleUrls: ['./invoice-modal.component.scss']
})
export class InvoiceModalComponent implements OnInit {
  @Input() walletId: string;
  @Input() walletName: string;
  @Input() wallet: any;
  @Input() invoiceToModify: Invoice;
  @Input() originator: Originator;

  invoiceCreationForm!: FormGroup;
  isEditing: boolean;
  isLoading = false;
  rate: number;
  passwordVerify: string;

  minDataEmiss: any;
  maxDataEmiss: any;
  minDataScad: any;
  maxDataScad: any;

  private uploadIbnProof: string;
  private uploadInvc: string;
  private uploadOrdr: string;
  private uploadOthr: string;

  private firstStep: boolean = true;

  private invoice_id: string;
  private filename: string;
  private user_id: string;
  private label: string;

  private docInvc: string;
  private docOrdr: string;
  private docIban: string;
  private docOthr: string;

  private presentDocs: any;
  private created_at: any;

  private btnInvc: boolean;
  private btnOrdr: boolean;
  private btnIban: boolean;
  private btnOthr: boolean;

  private requestedDocUploaded: boolean = false;

  private shwUpInvc: boolean;
  private shwUpOrdr: boolean;
  private shwUpIban: boolean;
  private shwUpOthr: boolean;

  private searched: boolean;

  private reloadInvc: boolean;
  private reloadOrdr: boolean;
  private reloadIban: boolean;
  private reloadOthr: boolean;

  private scontoDinamico: number;
  private valoreSconto: number;
  private usuryRate: number;

  private prepUpInvc: boolean = false;
  private prepUpOrdr: boolean = false;
  private prepUpIban: boolean = false;
  private prepUpOthr: boolean = false;
  private preparedUpReq: boolean = false;

  constructor(
    private storage: AngularFireStorage,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private firebase: FirebaseTranchedService,
    private sendMail: MailerService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private backendAuthService: BackendAuthService
  ) {
    this.maxDataEmiss = new Date();
    this.minDataScad = new Date();
  }

  ngOnInit() {
    this.createForm();
    this.getUsuryRate();

    this.firebase.getAttualUsuryRates().subscribe((data: any) => {
      // this.rate = data[0].amount;
    });

    //console.log('Comany=>', this.firebase.loggedUser.assignorName);
    //console.log('Wallet=>', this.wallet.originatorUId);
    this.firebase.getUserByID(this.wallet.originatorUId).then(data => {
      if (data.exists) {
        this.originator = data.data() as Originator;
        console.log('Wallet=>', this.wallet);
        console.log('originator=>', this.originator);
      } else {
        console.log('DAta vuoto');
      }
    });

    /* check se file già caricati req con invoice_id a alreadyUploaded [backend]*/
    this.checkFile(this.invoiceToModify.id);
  }

  getUsuryRate() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    const body = {};

    this.http
      .post<any>(`${environment.laravelServer}/usury_rate/getAllActive`, body, httpOptions)
      .subscribe(respInv => {
        if (respInv) {
          try {
            const usuryActive = respInv.data[0].info[0];
            const arrSplit = usuryActive.split('---');
            this.usuryRate = arrSplit[2];
            console.log(arrSplit[2]);
          } catch (e) {
            this.usuryRate = 0;
          }
        }
      });
  }

  calculcateMaturity() {
    const dateInit = new Date(this.invoiceCreationForm.value.dataFattura);
    const dateEnd = new Date(this.invoiceCreationForm.value.dataScadenza);
    const deltaDays = Math.floor(
      (Date.UTC(dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate()) -
        Date.UTC(dateInit.getFullYear(), dateInit.getMonth(), dateInit.getDate())) /
        (1000 * 60 * 60 * 24)
    );

    if (deltaDays > 0 && this.invoiceCreationForm.value.importoFattura > 0 && this.usuryRate > 0) {
      this.getDinamicDiscount(deltaDays, this.invoiceCreationForm.value.importoFattura, this.usuryRate);
    }
  }

  getDinamicDiscount(deltaDays, importoFattura, usuryRate) {
    // applico la formula per il calcolo dello sconto proposto
    // @ts-ignore
    this.scontoDinamico = ((parseFloat(usuryRate) * parseFloat(deltaDays)) / 365).toFixed(2);
    this.valoreSconto = importoFattura * this.scontoDinamico;
  }

  createForm() {
    console.log(this.invoiceToModify);
    let validatorsImportoFattura: any[];
    if (this.wallet.maxInvoice) {
      validatorsImportoFattura = [
        Validators.required,
        Validators.min(this.wallet.minInvoice),
        Validators.max(this.wallet.maxInvoice),
        this.importoTooBig()
      ];
    } else {
      validatorsImportoFattura = [Validators.required, Validators.min(this.wallet.minInvoice)];
    }

    this.invoiceCreationForm = this.formBuilder.group({
      numFattura: new FormControl('', [Validators.required]),
      importoFattura: new FormControl('', validatorsImportoFattura),
      dataFattura: new FormControl('', [Validators.required]),
      dataScadenza: new FormControl('', [Validators.required]),
      scontoProposto: new FormControl('', [Validators.required, Validators.min(0)]),
      importoDaScontare: new FormControl('', [Validators.min(0)])
      // rate: new FormControl(false)
    });

    if (this.isEditing) {
      this.invoiceCreationForm.setValue({
        numFattura: this.invoiceToModify.numFattura,
        importoFattura: this.invoiceToModify.importoFattura,
        dataFattura: this.invoiceToModify.dataFattura,
        dataScadenza: this.invoiceToModify.dataScadenza,
        scontoProposto: this.invoiceToModify.scontoProposto,
        importoDaScontare: (this.invoiceToModify.importoFattura * this.invoiceToModify.scontoProposto) / 100
      });
    }
  }

  importoTooBig(): ValidatorFn {
    // check if inserted value is contained in a (plafond - counter) of a enabled product
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return (this.wallet.productsNode[0].enabled &&
        control.value <= this.wallet.productsNode[0].plafond - this.wallet.productsNode[0].counter) ||
        (this.wallet.productsNode[1].enabled &&
          control.value <= this.wallet.productsNode[1].plafond - this.wallet.productsNode[1].counter) ||
        (this.wallet.productsNode[2].enabled &&
          control.value <= this.wallet.productsNode[2].plafond - this.wallet.productsNode[2].counter)
        ? null
        : { importoTooBig: true };
    };
  }

  dateValidation() {
    console.log(this.invoiceCreationForm.value.dataFattura);
    console.log(this.invoiceCreationForm.value.dataScadenza);

    const dateInit = new Date(this.invoiceCreationForm.value.dataFattura);
    const dateEnd = new Date(this.invoiceCreationForm.value.dataScadenza);

    if (dateInit.getTime() < dateEnd.getTime()) {
      return true;
    } else {
      alert('Le date inserite non sono valide!');
      return false;
    }
  }

  submit() {
    if (!this.dateValidation()) {
      return;
    }
    if (!this.invoiceCreationForm.valid) {
      this.invoiceCreationForm.get('numFattura').markAsTouched();
      this.invoiceCreationForm.get('importoFattura').markAsTouched();
      this.invoiceCreationForm.get('dataFattura').markAsTouched();
      this.invoiceCreationForm.get('dataScadenza').markAsTouched();
      this.invoiceCreationForm.get('scontoProposto').markAsTouched();
      this.invoiceCreationForm.get('importoDaScontare').markAsTouched();
    } else {
      this.firstStep = false;

      this.isLoading = true;
      if (!this.isEditing) {
        // this.invoiceCreationForm.controls.rate.setValue(this.invoiceCreationForm.value.scontoProposto <= this.rate);
        this.firebase
          .createInvoice(this.invoiceCreationForm, this.walletId, this.walletName)
          .then(() => {
            // invio email di notifica

            const body =
              '{\n' +
              '    "to":"' +
              this.originator.email +
              '",\n' +
              '    "firstname":"' +
              this.originator.originatorSolicitorName +
              '",\n' +
              '    "lastname":"' +
              this.originator.originatorSolicitorSurname +
              '",\n' +
              '    "subject":"Richiesta di anticipazione fattura",\n' +
              '    "senderCompany":"FX12 Srl",\n' +
              '    "from":"support@incassaora.it",\n' +
              '    "senderName":"Support of Incassaora",\n' +
              '    "templateName":"advanceRequest",\n' +
              '    "denominazioneCedente":"' +
              this.firebase.loggedUser.denominazione +
              '",\n' +
              '    "importoFattura":"' +
              this.invoiceCreationForm.value.importoFattura +
              '",\n' +
              '    "dataFattura":"' +
              this.invoiceCreationForm.value.dataFattura +
              '",\n' +
              '    "numeroFattura":"' +
              this.invoiceCreationForm.value.numFattura +
              '",\n' +
              '    "maturity":"' +
              this.invoiceCreationForm.value.dataScadenza +
              '",\n' +
              '    "scontoProposto":"' +
              this.invoiceCreationForm.value.scontoProposto +
              '"\n' +
              '}';

            // console.log('body=>', body);

            this.sendMail.sendEmailTemplateByBody(body);

            this.activeModal.dismiss();
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
            alert("Errore nell'inserimento della fattura");
          });
        alert('Riaprire la fattura per caricare i documenti necessari');
      } else {
        this.invoiceToModify.numFattura = this.invoiceCreationForm.value.numFattura;
        this.invoiceToModify.dataFattura = this.invoiceCreationForm.value.dataFattura;
        this.invoiceToModify.dataScadenza = this.invoiceCreationForm.value.dataScadenza;
        this.invoiceToModify.importoFattura = this.invoiceCreationForm.value.importoFattura;
        this.invoiceToModify.scontoProposto = this.invoiceCreationForm.value.scontoProposto;

        this.firebase
          .modInvoice(this.invoiceToModify)
          .then(() => {
            this.submitInvc();
            this.submitOrdr();
            this.submitIbanProof();
            if (this.prepUpOthr) {
              this.submitOthr();
            }

            this.activeModal.dismiss();
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    }
  }

  // getters
  get numFattura() {
    return this.invoiceCreationForm.get('numFattura');
  }

  get importoFattura() {
    return this.invoiceCreationForm.get('importoFattura');
  }

  get dataFattura() {
    return this.invoiceCreationForm.get('dataFattura');
  }

  get dataScadenza() {
    return this.invoiceCreationForm.get('dataScadenza');
  }

  get scontoProposto() {
    return this.invoiceCreationForm.get('scontoProposto');
  }

  prepareInvc(event: any) {
    this.uploadInvc = event.target.files[0];
    this.prepUpInvc = true;
    this.check();
  }

  prepareOrdr(event: any) {
    this.uploadOrdr = event.target.files[0];
    this.prepUpOrdr = true;
    this.check();
  }

  prepareIbanProof(event: any) {
    this.uploadIbnProof = event.target.files[0];
    this.prepUpIban = true;
    this.check();
  }

  prepareOthr(event: any) {
    this.uploadOthr = event.target.files[0];
    this.prepUpOthr = true;
    this.check();
  }

  submitInvc() {
    if (typeof this.uploadInvc === 'undefined') {
      alert('Selezionare il documento da caricare');
      return;
    }
    this.invoice_id = this.invoiceToModify.id;
    this.user_id = this.invoiceToModify.uid;
    this.label = 'INVC';
    this.filename = this.invoice_id + '-' + new Date().valueOf() + '-' + this.label + '.pdf';
    this.created_at = new Date(Timestamp.now().seconds * 1000).toUTCString();
    const filePath = '/documenti_fatture/' + this.filename;
    // console.log(filePath);

    let obj: any = {
      created_at: this.created_at,
      invoice_id: this.invoice_id,
      user_id: this.user_id,
      label: this.label,
      filename: this.filename
    };
    obj = JSON.stringify(obj);

    this.storage
      .upload(filePath, this.uploadInvc)
      .snapshotChanges()
      .pipe()
      .subscribe(data => {
        if (data.bytesTransferred == data.totalBytes) {
          console.log(data);
          this.send(obj);
          this.btnInvc = true;
          this.shwUpInvc = true;
        }
      });
  }

  submitOrdr() {
    if (typeof this.uploadOrdr === 'undefined') {
      alert('Selezionare il documento da caricare');
      return;
    }

    this.invoice_id = this.invoiceToModify.id;
    this.user_id = this.invoiceToModify.uid;
    this.label = 'ORDR';
    this.filename = this.invoice_id + '-' + new Date().valueOf() + '-' + this.label + '.pdf';
    this.created_at = new Date(Timestamp.now().seconds * 1000).toUTCString();
    const filePath = '/documenti_fatture/' + this.filename;
    // console.log(filePath);

    let obj: any = {
      created_at: this.created_at,
      invoice_id: this.invoice_id,
      user_id: this.user_id,
      label: this.label,
      filename: this.filename
    };
    obj = JSON.stringify(obj);

    this.storage
      .upload(filePath, this.uploadOrdr)
      .snapshotChanges()
      .pipe()
      .subscribe(data => {
        if (data.bytesTransferred == data.totalBytes) {
          console.log(data);
          this.send(obj);
          this.btnOrdr = true;
          this.shwUpOrdr = true;
          //this.activeModal.close();
        }
      });
  }

  submitIbanProof() {
    if (typeof this.uploadIbnProof === 'undefined') {
      alert('Selezionare il documento da caricare');
      return;
    }

    this.invoice_id = this.invoiceToModify.id;
    this.user_id = this.invoiceToModify.uid;
    this.label = 'IBAN';
    this.filename = this.invoice_id + '-' + new Date().valueOf() + '-' + this.label + '.pdf';
    this.created_at = new Date(Timestamp.now().seconds * 1000).toUTCString();
    const filePath = '/documenti_fatture/' + this.filename;
    // console.log(filePath);

    let obj: any = {
      created_at: this.created_at,
      invoice_id: this.invoice_id,
      user_id: this.user_id,
      label: this.label,
      filename: this.filename
    };
    obj = JSON.stringify(obj);

    this.storage
      .upload(filePath, this.uploadIbnProof)
      .snapshotChanges()
      .pipe()
      .subscribe(data => {
        if (data.bytesTransferred == data.totalBytes) {
          console.log(data);
          this.send(obj);
          this.btnIban = true;
          this.shwUpIban = true;
          //this.activeModal.close();
        }
      });
  }

  submitOthr() {
    if (typeof this.uploadOthr === 'undefined') {
      alert('Selezionare il documento da caricare');
      return;
    }

    this.invoice_id = this.invoiceToModify.id;
    this.user_id = this.invoiceToModify.uid;
    this.label = 'OTHR';
    this.filename = this.invoice_id + '-' + new Date().valueOf() + '-' + this.label + '.pdf';
    this.created_at = new Date(Timestamp.now().seconds * 1000).toUTCString();
    const filePath = '/documenti_fatture/' + this.filename;
    // console.log(filePath);

    let obj: any = {
      created_at: this.created_at,
      invoice_id: this.invoice_id,
      user_id: this.user_id,
      label: this.label,
      filename: this.filename
    };
    obj = JSON.stringify(obj);

    this.storage
      .upload(filePath, this.uploadOthr)
      .snapshotChanges()
      .pipe()
      .subscribe(data => {
        if (data.bytesTransferred == data.totalBytes) {
          // console.log(data);
          this.send(obj);
          this.btnOthr = true;
          this.shwUpOthr = true;
          //document.getElementById("invoice").style.visibility = "hidden";
          //this.activeModal.close();
        }
      });
  }

  send(obj: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post<any>(`${environment.laravelServer}/invoice/uploadDocuments`, obj, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  check() {
    if (this.prepUpInvc && this.prepUpOrdr && this.prepUpIban) {
      this.preparedUpReq = true;
    }
  }

  checkFile(_invId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/invoice/checkDocuments`, { invId: _invId }, httpOptions)
      .subscribe(data => {
        console.log('checkDoc: ' + JSON.stringify(data));

        if (data.docs[0][0]) {
          this.btnInvc = true;
          this.shwUpInvc = this.btnInvc;
          this.docInvc = data.docs[0][1];
        } else {
          this.btnInvc = false;
        }

        if (data.docs[1][0]) {
          this.btnOrdr = true;
          this.shwUpOrdr = this.btnOrdr;
          this.docOrdr = data.docs[1][1];
        } else {
          this.btnOrdr = false;
        }

        if (data.docs[2][0]) {
          this.btnIban = true;
          this.shwUpIban = this.btnIban;
          this.docIban = data.docs[2][1];
        } else {
          this.btnIban = false;
        }

        if (data.docs[3][0]) {
          this.btnOthr = true;
          this.shwUpOthr = this.btnOthr;
          this.docOthr = data.docs[3][1];
        } else {
          this.btnOthr = false;
        }
        // console.log(this.invDocs, this.invOrdr, this.invIban, this.invOthr);

        this.searched = true;

        if (this.btnInvc && this.btnOrdr && this.btnIban) {
          this.requestedDocUploaded = true;
        }
      });
  }

  downldDoc(type: string) {
    let path: string;
    if (type == 'INVC') {
      path = '/documenti_fatture/' + this.docInvc;
    }
    if (type == 'ORDR') {
      path = '/documenti_fatture/' + this.docOrdr;
    }
    if (type == 'IBAN') {
      path = '/documenti_fatture/' + this.docIban;
    }
    if (type == 'OTHR') {
      path = '/documenti_fatture/' + this.docOthr;
    }

    console.log('tipo: ', type, '\npath: ', path);

    const ref = this.storage.ref(path);

    ref.getDownloadURL().subscribe(data => {
      // console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  enableReupload(_type: string) {
    if (_type === 'INVC') {
      this.shwUpInvc = false;
    }
    if (_type === 'ORDR') {
      this.shwUpOrdr = false;
    }
    if (_type === 'IBAN') {
      this.shwUpIban = false;
    }
    if (_type === 'OTHR') {
      this.shwUpOthr = false;
    }
  }
}
