import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataTablesResponse } from '@app/models/DataTablesResponse.model';
import { environment } from '@env/environment.prod';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-company-allow-spv',
  templateUrl: './company-allow-spv.component.html',
  styleUrls: ['./company-allow-spv.component.scss']
})
export class CompanyAllowSPVComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;

  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() investorSPV = '';

  private listInvestorSPV: any[];

  constructor(
    public activeModal: NgbActiveModal,
    private http: HttpClient,
    private backendAuthService: BackendAuthService
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };
    this.http
      .post<any>(`${environment.laravelServer}/getAllUserByRole`, '{"role":"investorSPV"}', httpOptions)
      .subscribe(resp => {
        this.listInvestorSPV = resp.data;
      });
  }

  ngOnInit() {}

  onSelected(value: string) {
    this.investorSPV = value;
  }
}
