import { Component, OnInit } from '@angular/core';
import { MailerService } from '@app/mailer/mailer.service';

@Component({
  selector: 'app-send-pec',
  templateUrl: './send-pec.component.html',
  styleUrls: ['./send-pec.component.scss']
})
export class SendPecComponent implements OnInit {
  file64Encode: string | ArrayBuffer = '';
  filename: string;

  constructor(private sendMail: MailerService) {}

  ngOnInit() {}

  handleUpload($event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    console.log(file);
    this.filename = file.name;
    const typeFile = file.type;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // console.log(reader.result);
      this.file64Encode = reader.result as string;
      const strReplace = 'data:' + typeFile + ';base64,';
      this.file64Encode = this.file64Encode.replace(strReplace, '');
      console.log(this.file64Encode);
    };
  }

  inviaEmail() {
    // creo il body dell'email
    const body =
      '{\n' +
      '    "to":"gaetano.bitonte@gmail.com",\n' +
      '    "to_name":"Gaetano Bitonte",\n' +
      '    "subject":"Nuova PEC",\n' +
      '    "body":"Corpo dell\'email",\n' +
      '    "filename":"' +
      this.filename +
      '",\n' +
      '    "attachmentBase64":"' +
      this.file64Encode +
      '"\n' +
      '}';

    console.log('body=>', body);
    this.sendMail.sendPecByBody(body);
  }
}
