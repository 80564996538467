import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';

@Component({
  selector: 'app-profilo-personale',
  templateUrl: './profilo-personale.component.html',
  styleUrls: ['./profilo-personale.component.scss']
})
export class ProfiloPersonaleComponent implements OnInit {
  scheda = '';
  modalService: any;
  investor: boolean;

  constructor(private firebase: FirebaseTranchedService) {
    this.investor = this.firebase.isInvestor();
  }

  ngOnInit() {}

  showModalProfilo_personale() {
    const modalRef = this.modalService.open(ProfiloPersonaleComponent, {
      size: 'lg',
      centered: true,
      scrollable: true
    });
  }

  changeValScheda(valore) {
    console.log(valore);
    this.scheda = valore;
  }
}
