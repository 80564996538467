import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import DataTables from 'node_modules/datatables.net';
import { DataTablesResponse } from '@app/models/DataTablesResponse.model';
import { environment } from '@env/environment.prod';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { InvestorSPV } from '@app/models/InvestorSPV.model';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './company-spv-list.component.html',
  styleUrls: ['./company-spv-list.component.scss']
})
export class CompanySpvListComponent implements OnInit {
  message = '';

  // @ts-ignore
  dtOptions: DataTables.Settings = {};

  private investorSPVAssoc: InvestorSPV[];

  constructor(
    private router: Router,
    private http: HttpClient,
    private firebase: FirebaseTranchedService,
    private backendAuthService: BackendAuthService
  ) {}

  someClickHandler(info: any): void {
    this.message = info.uuid + ' - ' + info.name;
    this.router.navigate(['/servicerSPV/company/' + info.uuid + '/detail']);
  }

  ngOnInit() {
    this.investorSPVAssoc = [];
    this.dtOptions = 0;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(
        `${environment.laravelServer}/getInvestorsSPVByIdServicerSPV`,
        '{"id":"' + this.firebase.loggedUser.id + '"}',
        httpOptions
      )
      .subscribe(respInv => {
        this.investorSPVAssoc.push(respInv.data[0]);

        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
          autoWidth: false,
          columnDefs: [
            { width: '15%', targets: 0 },
            { width: '15%', targets: 1 },
            { width: '20%', targets: 2 },
            { width: '10%', targets: 3 },
            { width: '10%', targets: 4 },
            { width: '20%', targets: 5 },
            { width: '10%', targets: 6 }
          ],
          width: '200%',
          serverSide: false, // Set the flag
          ajax: (dataTablesParameters: any, callback) => {
            this.http
              .post<DataTablesResponse>(
                `${environment.laravelServer}/getCedentiAssegnati`,
                '{"idInvestor":"' + this.investorSPVAssoc[0].uuid + '"}',
                httpOptions
              )
              .subscribe(resp => {
                callback({
                  recordsTotal: resp.recordsTotal,
                  recordsFiltered: resp.recordsFiltered,
                  data: resp.data
                });
              });
          },
          columns: [
            {
              title: 'Nome',
              data: 'name'
            },
            {
              title: 'Cognome',
              data: 'surname'
            },
            {
              title: 'Rag. sociale',
              data: 'denominazione'
            },
            {
              title: 'P. IVA',
              data: 'pIva'
            },
            {
              title: 'C.F.',
              data: 'fiscalCode'
            },
            {
              title: 'Email',
              data: 'email'
            },
            {
              title: 'Stato SPV',
              data: 'statusSPV'
            },
            {
              title: 'Scadenza',
              data: 'expirationSPVDate'
            }
          ],
          // tslint:disable-next-line:ban-types
          rowCallback: (row: Node, data: any[] | Object, index: number) => {
            const self = this;
            // Unbind first in order to avoid any duplicate handler
            // (see https://github.com/l-lin/angular-datatables/issues/87)
            // Note: In newer jQuery v3 versions, `unbind` and `bind` are
            // deprecated in favor of `off` and `on`
            $('td', row).off('click');
            $('td', row).on('click', () => {
              self.someClickHandler(data);
            });
            return row;
          },
          // Declare the use of the extension in the dom parameter
          dom: 'Bfrtip',
          // Configure the buttons
          buttons: [
            'columnsToggle',
            'colvis',
            'copy',
            'print',
            'excel',
            {
              text: 'Some button',
              key: '1',
              action: function(e, dt, node, config) {
                alert('Button activated');
              }
            }
          ]
        };
      });
  }
}
