import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { Utilities } from '@app/utilities/Utilities';

import * as bcrypt from 'bcryptjs';
import { environment } from '@env/environment.prod';

@Component({
  selector: 'app-new-partner',
  templateUrl: './new-partner.component.html',
  styleUrls: ['./new-partner.component.scss']
})
export class NewPartnerComponent implements OnInit {
  products: string[] = Utilities.productNames;
  partnerCreationForm!: FormGroup;
  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;
  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router
  ) {
    this.createForm();
    this.firebaseService.createPageLog('User Visit Page new-partner');
  }

  ngOnInit() {}

  submit() {
    this.isSubmitted = true;
    if (!this.partnerCreationForm.valid) {
      this.partnerCreationForm.get('email').markAsTouched();
      this.partnerCreationForm.get('password').markAsTouched();
      this.partnerCreationForm.get('name').markAsTouched();
      this.partnerCreationForm.get('partnerPIva').markAsTouched();
    } else {
      let objectToSave = this.firebaseService.createPartner(this.partnerCreationForm);
      this.firebaseService
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);

          // @@ hydra - 2023-01-31 - PASSWORD HASH
          try {
            const newPass = bcrypt.hashSync(objectToSave.password, environment.saltPass);
            this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
          } catch (e) {}
          // *********************************************************************************************

          this.router.navigate(['/home//users-credential']);
        })
        .catch(error => {
          // Handle Errors here.
          let errorCode = error.code;
          this.errorMessage = error.message;
        });
    }
  }

  onChangeType() {
    this.createForm();
  }

  createForm() {
    this.partnerCreationForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      name: new FormControl('', [Validators.required]),
      partnerPIva: new FormControl('', [Validators.required]),
      partnerWebSite: new FormControl(''),
      partnerPec: new FormControl(''),
      // sede legale
      partnerOfficeRegisteredStreet: new FormControl(''),
      partnerOfficeRegisteredCity: new FormControl(''),
      partnerOfficeRegisteredProvince: new FormControl(''),
      tel: new FormControl(''),
      indirizzo: new FormControl('')
    });
  }

  get email() {
    return this.partnerCreationForm.get('email');
  }

  get password() {
    return this.partnerCreationForm.get('password');
  }

  get name() {
    return this.partnerCreationForm.get('name');
  }

  get partnerPIva() {
    return this.partnerCreationForm.get('partnerPIva');
  }
}
