import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { environment } from '@env/environment.prod';
import { AngularFireStorage } from '@angular/fire/storage';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { MailerService } from '@app/mailer/mailer.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  templateUrl: './document-funds.component.html',
  styleUrls: ['./document-funds.component.scss']
})
export class DocumentFundsComponent implements OnInit {
  private docData: any;
  private requests: any;
  private investorSPV: string;

  private doc2upload: any;
  private filename: string;
  private created_at: any;
  private request_id: string;
  public docUploaded: boolean;
  public docConfirmed: boolean;
  private requestsLoaded: boolean;

  constructor(
    private firebase: FirebaseTranchedService,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private sendMail: MailerService
  ) {}

  ngOnInit() {
    this.investorSPV = this.firebase.loggedUser.id;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(
        `${environment.laravelServer}/bankrequest/getRequestByInvSpvId`,
        '{"id":"' + this.investorSPV + '"}',
        httpOptions
      )
      .subscribe(resp => {
        this.requests = resp.data;
        this.requestsLoaded = true;
        console.log(this.requests);
      });
  }

  contrattoinvestitore(data) {
    //console.log(data);
    const now = new Date();
    const tomorrow = new Date().getDate() + 1 + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear();

    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [60, 60, 60, 60],
      content: [
        {
          text: 'RICHIESTA DI EROGAZIONE DEL FINANZIAMENTO',
          bold: true,
          alignment: 'center',
          margin: [0, 8, 0, 5]
        },
        {
          text: [
            {
              text: 'Milano, '
            },
            {
              text: now.toLocaleDateString()
            }
          ],
          alignment: 'right',
          margin: [0, 5]
        },
        {
          text: 'Spettabile',
          alignment: 'left',
          margin: [0, 20, 0, 10]
        },
        {
          text: data.bank.denomination,
          alignment: 'left',
          bold: true
        },
        {
          text: data.bank.street,
          alignment: 'left'
        },
        {
          text: data.bank.cap + ' - ' + data.bank.city + '(' + data.bank.province + ')',
          alignment: 'left'
        },
        {
          text: 'E-mail: ' + data.bank.email,
          alignment: 'left'
        },
        {
          text: 'PEC: ' + data.bank.pec
        },
        {
          text: 'Oggetto: Richiesta di Erogazione del Finanziamento',
          bold: true,
          alignment: 'center',
          margin: [0, 20, 0, 10]
        },
        {
          text: 'Egregi Signori.',
          alignment: 'left',
          margin: [0, 5]
        },
        {
          text: [
            {
              text:
                'facciamo riferimento al contratto di finanziamento stipulato in data 3 maggio 2023 tra la nostra società, in qualità di soggetto finanziato (il'
            },
            {
              text: '“Beneficiario”',
              bold: true
            },
            {
              text: '), e '
            },
            {
              text: data.bank.denomination + ',',
              bold: true
            },
            {
              text: ' in qualità di Banca finanziatrice (il '
            },
            {
              text: '“Contratto di Finanziamento”',
              bold: true
            },
            {
              text:
                '). I termini utilizzati nella presente Richesta di Erogazione hanno lo stesso significato  loro attribuito nel Contratto di Finanziamento. ',
              margin: [0, 10, 0, 10]
            }
          ]
        },
        {
          text:
            'Con la presente, Vi chiediamo irrevocabilmente, ai sensi degli articoli 2.6 e seguenti del Contratto di Finanziamento di voler porre a nostra disposizione l’erogazione a valere sul Finanziamento, avente le seguenti caratteristiche:',
          margin: [0, 10, 0, 5]
        },
        {
          text: '(a)	Importo complessivo da erogare: Euro ' + data.total + ';',
          margin: [0, 6]
        },
        {
          text: '(b)	Data di Erogazione: ' + tomorrow + ';',
          margin: [0, 6]
        },
        {
          text: '(c)	Conto Corrente: ' + data.invSPV.iban + '. ',
          margin: [0, 6]
        },
        {
          text:
            'Vi confermiamo e dichiariamo altresì, con riferimento alla data odierna ed alla Data di Erogazione sopra indicata, ove diversa, che non si è verificato un Evento Rilevante.',
          margin: [0, 5, 0, 5]
        },
        {
          text: [
            {
              text:
                'Vi confermiamo inoltre che l’Erogazione richiesta sarà utilizzata esclusivamente per gli scopi di cui all’articolo 2.2  ',
              margin: [0, 5, 0, 5]
            },
            {
              text: '(Scopo del Finanziamento)',
              italics: true
            },
            {
              text: 'del Contratto di Finanziamento.'
            }
          ]
        },
        {
          text: 'Distinti saluti.'
        },
        {
          text: data.invSPV.denomination,
          bold: true,
          alignement: 'left',
          margin: [0, 15]
        },
        {
          text: '___________________',
          margin: [0, 5]
        },
        {
          text: 'Nome:',
          margin: [0, 5]
        },
        {
          text: 'Titolo:'
        }
      ],

      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      }
    };
    pdfMake.createPdf(docDefinition).open();
  }

  caricaContratto(_reqID: string) {
    this.request_id = _reqID;
    console.log(this.request_id);
    this.filename = this.request_id + '-' + this.investorSPV + '-' + new Date().valueOf() + '.pdf';
    const filePath = '/documenti_richieste/' + this.filename;

    let obj: any = {
      investor_id: this.investorSPV,
      request_id: this.request_id,
      filename: this.filename
    };
    obj = JSON.stringify(obj);
    console.log('obj: ', obj);

    this.storage
      .upload(filePath, this.doc2upload)
      .snapshotChanges()
      .pipe()
      .subscribe(data => {
        if (data.bytesTransferred == data.totalBytes) {
          this.send(obj);
          this.docUploaded = true;
          alert("Documento Caricato con Successo\nE' necessionario confermare");
          window.location.reload();
        }
      });
  }

  viewLastDoc(_filename: string) {
    //console.log(_filename);
    let path: string = '/documenti_richieste/' + _filename;

    const ref = this.storage.ref(path);

    ref.getDownloadURL().subscribe(data => {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  confirmDoc(_reqID, _totale, _bankEmail) {
    //console.log("id: " + _reqID + "\ntotale: " + _totale + "\nemailbanca: " + _bankEmail);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/bankrequest/confirmDoc`, '{"id":"' + _reqID + '"}', httpOptions)
      .subscribe(data => {
        if (data) {
          this.sendMail2Bank(_totale, _bankEmail);
          alert('Il documento è stato confermato!');
          window.location.reload();
        } else {
          alert('Errore nella conferma del documento');
          window.location.reload();
        }
      });
  }

  getDati(reqID) {
    this.request_id = reqID;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(
        `${environment.laravelServer}/bankrequest/getInfoForDoc`,
        '{"id":"' + this.request_id + '"}',
        httpOptions
      )
      .subscribe(resp => {
        if (resp.status == 'OK') {
          this.contrattoinvestitore(resp.data);
        } else {
          console.log(resp.error);
        }
      });
  }

  send(obj: Object) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http.post<any>(`${environment.laravelServer}/bankrequest/uploadDocument`, obj, httpOptions).subscribe(data => {
      console.log(data);
    });
  }

  prepareDoc(event: any) {
    this.doc2upload = event.target.files[0];
  }

  sendMail2Bank(_totale, _bankEmail) {
    var body =
      '{' +
      ' \n  "to":"' +
      _bankEmail +
      '"' +
      ',\n  "created_at":"' +
      new Date().toLocaleDateString() +
      '"' +
      ',\n  "totale":"' +
      _totale +
      '"' +
      ',\n  "subject":"E\' presente una nuova richiesta d\'erogazione fondi"' +
      ',\n  "senderCompany":"FX12 Srl"' +
      ',\n  "from":"support@incassaora.it"' +
      ',\n  "senderName":"Support of Incassaora"' +
      ',\n  "templateName":"bankRequestCreated"' +
      '\n}';
    //console.log("\nbody: ", body);
    this.sendMail.sendEmailTemplateByBody(body);
  }
}
