import { Utilities } from '@app/utilities/Utilities';
import { User } from './User.model';
export class Investor extends User {
  investorOfficeRegisteredStreet: string; // sede legale
  investorOfficeRegisteredCity: string;
  investorOfficeRegisteredProvince: string;

  originatorSolicitorSurname: string;
  originatorSolicitorName: string;
  originatorSolicitorBirthplace: string;
  originatorSolicitorBirthdate: string;

  investorPIva: string;
  investorMoreScore: string;
  investorDefault: string;
  lastTigranDate: string;

  investorWebSite: string;
  investorPec: string;
  fiscalCode: string;
  isPrivato: boolean;
  listWallet: string[] = [];

  virtualIban: string;
  idvirtualIban: string;

  phone_number: string;

  lemonwayAccount: boolean = false;
  statoLemonway: string = Utilities.statoLemon[3];
  REA_CODE: string;

  viaLeg: string;
  comuneLeg: string;
  provinciaLeg: string;
  nazioneLeg: string;
  capLegale: string;
  legalAddressCAP: string;
  constructor() {
    super();
  }
}
