import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfiloPersonaleRoutingModule } from './profilo-personale-routing.module';
import { AppDatipersonaliComponent } from './app-datipersonali/app-datipersonali.component';
import { AppDocumentoComponent } from './app-documento/app-documento.component';
import { AppContoComponent } from './app-conto/app-conto.component';
import { ProfiloPersonaleComponent } from './profilo-personale.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalOperationComponent } from './personal-operation/personal-operation.component';
import { DataTablesModule } from 'angular-datatables';
import { PersonalPreferenceComponent } from './personal-preference/personal-preference.component';

@NgModule({
  declarations: [
    AppDatipersonaliComponent,
    AppDocumentoComponent,
    ProfiloPersonaleComponent,
    AppContoComponent,
    PersonalOperationComponent,
    PersonalPreferenceComponent
  ],
  imports: [CommonModule, ProfiloPersonaleRoutingModule, NgbModule, FormsModule, ReactiveFormsModule, DataTablesModule]
})
export class ProfiloPersonaleModule {}
