export class MailText {
  static getApprovaUtente(): string {
    return 'Nuovo utente effettuare una approvazione dell utente . ';
  }

  static getBonificoCaricatoFormalmente(): string {
    return 'il bonifico è stato caricato . ';
  }

  static getBonificoCaricatoUfficialmente(): string {
    return 'il bonifico è stato caricato . ';
  }

  static getBonificoCaricatoScartatoFormalmente(): string {
    return 'il bonifico caricato è stato scartata caricare un nuovo bonifico. ';
  }

  static getBonificoCaricatoScartatoUfficialmente(): string {
    return 'il bonifico caricato è stato scartata caricare un nuovo bonifico. ';
  }

  static getNotaCreditoCaricataScartataFormalmente(): string {
    return 'la nota di credito è stata scartata caricare una nuova nota di credito . ';
  }

  static getNotaCreditoCaricataScartataUfficialmente(): string {
    return 'la nota di credito è stata scartata caricare una nuova nota di credito . ';
  }

  static getPropostaAccettata(): string {
    return 'La sua proposta è stata accettata. Effettuare l\'accesso sull\'applicativo per prendere visione della trattativa.';
  }

  static getIpotesiFattura(walletName: string): string {
    return (
      'Salve volevamo informarla che le è arrivata una nuova proposta relativa al portafoglio ' +
      walletName +
      ".\nEffettuare l'accesso sull'applicativo per prenderne visione."
    );
  }

  static getPropostaRifiutata(walletName: string): string {
    return 'la pratica è terminata lo sconto è stato rifiutato per la proposta relativa al wallet: ' + walletName + '.';
  }

  static getValidaNuovoUtente(): string {
    return 'Salve volevamo informarla che ha un nuovo utente da approvare. ';
  }

  static getNotaCreditoCaricataFormalmente(): string {
    return 'nota credito caricata da approvare formalmente';
  }

  static getBonificoCaricataFormalmente(): string {
    return 'bonifico caricato da approvare formalmente';
  }

  static getNotaCreditoCaricataScartata(): string {
    return 'nota credito scartata, provvedere a caricarne una corretta';
  }

  static getNuovaProposta(numeroFattura: string, scontoProposto: string): string {
    return (
      'Salve volevamo informarla che ha una nuova proposta per la fattura numero. ' +
      numeroFattura +
      ', Lo sconto proposto è del ' +
      scontoProposto +
      '. Accedere alla piattaforma per effettuare controproposta o accettare'
    );
  }

  static getNuovaControproposta(numeroFattura: string, scontoProposto: string): string {
    return (
      'Salve volevamo informarla che ha una nuova proposta per la fattura numero. ' +
      numeroFattura +
      ', Lo sconto proposto è del ' +
      scontoProposto +
      '. Accedere alla piattaforma per effettuare controproposta o accettare'
    );
  }

  static getNotaCredito(numeroFattura: string): string {
    return (
      'Salve volevamo informarla che la proposta inerente alla fatture numero. ' +
      numeroFattura +
      'è stata confermata, inserire nota di credito per procedere ed effettuare il pagamento'
    );
  }

  static getNotaCreditoInserita(numeroFattura: string): string {
    return (
      'Salve volevamo informarla che la procedura inerente alla fatture numero. ' +
      numeroFattura +
      'sta procedendo la nota di credito è stata inserita'
    );
  }

  static getNotaCreditoApprovata(numeroFattura: string): string {
    return (
      'Salve volevamo informarla che la procedura inerente alla fatture numero. ' +
      numeroFattura +
      'sta procedendo la nota di credito è stata approvata'
    );
  }

  static getPagamentoInserita(numeroFattura: string): string {
    return (
      'Salve volevamo informarla che la procedura inerente alla fatture numero. ' +
      numeroFattura +
      'sta procedendo il pagamento è avvenuto con successo'
    );
  }

  static getPagamentoValidato(numeroFattura: string): string {
    return (
      'Salve volevamo informarla che la procedura inerente alla fatture numero. ' +
      numeroFattura +
      'sta procedendo il pagamento è stato confermato'
    );
  }

  static getOperazioneCompletata(numeroFattura: string): string {
    return (
      'Salve volevamo informarla che la procedura inerente alla fatture numero. ' + numeroFattura + 'è stata completata'
    );
  }

  getChiamateDaIncassaOra(firstName: string, lastName: string): string {
    return (
      'Buongiorno ' +
      firstName +
      ' ' +
      lastName +
      ' ti confermiamo di aver preso in carico la tua ' +
      'richiesta, verrai contattato entro poche ore, qualora non hai inviato tu questa proposta, ' +
      'ti preghiamo di segnalarcelo rispondendo alla presente mail'
    );
  }

  getApprovaUtente() {
    return 'Un nuovo utente si è registrato controllare le credenziali ed effettuare l\'aprovazione';
  }

  getIpotesiScontoDinamicoYellow(hours: number): string {
    return (
      'Salve volevamo informarla che la sua proposta di anticipazione è stata accettata. ' +
      'Il pagamento verrà effettuato entro ' +
      hours +
      ' ore. La preghiamo di trasmettere la nota di ' +
      "credito di questo importo. Il pagamento verrà effettuato entro ventiquattr'ore dalla verifica " +
      'della presenza della nota di credito nel cassetto fiscale.'
    );
  }

  getIpotesiAzureOrBlue(hours: number): string {
    return (
      'Salve volevamo informarla che la sua proposta di cessione è stata accettata. ' +
      'Il pagamento verrà effettuato entro ' +
      hours +
      ' ore dalla formalizzazione del contratto ' +
      "di cessione che troverà allegato. Si prega, qualora volesse procedere con l'operazione, " +
      'di scaricare il contratto, firmarlo in modalità P7M e caricarlo sulla piattaforma.'
    );
  }

  getPropostaAccettata(): string {
    return 'l offerta è stata accettata carica la nota di credito. ';
  }

  static getPropostaInvestitore(): string {
    return 'Hai una nuova proposta per un wallert accetta o rifiuta la proposta. ';
  }
}
