export class UsuryRate {
  static tableName = 'Usury_Rates';
  id: string;
  country: string;
  startDate: any;
  endDate: any;
  info: string[];
}

export class UsuryInfo {
  amount: number;
  a: number;
  da: number;
}
