import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Utilities } from '@app/utilities/Utilities';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import { requireCheckboxesToBeCheckedValidator } from '@app/models/utils/require-checkboxes-to-be-checked.validator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-profile-investor-view',
  templateUrl: './modal-profile-investor-view.component.html',
  styleUrls: ['./modal-profile-investor-view.component.scss']
})
export class ModalProfileInvestorViewComponent implements OnInit {
  profileAssModForm!: FormGroup;
  profileOriModForm!: FormGroup;
  profilePartModForm!: FormGroup;
  assignorMod: any;
  originatorMod: any;
  partnerMod: any;
  investorMod: any;
  profileInvertorModForm!: FormGroup;

  @Input() userInput: any;
  userToMod: any;
  sessione: boolean;
  userType: string[] = Utilities.userType;
  products: string[] = Utilities.productNames;
  userProducts: string[];

  constructor(
    public firebase: FirebaseTranchedService,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public auth: AngularFireAuth,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.userInput) {
      this.sessione = false;
      this.userToMod = this.userInput;
    } else {
      this.sessione = true;
      this.userToMod = this.firebase.loggedUser;
    }

    if (this.userToMod.roles[0] == this.userType[3]) {
      this.assignorMod = this.userToMod;
      this.createFormModCedente();
    } else if (this.userToMod.roles[0] == this.userType[2]) {
      this.originatorMod = this.userToMod;
      this.userProducts = this.originatorMod.originatorProducts;
      this.createFormModOriginator();
    } else if (this.userToMod.roles[0] == this.userType[4]) {
      this.partnerMod = this.userToMod;
      this.createFormModPartner();
    } else if (this.userToMod.roles[0] == this.userType[5]) {
      // @@hydra - 20211016
      this.investorMod = this.userToMod;
      this.createFormModInvestor();
    }
  }

  createFormModOriginator() {
    this.profileOriModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required, Validators.email]),
      email: new FormControl('', [Validators.required]),
      originatorPec: new FormControl('', [Validators.required]),
      originatorPIva: new FormControl('', [Validators.required]),
      originatorWebSite: new FormControl('', [Validators.required]),
      originatorCreditsCheck: new FormControl(false),
      // prodotti
      originatorProducts: new FormGroup(
        {
          originatorFintech: new FormControl(false),
          originatorSupply: new FormControl(false),
          originatorBlue: new FormControl(false)
        },
        requireCheckboxesToBeCheckedValidator()
      ),
      originatorOfficeRegisteredStreet: new FormControl('', [Validators.required]),
      originatorOfficeRegisteredCity: new FormControl('', [Validators.required]),
      originatorOfficeRegisteredProvince: new FormControl('', [Validators.required]),
      originatorOfficeOperationalStreet: new FormControl('', [Validators.required]),
      originatorOfficeOperationalCity: new FormControl('', [Validators.required]),
      originatorOfficeOperationalProvince: new FormControl('', [Validators.required]),
      originatorAdminReferentName: new FormControl('', [Validators.required]),
      originatorAdminReferentSurname: new FormControl('', [Validators.required]),
      originatorAdminReferentEmail: new FormControl('', [Validators.required]),
      originatorAdminReferentTelephone: new FormControl('', [Validators.required]),
      originatorSolicitorName: new FormControl('', [Validators.required]),
      originatorSolicitorSurname: new FormControl('', [Validators.required]),
      originatorSolicitorBirthplace: new FormControl('', [Validators.required]),
      originatorSolicitorBirthdate: new FormControl('', [Validators.required])
    });
    // fix in prod
    if (this.originatorMod.originatorSolicitorName == undefined) {
      this.originatorMod.originatorSolicitorName = '';
    }
    if (this.originatorMod.originatorSolicitorSurname == undefined) {
      this.originatorMod.originatorSolicitorSurname = '';
    }
    if (this.originatorMod.originatorSolicitorBirthplace == undefined) {
      this.originatorMod.originatorSolicitorBirthplace = '';
    }
    if (this.originatorMod.originatorSolicitorBirthdate == undefined) {
      this.originatorMod.originatorSolicitorBirthdate = '';
    }

    this.profileOriModForm.setValue({
      name: this.originatorMod.name,
      email: this.originatorMod.email,
      originatorPec: this.originatorMod.originatorPec,
      originatorPIva: this.originatorMod.originatorPIva,
      originatorWebSite: this.originatorMod.originatorWebSite,
      originatorCreditsCheck: this.originatorMod.originatorCreditsCheck,
      originatorProducts: {
        originatorFintech: this.originatorMod.originatorProducts[0],
        originatorSupply: this.originatorMod.originatorProducts[1],
        originatorBlue: this.originatorMod.originatorProducts[2]
      },
      originatorOfficeRegisteredStreet: this.originatorMod.originatorOfficeRegisteredStreet,
      originatorOfficeRegisteredCity: this.originatorMod.originatorOfficeRegisteredCity,
      originatorOfficeRegisteredProvince: this.originatorMod.originatorOfficeRegisteredProvince,
      originatorOfficeOperationalStreet: this.originatorMod.originatorOfficeOperationalStreet,
      originatorOfficeOperationalCity: this.originatorMod.originatorOfficeOperationalCity,
      originatorOfficeOperationalProvince: this.originatorMod.originatorOfficeOperationalProvince,
      originatorAdminReferentName: this.originatorMod.originatorAdminReferentName,
      originatorAdminReferentSurname: this.originatorMod.originatorAdminReferentSurname,
      originatorAdminReferentEmail: this.originatorMod.originatorAdminReferentEmail,
      originatorAdminReferentTelephone: this.originatorMod.originatorAdminReferentTelephone,
      originatorSolicitorName: this.originatorMod.originatorSolicitorName,
      originatorSolicitorSurname: this.originatorMod.originatorSolicitorSurname,
      originatorSolicitorBirthplace: this.originatorMod.originatorSolicitorBirthplace,
      originatorSolicitorBirthdate: this.originatorMod.originatorSolicitorBirthdate
    });
  }

  createFormModCedente() {
    this.profileAssModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      assignorType: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      assignorPec: new FormControl('', [Validators.required]),
      pIva: new FormControl('', [Validators.required]),
      refName: new FormControl('', [Validators.required]),
      refMail: new FormControl('', [Validators.required]),
      refPhone: new FormControl('', [Validators.required])
    });
    this.profileAssModForm.setValue({
      name: this.assignorMod.name,
      assignorType: this.assignorMod.assignorType,
      email: this.assignorMod.email,
      assignorPec: this.assignorMod.assignorPec,
      pIva: this.assignorMod.pIva,
      refName: this.assignorMod.refName,
      refMail: this.assignorMod.refMail,
      refPhone: this.assignorMod.refPhone
    });
  }

  // Form Partner
  createFormModPartner() {
    this.profilePartModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      partnerPIva: new FormControl('', [Validators.required]),
      partnerOfficeRegisteredStreet: new FormControl('', [Validators.required]),
      partnerOfficeRegisteredCity: new FormControl('', [Validators.required]),
      partnerOfficeRegisteredProvince: new FormControl('', [Validators.required]),
      partnerWebSite: new FormControl('', [Validators.required]),
      partnerPec: new FormControl('', [Validators.required])
    });
    this.profilePartModForm.setValue({
      name: this.partnerMod.name,
      email: this.partnerMod.email,
      partnerPIva: this.partnerMod.partnerPIva,
      partnerOfficeRegisteredStreet: this.partnerMod.partnerOfficeRegisteredStreet,
      partnerOfficeRegisteredCity: this.partnerMod.partnerOfficeRegisteredCity,
      partnerOfficeRegisteredProvince: this.partnerMod.partnerOfficeRegisteredProvince,
      partnerWebSite: this.partnerMod.partnerWebSite,
      partnerPec: this.partnerMod.partnerPec
    });
  }

  // Form Investor
  createFormModInvestor() {
    this.profileInvertorModForm = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      investorPIva: new FormControl('', [Validators.required]),
      investorOfficeRegisteredStreet: new FormControl('', [Validators.required]),
      investorOfficeRegisteredCity: new FormControl('', [Validators.required]),
      investorOfficeRegisteredProvince: new FormControl('', [Validators.required]),
      investorWebSite: new FormControl('', [Validators.required]),
      investorPec: new FormControl('', [Validators.required])
    });
    this.profileInvertorModForm.setValue({
      name: this.investorMod.name,
      email: this.investorMod.email,
      investorPIva: this.investorMod.investorPIva,
      investorOfficeRegisteredStreet: this.investorMod.investorOfficeRegisteredStreet,
      investorOfficeRegisteredCity: this.investorMod.investorOfficeRegisteredCity,
      investorOfficeRegisteredProvince: this.investorMod.investorOfficeRegisteredProvince,
      investorWebSite: this.investorMod.investorWebSite,
      investorPec: this.investorMod.investorPec
    });
  }

  // get partner per validator
  get investorEmail() {
    return this.profileInvertorModForm.get('email');
  }

  get investorPassword() {
    return this.profileInvertorModForm.get('password');
  }

  get investorName() {
    return this.profileInvertorModForm.get('name');
  }

  get investorPIva() {
    return this.profileInvertorModForm.get('investorPIva');
  }

  /*
   ******************************************
   */

  // get partner per validator
  get partnerEmail() {
    return this.profilePartModForm.get('email');
  }

  get partnerPassword() {
    return this.profilePartModForm.get('password');
  }

  get partnerName() {
    return this.profilePartModForm.get('name');
  }

  get partnerPIva() {
    return this.profilePartModForm.get('partnerPIva');
  }

  // get cedente per validator

  get assignorEmail() {
    return this.profileAssModForm.get('email');
  }

  get assignorPassword() {
    return this.profileAssModForm.get('password');
  }

  get assignorUSername() {
    return this.profileAssModForm.get('name');
  }

  get assignorType() {
    return this.profileAssModForm.get('assignorType');
  }

  // get originator per validator

  get originatorEmail() {
    return this.profileOriModForm.get('email');
  }

  get originatorPassword() {
    return this.profileOriModForm.get('password');
  }

  get originatorName() {
    return this.profileOriModForm.get('name');
  }

  get originatorPIVA() {
    return this.profileOriModForm.get('originatorPIva');
  }

  get originatorProducts() {
    return this.profileOriModForm.get('originatorProducts');
  }

  isCedente() {
    return this.userToMod.roles[0] == Utilities.userType[3];
  }

  isOriginator() {
    return this.userToMod.roles[0] == Utilities.userType[2];
  }

  goToWalletVisualizer() {
    this.activeModal.dismiss();
    this.router.navigate(['/investor/dashboard'], { queryParams: { id: this.userInput.id, replaceUrl: true } });
  }
}
