import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { environment } from '@env/environment.prod';

import * as bcrypt from 'bcryptjs';
import { MailerService } from '@app/mailer/mailer.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './servicer-spv-create.component.html',
  styleUrls: ['./servicer-spv-create.component.scss']
})
export class ServicerSpvCreateComponent implements OnInit {
  servicerSPVCreationForm!: FormGroup;
  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;
  private maxToday: any;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private mailerService: MailerService,
    private backendAuthService: BackendAuthService
  ) {
    this.maxToday = new Date();
    this.createForm();
    this.firebaseService.createPageLog('User Visit Page - Create Servicer SPV');
  }

  ngOnInit() {}

  submit() {
    this.isSubmitted = true;
    if (!this.servicerSPVCreationForm.valid && !this.verifyPass()) {
      this.servicerSPVCreationForm.get('firstName').markAsTouched();
      this.servicerSPVCreationForm.get('lastName').markAsTouched();
      this.servicerSPVCreationForm.get('email').markAsTouched();
      this.servicerSPVCreationForm.get('password').markAsTouched();
    } else {
      const objectToSave = this.firebaseService.createServicerSPV(this.servicerSPVCreationForm);
      this.firebaseService
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);

          // @@ hydra - 2023-05-01 - SEND EMAIL WITH TEMPALTE
          const params = {
            firstname: undefined,
            lastname: undefined,
            subject: undefined,
            templateName: undefined,
            to: undefined
          };

          params.to = objectToSave.email;
          params.firstname = objectToSave.firstName;
          params.lastname = objectToSave.lastName;
          params.subject = 'Registrazione IncassaOra';
          params.templateName = 'registrationSuccessFull';

          this.mailerService.sendEmailTemplate(params);

          // @@ hydra - 2023-01-31 - PASSWORD HASH
          try {
            const newPass = bcrypt.hashSync(objectToSave.password, environment.saltPass);
            this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
          } catch (e) {}
          // *********************************************************************************************

          this.router.navigate(['/home/servicer-spv']);
        })
        .catch(error => {
          // Handle Errors here.
          const errorCode = error.code;
          this.errorMessage = error.message;
        });
    }
  }

  createForm() {
    this.servicerSPVCreationForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      telefono: new FormControl(''),
      fiscal_code: new FormControl('', [Validators.required]),
      data_nascita: new FormControl('', [Validators.required]),
      data_assunzione: new FormControl(''),
      data_contratto: new FormControl(''),
      ragioneSociale: new FormControl('', [Validators.required]),
      indirizzo: new FormControl('', [Validators.required]),
      cap: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      province: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
      piva: new FormControl('', [Validators.required]),
      rea: new FormControl('', [Validators.required]),
      abi: new FormControl('', [Validators.required]),
      pec: new FormControl('', [Validators.required])
    });
  }

  get email() {
    return this.servicerSPVCreationForm.get('email');
  }

  get password() {
    return this.servicerSPVCreationForm.get('password');
  }

  get firstName() {
    return this.servicerSPVCreationForm.get('firstName');
  }

  get lastName() {
    return this.servicerSPVCreationForm.get('lastName');
  }

  verifyPass() {
    console.log(this.servicerSPVCreationForm.get('password').value);
    console.log(this.servicerSPVCreationForm.get('email').value);

    if (
      this.servicerSPVCreationForm.get('password').value === this.servicerSPVCreationForm.get('email').value ||
      this.servicerSPVCreationForm.get('password').value === this.servicerSPVCreationForm.get('firstName').value ||
      this.servicerSPVCreationForm.get('password').value === this.servicerSPVCreationForm.get('lastName').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
