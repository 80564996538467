import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment.prod';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-banner-kyc-spv',
  templateUrl: './banner-kyc-spv.component.html',
  styleUrls: ['./banner-kyc-spv.component.scss']
})
export class BannerKycSpvComponent implements OnInit {
  documentKycSPV: any;
  percentualeCompletamento: number;

  constructor(
    private firebase: FirebaseTranchedService,
    private http: HttpClient,
    private backendAuthService: BackendAuthService
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(
        `${environment.laravelServer}/getAllDocumentByUserId`,
        '{"user_id":"' + this.firebase.loggedUser.id + '"}',
        httpOptions
      )
      .subscribe(data => {
        // console.log(data);
        // this.entity = data;
        this.documentKycSPV = data.data;
        this.updatePercetableUpload();
      });
  }

  ngOnInit() {}

  updatePercetableUpload() {
    let modelloKYC = 0;
    let visuraCam = 0;
    let fronIBAN = 0;
    let docIdent = 0;
    let bilancio = 0;
    let attoCost = 0;

    this.documentKycSPV.forEach(document_ => {
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == "Carta d'Identità"
      ) {
        docIdent = 20;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Scan of a proof of Iban'
      ) {
        fronIBAN = 15;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'SchedaKYC'
      ) {
        modelloKYC = 20;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Documenti aziendali ufficiali (Visura Camerale e Statuto)'
      ) {
        visuraCam = 15;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Bilancio'
      ) {
        bilancio = 15;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Atto Costitutivo'
      ) {
        attoCost = 15;
      }

      const summa = modelloKYC + visuraCam + fronIBAN + docIdent + bilancio + attoCost;
      this.percentualeCompletamento = summa;
    });
  }
}
