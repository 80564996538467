import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '@env/environment.prod';
import { SessionStorageModel } from '@app/models/SessionStorage.model';

@Injectable({
  providedIn: 'root'
})
export class BackendAuthService {
  sessionStorageModel: SessionStorageModel = new SessionStorageModel();

  private httpOptions;
  private countLogin = 0;

  constructor(private http: HttpClient) {
    this.countLogin = 0;

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.sessionStorageModel.backendToken
      })
    };

    // TODO:
    //  verificare sempre il token,
    this.verify();
    //  se non valido rifare il login per un massimo di 3 tentativi
    //  se tutti i tentativi falliscono, allora lanciare alert e forzare il logout
  }

  login() {
    const body =
      '{\n' +
      '    "email":"' +
      environment.backend.auth.email +
      '",\n' +
      '    "password":"' +
      environment.backend.auth.password +
      '"\n' +
      '}';

    this.http.post<any>(environment.laravelServer + '/sysuser/login', body, this.httpOptions).subscribe(data => {
      try {
        // @ts-ignore
        if (data.token !== '') {
          // console.log(data.token);
          // @ts-ignore
          this.sessionStorageModel.backendToken = data.token;
        } else {
          alert('Error connection to backend');
        }
      } catch (e) {
        alert('Error connection to backend');
      }
    });
  }

  private verify() {
    console.log('Verify tk');
    if (this.sessionStorageModel.backendToken !== '') {
      console.log('tk FULL');
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.sessionStorageModel.backendToken
        })
      };
      this.http.post<any>(environment.laravelServer + '/sysuser/verifyLogin', '', httpOptions).subscribe(data => {
        console.log(data);
      });
    } else {
      console.log('tk vuoto');
      this.login();
    }
  }
}
