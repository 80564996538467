export class LogUtils {
  /* we can provide here all the log types we want to use*/
  static accessLogType = 'ACCESS';
  static operationLogType = 'OPERATION';
  static emailLogType = 'EMAIL';
  static pageVisitedLogType = 'PAGEVISITED';
  static tigranLogType = 'TIGRAN';
  static smsLogType = 'SMS';
  /*we can provide here all the log messages we want to use */
  static accessLogMessage = 'User logged in';
  static operationLogMessage = 'User makes an operation';
  static pageVisitedLogMessage = 'User visited new page';
  static tigranLogMessage = 'User made a tigran request';
  static smsLogMessage = 'User made a sms request';

  static getEmailLogMessage(userId: string, typology: string): string {
    return 'User ' + userId + 'received an email of ' + typology + 'typology.';
  }
}
