import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyComponent } from './company.component';
import { CompanyRoutingModule } from './company-routing.module';
import { HomeModule } from '@app/home/home.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PendingInvestorComponent } from './pending-investor/pending-investor.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule } from 'ngx-clipboard';
import { SharedModule } from '@app/shared';
import { DocumentSpvComponent } from './document-spv/document-spv.component';
import { WidgetReportCompanyComponent } from './widget-report-company/widget-report-company.component';
import { MonthlyDashboardComponent } from './monthly-dashboard/monthly-dashboard.component';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    CompanyComponent,
    PendingInvestorComponent,
    DocumentSpvComponent,
    WidgetReportCompanyComponent,
    MonthlyDashboardComponent
  ],
  imports: [
    HomeModule,
    CommonModule,
    ReactiveFormsModule,
    CompanyRoutingModule,
    NgbModule,
    ClipboardModule,
    SharedModule,
    FormsModule,
    ChartsModule,
    NgxChartsModule
  ]
})
export class CompanyModule {}
