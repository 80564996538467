import { Utilities } from '@app/utilities/Utilities';
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Invoice } from '@app/models/Invoice.model';
import { Router } from '@angular/router';
import { BackendAuthService } from '@app/core/backend-auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public usersList: Observable<any[]>;
  usersItems: any[] = [];
  invoiceStatus: string[] = Utilities.invoiceStatus;
  sub: Subscription;
  constructor(
    private firebase: FirebaseTranchedService,
    private router: Router,
    private backendAuthService: BackendAuthService
  ) {}

  ngOnInit() {
    this.usersList = this.firebase.getOriginatorsForPartner();
    this.sub = this.usersList.subscribe(data => {
      this.usersItems = data;
      this.countWallets();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  openDetail(id: string) {
    this.router.navigate(['/partner/wallet-details/' + id]);
  }

  countWallets() {
    for (let originator of this.usersItems) {
      //variabili d'appoggio non esistono nel model
      originator.walletsActive = 0;
      originator.walletsClosed = 0;
      originator.invoiceActive = 0;
      originator.invoiceClosed = 0;
      let wallets = this.firebase.getWalletsByIdOriginator(originator.id);
      let walletsItems;
      wallets.subscribe(data => {
        walletsItems = data;
        //variabile d'appoggio
        originator.wallets = walletsItems;

        for (let wallet of walletsItems) {
          wallet.invoiceActive = 0;
          wallet.invoiceClosed = 0;
          Utilities.getDaysLeft(wallet);
          if (wallet.daysLeft > 0) {
            originator.walletsActive++;
          } else {
            originator.walletsClosed++;
          }

          this.firebase.getInvoicesByID(wallet.id).subscribe(data => {
            let invoices = data as Invoice[];
            for (let invoice of invoices) {
              if (invoice.status === this.invoiceStatus[0]) continue;
              if (invoice.status === this.invoiceStatus[6] || invoice.status === this.invoiceStatus[5]) {
                originator.invoiceClosed++;
                wallet.invoiceClosed++;
              } else {
                originator.invoiceActive++;
                wallet.invoiceActive++;
              }
            }
          });
        }
      });
    }
  }
}
