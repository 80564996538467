import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomePartnerComponent } from './home-partner.component';
import { SharedModule } from '@app/shared';
import { HomePartnerRoutingModule } from './home-partner-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  declarations: [HomePartnerComponent, DashboardComponent],
  imports: [CommonModule, SharedModule, HomePartnerRoutingModule]
})
export class HomePartnerModule {}
