import { Investor } from './../Investor.model';

export class DocumentoKey {
  static tableName = 'DocumentiKey';
  id: string;
  uid: string;
  nome_file: string;
  descrizione: string;
  dataCaricamento: string;
  investorUUID: string;
  url: string;
  modified: boolean; // campo ausiliario da cancellare quando si va su firebase

  static cleanForFirebase(contractInvestor: DocumentoKey) {
    delete contractInvestor.modified;
  }
  constructor() {}
}
