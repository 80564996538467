import { Injectable } from '@angular/core';
import { FirebaseTranchedService } from './core/firebase-tranched.service';

@Injectable({
  providedIn: 'root'
})
export class PendingService {
  public pending: number;
  public pendingInvestor: number;
  public pendingFornitore: number;

  constructor(public firebaseService: FirebaseTranchedService) {
    this.pending = 0;
    this.pendingInvestor = 0;
    this.pendingFornitore = 0;
  }

  public getPending() {
    return this.pending;
  }

  public getPendingInvestor() {
    return this.pendingInvestor;
  }

  public getPendingFornitore() {
    return this.pendingFornitore;
  }

  public add(value: number) {
    this.pending = this.pending + value;
  }

  public addPenInvestor(value: number) {
    this.pendingInvestor = this.pendingInvestor + value;
  }
  public addPenFornitore(value: number) {
    this.pendingFornitore = this.pendingFornitore + value;
  }

  public decPenInvestor(value: number) {
    this.pendingInvestor = this.pendingInvestor - value;
  }
  public decPenFornitore(value: number) {
    this.pendingFornitore = this.pendingFornitore - value;
  }

  public dec(value: number) {
    this.pending = this.pending - value;
  }

  public setPendingInvestor(value: number) {
    this.pendingInvestor = value;
  }

  public setPendingFornitore(value: number) {
    this.pendingFornitore = value;
  }

  public setPending(value: number) {
    this.pending = value;
  }
}
