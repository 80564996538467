import { Component, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { SearchForSubjectResponse } from '@app/tigran/tigran';
import { Utilities } from '@app/utilities/Utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SmsService } from '@app/sms/sms.service';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { NgbdModalConfirmComponent } from '../wallet-details/request-detail-modal/request-detail-modal.component';
import { ShowMessageService } from '@app/core/show-message.service';
import { Shell } from '@app/shell/shell.service';
@Component({
  selector: 'app-tigran-report',
  templateUrl: './tigran-report.component.html',
  styleUrls: ['./tigran-report.component.scss']
})
export class TigranReportComponent implements OnInit {
  vatNumberForm!: FormGroup;
  currentVatNumber: string;
  showWarningMessage = false;
  isLoading = false;
  morescore: string;
  probdef: string;
  listaGenerale: any[];
  listaDocumenti: any[];
  userSelected: string;
  documentType: number;
  fileToUpload: any;
  uploading: boolean;
  uploadPercentage: string;
  base64: any;
  privato: boolean;
  tipoDocumento = Utilities.tipoDocumento;
  statoDocumento = Utilities.statoDocumento;
  error: string;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private http: HttpClient,
    private sms: SmsService,
    private ls: LemonwayService,
    private _modalService: NgbModal,
    public showService: ShowMessageService
  ) {
    this.createForm();
    this.listaGenerale = [];
    this.userSelected = '0';
    this.documentType = 100;
    firebaseService.getUsersByRole(Utilities.userType[5]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
    firebaseService.getUsersByRole(Utilities.userType[1]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
    firebaseService.getUsersByRole(Utilities.userType[2]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
    firebaseService.getUsersByRole(Utilities.userType[3]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
    // console.log(this.listaGenerale);
  }
  ngOnInit() {}

  open() {
    this.isLoading = true;
    if (this.userSelected == '0') {
      alert("seleziona l'utente");
      return;
    }
  }

  onChange(event: any) {
    // console.log(event);
    this.firebaseService.getUserByID(this.userSelected).then(data => {
      if (data.exists) {
        const us = data.data();
        console.log(us);

        this.firebaseService.getLogTigran(this.userSelected).subscribe(data => {
          console.log(data);
          this.listaDocumenti = data;
        });
      }
    });
  }

  onChange2(event: any) {
    // console.log(event);
  }

  createForm() {
    this.vatNumberForm = this.formBuilder.group({
      vatNumber: new FormControl('', Validators.required),
      user: new FormControl('')
    });
  }
  searchWithVatNumber() {
    /*
    this.http.get<any>(`http://incassaora-laravel.test/api/p2p`).subscribe(data => {
      //TODO finire il test di Lemonway con le informazioni di interesse andare ad insereire...
      console.log(data);
    });
    */
    /*
    this.sms.sendSms("3482560482").subscribe((data) => {
      console.log(data);
      var otp=data.otp;
      this.firebaseService.createSmsLog(otp);
    });
    */
    this.firebaseService.getLog('sqS4r').subscribe(data => {
      // console.log(data[0]);
    });

    const comment = '';
    this.ls.transaction(1500, '03296450780', '123456', comment).subscribe(data => {
      // console.log(data);
    });
  }

  get vatNumber() {
    return this.vatNumberForm.get('vatNumber');
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];
  }
}

const MODALS: { [name: string]: Type<any> } = {
  focusFirst: NgbdModalConfirmComponent
};
