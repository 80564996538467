import { Component, OnInit } from '@angular/core';

import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-app-datipersonali',
  templateUrl: './app-datipersonali.component.html',
  styleUrls: ['./app-datipersonali.component.scss']
})
export class AppDatipersonaliComponent implements OnInit {
  investorEditForm!: FormGroup;
  companyEditForm!: FormGroup;
  originatorEditForm!: FormGroup;
  user: any;
  loggedUser: any;

  private certificazioneCredito = '';
  private prodotti = [];

  constructor(
    private firebase: FirebaseTranchedService,
    private formBuilder: FormBuilder,
    private backendAuthService: BackendAuthService
  ) {
    if (this.firebase.isCompany()) {
      this.createFormCompany();
    }
    if (this.firebase.isInvestor()) {
      this.createFormInvestor();
    }
    if (this.firebase.isOriginator()) {
      this.createFormOriginator();
    }
  }

  ngOnInit() {
    console.log(this.firebase.loggedUser);
    //INVESTOR & ORIGINATOR//

    if (this.firebase.loggedUser.originatorCreditsCheck) {
      this.certificazioneCredito = 'SI';
    } else {
      this.certificazioneCredito = 'NO';
    }

    let count = 0;
    this.firebase.loggedUser.originatorProducts.forEach(element => {
      if (element) {
        if (count == 0) {
          this.prodotti.push('Yellow Finance');
        }
        if (count == 1) {
          this.prodotti.push('Supply Cash Finance');
        }
        if (count == 2) {
          this.prodotti.push('Blue Finance');
        }
      }
      count++;
    });

    //COMPANY//

    this.firebase.loggedUser.originatorProducts.forEach(element => {
      if (element) {
        if (count == 0) {
          this.prodotti.push('true');
        }
        if (count == 1) {
          this.prodotti.push('false');
        }
        if (count == 2) {
          this.prodotti.push('false');
        }
      }
      count++;
    });
  }
  //INVESTOR//

  createFormInvestor() {
    this.investorEditForm = this.formBuilder.group({
      Referentename: new FormControl('', [Validators.required]),
      Referentesurname: new FormControl('', [Validators.required]),
      investorPec: new FormControl('', [Validators.required]),
      legalstreet: new FormControl('', [Validators.required]),
      legalcity: new FormControl('', [Validators.required]),
      legalprovince: new FormControl('', [Validators.required]),
      //viasedeop:new FormControl('', [Validators.required]),
      // city:new FormControl('', [Validators.required]),
      // province:new FormControl('', [Validators.required]),
      //mailreferente:new FormControl('', [Validators.required]),
      phone_number: new FormControl('', [Validators.required]),
      investorWebSite: new FormControl('', [Validators.required])
    });

    this.investorEditForm.setValue({
      Referentename: this.firebase.loggedUser.originatorSolicitorName,
      Referentesurname: this.firebase.loggedUser.originatorSolicitorSurname,
      investorPec: this.firebase.loggedUser.investorPec,
      legalstreet: this.firebase.loggedUser.investorOfficeRegisteredStreet,
      legalcity: this.firebase.loggedUser.investorOfficeRegisteredCity,
      legalprovince: this.firebase.loggedUser.investorOfficeRegisteredProvince,
      // viasedeop:this.firebase.loggedUser.investorrOfficeOperationalStreet,
      // city:this.firebase.loggedUser.investorOfficeOperationalCity,
      // Province:this.firebase.loggedUser.investorOfficeOperationalProvince,
      // mailreferente:this.firebase.loggedUser.originatorSolicitorEmail,
      phone_number: this.firebase.loggedUser.phone_number,
      investorWebSite: this.firebase.loggedUser.investorWebSite
    });
  }

  salvaInvestor() {
    console.log(this.investorEditForm.value.Referentename);
    console.log(this.investorEditForm.value.Referentesurname);
    console.log(this.investorEditForm.value.investorPec);
    console.log(this.investorEditForm.value.legalstreet);
    console.log(this.investorEditForm.value.legalcity);
    console.log(this.investorEditForm.value.legalprovince);
    // console.log(this.investorEditForm.value.viasedeop);
    //console.log(this.investorEditForm.value.city);
    //console.log(this.investorEditForm.value.province);
    // console.log(this.investorEditForm.value.mailreferente);
    console.log(this.investorEditForm.value.phone_number);
    console.log(this.investorEditForm.value.investorWebSite);

    const object = {
      originatorSolicitorName: this.investorEditForm.value.Referentename,
      originatorSolicitorSurname: this.investorEditForm.value.Referentesurname,
      investorPec: this.investorEditForm.value.investorPec,
      investorOfficeRegisteredStreet: this.investorEditForm.value.legalstreet,
      investorOfficeRegisteredCity: this.investorEditForm.value.legalcity,
      investorOfficeRegisteredProvince: this.investorEditForm.value.legalprovince,
      //investorrOfficeOperationalStreet:this.investorEditForm.value.viasedeop,
      //investorOfficeOperationalCity:this.investorEditForm.value.city,
      //investorOfficeOperationalProvince:this.investorEditForm.value.province,
      originatorSolicitorEmail: this.investorEditForm.value.mailreferente,
      phone_number: this.investorEditForm.value.phone_number,
      investorWebSite: this.investorEditForm.value.investorWebSite
    };

    this.firebase.updateEntity(this.firebase.loggedUser.id, 'Users', object);
  }

  //COMPANY//
  createFormCompany() {
    this.companyEditForm = this.formBuilder.group({
      type: new FormControl('', [Validators.required]),
      pec: new FormControl('', [Validators.required]),
      refName: new FormControl('', [Validators.required]),
      refMail: new FormControl('', [Validators.required]),
      refPhone: new FormControl('', [Validators.required]),
      legalName: new FormControl('', [Validators.required]),
      legalcity: new FormControl('', [Validators.required]),
      legalprovince: new FormControl('', [Validators.required]),
      legalstreet: new FormControl('', [Validators.required]),
      legalcap: new FormControl('', [Validators.required])
    });

    //console.log(this.firebase.loggedUser);

    this.companyEditForm.setValue({
      type: this.firebase.loggedUser.assignorType,
      pec: this.firebase.loggedUser.assignorPec,
      refName: this.firebase.loggedUser.refName,
      refMail: this.firebase.loggedUser.refMail,
      refPhone: this.firebase.loggedUser.refPhone,
      legalName: this.firebase.loggedUser.legalName,
      legalcity: this.firebase.loggedUser.legalAddressCity,
      legalprovince: this.firebase.loggedUser.legalAddressProvince,
      legalstreet: this.firebase.loggedUser.legalAddressStreet,
      legalcap: this.firebase.loggedUser.legalAddressCAP
    });
  }

  salvaCompany() {
    console.log(this.companyEditForm.value.type);
    console.log(this.companyEditForm.value.pec);
    console.log(this.companyEditForm.value.refName);
    console.log(this.companyEditForm.value.refMail);
    console.log(this.companyEditForm.value.refPhone);
    console.log(this.companyEditForm.value.legalName);
    console.log(this.companyEditForm.value.legalcity);
    console.log(this.companyEditForm.value.legalprovince);
    console.log(this.companyEditForm.value.legalstreet);
    console.log(this.companyEditForm.value.legalcap);

    const object = {
      assignorType: this.companyEditForm.value.type,
      assignorPec: this.companyEditForm.value.pec,
      refName: this.companyEditForm.value.refName,
      refMail: this.companyEditForm.value.refMail,
      refPhone: this.companyEditForm.value.refPhone,
      legalName: this.companyEditForm.value.legalName,
      legalAddressCity: this.companyEditForm.value.legalAddressCity,
      legalAddressProvince: this.companyEditForm.value.legalprovince,
      legalAddressStreet: this.companyEditForm.value.legalstreet,
      legalAddressCAP: this.companyEditForm.value.legalcap
    };

    this.firebase.updateEntity(this.firebase.loggedUser.id, 'Users', object);
  }

  //ORIGINATOR//

  createFormOriginator() {
    this.originatorEditForm = this.formBuilder.group({
      PEC1: new FormControl('', [Validators.required]),
      legalstreet1: new FormControl('', [Validators.required]),
      legalcity1: new FormControl('', [Validators.required]),
      legalprovince1: new FormControl('', [Validators.required]),
      Operativa1: new FormControl('', [Validators.required]),
      OperativaCity1: new FormControl('', [Validators.required]),
      OperativaProvince1: new FormControl('', [Validators.required]),
      Referentename1: new FormControl('', [Validators.required]),
      Referentesurname1: new FormControl('', [Validators.required]),
      refMail1: new FormControl('', [Validators.required]),
      refPhone1: new FormControl('', [Validators.required]),
      Web: new FormControl('', [Validators.required])
    });

    this.originatorEditForm.setValue({
      PEC1: this.firebase.loggedUser.originatorPec,
      legalstreet1: this.firebase.loggedUser.originatorOfficeRegisteredStreet,
      legalcity1: this.firebase.loggedUser.originatorOfficeRegisteredCity,
      legalprovince1: this.firebase.loggedUser.originatorOfficeRegisteredProvince,
      Operativa1: this.firebase.loggedUser.originatorOfficeOperationalStreet,
      OperativaCity1: this.firebase.loggedUser.originatorOfficeOperationalCity,
      OperativaProvince1: this.firebase.loggedUser.originatorOfficeOperationalProvince,
      Referentename1: this.firebase.loggedUser.originatorAdminReferentName,
      Referentesurname1: this.firebase.loggedUser.originatorAdminReferentSurname,
      refMail1: this.firebase.loggedUser.originatorAdminReferentEmail,
      refPhone1: this.firebase.loggedUser.originatorAdminReferentTelephone,
      Web: this.firebase.loggedUser.originatorWebSite
    });
  }

  salvaOriginator() {
    console.log(this.originatorEditForm.value.PEC1);
    console.log(this.originatorEditForm.value.legalstreet1);
    console.log(this.originatorEditForm.value.legalcity1);
    console.log(this.originatorEditForm.value.legalprovince1);
    console.log(this.originatorEditForm.value.Operativa1);
    console.log(this.originatorEditForm.value.OperativaCity1);
    console.log(this.originatorEditForm.value.OperativaProvince1);
    console.log(this.originatorEditForm.value.Referentename1);
    console.log(this.originatorEditForm.value.Referentesurname1);
    console.log(this.originatorEditForm.value.refMail1);
    console.log(this.originatorEditForm.value.refPhone1);
    console.log(this.originatorEditForm.value.Web);

    const object = {
      originatorPec: this.originatorEditForm.value.PEC1,
      originatorOfficeRegisteredStreet: this.originatorEditForm.value.legalstreet1,
      originatorOfficeRegisteredCity: this.originatorEditForm.value.legalcity1,
      originatorOfficeRegisteredProvince: this.originatorEditForm.value.legalprovince1,
      originatorOfficeOperationalStreet: this.originatorEditForm.value.Operativa1,
      originatorOfficeOperationalCity: this.originatorEditForm.value.OperativaCity1,
      originatorOfficeOperationalProvince: this.originatorEditForm.value.OperativaProvince1,
      originatorAdminReferentName: this.originatorEditForm.value.Referentename1,
      originatorAdminReferentSurname: this.originatorEditForm.value.Referentesurname1,
      originatorAdminReferentEmail: this.originatorEditForm.value.refMail1,
      originatorAdminReferentTelephone: this.originatorEditForm.value.refPhone1,
      originatorWebSite: this.originatorEditForm.value.Web
    };

    this.firebase.updateEntity(this.firebase.loggedUser.id, 'Users', object);
  }
}
