import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { ProfiloPersonaleComponent } from './profilo-personale.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/profiloPersonale', pathMatch: 'full' },
    {
      path: 'profiloPersonale',
      component: ProfiloPersonaleComponent,
      data: { title: 'profiloPersonale' }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProfiloPersonaleRoutingModule {}
