import { Originator } from './Originator.model';
import { User } from './User.model';

export class WalletFromDb {
  static tableName = 'Wallets';
  id: string;
  walletName = '';
  region = '';
  sector = '';
  openingDate = '';
  endingDate = '';
  minInvoice = -1;
  maxInvoice = -1;
  originatorName = '';
  originatorUId = '';
  description = '';
  logo = '';
  listInvestitori: string[] = [];

  productsNode = [new ProductNode(), new ProductNode(), new ProductNode()];

  paymentDays = -1;
  ratingSystemLabel: string[] = [];
  ratingSystemPercentage: number[] = [];
  closed = false;
  daysLeft = -1;
}
export class Wallet extends WalletFromDb {
  plafondYellow: number;
  plafondBlue: number;
  plafondAzure: number;
  logo: string;
  enabledYellow: boolean;
  enabledBlue: boolean;
  enabledAzure: boolean;

  priorityYellow: number;
  priorityBlue: number;
  priorityAzure: number;

  old = false;

  // phase number id
  // 1: verifica preliminare
  // 2: istruttoria
  // 3: ramp up
  // 4: erogazione
  // 5: monitoring
  phaseId: number;

  constructor() {
    super();
  }
}

export class ProductNode {
  name: string;
  enabled: boolean;
  priority: number;
  plafond: number;
  counter: number;
}
