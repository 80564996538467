import { Utilities } from '@app/utilities/Utilities';
import { User } from './User.model';

// FORNITORE
export class Assignor extends User {
  assignorType: string; // Spa, srl
  assignorPec: string;
  refMail: string;
  refPhone: string;
  pIva: string;
  fiscalCode: string;
  originatorSolicitorBirthdate: string;
  originatorSolicitorBirthplace: string;
  assignorMoreScore: string;
  assignorDefault: string;
  lastTigranDate: string;

  isPrivato: boolean;

  legalName: string; // Nome e cognome rappresenta legale
  refName: string; // ??????
  refCogn: string;
  listWallet: Array<string> = [];

  virtualIban: string;
  idvirtualIban: string;
  assignorName: string;
  denominazione: string;

  iban: string;

  phone_number: number;

  lemonwayAccount: boolean = false;
  statoLemonway: string = Utilities.statoLemon[3];

  // nuovi valori
  legalAddressStreet: string;
  legalAddressCity: string;
  legalAddressProvince: string;
  legalAddressCAP: string;
  REA_CODE: string;
  legalAddressDataNascita: string;

  viaLeg: string;
  comuneLeg: string;
  provinciaLeg: string;
  nazioneLeg: string;
  capLegale: string;
  nazione = '';

  statusSPV: string;
  investorSPV: any[];
  rappresentante: any;
  legalrappresentante: any;

  constructor() {
    super();
  }
}
