import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { environment } from '@env/environment';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-widget-report-company',
  templateUrl: './widget-report-company.component.html',
  styleUrls: ['./widget-report-company.component.scss']
})
export class WidgetReportCompanyComponent implements OnInit {
  private progressivoInvestimento = 0;
  private cessioneMedia: any;
  private numeroOperazioni: any;

  private balance: any = 0;
  private nessunConto: boolean;

  constructor(
    private firebase: FirebaseTranchedService,
    private http: HttpClient,
    private ls: LemonwayService,
    private backendAuthService: BackendAuthService
  ) {
    this.getSaldo();
    this.getNumeroOperazioni();
  }

  getSaldo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.ls.infoAccount(this.firebase.loggedUser.pIva).subscribe(data => {
      //console.log('profile data=>', data);
      this.balance = data.account.balance / 100;
      if (this.balance == null) {
        this.nessunConto = true;
      }
    });
  }

  getNumeroOperazioni() {
    if (this.firebase.loggedUser.roles == 'company') {
      const body = {
        id: this.firebase.loggedUser.id
      };
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      this.http
        .post<any>(`${environment.laravelServer}/invoice/getInvoicesByCedenteId`, body, httpOptions)
        .subscribe(resp => {
          this.numeroOperazioni = resp.recordsTotal;

          resp.data.forEach(dato => {
            this.progressivoInvestimento = this.progressivoInvestimento + dato.importoFattura;
          });

          this.cessioneMedia = this.progressivoInvestimento / this.numeroOperazioni;
        });
    }
  }

  ngOnInit() {}
}
