import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { environment } from '@env/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MonthlyDashboardService {
  private loggedID: string;
  public progCed: Number[] = [];
  public cessioni: Number[] = [];
  public mediaFatture: Number[] = [];

  constructor(public firebase: FirebaseTranchedService, private http: HttpClient) {
    this.loggedID = this.firebase.loggedUser.id;
    this.getInfo();
  }

  getInfo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(
        `${environment.laravelServer}/invoice/getInvoicesByCedenteIdDateFilter`,
        '{"id":"' + this.loggedID + '"}',
        httpOptions
      )
      .subscribe((resp: any) => {
        for (let i in resp.progCeduto) {
          this.progCed.push(JSON.parse(resp.progCeduto[i]));
        }
        //console.log("ceduto: " + this.progCed);
        for (let i in resp.importoMedioFatture) {
          this.mediaFatture.push(JSON.parse(resp.importoMedioFatture[i]));
        }
        //console.log("media fatture: " + this.mediaFatture);
        for (let i in resp.cessioni) {
          this.cessioni.push(JSON.parse(resp.cessioni[i]));
        }
        //console.log("cedenti: " + this.cessioni);
      });
  }
}
