import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { Utilities } from '@app/utilities/Utilities';
import { HomeServicerComponent } from '@app/home-servicer/home-servicer.component';
import { WalletListComponent } from '@app/home/wallet-list/wallet-list.component';
import { PendingRequestListComponent } from '@app/home/pending-request-list/pending-request-list.component';
import { UsersCredentialComponent } from '@app/home/users-credential/users-credential.component';
import { WalletDetailsComponent } from '@app/home/wallet-details/wallet-details.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/servicer/pending-requests', pathMatch: 'full' },
    {
      path: 'servicer',
      component: HomeServicerComponent,
      children: [
        {
          path: 'pending-requests',
          component: PendingRequestListComponent
        },
        {
          path: 'list-wallet',
          component: WalletListComponent
        },
        {
          path: 'wallet-details/:id',
          component: WalletDetailsComponent
        },
        {
          path: 'user-credential',
          component: UsersCredentialComponent
        }
      ],
      data: { title: extract('Servicer'), role: Utilities.userType[6] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeServicerRoutingModule {}
