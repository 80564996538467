import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utilities } from '@app/utilities/Utilities';
import { Observable } from 'rxjs';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Injectable({
  providedIn: 'root'
})
export class LemonwayService {
  newLegalAccount = Utilities.newLegalAccount;

  constructor(private http: HttpClient, private backendAuthService: BackendAuthService) {}

  urlp2p = Utilities.urlgenerale + '/p2p';
  urlcreateaNewLegalAccount = Utilities.urlgenerale + '/createaNewLegalAccount';
  urlcreateaNewIndividualAccount = Utilities.urlgenerale + '/createaNewIndividualAccount';
  urlUpload = Utilities.urlgenerale + '/uploadDocumentsforKYC';
  urlInfo = Utilities.urlgenerale + '/getDetailedPaymentAccount';
  urlPaymentInfo = Utilities.urlgenerale + '/getlistofallPaymentAccountTransactions';
  urlDocumentInfo = Utilities.urlgenerale + '/getDocumentAssociatedPaymentAccount';
  urlStatusAccount = Utilities.urlgenerale + '/getDetailedPaymentAccount';
  urlvirtualIban = Utilities.urlgenerale + '/createDedicatedVirtualIBANs';
  urlmoneyout = Utilities.urlgenerale + '/addanIBANtoaPaymentAccountforMoneyOuts';

  transaction(amount: number, creditAccountId: string, debitAccountId: string, comment: string): Observable<any> {
    // console.log(
    //   this.urlp2p + '?amount=' + amount + '&creditAccountId=' + creditAccountId + '&debitAccountId=' + debitAccountId
    // );
    return this.http.get<any>(
      this.urlp2p +
        '?amount=' +
        amount +
        '&creditAccountId=' +
        creditAccountId +
        '&debitAccountId=' +
        debitAccountId +
        '&comment=' +
        comment
    );
  }

  uploadDoc(id: string, type: number, name: string, buffer: any): Observable<any> {
    //  console.log(this.urlUpload);
    const body = {
      name: name,
      type: type,
      buffer: buffer,
      id: id
    };
    // console.log(body);
    return this.http.post<any>(this.urlUpload, {
      name: name,
      type: type,
      buffer: buffer,
      id: id
    });
  }

  infoAccount(id: string): Observable<any> {
    return this.http.get<any>(this.urlInfo + '?id=' + id);
  }

  infoDocument(id: string): Observable<any> {
    return this.http.get<any>(this.urlDocumentInfo + '?id=' + id);
  }

  infoStatus(id: string): Observable<any> {
    return this.http.get<any>(this.urlStatusAccount + '?id=' + id);
  }

  infoListofPayment(id: string): Observable<any> {
    return this.http.get<any>(this.urlPaymentInfo + '?id=' + id);
  }

  createDedicateVirtualIban(wallet: string, country: string): Observable<any> {
    //infostatus internalId per ottenere wallet
    //country attualmente non è utilizzato va impostato tramite parametro per il momento non inserito poichè
    //l'utente può selezionare solo IBAN italiani capire se il sistema permette questa modifica
    //console.log(this.urlvirtualIban + '?wallet=' + wallet + '&country=' + 'IT');
    return this.http.get<any>(this.urlvirtualIban + '?wallet=' + wallet + '&country=' + 'IT');
  }

  moneOuts(id: string, iban: string, holder: string): Observable<any> {
    //infostatus internalId per ottenere wallet
    return this.http.get<any>(this.urlmoneyout + '?accountId=' + id + '&holder=' + holder + '&iban=' + iban);
  }

  createaNewLegalAccount(
    accountId: string,
    firstName: string,
    lastName: string,
    nationality: string,
    payerOrBeneficiary: number,
    email: string,
    street: string,
    postCode: string,
    city: string,
    date: string,
    cityNa: string,
    title: string,
    phoneNumber: string,
    mobileNumber: string,
    isDebtor: boolean,
    isOneTimeCustomerAccount: boolean,
    name: string,
    description: string,
    isTechnicalAccount: boolean,
    reacode: string
  ): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.newLegalAccount.accountId = accountId;
    this.newLegalAccount.firstName = firstName;
    this.newLegalAccount.lastName = lastName;
    this.newLegalAccount.nationality = nationality;
    this.newLegalAccount.payerOrBeneficiary = payerOrBeneficiary;
    this.newLegalAccount.email = email;
    this.newLegalAccount.street = street;
    this.newLegalAccount.postCode = postCode;
    this.newLegalAccount.city = city;
    this.newLegalAccount.country = nationality;
    this.newLegalAccount.date = date;
    this.newLegalAccount.title = title;
    this.newLegalAccount.phoneNumber = phoneNumber;
    this.newLegalAccount.mobileNumber = mobileNumber;
    this.newLegalAccount.isDebtor = isDebtor;
    this.newLegalAccount.isOneTimeCustomerAccount = isOneTimeCustomerAccount;
    this.newLegalAccount.name = name;
    this.newLegalAccount.description = description;
    this.newLegalAccount.nationality = nationality;
    this.newLegalAccount.isTechnicalAccount = isTechnicalAccount;
    this.newLegalAccount.reacode = reacode;
    this.newLegalAccount.cityNascita = cityNa;

    return this.http.post<any>(this.urlcreateaNewLegalAccount, this.newLegalAccount, httpOptions);

    //
    // return this.http.get<any>(
    //   this.urlcreateaNewLegalAccount +
    //     '?accountId=' +
    //     accountId +
    //     '&firstName=' +
    //     firstName +
    //     '&lastName=' +
    //     lastName +
    //     '&nationality=' +
    //     nationality +
    //     '&payerOrBeneficiary=' +
    //     payerOrBeneficiary +
    //     '&email=' +
    //     email +
    //     '&street=' +
    //     street +
    //     '&postCode=' +
    //     postCode +
    //     '&city=' +
    //     city +
    //     '&date=' +
    //     date +
    //     '&title=' +
    //     title +
    //     '&phoneNumber=' +
    //     phoneNumber +
    //     '&mobileNumber=' +
    //     mobileNumber +
    //     '&isDebtor=' +
    //     isDebtor +
    //     '&isOneTimeCustomerAccount=' +
    //     isOneTimeCustomerAccount +
    //     '&name=' +
    //     name +
    //     '&description=' +
    //     description +
    //     '&country=' +
    //     nationality +
    //     '&isTechnicalAccount=' +
    //     isTechnicalAccount +
    //     '&reacode=' +
    //     reacode +
    //     '&cityNascita=' +
    //     cityNa
    // );
  }

  createaNewIndividualAccount(
    accountId: string,
    firstName: string,
    lastName: string,
    nationality: string,
    payerOrBeneficiary: number,
    email: string,
    street: string,
    postCode: string,
    city: string,
    date: string,
    cityNa: string,
    title: string,
    phoneNumber: string,
    mobileNumber: string,
    isDebtor: boolean,
    isOneTimeCustomerAccount: boolean
  ): Observable<any> {
    /*console.log(
      this.urlcreateaNewIndividualAccount +
        '?accountId=' +
        accountId +
        '&firstName=' +
        firstName +
        '&lastName=' +
        lastName +
        '&nationality=' +
        nationality +
        '&payerOrBeneficiary=' +
        payerOrBeneficiary +
        '&email=' +
        email +
        '&street=' +
        street +
        '&postCode=' +
        postCode +
        '&city=' +
        city +
        '&date=' +
        date +
        '&title=' +
        title +
        '&phoneNumber=' +
        phoneNumber +
        '&mobileNumber=' +
        mobileNumber +
        '&isDebtor=' +
        isDebtor +
        '&isOneTimeCustomerAccount=' +
        isOneTimeCustomerAccount +
        '&country=' +
        nationality
    );
    */
    return this.http.get<any>(
      this.urlcreateaNewIndividualAccount +
        '?accountId=' +
        accountId +
        '&firstName=' +
        firstName +
        '&lastName=' +
        lastName +
        '&nationality=' +
        nationality +
        '&payerOrBeneficiary=' +
        payerOrBeneficiary +
        '&email=' +
        email +
        '&street=' +
        street +
        '&postCode=' +
        postCode +
        '&city=' +
        city +
        '&date=' +
        date +
        '&title=' +
        title +
        '&phoneNumber=' +
        phoneNumber +
        '&mobileNumber=' +
        mobileNumber +
        '&isDebtor=' +
        isDebtor +
        '&isOneTimeCustomerAccount=' +
        isOneTimeCustomerAccount +
        '&country=' +
        nationality +
        '&cityNascita=' +
        cityNa
    );
  }
}
