import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { DataTablesResponse } from '@app/models/DataTablesResponse.model';

import { environment } from '@env/environment.prod';
import * as bcrypt from 'bcryptjs';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Injectable({
  providedIn: 'root'
})
export class InvestorSpvService {
  public data = [];

  constructor(
    private firebaseService: FirebaseTranchedService,
    private http: HttpClient,
    private backendAuthService: BackendAuthService
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<DataTablesResponse>(`${environment.laravelServer}/getAllUserByRole`, '{"role":"servicerSPV"}', httpOptions)
      .subscribe(resp => {
        // console.log("Data: ",resp.data);
        resp.data.forEach(element => {
          this.data.push({ item_id: element.uuid, item_text: element.email });
        });
      });
  }

  getData() {
    return this.data;
  }
}
