import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { PendingService } from '@app/pending.service';

@Component({
  selector: 'app-home-servicer',
  templateUrl: './home-servicer.component.html',
  styleUrls: ['./home-servicer.component.scss']
})
export class HomeServicerComponent implements OnInit {
  isLoading = false;
  pendingRequests = 0;

  constructor(public firebase: FirebaseTranchedService, public pending: PendingService) {}

  ngOnInit() {
    this.calculatePendingRequests();
  }

  calculatePendingRequests(): void {
    this.pending.setPending(0);
    this.firebase.getInvoicesNotApproved().subscribe(docs => {
      if (docs.length > 0) {
        // this.pendingRequests += docs.length;
        this.pending.add(docs.length);
      }
      this.firebase.getUsersByVerified(false).subscribe(users => {
        if (users.length > 0) {
          // this.pendingRequests += users.length;
          this.pending.add(users.length);
        }
      });
    });
  }
}
