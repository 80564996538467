import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-documento',
  templateUrl: './app-documento.component.html',
  styleUrls: ['./app-documento.component.scss']
})
export class AppDocumentoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
