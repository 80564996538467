import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFireAuth } from '@angular/fire/auth';
import { ContractInvestor } from '@app/models/document/ContractInvestor.model';
import jspdf, { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContrattoInvestitoreComponent } from '@app/home-investor/contratto-investitore/contratto-investitore.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit {
  uploading: boolean;
  fileToUpload: any;
  uploadPercentage: string;
  visualizza: boolean = true;
  contract: ContractInvestor;

  investorCreationForm!: FormGroup;

  listDocument: any;
  nessunaTrans: boolean;
  data;
  pdfTable: any;

  constructor(
    private storage: AngularFireStorage,
    public firebase: FirebaseTranchedService,
    private afAuth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal
  ) {
    this.contract = new ContractInvestor();
    this.createForm();
  }

  ngOnInit() {
    var date = new Date();
    this.data = date.toLocaleDateString();
    this.listDocument = [];
    // console.log(this.firebase.loggedUser.id);
    this.loadContract();
  }

  exportAsPDF(divId) {
    let data = document.getElementById('divId');
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL('image/png'); // 'image/jpeg' for lower quality output.
      let pdf = new jspdf('l', 'cm', 'a4'); //Generates PDF in landscape mode
      // let pdf = new jspdf('p', 'cm', 'a4'); Generates PDF in portrait mode
      pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);
      pdf.save('Filename.pdf');
    });
  }

  exportAsPDF2() {
    const link = document.createElement('div');

    // sdsd
    const data =
      '' +
      this.firebase.loggedUser.name +
      '<br>' +
      '' +
      this.firebase.loggedUser.investorOfficeRegisteredCity +
      '<br>' +
      '' +
      this.firebase.loggedUser.investorOfficeRegisteredProvince +
      '<br>' +
      '' +
      this.firebase.loggedUser.investorOfficeRegisteredStreet +
      '<br>' +
      '<p>Alla as u as em cortese attenzione</p> ' +
      '<p>[●]</p>' +
      '<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; [DATA]</p>' +
      '<p>&nbsp;/p> as' +
      '<p>Egregi as em Signori, /em></p as >' +
      'facendo as em as p seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di adesione ai servizi di FX12</em></p>' +
      ' as p&nbsp;/p>' +
      '<p>&nbsp as ;/p> as' +
      '<p>Oggetto as strong: Accordo di adesione ai servizi di FX12</strong></p>' +
      'as p&nbsp;/p> as' +
      '<p>Egregi Signori:/p>' +
      '<p>&nbsp as ;/p> as' +
      '<p>facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue:/p>' +
      '<p>&nbsp as ;/p>' +
      '<p>&nbsp as ;/p> as' +
      '<p>ACCORDO as strong</strong></p>' +
      ' as p&nbsp;/p> as';

    // data= data+ data + data +data;

    const doc = new jsPDF('p', 'pt', 'letter');
    //doc.setFontSize(20);

    doc.html(data, {
      // tslint:disable-next-line:no-shadowed-variable
      callback(doc) {
        doc.save('doc.pdf');
      }
    });
  }

  scarica() {
    let docDefinition = {
      pageSize: 'A4',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [60, 60, 60, 60],
      header: [
        {
          image: 'logo',
          width: 120,
          height: 100,
          alignment: 'center',
          margin: [5, 10]
        },

        { canvas: [{ type: 'rect', x: 170, y: 32, h: 100 }] }
      ],

      footer: [
        {
          text: 'FX12 s.r.l iscritta nell’albo speciale delle Start up innovative ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text:
            'Capitale sociale Euro 10.0000 i.v., Registro Imprese Napoli e Codice Fiscale N. 09528731210, R.E.A. Napoli N. 1039449, Part. IVA 09528731210 ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text: 'Sede legale: Via Caracciolo, 17, 80122 Napoli.',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text:
            'Sede operativa: Via Coroglio, 57/104, 80124 Napoli c/o Campania NewSteel | Tel: +39 081 7352503 Email: desk@fx12.it PEC fx12@pec.it',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        }
      ],

      content: [
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: this.firebase.loggedUser.name,
          margin: [0, 5]
        },
        {
          text: this.firebase.loggedUser.investorOfficeRegisteredCity,
          margin: [0, 5]
        },
        {
          text: this.firebase.loggedUser.investorOfficeRegisteredStreet,
          margin: [0, 5]
        },
        {
          text: 'Alla cortese attenzione',
          margin: [0, 10],
          decoration: 'underline',
          italics: true
        },
        {
          text: this.data,
          alignment: 'right',
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori, ',
          italics: true,
          margin: [0, 10]
        },
        {
          text:
            'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di adesione ai servizi di FX12',
          italics: true,
          margin: [0, 10]
        },
        {
          text: 'Oggetto: Accordo di adesione ai servizi di FX12',
          bold: true,
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori: ',
          margin: [0, 5]
        },
        {
          text: 'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue: '
        },
        {
          text: 'ACCORDO',
          bold: true,
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text: 'Tra',
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text:
            'FX12 s.r.l., con sede legale in Napoli, via Caracciolo 17, Partita IVA 09528731210  Codice Fiscale 09528731210 e numero di iscrizione al Registro delle Imprese di 1039449, in persona della Dott.ssa Rita Capitelli, che sottoscrive il presente accordo in qualità di amministratore e legale rappresentante (di seguito, “FX12”) ',
          margin: [0, 2],
          alignment: 'justify'
        },
        {
          text: '- da una parte -',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text:
            '' +
            this.firebase.loggedUser.name +
            ', residente in ' +
            this.firebase.loggedUser.investorOfficeRegisteredStreet +
            ' / con sede in ' +
            this.firebase.loggedUser.investorOfficeRegisteredStreet +
            ', c.f. ' +
            this.firebase.loggedUser.fiscalCode +
            ', / P.IVA n. ' +
            this.firebase.loggedUser.investorPIva +
            ' (nel seguito l’“Investitore”) ',
          margin: [0, 2],
          alignment: 'justify'
        },
        {
          text: '- dall’altra parte - ',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text:
            '(FX12 e l’Investitore, d’ora in avanti congiuntamente denominate le "Parti", e ciascuna singolarmente una "Parte"); ',
          margin: [0, 5]
        },
        {
          margin: [0, 2],
          text: 'PREMESSO CHE',
          alignment: 'center',
          bold: true,
          pageBreak: 'after'
        },
        //PROSSIMO FOGLIO
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          type: 'upper-alpha',
          ol: [
            {
              text:
                'FX12 è una start up innovativa che progetta e realizza piattaforme web finalizzate allo sviluppo di servizi di finanza digitale dedicati all’accelerazione dei pagamenti ed alla cessione di fatture commerciali; ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                'più dettagliatamente, nell’ambito della sua attività, FX12 ha sviluppato servizi fintech di negoziazione di crediti attraverso la piattaforma Incassa Ora® (di seguito, la “Piattaforma”) finalizzati, inter alia, a gestire il dynamic discounting, il supply chain finance e l’invoice trading (di seguito, i “Servizi”);',
              alignment: 'justify',
              margin: [0, 5]
            },
            {
              text:
                'al fine di garantire la compiuta operatività della Piattaforma e dei Servizi, in particolare, FX12 ha stipulato una collaborazione con un partner finanziario (di seguito, l’“Istituto di Pagamento Partner”) avente ad oggetto la gestione dei flussi finanziari collegati alle operazioni di acquisto e vendita di fatture commerciali, mediante l’apertura di appositi conti di pagamento;',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                'FX12, inoltre, è registrata, con numero di identificazione 168165, su un apposito elenco, il REGAFI – Registre des agents financiers (www.regafi.fr) – in qualità di Agente di pagamento dell’Istituto di Pagamento Partner, e quindi è in possesso di tutti i requisiti, formali e sostanziali, prescritti dalla normativa di settore per poter svolgere l’attività di agenzia nei servizi di pagamento per conto dell’Istituto di Pagamento Partner; ',
              alignment: 'justify',
              margin: [0, 2]
            },

            {
              text:
                'i Servizi di FX12 si rivolgono ad un mercato costituito da aziende dotate di un adeguato merito creditizio interessate ad accelerare i pagamenti ai propri fornitori (di seguito, le “Aziende Target”);',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                'l’Investitore è un soggetto interessato: (i) ad acquistare i crediti vantati dai fornitori dell’Azienda Target e ad incassarli -anche con scadenze rimodulate- per ottenere un ricavo nel tempo; nonché (ii) ad acquistare i crediti vantati dall’Azienda Target verso i propri clienti, per trasferire il rischio di credito fuori dal perimetro aziendale;',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                'in ragione della premessa di cui al punto F) che precede, FX12 stipulerà, per conto dell’Investitore, contratti di acquisto crediti (di seguito, i “Contratto di Acquisto”) con i fornitori delle Aziende Target (di seguito, i “Cedenti”);',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                'le condizioni previste nei Contratti di Acquisto saranno negoziate da FX12 sulla Piattaforma ed accettate dall’Investitore mediante una procedura di controllo dotata di One Time Password (“OTP”); ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                'in considerazione di quanto sopra, è interesse delle Parti porre in essere una collaborazione tra loro nei termini di cui al presente Accordo. ',
              alignment: 'justify',
              margin: [0, 0, 0, 10]
            }
          ]
        },
        {
          text:
            "TUTTO CIÒ PREMESSO, in virtù dei reciproci accordi qui contenuti, e secondo i termini e le condizioni del presente accordo (l'Accordo'), le Parti convengono quanto segue:",
          margin: [0, 10],
          alignment: 'justify'
        },
        {
          ol: [
            [
              { text: 'Premesse e Allegati ', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'Tutte le Premesse di cui sopra e gli Allegati formano parte integrante e sostanziale del presente Accordo. ',
                    pageBreak: 'after',
                    margin: [0, 1]
                  }
                ]
              }
            ],
            //PROSSIMA PAGINA QUI
            [
              { text: 'Obbligazioni preliminari', bold: true, decoration: 'underline', margin: [0, 20, 0, 0] },
              {
                ol: [
                  {
                    text: 'In via preliminare alla stipulazione del presente Accordo, l’Investitore si impegna a: ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'registrarsi sulla Piattaforma fornendo tutta la documentazione necessaria per lo svolgimento dei controlli sulla normativa antiriciclaggio ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'sottoscrivere un contratto di conto di pagamento con l’Istituto di Pagamento Partner (di seguito, il “Conto Provvista”) la cui funzione è esclusivamente quella di permettere l’operatività dei servizi di negoziazione di crediti. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'FX12 conferma la richiesta di adesione ai Servizi dell’Investitore solo dopo aver ricevuto conferma della corretta registrazione dell’anagrafica nella Piattaforma e l’attivazione del Conto Provvista da parte dell’Istituto di Pagamento Partner.  ',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Oggetto', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'In forza della sottoscrizione del presente Accordo, l’Investitore conferisce a FX12 mandato per acquistare per suo conto i crediti vantati dai fornitori dell’Azienda Target (o vantati da soggetti terzi nei confronti della stessa), risultanti dalle fatture aventi le caratteristiche declinate all’Allegato sub B (di seguito “Operazioni di Cessione”). ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'A seguito della conclusione di ciascun Contratto di Acquisto con i Cedenti , FX12 provvederà, per il tramite dell’Istituto di Pagamento Partner, ad addebitare per il corrispondente importo il Conto Provvista dell’Investitore. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Alle scadenze di pagamento indicate nei Contratti di Acquisto, FX12 provvederà ad incassare il corrispettivo in una o più rate di rimborso e ad accreditarlo, al netto della commissione trattenuta, per il tramite dell’Istituto di Pagamento Partner, sul Conto Provvista.  ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'I Contratti di Acquisto sono conclusi tra i Cedenti e FX12, che agisce quale mandataria dell’Investitore. FX12, per la porzione di credito dell’Investitore, agisce come mandataria senza rappresentanza ai sensi dell’art. 1705 c.c., assumendosi in proprio tutti gli obblighi e i diritti derivanti dal Contratto di Acquisto.  ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Come parte dei Servizi forniti all’Investitore ai sensi del presente Accordo, FX12 si occuperà, oltre che della stipula, anche dell’esecuzione delle Operazioni di Cessione. In particolare, FX12 verificherà che i pagamenti previsti nel Contratto di Acquisto siano puntualmente eseguiti ed organizzerà, per il tramite dell’Istituto di Pagamento Partner, i relativi accrediti sul Conto Provvista. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'I crediti che FX12 potrà acquistare sono quelli aventi le caratteristiche declinate all’Allegato sub B. Il contenuto dell’allegato B potrà essere modificato in qualsiasi momento dall’Investitore, dandone comunicazione mediante PEC a FX12 con preavviso scritto di 30 (trenta) giorni, salva l’esecuzione delle Operazioni di Cessione pendenti. Ciascun Investitore ha la facoltà di identificare tramite la Piattaforma, le caratteristiche delle Operazioni di Cessione cui partecipare ed autorizzare, mediante procedura OTP, il prelievo dei fondi corrispondenti alle operazioni selezionate per tutti o parte dei fondi depositati presso il proprio Conto Provvista. ',
                    alignment: 'justify',
                    margin: [0, 15],
                    pageBreak: 'after'
                  }
                ]
              }
            ],
            //PROSSIMA PAGINA
            [
              { text: 'Conto Provvista', bold: true, decoration: 'underline', margin: [0, 20, 0, 0] },
              {
                ol: [
                  {
                    text:
                      'Gli importi giacenti sul Conto Provvista sono di piena ed assoluta titolarità dell’Investitore e costituiscono un patrimonio distinto ad ogni effetto da quello di FX12, degli altri Investitori e dell’Istituto di Pagamento Partner.',
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Sul Conto Provvista FX12 opera al solo scopo di dare esecuzione alle disposizioni dell’Investitore, ossia (i) per allocare i fondi sulle Operazioni di Cessione di crediti concluse con l’Azienda Target e (ii) per incassare le relative rate di rimborso.',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Dichiarazioni e garanzie', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'L’Investitore dichiara espressamente di non svolgere, né di voler svolgere, professionalmente attività finanziaria e che pertanto la sua partecipazione alle Operazioni di Cessione deve semplicemente intendersi come una delle modalità di utilizzo della sua liquidità personale. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'L’Investitore persona fisica riconosce, altresì, che in ogni momento l’importo massimo dei fondi investiti in Operazioni di Cessione non può essere complessivamente superiore a Euro [-].',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'L’Investitore prende espressamente atto che FX12 non svolge alcuna attività di raccolta di denaro o di risparmio, né di impiego per conto terzi dei medesimi.',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'FX12 prende atto che l’Azienda Target può intrattenere con l’Investitore rapporti professionali diversi da quelli che possono sorgere a seguito dell’intervento di FX12, rispetto ai quali le Parti riconoscono che FX12 non avrà alcun ruolo né sarà creditrice o debitrice di alcun importo.',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'L’Investitore dichiara di aver preso visione della funzionalità della Piattaforma e, in particolare, dichiara di aver verificato le procedure di accettazione dell’Operazione di Cessione e di autorizzazione all’addebito automatico al Conto di Provvista. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'L’Investitore dichiara di aver preso visione dei termini e condizioni dell’Istituto di Pagamento Partner pubblicato al seguente link https://www.lemonway.com/it/condizioni-generali-di-utilizzo/.',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Partecipazione di FX12 alle Operazioni di Cessione', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'L’Investitore prende atto che FX12 si riserva la facoltà di partecipare direttamente alle Operazioni di Cessione, restando libera di determinare detta propria partecipazione, che comunque sarà preventivamente negoziata per ciascuna operazione sulla Piattaforma con l’Investitore',
                    alignment: 'justify'
                  },
                  {
                    text:
                      'FX12 può nel tempo modificare la propria quota di partecipazione alle Operazioni di Cessione.',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Corrispettivo', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'Le Parti riconoscono espressamente che l’Investitore riconoscerà a FX12, per lo svolgimento dei Servizi ai sensi del presente Accordo, un importo pari al 30% (trentapercento) del rendimento lordo ottenuto (il “Corrispettivo”).	',
                    alignment: 'justify',
                    pageBreak: 'after',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Il Corrispettivo verrà trattenuto direttamente da FX12, che provvederà ad accreditare, a seguito del pagamento delle rate di rimborso da parte dei Cedenti, il rendimento netto sul Conto Provvista attraverso l’Istituto di Pagamento Partner.    ',
                    alignment: 'justify',
                    margin: [0, 20, 0, 2]
                  },
                  {
                    text:
                      "FX12 eroga i proventi generati dalle Operazioni di Cessione al lordo delle imposte, non ponendosi come sostituto d'imposta.",
                    alignment: 'justify',
                    margin: [0, 0, 0, 10]
                  }
                ]
              }
            ],
            [
              { text: 'Durata e risoluzione', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text: 'Il presente Accordo è sottoscritto a tempo indeterminato. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Ciascuna Parte potrà recedere liberamente dal presente Accordo con preavviso scritto di 30 (trenta) giorni dandone comunicazione motivata, mediante PEC, all’altra Parte, salva l’esecuzione dei Servizi relativi alle Operazioni di Cessione ancora pendenti.',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Fermo restando quando previsto ai commi precedenti, FX12 si riserva la facoltà di recedere dal presente Accordo in caso di scioglimento o blocco del rapporto di Conto Provvista (ad esempio qualora ricorrano giustificati motivi relativi alla sicurezza del Conto Provvista, o in caso di sospetto di un suo utilizzo fraudolento o non autorizzato).',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'In caso di recesso, le spese dei Servizi di FX12 sono dovute dall’Investitore fino al termine delle Operazioni di Cessione ancora pendenti al momento dello scioglimento, secondo le modalità di cui all’Articolo 7. ',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Clausola risolutiva', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'Ai sensi dell’art. 1456 cod. civ., FX12 potrà risolvere il presente Accordo con effetto immediato, mediante semplice invio di comunicazione a mezzo PEC, o altra forma scritta, all’Investitore, in caso di inadempimento di non scarsa importanza o reiterato a qualsivoglia obbligo assunto ai sensi del presente Accordo, al quale l’Investitore stesso non abbia posto rimedio entro 15 (quindici) giorni dalla comunicazione ricevuta da FX12 in tal senso.',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Ritardi e insoluti del debitore ceduto  ', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'Nel caso si manifestassero ritardi nei pagamenti dell’ambito delle Operazioni di Cessione da parte del debitore ceduto, FX12 gestirà in via esclusiva, per conto dell’Investitore, i relativi ritardi e le eventuali sofferenze.',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Nell’eseguire tale attività, FX12 agirà con tempestività e diligenza. Nell’ambito di tale attività, FX12 potrà azionare i crediti in giudizio in nome e per conto dell’Investitore, esperendo ogni azione nei confronti del debitore ceduto e dei suoi beni che FX12 ritenga necessaria a sua discrezione. FX12, inoltre, potrà a sua volta conferire mandato ad intermediari o primarie società di recupero del credito, negoziando e stabilendo per conto dell’Investitore il relativo prezzo con adeguata diligenza, tenendo conto dei criteri di mercato e dell’interesse dell’Investitore.',
                    alignment: 'justify',
                    margin: [0, 2],
                    pageBreak: 'after'
                  },
                  {
                    text: '',
                    alignment: 'justify',
                    margin: [0, 20, 0, 2]
                  },
                  {
                    text:
                      'In nessun caso FX12 risponderà in alcun modo dell’esito della procedura di recupero dei crediti, né di quello degli eventuali accordi conclusi con i debitori ceduti morosi per la transazione e definizione a saldo e stralcio di qualsiasi posizione.',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'I costi e le spese che non fossero rimborsati dal debitore ceduto saranno a carico dell’Investitore, restando peraltro inteso che, in relazione a ciascuna Operazione di Cessione, l’importo massimo che FX12 potrà addebitare all’Investitore a titolo di costi e spese di recupero del credito non potrà eccedere l’ammontare complessivamente recuperato. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'In nessun caso l’Investitore potrà attivarsi direttamente per il recupero delle somme dovute, anche in espressa deroga al disposto dell’articolo 1705, secondo comma, Cod. Civile.',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'FX12 non assume alcuna garanzia in merito al rimborso delle Operazioni di Cessione da parte dei Cedenti, avendo quale unico incarico esclusivamente quello dell’esecuzione dei Servizi previsti dal presente Accordo.',
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Per nessuna ragione FX12 potrà essere ritenuta responsabile dell’eventuale mancata solvibilità dei Cedenti. Con la sottoscrizione del presente Accordo l’Investitore dichiara di essere consapevole che i fondi del Conto Provvista allocati sulle Operazioni di Cessione possano andare persi, in tutto o in parte, in relazione all’eventualità che un Cedente non sia in grado di adempiere e comunque non adempia, anche solo in parte, al proprio obbligo di rimborso. ',
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Il rischio di credito è a carico degli Investitori, in ragione della percentuale di partecipazione all’Operazione di Cessione, in linea con quanto indicato all’Articolo 6 - Partecipazione di FX12 alle Operazioni di Cessione.',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Condizione sospensiva', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'Il presente contratto diverrà efficace a seguito della valutazione positiva effettuata da parte di Lemonway sui presupposti necessari all’erogazione dei servizi oggetto del presente accordo ovvero per le necessarie verifiche in termini di Know Your Customer richieste ai sensi di legge. FX12 provvederà a comunicare l’esito della valutazione.',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Trattamento dei dati personali', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'FX12 si impegna a raccogliere e trattare i dati sensibili dell’Investitore nel rispetto delle prescrizioni di cui al Regolamento Europeo in materia di dati personali n. 2016/679 del 27 aprile 2016 (il “GDPR”). ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Le informazioni sul trattamento dei dati personali da parte di FX12, sulla policy di FX12 in materia di tutela della privacy e sono pubblicati sul sito [-], nella sezione sulla Privacy.',
                    alignment: 'justify',
                    margin: [0, 2],
                    pageBreak: 'after'
                  }
                ]
              }
            ],
            [
              { text: 'Comunicazioni ', bold: true, decoration: 'underline', margin: [0, 20, 0, 0] },
              {
                ol: [
                  {
                    text:
                      'Le comunicazioni ai sensi del presente Accordo dovranno avvenire esclusivamente a mezzo PEC ai seguenti indirizzi:\nper FX12: ' +
                      'fx12@pec.it' +
                      '\nper l’Investitore: ' +
                      this.firebase.loggedUser.investorPec,
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ],
            [
              { text: 'Miscellanea', bold: true, decoration: 'underline' },
              {
                ol: [
                  {
                    text:
                      'Non trasferibilitIl \npresente Accordo ed i diritti, gli interessi o gli obblighi che ne conseguono non potranno essere trasferiti da nessuna delle Parti senza il preventivo assenso scritto dell’altra Parte e qualsiasi tentativo di trasferimento del presente Accordo o dei diritti, degli interessi o degli obblighi che ne conseguono senza assenso sarà nullo e inefficacie.   ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Modifiche e rinunce \nNessuna modifica o rinuncia a qualsiasi delle condizioni del presente Accordo sarà vincolante per alcuna delle Parti se non vi sia l’assenso scritto di tale Parte. Nessuna deroga a qualsiasi condizione o termine del presente Accordo si intenderà quale deroga di altro diverso termine o condizione, né costituirà deroga definitiva se non altrimenti specificamente previsto. ',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Negoziazione tra le Parti \nLe Parti riconoscono che i termini del presente Accordo sono stati congiuntamente definiti, negoziati ed analizzati, singolarmente e nel loro insieme. Per l’effetto, non trovano applicazione le disposizioni di cui agli artt. 1341 e 1342 c.c.',
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      "Legge dell'Accordo  \nIl presente Accordo sarà regolato e interpretato ai sensi dalla legge italiana. ",
                    alignment: 'justify',
                    margin: [0, 2]
                  },
                  {
                    text:
                      'Foro competente  \nLe Parti si adopereranno in ogni maniera ragionevole per risolvere ogni disputa, controversia o reclamo derivante da o relativo alla stipula, l’esecuzione, l’interpretazione, la validità o la risoluzione del presente Accordo ovvero qualsiasi violazione dello stesso. Qualsiasi disputa che non venga risolta in maniera amichevole sarà devoluta alla giurisdizione esclusiva del Foro di Napoli. ',
                    alignment: 'justify',
                    margin: [0, 15]
                  }
                ]
              }
            ]
          ]
        },
        {
          text: 'Napoli,   ',
          margin: [0, 20, 0, 0]
        },
        {
          text: 'Per approvazione ed accettazione, ',
          margin: [0, 10, 0, 10]
        },
        {
          text: 'Data                                               Firma',
          alignment: 'center',
          bold: true
        },
        {
          text: '____________                                    __________',
          margin: [0, 10, 0, 5],
          alignment: 'center'
        },
        {
          text: ' ',
          margin: [0, 10, 0, 5],
          bold: true
        },
        {
          text: 'Allegati: ',
          bold: true
        }
      ],
      images: {
        logo: 'https://panel.incassaora.it/assets/logoPDF.png'
      },

      styles: {}
    };

    pdfMake.createPdf(docDefinition).open();
  }

  caricaContratto() {
    const modalRef = this.modalService.open(ContrattoInvestitoreComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.result.then(
      () => {
        // fulfilled
      },
      () => {
        // rejected
      }
    );
  }

  loadContract() {
    this.firebase.getContractsByIdInvestor(this.firebase.loggedUser.id).subscribe(data => {
      data.forEach(element => {
        this.listDocument.push(element);
      });
      if (this.listDocument.length == 0) {
        this.nessunaTrans = true;
      } else {
        this.nessunaTrans = false;
      }
      // console.log(this.listDocument);
    });
  }

  createForm() {
    this.investorCreationForm = this.formBuilder.group({
      nome: new FormControl('', [Validators.required]),
      descrizione: new FormControl('', [Validators.required])
    });
  }

  open(name: string, fileToUpload: number) {
    // console.log('carico')
    if (this.investorCreationForm.value.nome != '' && this.investorCreationForm.value.descrizione != '') {
      // console.log('creo oggetto')
      this.investorCreationForm.get('nome').markAsTouched();
      this.investorCreationForm.get('descrizione').markAsTouched();
      this.contract.nome_file = this.investorCreationForm.value.nome;
      this.contract.descrizione = this.investorCreationForm.value.descrizione;
      this.contract.dataCaricamento = new Date().toUTCString();
      this.contract.investorUUID = this.firebase.loggedUser.id;
      this.contract.modified = false;

      /*const today = new Date();
      const pipe = new DatePipe('en-US');
      this.contract.dataCaricamento = pipe.transform(today, 'yyyy-MM-dd');*/
      this.firebase.createContractInvestor(this.contract).then(data => {
        // console.log('RESULT=>');
        // console.log(data.id);
        if (data.id) {
          this.contract.id = data.id;
          this.contract.uid = data.id;
          const filePath = this.firebase.loggedUser.id + '-' + data.id + '-INVESTOR_SIGNED_CONTRACT.pdf';
          // console.log(filePath);
          this.contract.url = filePath;
          if (fileToUpload === 0) {
            this.uploadFileContratto(filePath);
          }
        }
      });
    } else {
      alert('I campi nome e descrizione sono obbligatori');
    }
  }

  uploadFileContratto(filePath: any) {
    const task = this.storage.upload(filePath, this.fileToUpload);

    // console.log(task);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
        })
      )
      .subscribe(data => {
        this.firebase.updateContractInvestor(this.contract).then(() => {
          // this.router.navigate(['#']);
          // alert('file caricato correttamente');
        });
      });
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  getDownloadURL2(trans: string) {
    // console.log(trans);
    const ref = this.storage.ref(trans);
    // console.log(ref);

    ref.getDownloadURL().subscribe(data => {
      // console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', trans);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }
}
