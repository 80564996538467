import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utilities } from '@app/utilities/Utilities';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmsService {
  constructor(private http: HttpClient) {}
  //testURL sendSmsUrl="http://fx12.test/api/sendsms";
  sendSmsUrl = Utilities.sendSms;

  sendSms(to: string): Observable<any> {
    return this.http.get<any>(this.sendSmsUrl + '?number=' + to);
  }
}
