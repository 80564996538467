import { ShowMessageService } from './../core/show-message.service';
import { Utilities } from '@app/utilities/Utilities';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { environment } from '@env/environment';
import { Logger } from '@app/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';

const log = new Logger('Login');

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  version: string | null = environment.version;
  error: string;
  loginForm!: FormGroup;
  isLoading = false;
  products: string[];
  product: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseTranchedService,
    public showService: ShowMessageService
  ) {
    this.createForm();
    this.products = Utilities.productNames;
    this.product = this.products[0];
  }

  ngOnInit() {}

  ngOnDestroy() {}

  login() {
    if (this.product != '') {
      this.isLoading = true;
      this.afAuth.auth
        .signInWithEmailAndPassword(this.loginForm.value.username, this.loginForm.value.password)
        .then(user => {
          this.firebaseService
            .getUserByID(user.user.uid)
            .then(doc => {
              if (doc.exists) {
                const myUser = doc.data();
                delete myUser.password;
                this.firebaseService.loggedUser = myUser;
                this.firebaseService.loggedUser.id = user.user.uid;
                localStorage.setItem('sessioneYellow', JSON.stringify(myUser));
                if (!this.firebaseService.checkPasswordDate()) {
                  this.showService.showPasswordReset = true;
                  this.afAuth.auth.sendPasswordResetEmail(this.firebaseService.loggedUser.email);
                  this.firebaseService.logout();
                } else if (!this.firebaseService.loggedUser.verified) {
                  alert('Utente non verificato');
                  this.showService.showVerified = true;
                  this.firebaseService.logout();
                } else if (this.firebaseService.loggedUser.stato == 'DISATTIVATO') {
                  alert('Utente DISATTIVO');
                  this.showService.showDisattivato = true;
                  this.firebaseService.logout();
                } else {
                  try {
                    if (!this.firebaseService.isInvestorSPV) {
                      if (this.product == this.products[0] && this.firebaseService.loggedUser.originatorProducts[0]) {
                        // predisponi per fintech dinamic
                      } else if (
                        this.product == this.products[1] &&
                        this.firebaseService.loggedUser.originatorProducts[1]
                      ) {
                        // predisponi per supply
                      } else if (
                        this.product == this.products[2] &&
                        this.firebaseService.loggedUser.originatorProducts[2]
                      ) {
                        // predisponi per blue
                      } else {
                        this.error = "Non sei abilitato per l'accesso a questo prodotto. Contatta l'admin.";
                        return;
                      }
                    }
                  } catch (e) {
                    console.error(e);
                  }

                  // console.log(this.firebaseService.loggedUser);
                  switch (this.firebaseService.loggedUser.roles[0]) {
                    case Utilities.userType[0]: {
                      // god
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/home/list-wallet']);
                      break;
                    }

                    case Utilities.userType[1]: {
                      // admin
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/home/list-wallet']);
                      break;
                    }

                    case Utilities.userType[2]: {
                      // originator
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/originator/list-wallet']);
                      break;
                    }

                    case Utilities.userType[3]: {
                      // company
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/cedente/dashboard']);
                      break;
                    }

                    case Utilities.userType[4]: {
                      // partner
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/partner/dashboard']);
                      break;
                    }
                    case Utilities.userType[5]: {
                      // investor
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/investor/dashboard']);
                      break;
                    }
                    case Utilities.userType[6]: {
                      // investor
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/servicer/pending-requests']);
                      break;
                    }

                    case Utilities.userType[7]: {
                      // servicer spv
                      console.log('servicerSPV LOGIN');
                      this.firebaseService.createAccessLog();
                      console.log('servicerSPV LOGIN POST');
                      this.router.navigate(['/servicerSPV/invoiceList']);
                      break;
                    }

                    case Utilities.userType[8]: {
                      // investor
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/investorSPV/invoiceList']);
                      break;
                    }

                    case Utilities.userType[9]: {
                      // investor
                      this.firebaseService.createAccessLog();
                      this.router.navigate(['/bankSPV/invoiceList']);
                      break;
                    }

                    default: {
                      console.log('DEAFULT');
                      this.logOut();
                      break;
                    }
                  }
                }
              } else {
                this.logOut();
              }
            })
            .catch(error => {
              this.logOut();
            });
        })
        .catch(error => {
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  openFaq() {
    window.open('https://incassaora.it/faq/');
  }

  openRegi(x: number) {
    if (x == 1) {
      window.open('/#/registration/investor');
    }
    if (x == 2) {
      window.open('/#/registration/originator');
    }
    if (x == 3) {
      window.open('/#/registration/company');
    }
  }

  termCondition() {
    window.open('https://incassaora.it/termini-e-condizioni/');
  }

  termConditionLemonWay() {
    window.open('https://www.lemonway.com/it/condizioni-generali-di-utilizzo/');
  }

  openRegistrazione() {
    window.open('#/registration');
  }

  selectProduct(product: string) {
    this.product = product;
  }

  goTo(s: string): void {
    this.router.navigate([s]);
  }

  private logOut() {
    this.afAuth.auth.signOut();
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      remember: true
    });
  }
}
