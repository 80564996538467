export class LogSms {
  static tableName: string = 'Logs';

  description: string;
  date: string;
  userId: string;
  type: string;
  ip: string;
  otp: string;
  invoice: string;
  timestamp: firebase.firestore.FieldValue;
  constructor() {}
}
