import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { ClipboardService } from 'ngx-clipboard';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-wallet-list-item',
  templateUrl: './wallet-list-item.component.html',
  styleUrls: ['./wallet-list-item.component.scss']
})
export class WalletListItemComponent implements OnInit {
  @Input() item: any;
  @Input() positionYellow: number;
  @Input() positionBlue: number;
  @Input() positionAzure: number;

  @Output() copyEvt: EventEmitter<boolean> = new EventEmitter<boolean>();

  isCopied = false;
  baseUrl: string;
  file: File;
  url = '';
  images: Observable<any[]>;
  constructor(
    public firebase: FirebaseTranchedService,
    private _clipboardService: ClipboardService,
    private router: Router,
    private storage: AngularFireStorage
  ) {
    this.baseUrl = environment.baseUrl;
  }

  async ngOnInit() {
    this._clipboardService.copyResponse$.subscribe(re => {
      if (re.isSuccess) {
        this.isCopied = true;
        this.copyEvt.emit(this.isCopied);
      }
    });
    if (this.item.logo) {
      this.getDownloadURL2(this.item.logo);
      // console.log(this.item);
    }
  }

  private async getUrl() {
    this.storage
      .ref(this.item.logo)
      .getDownloadURL()
      .subscribe(downloadURL => {
        var images = downloadURL;
        this.url = images.url;
      });
  }
  getDownloadURL2(trans: string) {
    //console.log(trans);
    const ref = this.storage.ref(trans);
    //console.log(ref);

    ref.getDownloadURL().subscribe(data => {
      // console.log(data);
      this.url = data;
    });
  }

  showDetailPage(id: string) {
    if (this.firebase.isAdmin()) {
      this.router.navigate(['/home/wallet-details/' + id]);
    } else if (this.firebase.isOriginator()) {
      this.router.navigate(['/originator/wallet-details/' + id]);
    } else if (this.firebase.isServicer()) {
      this.router.navigate(['/servicer/wallet-details/' + id]);
    } else if (this.firebase.isInvestor()) {
      this.router.navigate(['/investor/wallet-details/' + id]);
    } else {
      this.router.navigate(['/cedente/wallet-details/' + id]);
    }
  }

  onCopyFailure() {}
}
