import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/models/User.model';
import { ModalProfileComponent } from '@app/profile/modal-profile/modal-profile.component';
import { Observable, Subscription } from 'rxjs';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-pending-request-list',
  templateUrl: './pending-request-list.component.html',
  styleUrls: ['./pending-request-list.component.scss']
})
export class PendingRequestListComponent implements OnInit, OnDestroy {
  // TODO implementare meccanismo con cui il badge si aggiorna se è stata approvata qualcosa

  public tipologia: string;
  public usersList: Observable<any[]>;
  public docsList: Observable<any[]>;
  public pendingUsers: any[] = [];
  public pendingDocs: any[] = [];
  sub: Subscription;

  constructor(
    private firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private backendAuthService: BackendAuthService
  ) {
    this.tipologia = 'users';
    this.firebase.createPageLog('User Visit Page pending Requests');
  }

  ngOnInit() {
    this.usersList = this.firebase.getUsersByVerified(false);
    this.sub = this.usersList.subscribe(data => {
      this.pendingUsers = data;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  showModal(userIn: User) {
    const modalRef = this.modalService.open(ModalProfileComponent, {
      size: 'lg',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.userInput = userIn;
    modalRef.componentInstance.showUpdateButton = false;
  }

  approve(obj: any, type: string) {
    switch (type) {
      case 'user': {
        this.firebase.approveUser(obj);
        break;
      }
      case 'doc': {
        this.firebase.approveDocs(obj);
      }
    }
  }

  loadPendingRequests(): void {
    switch (this.tipologia) {
      case 'users': {
        this.usersList = this.firebase.getUsersByVerified(false);
        this.sub = this.usersList.subscribe(data => {
          this.pendingUsers = data;
        });
        break;
      }
      case 'docs': {
        this.docsList = this.firebase.getInvoicesNotApproved();
        this.sub = this.docsList.subscribe(data => {
          this.pendingDocs = data;
        });
        break;
      }
    }
  }
}
