import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TigranService } from '@app/tigran/tigran.service';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Utilities } from '@app/utilities/Utilities';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() pIva: string;

  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() moreScore: EventEmitter<string[]> = new EventEmitter<string[]>();

  constructor(
    public activeModal: NgbActiveModal,
    private tigran: TigranService,
    private firebaseService: FirebaseTranchedService
  ) {}

  ngOnInit() {}

  searchTigran() {
    // retrive the uuid of user from piva
    let userRet = '';
    this.firebaseService.getUserByRoleAndStringField('', 'pIva', this.pIva).subscribe(data => {
      if (data.length > 0) {
        userRet = data[0].id;
      }

      if (userRet === '') {
        this.firebaseService.getUserByRoleAndStringField('', 'investorPIva', this.pIva).subscribe(dataInv => {
          if (dataInv.length > 0) {
            userRet = dataInv[0].id;
          }

          if (userRet === '') {
            // tslint:disable-next-line:max-line-length
            this.firebaseService.getUserByRoleAndStringField('', 'originatorPIva', this.pIva).subscribe(dataOrig => {
              if (dataOrig.length > 0) {
                userRet = dataOrig[0].id;
              }

              if (userRet === '') {
                alert('Errore individuazione utente');
              }
            });
          } else {
            this.searchTigranValue(userRet);
          }
        });
      } else {
        this.searchTigranValue(userRet);
      }
    });

    /* ************************************************ */

    /*

    */
  }

  searchTigranValue(uuid: string) {
    console.log('uuid=>', uuid);

    if (uuid !== '') {
      this.activeModal.close();
      this.loading.emit(true);
      this.tigran.entireFlow(this.pIva).subscribe(data => {
        this.loading.emit(false);
        this.moreScore.emit([data['more_score'], data['more_probability_of_default']]);
        this.firebaseService.createLogTigran(
          data['more_score'],
          data['more_probability_of_default'],
          this.pIva,
          data['subject'].name,
          JSON.stringify(data),
          uuid
        );
      });
    }
  }
}
