import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { ModalProfileComponent } from './modal-profile/modal-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalProfileInvestorViewComponent } from './modal-profile-investor-view/modal-profile-investor-view.component';

@NgModule({
  declarations: [ProfileComponent, ModalProfileComponent, ModalProfileInvestorViewComponent],
  imports: [CommonModule, ProfileRoutingModule, NgbModule, ReactiveFormsModule],
  entryComponents: [ModalProfileComponent, ModalProfileInvestorViewComponent]
})
export class ProfileModule {}
