import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password.component';
import { ResetPasswordRequestComponent } from './reset-password-request/reset-password-request.component';

@NgModule({
  declarations: [ResetPasswordComponent, ResetPasswordRequestComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, NgbModule, ResetPasswordRoutingModule]
})
export class ResetPasswordModule {}
