import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { extract } from '@app/core';
import { Utilities } from '@app/utilities/Utilities';
import { UsersCredentialComponent } from '@app/home/users-credential/users-credential.component';
import { WalletDetailsComponent } from '@app/home/wallet-details/wallet-details.component';
import { HomeServicerSPVComponent } from './home-servicer-spv.component';
import { CompanySpvListComponent } from './company-spv-list/company-spv-list.component';
import { CompanySpvDetailComponent } from '@app/home-servicer-spv/company-spv-detail/company-spv-detail.component';
import { InvoiceListComponent } from '@app/home-investor-spv/invoice-list/invoice-list.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/servicerSPV/companyList', pathMatch: 'full' },
    {
      path: 'servicerSPV',
      component: HomeServicerSPVComponent,
      children: [
        {
          path: 'companyList',
          component: CompanySpvListComponent
        },
        {
          path: 'invoiceList',
          component: InvoiceListComponent
        },
        {
          path: 'company/:id/detail',
          component: CompanySpvDetailComponent
        },
        {
          path: 'wallet-details/:id',
          component: WalletDetailsComponent
        },
        {
          path: 'user-credential',
          component: UsersCredentialComponent
        }
      ],
      data: { title: extract('servicerSPV'), role: Utilities.userType[7] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeServicerSPVRoutingModule {}
