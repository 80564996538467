import { Utilities } from '@app/utilities/Utilities';
import { User } from './User.model';

export class Originator extends User {
  tipologiaOriginator: string;
  originatorType: string;

  originatorCreditsCheck: boolean;

  originatorOfficeRegisteredStreet: string; // sede legale
  originatorOfficeRegisteredCity: string;
  originatorOfficeRegisteredProvince: string;

  originatorOfficeOperationalStreet: string; // sede operativa
  originatorOfficeOperationalCity: string;
  originatorOfficeOperationalProvince: string;

  originatorPIva: string;

  originatorMoreScore: string = '';
  originatorDefault: string;
  lastTigranDate: string;

  originatorSolicitorSurname: string; // rappresentante legale
  originatorSolicitorName: string;
  originatorSolicitorBirthplace: string;
  originatorSolicitorBirthdate: string;

  originatorWebSite: string;
  originatorPec: string;

  originatorAdminReferentSurname: string; // referente Tranched cover
  originatorAdminReferentName: string;
  originatorAdminReferentTelephone: string;
  originatorAdminReferentEmail: string;

  partnerUId: string;
  partnerName: string;

  virtualIban: string;
  idvirtualIban: string;

  lemonwayAccount: boolean = false;
  statoLemonway: string = Utilities.statoLemon[3];
  REA_CODE: string;

  viaLeg: string;
  comuneLeg: string;
  provinciaLeg: string;
  nazioneLeg: string;
  capLegale: string;
  legalAddressCAP: string;
  constructor() {
    super();
  }
}
