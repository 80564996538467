import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InvestorSpvRoutingModule } from './investor-spv-routing.module';
import { InvestorSpvComponent } from './investor-spv.component';
import { InvestorSpvCreateComponent } from './investor-spv-create/investor-spv-create.component';
import { InvestorSpvListComponent } from './investor-spv-list/investor-spv-list.component';
import { InvestorSpvDetailComponent } from './investor-spv-detail/investor-spv-detail.component';
import { InvestorSpvEditComponent } from './investor-spv-edit/investor-spv-edit.component';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { ClipboardModule } from 'ngx-clipboard';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    InvestorSpvComponent,
    InvestorSpvCreateComponent,
    InvestorSpvListComponent,
    InvestorSpvDetailComponent,
    InvestorSpvEditComponent
  ],
  imports: [
    CommonModule,
    InvestorSpvRoutingModule,
    NgbModule,
    SharedModule,
    ClipboardModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [InvestorSpvComponent, InvestorSpvCreateComponent, InvestorSpvListComponent]
})
export class InvestorSpvModule {}
