import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment.prod';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { type } from 'os';
import { MonthlyDashboardService } from './monthly-dashboard.service';

@Component({
  templateUrl: './monthly-dashboard.component.html',
  styleUrls: ['./monthly-dashboard.component.scss']
})
export class MonthlyDashboardComponent implements OnInit {
  private completo = false;
  private numeroMesiRiferimento: number;

  public selectedChart = '';
  public chartsType = new Map<string, number[]>();
  public graphTypeLabel: string[] = ['Numero di Cessioni', 'Progressivo Ceduto', 'Importo Medio Fatture'];

  public lineChartData: ChartDataSets[] = [{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' }];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [{}]
    },
    annotation: { annotations: [{}] },
    legend: {
      display: true,
      labels: {
        fontColor: '#6f42c1',
        fontSize: 14
      }
    }
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins: any[] = [];
  private loggedID;
  private dati: any = 0;

  constructor(public service: MonthlyDashboardService) {
    this.dati = this.service;
  }

  ngOnInit() {
    setTimeout(() => {
      this.getAllData();

      this.chartsType.set(this.graphTypeLabel[0], this.dati.cessioni);
      this.chartsType.set(this.graphTypeLabel[1], this.dati.progCed);
      this.chartsType.set(this.graphTypeLabel[2], this.dati.mediaFatture);

      this.setChart(this.graphTypeLabel[0]);

      this.completo = true;
    }, 2500);
  }

  setChart(key: string) {
    this.selectedChart = key;
    this.lineChartData = [{ data: this.chartsType.get(key), label: key }];
  }

  getKeys() {
    return Array.from(this.chartsType.keys());
  }

  getAllData() {
    const resizeArray = (array: number[], newSize: number): number[] => {
      const changeSize = newSize - array.length;
      if (changeSize > 0) {
        return array.concat(Array(changeSize).fill(0));
      }
      return array.slice(0, newSize);
    };

    let pipe = new DatePipe('en-US');
    let today = new Date();
    let openingDate = new Date(new Date().getFullYear() + '-01-01');

    let month = today.getFullYear() - openingDate.getFullYear() ? today.getFullYear() - openingDate.getFullYear() : 12;

    this.numeroMesiRiferimento = today.getDate() - openingDate.getDate() - 1;
    //console.log(this.numeroMesiRiferimento)

    for (let i = 0; i < this.numeroMesiRiferimento; i++) {
      let today = new Date(new Date().getFullYear() + '-' + (new Date().getMonth() + 1) + '-01');

      today.setMonth(today.getMonth() - i);

      this.lineChartLabels.push(pipe.transform(today, 'MMM-yy'));
    }

    if (this.numeroMesiRiferimento < 12) {
      this.dati.cessioni = resizeArray(this.dati.cessioni, this.numeroMesiRiferimento);
      this.dati.progCed = resizeArray(this.dati.progCed, this.numeroMesiRiferimento);
      this.dati.mediaFatture = resizeArray(this.dati.mediaFatture, this.numeroMesiRiferimento);
    }

    this.lineChartLabels.reverse();
  }
}
