import { Component, Input, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Investor } from '@app/models/Investor.model';
import { Invoice } from '@app/models/Invoice.model';
import { Originator } from '@app/models/Originator.model';
import { Wallet } from '@app/models/Wallet.model';
import { Utilities } from '@app/utilities/Utilities';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-add-originator',
  templateUrl: './modal-add-originator.component.html',
  styleUrls: ['./modal-add-originator.component.scss']
})
export class ModalAddOriginatorComponent implements OnInit {
  @Input() walletToModify: Wallet;
  @Input() invoice: Invoice;
  listaInvestitori: string[];
  listaGenerale: Investor[];
  InvestitorOnWallet: string[];
  constructor(public activeModal: NgbActiveModal, private firebase: FirebaseTranchedService) {
    firebase.getUsersByRole(Utilities.userType[5]).subscribe(data => {
      this.listaGenerale = data;
      this.listaInvestitori = [];
      for (let entry of data) {
        this.listaInvestitori.push(entry.id);
      }
      this.InvestitorOnWallet = this.walletToModify.listInvestitori ? this.walletToModify.listInvestitori : [];
    });
  }

  ngOnInit() {}

  add(user: string) {
    if (this.contiene(user) == false) {
      this.InvestitorOnWallet.push(user);
      this.walletToModify.listInvestitori = this.InvestitorOnWallet;
      this.firebase.modWallet(this.walletToModify);
      //Aggiungiamo l'investitore nel wallet
      this.firebase.getUserByID(user).then(data => {
        if (data.exists) {
          const us = data.data() as Investor;
          var listWallet = us.listWallet ? us.listWallet : [];
          if (!this.contieneWallet(listWallet) || listWallet == []) {
            listWallet.push(this.walletToModify.id);
            //console.log(listWallet);
            us.listWallet = listWallet;
            // console.log(us);
            this.firebase.modInvestor(us);
          }
        }
      });
    }
  }

  rimuoviWalletInvestitore(user: string) {
    this.firebase.getUserByID(user).then(data => {
      if (data.exists) {
        const us = data.data() as Investor;
        var listWallet = us.listWallet ? us.listWallet : [];
        us.listWallet = this.removeWallet(listWallet);
        this.firebase.modInvestor(us);
      }
    });
  }

  delete(user: string) {
    var newList: string[] = [];
    this.InvestitorOnWallet.forEach(element => {
      if (user != element) {
        newList.push(element);
      }
    });
    this.InvestitorOnWallet = newList;
    this.walletToModify.listInvestitori = this.InvestitorOnWallet;
    this.firebase.modWallet(this.walletToModify);
    //Rimuovo l'investitore nel wallet
    this.firebase.getUserByID(user).then(data => {
      if (data.exists) {
        const us = data.data() as Investor;
        var listWallet = us.listWallet ? us.listWallet : [];
        us.listWallet = this.removeWallet(listWallet);
        this.firebase.modInvestor(us);
      }
    });
  }

  contiene(user: string): boolean {
    for (let entry of this.InvestitorOnWallet) {
      if (user == entry) {
        return true;
      }
    }
    return false;
  }

  contieneWallet(list: string[]): boolean {
    for (let entry of list) {
      if (this.walletToModify.id == entry) {
        return true;
      }
    }
    return false;
  }

  removeWallet(list: string[]): string[] {
    var nuovaL = [];
    for (let entry of list) {
      if (this.walletToModify.id != entry) {
        nuovaL.push(entry);
      }
    }
    return nuovaL;
  }

  onCloseAction() {
    if (this.controlloLista()) {
      return;
    }
    this.invoice.investor = this.InvestitorOnWallet[0];
    this.invoice.status = Utilities.invoiceStatus[3];
    this.firebase.modInvoice(this.invoice);
    this.activeModal.close();
  }

  controlloLista() {
    return this.InvestitorOnWallet.length == 0;
  }
}
