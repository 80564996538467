import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { MailerService } from '@app/mailer/mailer.service';
import { DataTablesResponse } from '@app/models/DataTablesResponse.model';
import { environment } from '@env/environment.prod';

@Component({
  templateUrl: './funds-request.component.html',
  styleUrls: ['./funds-request.component.scss']
})
export class FundsRequestComponent implements OnInit {
  private requests: any;
  private reqLoad: boolean;
  private userid: any;
  public invSPVdata: any;
  private finished: boolean;

  constructor(
    public firebase: FirebaseTranchedService,
    private storage: AngularFireStorage,
    private sendMail: MailerService,
    public http: HttpClient
  ) {}

  ngOnInit() {
    this.reqLoad = false;
    this.userid = this.firebase.loggedUser.id;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<DataTablesResponse>(
        `${environment.laravelServer}/bankrequest/getAllRequestWthDocuments`,
        '{"id":"' + this.userid + '"}',
        httpOptions
      )
      .subscribe(resp => {
        this.requests = resp.data;
        console.log(this.requests);
        this.reqLoad = true;
      });
  }

  acceptReq(_invoices, _reqID, _action, _investorID, _bankName) {
    this.getInvData(_investorID);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.http
      .post<DataTablesResponse>(
        `${environment.laravelServer}/bankrequest/changeStatus`,
        '{"reqID": "' + _reqID + '", "action": "' + _action + '", "invoices": "' + _invoices + '"}',
        httpOptions
      )
      .subscribe(resp => {
        //console.log(resp);
        if (resp['status'] == 'OK') {
          //console.log("dati ricevuti: ", this.invSPVdata);
          var bodyInvestor =
            '{' +
            ' \n  "to":"' +
            this.invSPVdata.email +
            '"' +
            ',\n  "firstname":"' +
            this.invSPVdata.name +
            '"' +
            ',\n  "lastname":"' +
            this.invSPVdata.lastName +
            '"' +
            ',\n  "bankName":"' +
            _bankName +
            '"' +
            ',\n  "subject":"La richiesta fondi è stata accettata"' +
            ',\n  "senderCompany":"FX12 Srl"' +
            ',\n  "from":"support@incassaora.it"' +
            ',\n  "senderName":"Support of Incassaora"' +
            ',\n  "templateName":"bankRequestAccepted"' +
            '\n}';

          //console.log("\nbody: ", bodyInvestor);
          this.sendMail.sendEmailTemplateByBody(bodyInvestor);

          var bodyFX =
            '{' +
            ' \n  "to":"mario.celano@hydrasolutions.it"' +
            ',\n  "firstname":"Amministratore"' +
            ',\n  "lastname":"FX12"' +
            ',\n  "bankName":"' +
            _bankName +
            '"' +
            ',\n  "subject":"La richiesta fondi è stata accettata"' +
            ',\n  "senderCompany":"FX12 Srl"' +
            ',\n  "from":"support@incassaora.it"' +
            ',\n  "senderName":"Support of Incassaora"' +
            ',\n  "templateName":"bankRequestAccepted"' +
            '\n}';
          //console.log("\nbody: ", bodyFX);
          this.sendMail.sendEmailTemplateByBody(bodyFX);
          alert('Richiesta accettata');
          window.location.reload();
        } else {
          console.log(resp);
          alert('Si sono riscontrati dei problemi');
          window.location.reload();
        }
      });
  }

  rejectReq(_invoices, _reqID, _action, _investorID, _bankName) {
    this.getInvData(_investorID);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    this.http
      .post<DataTablesResponse>(
        `${environment.laravelServer}/bankrequest/changeStatus`,
        '{"reqID": "' + _reqID + '", "action": "' + _action + '", "invoices": "' + _invoices + '"}',
        httpOptions
      )
      .subscribe(resp => {
        //console.log(resp);
        if (resp['status'] == 'OK') {
          //console.log("dati ricevuti: ", this.invSPVdata);
          var bodyInvestor =
            '{' +
            ' \n  "to":"' +
            this.invSPVdata.email +
            '"' +
            ',\n  "firstname":"' +
            this.invSPVdata.name +
            '"' +
            ',\n  "lastname":"' +
            this.invSPVdata.lastName +
            '"' +
            ',\n  "bankName":"' +
            _bankName +
            '"' +
            ',\n  "subject":"La richiesta fondi è stata rifiutata"' +
            ',\n  "senderCompany":"FX12 Srl"' +
            ',\n  "from":"support@incassaora.it"' +
            ',\n  "senderName":"Support of Incassaora"' +
            ',\n  "templateName":"bankRequestRejected"' +
            '\n}';

          //console.log("\nbody: ", bodyInvestor);
          this.sendMail.sendEmailTemplateByBody(bodyInvestor);

          var bodyFX =
            '{' +
            ' \n  "to":"mario.celano@hydrasolutions.it"' +
            ',\n  "firstname":"Amministratore"' +
            ',\n  "lastname":"FX12"' +
            ',\n  "bankName":"' +
            _bankName +
            '"' +
            ',\n  "subject":"La richiesta fondi è stata rifiutata"' +
            ',\n  "senderCompany":"FX12 Srl"' +
            ',\n  "from":"support@incassaora.it"' +
            ',\n  "senderName":"Support of Incassaora"' +
            ',\n  "templateName":"bankRequestRejected"' +
            '\n}';
          //console.log("\nbody: ", bodyFX);
          this.sendMail.sendEmailTemplateByBody(bodyFX);

          alert('Richiesta rifiutata');
          window.location.reload();
        } else {
          console.log(resp);
          alert('Si sono riscontrati dei problemi');
          window.location.reload();
        }
      });
  }

  getInvData(_investorId) {
    this.finished = false;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<DataTablesResponse>(
        `${environment.laravelServer}/getUserById`,
        '{"id": "' + _investorId + '"}',
        httpOptions
      )
      .subscribe(resp => {
        this.invSPVdata = resp;
      });
  }

  downloadInvestorDoc(_flnm) {
    const path = '/documenti_richieste/' + _flnm;
    const ref = this.storage.ref(path);

    ref.getDownloadURL().subscribe(data => {
      //console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }
}
