import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeInvestorSpvComponent } from './home-investor-spv.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { Utilities } from '@app/utilities/Utilities';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { DocumentFundsComponent } from './document-funds/document-funds.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/investorSPV/invoiceList', pathMatch: 'full' },
    {
      path: 'investorSPV',
      component: HomeInvestorSpvComponent,
      children: [
        {
          path: 'invoiceList',
          component: InvoiceListComponent
        },
        {
          path: 'invoicedocument',
          component: DocumentFundsComponent
        }
      ],
      data: { title: extract('investorSPV'), role: Utilities.userType[8] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeInvestorSpvRoutingModule {}
