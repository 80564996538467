import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShowMessageService {
  show: boolean = false;
  showPasswordReset: boolean = false;
  showVerified: boolean = false;
  showDisattivato: boolean;
  showRegistazione: boolean = false;
  showCreazioneCompany: boolean = false;
  showListaUtenti: boolean = false;
  showListaUtentiSuccess: boolean = false;
  showDocCaricatoConSuccesso: boolean = false;
  showIbanCreato: boolean = false;
  showDocNonCaricato: boolean = false;
  constructor() {}
}
