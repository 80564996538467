import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { AngularFireStorage } from '@angular/fire/storage';
import * as url from 'url';
import { Invoice } from '@app/models/Invoice.model';
import { RequestDetailModalComponent } from '@app/home/wallet-details/request-detail-modal/request-detail-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Wallet } from '@app/models/Wallet.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment.prod';
import { Utilities } from '@app/utilities/Utilities';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-invoice-list-item',
  templateUrl: './invoice-list-item.component.html',
  styleUrls: ['./invoice-list-item.component.scss']
})
export class InvoiceListItemComponent implements OnInit {
  gridColumns = 3;

  @Input() item: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  private dataRecords: any[] = [];

  private urlLogo: any[] = [];
  private percentRacc: any[] = [];

  private wallet: Wallet;
  private backupWallet: Wallet;

  constructor(
    private storage: AngularFireStorage,
    private modalService: NgbModal,
    private http: HttpClient,
    private firebase: FirebaseTranchedService,
    private backendAuthService: BackendAuthService
  ) {
    this.wallet = new Wallet();
  }

  getDownloadURL(trans: string, index: number) {
    if (trans) {
      const ref = this.storage.ref(trans);
      ref.getDownloadURL().subscribe(data => {
        // console.log(data);

        this.urlLogo[index] = data;
        return data;
      });
    } else {
      this.urlLogo[index] = 'assets/logo.png';
    }
  }

  ngOnInit() {
    console.log('Items OnInit=>', this.item);

    this.dataRecords = this.item.data;
    let indexe = 0;
    this.dataRecords.forEach(data => {
      console.log('DD index=>', indexe);
      this.percentRacc[indexe] = 0;
      // console.log(data.wallet.logo);
      this.getDownloadURL(data.wallet.logo, indexe);
      // prendo l'array degli investitori che hanno assengati
      if (data.listaInvestitoriPagamento && data.listaInvestitoriPagamento.length > 0) {
        this.percentRacc[indexe] = 0;
        data.listaInvestitoriPagamento.forEach(dataInv => {
          const newArr = dataInv.split('---');
          console.log('newArr=>', newArr);
          console.log('newArr=>', newArr.length);

          if (newArr.length > 0) {
            console.log('DD=>', dataInv);

            console.log('DD newArr=>', newArr[1]);
            this.percentRacc[indexe] = this.percentRacc[indexe] + Number(newArr[1]);
          } else {
            this.percentRacc[indexe] = 0;
          }

          console.log('DD val=>', this.percentRacc[indexe]);
        });
      } else {
        this.percentRacc[indexe] = 0;
      }

      indexe++;
    });
  }

  getDetail(uuid_: string) {
    // devo recuperare i dettagli della fattura dal suo id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getInvoiceById`, '{"id":"' + uuid_ + '"}', httpOptions)
      .subscribe(respInv => {
        const idWallet = respInv.walletId;

        this.firebase
          .getWalletfromID(idWallet)
          .then(doc => {
            if (doc.exists) {
              this.wallet = doc.data() as Wallet;
              this.backupWallet = { ...this.wallet };
              this.wallet.id = idWallet;
              Utilities.getDaysLeft(this.wallet);

              this.showRequestDetail(respInv);
            } else {
            }
          })
          .catch(error => {});
      });
  }

  showRequestDetail(request: Invoice) {
    const modalRef = this.modalService.open(RequestDetailModalComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.invoice = request;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.result.then(
      () => {
        // Save
      },
      () => {
        // Dismiss
      }
    );
  }
}
