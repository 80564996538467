import { Utilities } from '@app/utilities/Utilities';
import { Component, OnInit, QueryList, ViewChildren, ViewChild, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Wallet } from '@app/models/Wallet.model';
import { BaseChartDirective } from 'ng2-charts';
import { NgbdSortableHeader, SortEvent } from '@app/models/utils/sortable.directive';
import { Observable } from 'rxjs';
import { RequestSortService } from './request-sort.service';
import { RatingModalComponent } from './rating-modal/rating-modal.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Invoice } from '@app/models/Invoice.model';
import { RequestDetailModalComponent } from './request-detail-modal/request-detail-modal.component';
import { InvoiceModalComponent } from './invoice-modal/invoice-modal.component';
import * as _ from 'lodash';

import { Location } from '@angular/common';

@Component({
  selector: 'app-wallet-details',
  templateUrl: './wallet-details.component.html',
  styleUrls: ['./wallet-details.component.scss']
})
export class WalletDetailsComponent implements OnInit {
  [x: string]: any;
  public wallet: Wallet;
  public daysLeft: number;
  public invoices: Invoice[];
  public invoicesChiuse: Invoice[];
  public invoicesInAttesadiPagamento: Invoice[];
  public invoicesInCorso: Invoice[];

  successMessage: string;
  errorMessage: string;
  status: string[];
  active = 1;

  ordDataFattura: boolean;
  ordDataScadenza: boolean;
  ordClienti: boolean;
  ordImportoFattura: boolean;
  ordImportoDaScontare: boolean;
  ordScontoAccettato: boolean;
  ordScontoProposto: boolean;
  ordControproposta: boolean;

  ordImporto: boolean;
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  // Company table order vars
  total: Observable<number>;

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;
  invoiceStatus: string[];

  private id: string;
  private backupWallet: Wallet;
  private backUpInvoice: Invoice;

  private _success = new Subject<string>();
  private _error = new Subject<string>();

  // Constructor
  constructor(
    private route: ActivatedRoute,
    public firebase: FirebaseTranchedService,
    public sortingService: RequestSortService,
    private modalService: NgbModal,
    private router: Router,
    private location: Location
  ) {
    this.firebase.createPageLog('User Visit Page wallet-details/:' + this.id);
    this.invoiceStatus = Utilities.invoiceStatus;
    this._error.subscribe(message => (this.errorMessage = message));

    this._error.pipe(debounceTime(5000)).subscribe(() => (this.errorMessage = null));
    this._success.subscribe(message => (this.successMessage = message));

    this._success.pipe(debounceTime(5000)).subscribe(() => (this.successMessage = null));
    // this.status = Utilities.requestStatus;
    this.id = this.route.snapshot.paramMap.get('id');
    this.wallet = new Wallet();
    this.invoices = [];
    this.invoicesChiuse = [];
    this.invoicesInAttesadiPagamento = [];
    this.invoicesInCorso = [];
    this.ordDataFattura = false;
    this.ordDataScadenza = false;
    this.ordClienti = false;
    this.ordImportoFattura = false;
    this.ordImportoDaScontare = false;
    this.ordScontoAccettato = false;
    this.ordScontoProposto = false;
    this.ordControproposta = false;

    this.ordImporto = false;
    firebase
      .getWalletfromID(this.id)
      .then(doc => {
        if (doc.exists) {
          this.wallet = doc.data() as Wallet;
          this.backupWallet = { ...this.wallet };
          this.wallet.id = this.id;
          Utilities.getDaysLeft(this.wallet);
        } else {
        }
      })
      .catch(error => {});

    this.firebase.getInvoicesByID(this.id).subscribe(data => {
      this.invoices = data as Invoice[];

      if (this.firebase.isCompany()) {
        this.filterInvForUser();
      }
      if (this.firebase.isInvestor()) {
        this.filterInvForUserInvestor(data);
      }
      this.invoicesChiuse = { ...this.invoices };
      this.invoicesInCorso = { ...this.invoices };
      this.invoicesInAttesadiPagamento = { ...this.invoices };

      this.filterForConcluse();
      this.filterForInCorso();
      this.filterForAcquistata();
      // Ordinamento per data dec
      this.invoicesInCorso = _.orderBy(
        this.invoicesInCorso,
        [invoice => invoice.cedenteName.toLowerCase(), 'dataFattura'],
        ['asc', 'desc']
      );
      this.invoicesChiuse = _.orderBy(
        this.invoicesChiuse,
        [invoice => invoice.cedenteName.toLowerCase(), 'dataFattura'],
        ['asc', 'desc']
      );
      this.invoicesInAttesadiPagamento = _.orderBy(
        this.invoicesInAttesadiPagamento,
        [invoice => invoice.cedenteName.toLowerCase(), 'dataFattura'],
        ['asc', 'desc']
      );
      // this.sortingService.setRequests(this.invoices);
      // this.sortingService.initialize();
      // this.setCharts();
    });
  }

  filterInvForUser() {
    this.invoices = _.filter(this.invoices, item => item.uid === this.firebase.loggedUser.id);
  }

  filterInvForUserInvestor(data: any) {
    // console.log(data);
    var arr: any[] = [];
    for (var inv of data) {
      //Serve per mantenere tutte le fatture terminate
      //Serve per mostrare le fatture nelle quali può proporsi
      if (inv.status === this.invoiceStatus[0] || inv.status === this.invoiceStatus[9] || inv.status_index == 32) {
        arr.push(inv);
      }

      var inve: any = inv;
      //Serve per mostrare tutte le operazioni in una trattativa nella quale lui è inserito
      if (inve.listaInvestitori) {
        //console.log(inve.listaInvestitori);
        for (var valore of inve.listaInvestitori) {
          if (
            valore.split('---')[0] === this.firebase.loggedUser.investorPIva &&
            !(inv.status === this.invoiceStatus[0] || inv.status === this.invoiceStatus[9] || inv.status_index == 32)
          ) {
            arr.push(inve);
          }
        }
      }
    }
    this.invoices = arr;
  }
  ordinaDataFattura() {
    //(this.ordDataFattura);
    if (this.ordDataFattura == false) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['dataFattura'], ['asc']);
    }
    if (this.ordDataFattura == true) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['dataFattura'], ['desc']);
    }

    this.ordDataFattura = !this.ordDataFattura;
  }

  ordinaDataScadenza() {
    //console.log(this.ordDataScadenza);
    if (this.ordDataScadenza == false) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['dataScadenza'], ['asc']);
    }
    if (this.ordDataScadenza == true) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['dataScadenza'], ['desc']);
    }

    this.ordDataScadenza = !this.ordDataScadenza;
  }
  ordinaImportoFattura() {
    //console.log(this.ordImportoFattura);
    if (this.ordImportoFattura == false) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['importoFattura'], ['asc']);
    }
    if (this.ordImportoFattura == true) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['importoFattura'], ['desc']);
    }

    this.ordImportoFattura = !this.ordImportoFattura;
  }

  ordinaScontoProposto() {
    // console.log(this.ordScontoProposto);
    if (this.ordScontoProposto == false) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['scontoProposto'], ['asc']);
    }
    if (this.ordScontoProposto == true) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['scontoProposto'], ['desc']);
    }

    this.ordScontoProposto = !this.ordScontoProposto;
  }

  ordinaControproposta() {
    //console.log(this.ordControproposta);
    if (this.ordControproposta == false) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['controproposta'], ['asc']);
    }
    if (this.ordControproposta == true) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['controproposta'], ['desc']);
    }

    this.ordControproposta = !this.ordControproposta;
  }

  ordinaScontoAccettato() {
    //(this.ordScontoAccettato);
    if (this.ordScontoAccettato == false) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['scontoAccettato'], ['asc']);
    }
    if (this.ordScontoAccettato == true) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, ['scontoAccettato'], ['desc']);
    }

    this.ordScontoAccettato = !this.ordScontoAccettato;
  }

  ordinaCliente() {
    //console.log(this.ordClienti);
    if (this.ordClienti == true) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, [invoice => invoice.cedenteName.toLowerCase()], ['asc']);
    }
    if (this.ordClienti == false) {
      this.invoicesInCorso = _.orderBy(this.invoicesInCorso, [invoice => invoice.cedenteName.toLowerCase()], ['desc']);
    }

    this.ordClienti = !this.ordClienti;
  }

  filterForConcluse() {
    this.invoicesChiuse = _.filter(
      this.invoicesChiuse,
      item =>
        item.status === this.invoiceStatus[0] ||
        item.status_index === 17 ||
        item.status_index === 37 ||
        item.status_index === 3 ||
        item.status_index === 60
    );
  }

  filterForAcquistata() {
    this.invoicesInAttesadiPagamento = _.filter(
      this.invoicesInAttesadiPagamento,
      item =>
        item.status === this.invoiceStatus[0] ||
        item.status === this.invoiceStatus[12] ||
        item.status_index === 36 ||
        item.status_index === 59
    );

    //console.log(this.invoicesInAttesadiPagamento);
  }

  filterForInCorso() {
    this.invoicesInCorso = _.filter(
      this.invoicesInCorso,
      item =>
        item.status_index === 0 ||
        item.status_index === 1 ||
        item.status_index === 2 ||
        item.status_index === 4 ||
        item.status_index === 5 ||
        (item.status_index >= 10 && item.status_index < 16) ||
        (item.status_index >= 30 && item.status_index < 36) ||
        (item.status_index >= 50 && item.status_index < 59)
    );
  }

  onSort({ column, direction }: SortEvent) {
    // resetting other header
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.sortingService.sortColumn = column;
    this.sortingService.sortDirection = direction;
  }

  ngOnInit() {}

  open() {
    const modalRef = this.modalService.open(RatingModalComponent);
    modalRef.componentInstance.ratingSystemLabel = this.wallet.ratingSystemLabel;
    modalRef.componentInstance.ratingSystemPercentage = this.wallet.ratingSystemPercentage;
    modalRef.result.then((result: { label: string[]; percentage: number[] }) => {
      this.wallet.ratingSystemLabel = result.label;
      this.wallet.ratingSystemPercentage = result.percentage;

      this.firebase.modWallet(this.wallet);
    });
  }

  saveRequest() {
    for (const request of this.invoices) {
      if (request.modified) {
        this.firebase
          .modInvoice(request)
          .then(res => {
            this._success.next('Dati salvati con successo');
          })
          .catch(err => {
            this._error.next('Errore, dati non salvati!');
          });
        request.modified = false;
      }
    }
  }

  editInvoice(invoice: Invoice) {
    const modalRef = this.modalService.open(InvoiceModalComponent, { size: 'xl', centered: true, scrollable: true });

    modalRef.componentInstance.isEditing = true;
    modalRef.componentInstance.invoiceToModify = invoice;
    modalRef.componentInstance.wallet = this.wallet;
    this.backUpInvoice = { ...invoice };
    // modalRef.componentInstance.ratingSystemPercentage = this.wallet.ratingSystemPercentage;
    modalRef.result.then(
      () => {
        /*result*/
      },
      () => {
        // reason
        invoice = { ...this.backUpInvoice };
      }
    );
  }
  showRequestDetail(request: Invoice) {
    //console.log('Fattura', request.id);
    const modalRef = this.modalService.open(RequestDetailModalComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    try {
      if (!request.status_index) {
        request.status_index = 0;
      }
    } catch (e) {
      request.status_index = 0;
    }
    modalRef.componentInstance.invoice = request;
    modalRef.componentInstance.invoice_id = request.id;
    modalRef.componentInstance.wallet = this.wallet;
    // modalRef.componentInstance.ratingSystemPercentage = this.wallet.ratingSystemPercentage;
    modalRef.result.then(
      () => {
        // Save
      },
      () => {
        // Dismiss
      }
    );
  }

  showInvoiceModal() {
    const modalRef = this.modalService.open(InvoiceModalComponent, {
      size: 'lg',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.walletId = this.id;
    modalRef.componentInstance.walletName = this.wallet.walletName;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.result.then(
      () => {
        // Save
      },
      () => {
        // Dismiss
      }
    );
  }

  back() {
    this.location.back();
  }

  gotoDetail(id: any) {
    this.router.navigate(['../../anagrafiche/' + id + '/detail'], { relativeTo: this.route });
  }
}

@Component({
  selector: 'ngbd-modal-confirm-close',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Chiusura portafoglio</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Sei sicuro di voler chiudere il portafoglio?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Indietro</button>
      <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">Chiudi</button>
    </div>
  `
})
export class NgbdModalConfirmCloseComponent {
  constructor(public modal: NgbActiveModal) {}
}
