import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuryRate } from '@app/models/UsuryRates.model';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './delete-usury-rate-modal.component.html',
  styleUrls: ['./delete-usury-rate-modal.component.scss']
})
export class DeleteUsuryRateModalComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() usuryRate: UsuryRate;

  constructor(public activeModal: NgbActiveModal, private firebase: FirebaseTranchedService) {}

  ngOnInit() {}

  deleteUsuryRate() {
    this.firebase.deleteUsuryRate(this.usuryRate.id);
    this.activeModal.dismiss();
  }
}
