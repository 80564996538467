import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeServicerRoutingModule } from './home-servicer-routing.module';
import { HomeServicerComponent } from './home-servicer.component';
import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { PendingRequestListComponent } from './pending-request-list/pending-request-list.component';

@NgModule({
  declarations: [HomeServicerComponent, PendingRequestListComponent],
  imports: [CommonModule, HomeServicerRoutingModule, SharedModule, FormsModule]
})
export class HomeServicerModule {}
