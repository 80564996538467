import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LandingRoutingModule } from './landing-routing.module';
import { LandingComponent } from './landing.component';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [LandingComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, SharedModule, NgbModule, LandingRoutingModule]
})
export class LandingModule {}
