import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalGenericComponent } from '@app/confirmation-modal-generic/confirmation-modal-generic.component';
import { BankSpvServiceService } from '@app/home/bank-spv/bank-spv-service.service';
import { BankSPV } from '@app/models/BankSPV.model';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './bank-spv-edit.component.html',
  styleUrls: ['./bank-spv-edit.component.scss']
})
export class BankSpvEditComponent implements OnInit {
  entityCreationForm!: FormGroup;
  showSelect: boolean = false;
  get email() {
    return this.entityCreationForm.get('email');
  }
  get pec() {
    return this.entityCreationForm.get('pec');
  }

  get firstName() {
    return this.entityCreationForm.get('firstName');
  }

  get lastName() {
    return this.entityCreationForm.get('lastName');
  }

  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;
  entity: BankSPV;
  id: string;
  private sub: any;

  private selectedInvestorsSPV = [];

  /* dropdown multi select */
  private dropdownList = [];
  private selectedItems = [];
  private dropdownSettings = {};
  /* ********************* */

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private myService: BankSpvServiceService,
    private backendAuthService: BackendAuthService
  ) {
    console.log('Data return=>', this.myService.data);
    this.dropdownList = this.myService.data;

    this.createForm();
  }

  createForm() {
    this.entityCreationForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      ragioneSociale: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      pec: new FormControl(''),
      telefono: new FormControl(''),
      indirizzo: new FormControl(''),
      city: new FormControl(''),
      cap: new FormControl(''),
      state: new FormControl(''),
      investorsSPVList: new FormControl('')
    });
  }

  ngOnInit() {
    this.entity = new BankSPV();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.sub = this.route.params.subscribe(params => {
      this.id = params.id; // (+) converts string 'id' to a number
      console.log(params.id);
      this.http
        .post<BankSPV>(`${environment.laravelServer}/getUserById`, '{"id":"' + params.id + '"}', httpOptions)
        .subscribe(data => {
          console.log('DATA=>', data);
          this.entity.firstName = data.name;
          this.entity.ragioneSociale = data.ragioneSociale;
          this.entity.lastName = data.lastName;
          this.entity.email = data.email;
          this.entity.pec = data.pec;
          this.entity.telefono = data.telefono;
          this.entity.indirizzo = data.indirizzo;
          this.entity.city = data.city;
          this.entity.state = data.state;
          this.entity.cap = data.cap;
          console.log('entity=>', this.entity);

          this.selectedItems = [];

          this.dropdownList.forEach(item => {
            console.log('item=>', item);
            if (data.investorsSPV.indexOf(item.item_id) > -1) {
              console.log('PRENSENTE=>');
              this.selectedItems.push(item);
              this.selectedInvestorsSPV.push(item.item_id);
            } else {
              console.log('ASSENTE=>');
            }
          });

          console.log('showSelect=>', this.showSelect);
          console.log('dropdownList=>', this.dropdownList);
          console.log('selectedItems=>', this.selectedItems);
          this.showSelect = true;

          console.log('Selected=>', this.selectedItems);

          if (!this.entity.pec) {
            this.entity.pec = '';
          }

          this.entityCreationForm.patchValue({
            firstName: this.entity.firstName,
            ragioneSociale: this.entity.ragioneSociale,
            lastName: this.entity.lastName,
            email: this.entity.email,
            pec: this.entity.pec,
            telefono: this.entity.telefono,
            indirizzo: this.entity.indirizzo,
            city: this.entity.city,
            state: this.entity.state,
            cap: this.entity.cap,
            investorsSPVList: this.dropdownList
          });
          console.log('se1=>', this.entityCreationForm);
        });
    });

    this.firebaseService.createPageLog('User Visit Page - UPDATE BANK SPV');

    console.log('ONINIT');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      allowRemoteDataSearch: true
    };
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
    this.selectedInvestorsSPV.push(item.item_id);
    console.log('ServersADD=>', this.selectedInvestorsSPV);
  }

  onItemDeSelect(item: any) {
    const index = this.selectedInvestorsSPV.indexOf(item.item_id);
    if (index !== -1) {
      this.selectedInvestorsSPV.splice(index, 1);
    }
    console.log('ServersDELETE=>', this.selectedInvestorsSPV);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll', items);
    items.forEach(item => {
      this.selectedInvestorsSPV.push(item.item_id);
    });
    console.log('ServersADD=>', this.selectedInvestorsSPV);
  }

  onDeSelectAll(items: any) {
    this.selectedInvestorsSPV = [];
    console.log('ServersADD=>', this.selectedInvestorsSPV);
  }

  submit() {
    if (this.verifyMandatoryField()) {
      // console.log('se=>',this.servicerSPVCreationForm);
      const modalRef = this.modalService.open(ConfirmationModalGenericComponent, {
        size: 'md',
        centered: true,
        scrollable: true
      });
      modalRef.componentInstance.title = 'Attenzione';
      modalRef.componentInstance.text = 'Confermi di voler aggiornare i dati?';
      modalRef.componentInstance.loading.subscribe((data: boolean) => {
        console.log(data);
        if (data) {
          modalRef.close();

          const objectToSave = this.firebaseService.updateBankSPV(this.entityCreationForm, this.selectedInvestorsSPV);
          console.log('objectToSave=>', objectToSave);
          this.firebaseService.updateUserDocAfterCreate(this.id, objectToSave);

          this.router.navigate(['/home/bank-spv/' + this.id + '/detail']);
        }
      });
    } else {
      alert(
        'Attenzione - Non tutti i campi risultano compilati correttanemte o mancati. Sarete reindizizzati alla pagina precendente.'
      );
      this.router.navigate(['/home/bank-spv/list']);
    }
  }

  verifyMandatoryField() {
    let response = true;

    if (this.entityCreationForm.value.email == '') {
      response = false;
    }
    if (this.entityCreationForm.value.pec == '') {
      response = false;
    }
    if (this.entityCreationForm.value.firstName == '') {
      response = false;
    }
    if (this.entityCreationForm.value.lastName == '') {
      response = false;
    }
    if (this.entityCreationForm.value.ragioneSociale == '') {
      response = false;
    }
    if (this.entityCreationForm.value.telefono == '') {
      response = false;
    }
    if (this.entityCreationForm.value.indirizzo == '') {
      response = false;
    }
    if (this.entityCreationForm.value.cap == '') {
      response = false;
    }
    if (this.entityCreationForm.value.city == '') {
      response = false;
    }
    if (this.entityCreationForm.value.state == '') {
      response = false;
    }

    return response;
  }
  goBack() {
    this.router.navigate(['/home/bank-spv/' + this.id + '/detail']);
  }
}
