import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeInvestorRoutingModule } from './home-investor-routing.module';
import { HomeInvestorComponent } from './home-investor.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { PendingInvestorComponent } from './pending-investor/pending-investor.component';
import { ContrattoInvestitoreComponent } from './contratto-investitore/contratto-investitore.component';
import { WidgetReportComponent } from './widget-report/widget-report.component';

import { MatButtonModule, MatPaginatorModule, MatSliderModule, MatToolbarModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material';
import { InvoiceListComponent } from '@app/home/invoice-list/invoice-list.component';
import { InvoiceListItemComponent } from '@app/home/invoice-list/invoice-list-item/invoice-list-item.component';
import { InvestorCalendarComponent } from './investor-calendar/investor-calendar.component';
import { InvestorTermListComponent } from './investor-term-list/investor-term-list.component';

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { CalendarModule as AngularCalendarModule, CalendarCommonModule } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlatpickrModule } from 'angularx-flatpickr';
import { DataTablesModule } from 'angular-datatables';
import { MonthlyDashboardComponent } from './monthly-dashboard/monthly-dashboard.component';

import { ChartsModule } from 'ng2-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';

@NgModule({
  declarations: [
    HomeInvestorComponent,
    DashboardComponent,
    PendingInvestorComponent,
    ContrattoInvestitoreComponent,
    WidgetReportComponent,
    InvoiceListComponent,
    InvoiceListItemComponent,
    InvestorCalendarComponent,
    InvestorTermListComponent,
    MonthlyDashboardComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    ClipboardModule,
    SharedModule,
    NgbModule,
    HomeInvestorRoutingModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    FlexLayoutModule,
    MatCardModule,
    MatSliderModule,
    MatPaginatorModule,
    NgbModalModule,
    ChartsModule,
    NgxChartsModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    DataTablesModule
  ],
  exports: [InvestorCalendarComponent, InvestorTermListComponent]
})
export class HomeInvestorModule {}
