import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '@app/models/User.model';
import { ModalProfileComponent } from '@app/profile/modal-profile/modal-profile.component';
import { Observable, Subscription } from 'rxjs';
import { PendingService } from '@app/pending.service';
import { Utilities } from '@app/utilities/Utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment.prod';
import { Wallet } from '@app/models/Wallet.model';
import { Invoice } from '@app/models/Invoice.model';
import { RequestDetailModalComponent } from '@app/home/wallet-details/request-detail-modal/request-detail-modal.component';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-pending-request-list',
  templateUrl: './pending-request-list.component.html',
  styleUrls: ['./pending-request-list.component.scss']
})
export class PendingRequestListComponent implements OnInit, OnDestroy {
  public tipologia: string;
  public usersList: Observable<any[]>;
  public docsList: Observable<any[]>;
  public pendingUsers: any[] = [];
  public pendingDocs: any[] = [];
  sub: Subscription;

  private wallet: Wallet;
  private backupWallet: Wallet;

  constructor(
    private firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private pending: PendingService,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private backendAuthService: BackendAuthService
  ) {
    this.tipologia = 'users';
    this.firebase.createPageLog('User Visit Page pending Requests');
  }

  ngOnInit() {
    this.usersList = this.firebase.getUsersByVerified(false);
    this.sub = this.usersList.subscribe(data => {
      this.pendingUsers = data;
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  showModal(userIn: User) {
    const modalRef = this.modalService.open(ModalProfileComponent, {
      size: 'lg',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.userInput = userIn;
    modalRef.componentInstance.showUpdateButton = false;
  }

  approve(obj: any, type: string) {
    switch (type) {
      case 'user': {
        this.firebase.approveUser(obj);
        break;
      }
      case 'doc': {
        this.firebase.approveDocs(obj);
      }
    }
    this.pending.dec(1);
  }

  loadPendingRequests(): void {
    switch (this.tipologia) {
      case 'users': {
        this.usersList = this.firebase.getUsersByVerified(false);
        this.sub = this.usersList.subscribe(data => {
          this.pendingUsers = data;
        });
        break;
      }
      case 'docs': {
        this.docsList = this.firebase.getInvoicesNotApproved();
        this.sub = this.docsList.subscribe(data => {
          // console.log(data);
          this.pendingDocs = data;
        });
        break;
      }
    }
  }

  info(data: any) {
    this.router.navigate(['../wallet-details/' + data.walletId], { relativeTo: this.route });
  }

  infoOperazione(doc_: any) {
    // devo recuperare i dettagli della fattura dal suo id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getInvoiceById`, '{"id":"' + doc_.id + '"}', httpOptions)
      .subscribe(respInv => {
        const idWallet = respInv.walletId;

        this.firebase
          .getWalletfromID(idWallet)
          .then(doc => {
            if (doc.exists) {
              this.wallet = doc.data() as Wallet;
              this.backupWallet = { ...this.wallet };
              this.wallet.id = idWallet;
              Utilities.getDaysLeft(this.wallet);

              this.showRequestDetail(respInv);
            } else {
            }
          })
          .catch(error => {});
      });
  }

  showRequestDetail(request: Invoice) {
    const modalRef = this.modalService.open(RequestDetailModalComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.invoice = request;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.result.then(
      () => {
        // Save
      },
      () => {
        // Dismiss
      }
    );
  }
}
