import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Observable, Subscription } from 'rxjs';
import { UsuryRate } from '@app/models/UsuryRates.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteUsuryRateModalComponent } from '@app/home/usury-rates/delete-usury-rate/delete-usury-rate-modal.component';
import { Utilities } from '@app/utilities/Utilities';

@Component({
  selector: 'app-usury-rates',
  templateUrl: './usury-rates.component.html',
  styleUrls: ['./usury-rates.component.scss']
})
export class UsuryRatesComponent implements OnInit {
  [x: string]: any;

  usuryRateList: Observable<any[]>;
  sub: Subscription;
  usuryRateItems: any[];

  utilities = Utilities;

  constructor(private firebase: FirebaseTranchedService, private modalService: NgbModal) {
    this.firebase.createPageLog('User Visit Page usury-rates');
  }

  ngOnInit() {
    this.usuryRateList = this.firebase.getUsuryRates();
    this.sub = this.usuryRateList.subscribe(data => {
      this.usuryRateItems = data;
    });
  }

  formatta(s: string) {
    //console.log(s);
    var arr = s.split('---');
    return 'da ' + arr[0] + '€ a ' + arr[1] + '€ tasso ' + arr[2] + '%';
  }

  showModal(usuryRate: UsuryRate) {
    const modalRef = this.modalService.open(DeleteUsuryRateModalComponent, {
      size: 'md',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.title = 'Attenzione';
    modalRef.componentInstance.text = 'Sei sicuro di voler eliminare questo tasso di usura?';
    modalRef.componentInstance.usuryRate = usuryRate;
  }
}
