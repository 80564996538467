import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-bank-spv',
  templateUrl: './home-bank-spv.component.html',
  styleUrls: ['./home-bank-spv.component.scss']
})
export class HomeBankSpvComponent implements OnInit {
  isLoading = false;
  constructor() {}

  ngOnInit() {}
}
