import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Utilities } from '@app/utilities/Utilities';

@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent implements OnInit {
  version: string | null = environment.version;
  error: string;
  resetPasswordForm!: FormGroup;
  isLoading = false;
  products: string[];
  product: string;
  showErrorMessage = false;
  showSuccessMessage = false;
  successMessage =
    "E' stata inviata una mail con un link all'indirizzo da te specificato. Cliccalo per ripristinare la password del tuo account.";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseTranchedService
  ) {
    this.createForm();
    this.products = Utilities.productNames;
    this.product = this.products[0];
  }

  ngOnInit() {}

  selectProduct(product: string) {
    this.product = product;
  }

  goTo(s: string): void {
    this.router.navigate([s]);
  }

  sendResetPasswordRequest() {
    if (this.product !== '') {
      this.isLoading = true;
      this.afAuth.auth
        .sendPasswordResetEmail(this.resetPasswordForm.value.username)
        .then(user => {
          this.showSuccessMessage = true;
        })
        .catch(error => {
          this.error = error;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  }

  openFaq() {
    window.open('#/faq');
  }
  openRegistrazione() {
    window.open('#/registration');
  }

  private createForm() {
    this.resetPasswordForm = this.formBuilder.group({
      username: ['', Validators.required],
      remember: true
    });
  }
}
