// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.

export const environment = {
  production: true,
  hmr: false,
  version: '2',
  serverUrl: 'https://panel.incassaora.it',
  defaultLanguage: 'it-IT',
  supportedLanguages: ['it-IT', 'en-US', 'fr-FR'],
  baseUrl: 'https://panel.incassaora.it',
  firebase: {
    apiKey: 'AIzaSyCteMPSoI3TEWAfSpQWtmJSl6XNlE_bFyI',
    authDomain: 'fx-12-prod.firebaseapp.com',
    databaseURL: 'https://incassaora-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'incassaora',
    storageBucket: 'incassaora.appspot.com',
    messagingSenderId: '939382233883',
    appId: '1:939382233883:web:a24047dfd804d40ef891be',
    measurementId: 'G-FJWW6LZKVJ'
  },
  saltPass: '$2a$10$R02YXA0PITXFup5js4IBfuQQH6uSMaYRTb5RDJocXO2QcT.ItMZz.',
  tigranUrl: 'https://fx12.oplonrisk.com',
  tigranUsername: 'fx12_api_user',
  tigranPassword: '986;IUGiguihG54678',
  tigranUserMail: 'fx12_api_user@modefinance.com',
  secretFx12_: 'k1gJZU0QYWVysIpzsRx3',
  // laravelServer: 'https://apibackend.incassaora.it/api',
  laravelServer: 'https://incassaoradevback.hydrasolutions.it/api',
  // laravelServer: 'http://incassaora.test/api',
  backend: {
    auth: {
      email: 'test@test.it',
      password: 'asd147asd'
    }
  }
};
