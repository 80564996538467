import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { environment } from '@env/environment.prod';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-mypreferences',
  templateUrl: './personal-preference.component.html',
  styleUrls: ['./personal-preference.component.scss']
})
export class PersonalPreferenceComponent implements OnInit {
  preferenceForm!: FormGroup;
  private loggedUserId: string;
  private invoiceMin: number;
  private invoiceMax: number;
  private defaultMin: number;
  private defaultMax: number;
  private maturityMin: number;
  private maturityMax: number;

  constructor(
    private firebase: FirebaseTranchedService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private backendAuthService: BackendAuthService
  ) {
    //this.retObj = {};
    this.loggedUserId = this.firebase.loggedUser.id;
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.preferenceForm = this.formBuilder.group({
      invoiceMin: 0,
      invoiceMax: 99000000,
      defaultMin: 0,
      defaultMax: 99000000,
      maturityMin: 0,
      maturityMax: 99000000
    });

    this.getDati();
  }

  getDati(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(
        `${environment.laravelServer}/investorpreferences/getPreferences`,
        '{"id":"' + this.loggedUserId + '"}',
        httpOptions
      )
      .subscribe(data => {
        this.preferenceForm.setValue({
          invoiceMin: data.invoiceMin,
          invoiceMax: data.invoiceMax,
          defaultMin: data.defaultMin,
          defaultMax: data.defaultMax,
          maturityMin: data.maturityMin,
          maturityMax: data.maturityMax
        });
      });
  }

  sendData() {
    var id = this.loggedUserId;
    var _invc: boolean;
    var _deflt: boolean;
    var _matrt: boolean;

    /************************* FORMATTAZIONE *************************/
    if (!isNaN(this.preferenceForm.value.invoiceMin)) {
      //fattura min
      this.invoiceMin = parseFloat(this.preferenceForm.value.invoiceMin.toFixed(4));
    } else {
      this.invoiceMin = 0;
    }

    if (!isNaN(this.preferenceForm.value.invoiceMax)) {
      //fattura max
      this.invoiceMax = parseFloat(this.preferenceForm.value.invoiceMax.toFixed(4));
    } else {
      this.invoiceMax = 99000000;
    }

    if (!isNaN(this.preferenceForm.value.defaultMin)) {
      // default minimo
      this.defaultMin = parseFloat(this.preferenceForm.value.defaultMin.toFixed(4));
    } else {
      this.defaultMin = 0;
    }

    if (!isNaN(this.preferenceForm.value.defaultMax)) {
      // default massimo
      this.defaultMax = parseFloat(this.preferenceForm.value.defaultMax.toFixed(4));
    } else {
      this.defaultMax = 99000000;
    }

    if (!isNaN(this.preferenceForm.value.maturityMin)) {
      //scadenza min
      this.maturityMin = parseFloat(this.preferenceForm.value.maturityMin.toFixed(4));
    } else {
      this.maturityMin = 0;
    }

    if (!isNaN(this.preferenceForm.value.maturityMax)) {
      // scadenza max
      this.maturityMax = parseFloat(this.preferenceForm.value.maturityMax.toFixed(4));
    } else {
      this.maturityMax = 99000000;
    }
    /*****************************************************************/

    /*********** CHECK SE VAR NUMERICHE E CHECK SE MIN < MAX ***********/
    if (!isNaN(this.invoiceMax) && !isNaN(this.invoiceMin)) {
      if (this.invoiceMax - this.invoiceMin > -1) {
        _invc = true;
      } else {
        _invc = false;
      }
    } else {
      _invc = true;
    }

    if (!isNaN(this.defaultMax) && !isNaN(this.defaultMin)) {
      if (this.defaultMax - this.defaultMin > -1) {
        _deflt = true;
      } else {
        _deflt = false;
      }
    } else {
      _deflt = true;
    }

    if (!isNaN(this.maturityMax) && !isNaN(this.maturityMin)) {
      if (this.maturityMax - this.maturityMin > -1) {
        _matrt = true;
      } else {
        _matrt = false;
      }
    } else {
      _matrt = true;
    }
    /*****************************************************************/

    /******** INVIO DATI AD API ********/
    if (_invc && _deflt && _matrt) {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      this.http
        .post<any>(
          `${environment.laravelServer}/investorpreferences/setPreferences`,
          '{"id":"' +
            this.loggedUserId +
            '", "invoiceMin":' +
            this.invoiceMin +
            ', "invoiceMax":' +
            this.invoiceMax +
            ', "defaultMin":' +
            this.defaultMin +
            ', "defaultMax":' +
            this.defaultMax +
            ', "maturityMin":' +
            this.maturityMin +
            ', "maturityMax":' +
            this.maturityMax +
            '}',
          httpOptions
        )
        .subscribe(data => {
          if (data) {
            alert('Le preferenze sono state aggiornate correttamente.');
          } else {
            alert("Errore durante l'aggiornamento delle preferenze.");
          }
        });
    } else {
      alert('Controllare i dati inseriti');
    }
  }
}
