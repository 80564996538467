import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirebaseTranchedService } from '../firebase-tranched.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  ignoreListRole: string[] = ['profile', 'profiloPersonale'];

  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private auth: AngularFireAuth,
    private firebase: FirebaseTranchedService,
    private backendAuthService: BackendAuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.authState.pipe(
      map(user => {
        if (this.firebase.loggedUser && user) {
          console.log('title=>', route.children[0].data.title);
          console.log('ROLE=>', this.firebase.loggedUser.roles[0]);
          console.log('INGORE=>', this.isInIgnoreList(route.children[0].data.title));
          console.log('cond=>', this.firebase.loggedUser.roles[0] === route.children[0].data.role);
          if (
            (this.isInIgnoreList(route.children[0].data.title) ||
              this.firebase.loggedUser.roles[0] === route.children[0].data.role) &&
            this.firebase.loggedUser.verified
          ) {
            console.warn('*********OK*******');
            return true;
          }
        }

        log.debug('1 - Not authenticated, redirecting and adding redirect url...');

        if (!user) {
          this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        } else {
          this.goHomepage(state);
        }
        return false;
      })
    );
  }

  isInIgnoreList(title: string) {
    return this.ignoreListRole.includes(title);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.authState.pipe(
      map(user => {
        if (user && this.firebase.loggedUser.roles[0] === route.data.role) {
          return true;
        }

        log.debug('2 - Not authenticated, redirecting and adding redirect url...');
        this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        return false;
      })
    );
  }

  goHomepage(state: RouterStateSnapshot) {
    try {
      if (this.firebase.isAdmin()) {
        this.router.navigate(['/home/list-wallet'], { queryParams: { redirect: state.url }, replaceUrl: true });
      } else if (this.firebase.isOriginator()) {
        this.router.navigate(['/originator/list-wallet'], { queryParams: { redirect: state.url }, replaceUrl: true });
      } else if (this.firebase.isCompany()) {
        this.router.navigate(['/cedente/dashboard'], { queryParams: { redirect: state.url }, replaceUrl: true });
      } else if (this.firebase.isPartner()) {
        this.router.navigate(['/partner/dashboard'], { queryParams: { redirect: state.url }, replaceUrl: true });
      } else if (this.firebase.isInvestor()) {
        this.router.navigate(['/investor/dashboard'], {
          queryParams: { redirect: state.url },
          replaceUrl: true
        });
      } else if (this.firebase.isServicerSPV()) {
        this.router.navigate(['/servicerSPV/pending-requests'], {
          queryParams: { redirect: state.url },
          replaceUrl: true
        });
      } else {
        console.log('NO ACCESS');
        this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
      }
    } catch {
      console.warn('EXCEPTION ACCESS');
      this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
    }
  }
}
