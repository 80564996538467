import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FaqRoutingModule } from './faq-routing.module';
import { FaqComponent } from './faq.component';
import { ShellModule } from '@app/shell/shell.module';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, NgbModule, FaqRoutingModule, ShellModule],
  declarations: [FaqComponent]
})
export class FaqModule {}
