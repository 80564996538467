import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { environment } from '@env/environment.prod';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetDataService {
  private loggedID: string;
  public numeroOperazioni: any;
  public progressivoInvestimento: number[] = [];
  public rendimento: number[] = [];
  public rendimentoPercent: number[] = [];
  public percentPartecMedia: number[] = [];
  public numeroCedenti: number[] = [];

  constructor(public firebase: FirebaseTranchedService, private http: HttpClient) {
    this.loggedID = this.firebase.loggedUser.id;

    this.getNumeroOperazioni();
    this.getProgressivoInvestimento();
    this.getRendimento();
    this.getRendimentoPercent();
    this.getPercentPartecMedia();
    this.getNumeroCedenti();
  }

  getNumeroOperazioni() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getMonthlyOperation`, '{"id":"' + this.loggedID + '"}', httpOptions)
      .subscribe((resp: any) => {
        let tmp = [];
        for (let i in resp.data) {
          tmp.push(resp.data[i]);
        }
        this.numeroOperazioni = tmp;
        //console.log(this.numeroOperazioni);
      });
  }

  getProgressivoInvestimento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getMonthlyInvProg`, '{"id":"' + this.loggedID + '"}', httpOptions)
      .subscribe(resp => {
        let tmp = [];
        for (let i in resp.data) {
          tmp.push(resp.data[i]);
        }
        this.progressivoInvestimento = tmp;
      });
  }

  getRendimento() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getMonthlyRendimento`, '{"id":"' + this.loggedID + '"}', httpOptions)
      .subscribe(resp => {
        let tmp = [];
        for (let i in resp.data) {
          tmp.push(resp.data[i]);
        }
        this.rendimento = tmp;
      });
  }

  getRendimentoPercent() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getMonthlyPercRend`, '{"id":"' + this.loggedID + '"}', httpOptions)
      .subscribe(resp => {
        let tmp = [];
        for (let i in resp.data.percentuale) {
          tmp.push(resp.data.percentuale[i]);
        }
        this.rendimentoPercent = tmp;
      });
  }

  getPercentPartecMedia() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getMonthlyPartMedia`, '{"id":"' + this.loggedID + '"}', httpOptions)
      .subscribe(resp => {
        let tmp = [];
        for (let i in resp.data) {
          tmp.push(resp.data[i]);
        }
        this.percentPartecMedia = tmp;
      });
  }

  getNumeroCedenti() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getMonthlyCedenti`, '{"id":"' + this.loggedID + '"}', httpOptions)
      .subscribe(resp => {
        let tmp = [];
        for (let i in resp.data) {
          tmp.push(resp.data[i]);
        }
        this.numeroCedenti = tmp;
      });
  }
}
