import { Utilities } from '@app/utilities/Utilities';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { Invoice } from '@app/models/Invoice.model';
import * as _ from 'lodash';
import { Wallet } from '@app/models/Wallet.model';
import { count } from 'console';

@Component({
  selector: 'app-chart-modal',
  templateUrl: './chart-modal.component.html',
  styleUrls: ['./chart-modal.component.scss']
})
export class ChartModalComponent implements OnInit {
  @Input() allInvoices: Invoice[];
  @Input() paymentDays: number;
  @Input() wallet: Wallet;
  public invoices: Invoice[];
  public selectedChart = '';
  public chartsType = new Map<string, number[]>();
  public graphTypeLabel: string[] = [
    'Anticipazioni mensili',
    'Anticipazioni progressive',
    'Sconto mensile',
    'Sconto progressivo',
    'Maturity mese',
    'Maturity media',
    'Sconto medio',
    'Rendimento mensile',
    'Rendimento medio',
    'Numero di fatture anticipate',
    'Valore medio fattura'
  ];
  public anticipoMensile: number[] = [];
  public anticipazioniProg: number[] = [];
  public scontoMensile: number[] = [];
  public scontoProg: number[] = [];
  public maturityMedia: number[] = [];
  public scontoMedio: number[] = [];
  public rendimentoMensile: number[] = [];
  public rendimentoMedio: number[] = [];
  public numeroFattureAnti: number[] = [];
  public valoreMedioFattura: number[] = [];

  public lineChartData: ChartDataSets[] = [{ data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' }];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions & { annotation: any } = {
    responsive: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [{}],
      yAxes: [{}]
    },
    annotation: {
      annotations: [{}]
    }
  };
  public lineChartColors: Color[] = [
    {
      // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins: any[] = [];

  public invoiceStatus: string[];
  public numeroMesiRiferimento = 6;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.invoiceStatus = Utilities.invoiceStatus;
    this.invoices = { ...this.allInvoices };
    this.filterRifiutate();
    this.setMonths();
    // anticipo mensile
    this.chartsType.set(this.graphTypeLabel[0], this.anticipoMensile);
    this.chartsType.set(this.graphTypeLabel[1], this.anticipazioniProg);
    this.chartsType.set(this.graphTypeLabel[2], this.scontoMensile);
    this.chartsType.set(this.graphTypeLabel[3], this.scontoProg);
    // this.chartsType.set(this.graphTypeLabel[4], this.maturityMese);
    this.chartsType.set(this.graphTypeLabel[5], this.maturityMedia);
    this.chartsType.set(this.graphTypeLabel[6], this.scontoMedio);
    this.chartsType.set(this.graphTypeLabel[7], this.rendimentoMensile);
    this.chartsType.set(this.graphTypeLabel[8], this.rendimentoMedio);
    this.chartsType.set(this.graphTypeLabel[9], this.numeroFattureAnti);
    this.chartsType.set(this.graphTypeLabel[10], this.valoreMedioFattura);

    this.setChart(this.graphTypeLabel[0]);
  }

  setMonths() {
    let pipe = new DatePipe('en-US');
    let today = new Date();
    let openingDate = new Date(this.wallet.openingDate);
    let itarazioni = today.getFullYear() - openingDate.getFullYear();
    let ite = itarazioni * 12;
    if (ite == 0) {
      ite = 12;
    }

    var totanticipoMensile = 0;
    var totscontoMensile = 0;
    var totmaturityMedia = 0;
    var totnumeroFattureAnti = 0;
    var totrendimentoMensile = 0;

    for (let i = 0; i < ite; i++) {
      // ciclo mensile
      let today2 = new Date();
      let month_new = today2.getMonth() + 1;
      let year_new = today2.getFullYear();
      let today = new Date(year_new + '-' + month_new + '-01');
      let openingDate = new Date(this.wallet.openingDate);

      today.setMonth(today.getMonth() - i);
      // settiamo le label dei mesi
      this.lineChartLabels.push(pipe.transform(today, 'MMM-yy'));

      // anticipazioni mensili
      let anticipazioneMensile = 0;
      for (let inv of this.invoices) {
        if (!inv.dataCaricaBonifico) {
          inv.dataCaricaBonifico = inv.dataFattura;
        }
        let invodate = new Date(inv.dataCaricaBonifico);

        if (this.checkIfRightDate(invodate, today)) {
          anticipazioneMensile += inv.importoFattura;
        }
      }
      totanticipoMensile = totanticipoMensile + anticipazioneMensile;
      this.anticipoMensile.push(anticipazioneMensile);

      // sconti mensili
      let scontimensili = 0;
      for (let inv of this.invoices) {
        if (!inv.dataCaricaBonifico) {
          inv.dataCaricaBonifico = inv.dataFattura;
        }
        let invodate = new Date(inv.dataCaricaBonifico);

        if (this.checkIfRightDate(invodate, today)) {
          scontimensili += inv.scontoAccettato;
        }
      }
      totscontoMensile = totscontoMensile + scontimensili;
      this.scontoMensile.push(scontimensili);

      // maturity media, fatture mensili
      let maturityMese = 0;
      let numFatture = 0;
      for (let inv of this.invoices) {
        if (!inv.dataCaricaBonifico) {
          inv.dataCaricaBonifico = inv.dataFattura;
        }
        let invodate = new Date(inv.dataCaricaBonifico);
        if (this.checkIfRightDate(invodate, today)) {
          numFatture++;
          let firstDate = new Date(inv.dataFattura);
          let secondDate = new Date(inv.dataScadenza);
          let diff = Math.abs(firstDate.getTime() - secondDate.getTime());
          let maturity = Math.ceil(diff / (1000 * 3600 * 24));
          maturityMese += maturity;
        }
      }
      if (numFatture != 0) {
        totmaturityMedia = totmaturityMedia + maturityMese / numFatture;
        this.maturityMedia.push(maturityMese / numFatture);
      } else {
        this.maturityMedia.push(0);
      }
      totnumeroFattureAnti = totnumeroFattureAnti + numFatture;
      this.numeroFattureAnti.push(numFatture);

      // rendimento mensile
      // sconti mensili
      let rendimentoMensile = 0;
      for (let inv of this.invoices) {
        if (!inv.dataCaricaBonifico) {
          inv.dataCaricaBonifico = inv.dataFattura;
        }
        let invodate = new Date(inv.dataCaricaBonifico);

        if (this.checkIfRightDate(invodate, today)) {
          // calcoli
          let firstDate = new Date(inv.dataFattura);
          let secondDate = new Date(inv.dataScadenza);
          let diff = Math.abs(firstDate.getTime() - secondDate.getTime());
          let maturity = Math.ceil(diff / (1000 * 3600 * 24));
          let rendimentoEff =
            ((inv.importoFattura * inv.scontoAccettato) / 100 / inv.importoFattura / (maturity - this.paymentDays)) *
            365 *
            100;
          rendimentoMensile += rendimentoEff * inv.importoFattura;
        }
      }
      if (rendimentoMensile == 0) {
        this.rendimentoMensile.push(0);
      } else {
        totrendimentoMensile = totrendimentoMensile + rendimentoMensile / anticipazioneMensile;
        this.rendimentoMensile.push(rendimentoMensile / anticipazioneMensile);
      }
    }

    //this.lineChartLabels.reverse();
    var arr = [];
    var count = 0;
    for (let inv of this.lineChartLabels) {
      if (count < this.numeroMesiRiferimento) {
        arr.push(inv);
        count = count + 1;
      }
    }
    this.lineChartLabels.reverse();
    arr.reverse();
    this.lineChartLabels = arr;
    arr = [];
    count = 0;
    for (let inv of this.anticipoMensile) {
      if (count < 6) {
        arr.push(inv);
        count = count + 1;
      }
    }
    this.anticipoMensile = arr;
    arr = [];
    count = 0;
    for (let inv of this.scontoMensile) {
      if (count < 6) {
        arr.push(inv);
        count = count + 1;
      }
    }
    this.scontoMensile = arr;
    arr = [];
    count = 0;
    for (let inv of this.maturityMedia) {
      if (count < 6) {
        arr.push(inv);
        count = count + 1;
      }
    }
    this.maturityMedia = arr;
    arr = [];
    count = 0;
    for (let inv of this.numeroFattureAnti) {
      if (count < 6) {
        arr.push(inv);
        count = count + 1;
      }
    }
    this.numeroFattureAnti = arr;
    arr = [];
    count = 0;
    for (let inv of this.rendimentoMensile) {
      if (count < 6) {
        arr.push(inv);
        count = count + 1;
      }
    }
    this.rendimentoMensile = arr;

    totscontoMensile = totscontoMensile / this.numeroMesiRiferimento;
    totrendimentoMensile = totrendimentoMensile / this.numeroMesiRiferimento;

    this.lineChartLabels.push('TOT');
    this.anticipoMensile.push(totanticipoMensile);
    this.scontoMensile.push(totscontoMensile);
    this.maturityMedia.push(totmaturityMedia);
    this.numeroFattureAnti.push(totnumeroFattureAnti);
    this.rendimentoMensile.push(totrendimentoMensile);

    // sconto medio
    for (let i = 0; i < this.numeroFattureAnti.length; i++) {
      if (this.numeroFattureAnti[i] === 0) this.scontoMedio.push(0);
      else this.scontoMedio.push(this.scontoMensile[i] / this.numeroFattureAnti[i]);
    }
    // valore fattura medio
    for (let i = 0; i < this.numeroFattureAnti.length; i++) {
      if (this.numeroFattureAnti[i] === 0) this.valoreMedioFattura.push(0);
      else this.valoreMedioFattura.push(this.anticipoMensile[i] / this.numeroFattureAnti[i]);
    }

    // rendimento medio
    for (let i = 0; i < this.numeroFattureAnti.length; i++) {
      if (this.numeroFattureAnti[i] === 0) this.rendimentoMedio.push(0);
      else {
        this.rendimentoMedio.push(this.rendimentoMensile[i] / this.numeroFattureAnti[i]);
      }
    }

    // anticipo progressivo
    /*
    let anticipoProg = 0;
    for (let anticipo of this.anticipoMensile) {
      anticipoProg = anticipoProg + anticipo;
      this.anticipazioniProg.push(anticipoProg);
    }
    */

    let anticipoProg = 0;
    for (let i = 0; i < this.anticipoMensile.length - 1; i++) {
      anticipoProg += this.anticipoMensile[i];
      this.anticipazioniProg.push(anticipoProg);
    }
    this.anticipazioniProg.push(anticipoProg);
    /** Fine modifica*/

    // anticipo progressivo
    /*
    let scontoProg = 0;
    for (let sconto of this.scontoMensile) {
      scontoProg = sconto;
      this.scontoProg.push(scontoProg);
    }
    */
    let scontoProg = 0;
    for (let i = 0; i < this.scontoMensile.length - 1; i++) {
      scontoProg += this.scontoMensile[i];
      let meseMedia = i + 1;
      scontoProg = scontoProg / meseMedia;
      this.scontoProg.push(scontoProg);
    }
    this.scontoProg.push(scontoProg);
    /** Fine modifica*/
  }

  getKeys() {
    return Array.from(this.chartsType.keys());
  }

  setChart(key: string) {
    this.selectedChart = key;
    this.lineChartData = [{ data: this.chartsType.get(key), label: key }];
  }

  filterRifiutate() {
    this.invoices = _.filter(this.invoices, item => item.status === this.invoiceStatus[9]);
  }

  checkIfRightDate(invodate: Date, today: Date) {
    return invodate.getMonth() === today.getMonth() && invodate.getFullYear() === today.getFullYear();
  }
}
