import { Injectable, PipeTransform } from '@angular/core';
import { SortDirection } from '@app/models/utils/sortable.directive';
import { Invoice } from '@app/models/Invoice.model';
import { BehaviorSubject, Subject, of, Observable } from 'rxjs';
import { DecimalPipe } from '@angular/common';
import { tap, debounceTime, switchMap, delay } from 'rxjs/operators';

interface SearchResult {
  requests: Invoice[];
  total: number;
}

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
}

function compare(v1: any, v2: any) {
  return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
}

function sort(requests: Invoice[], column: string, direction: string): Invoice[] {
  if (direction === '') {
    return requests;
  } else {
    return [...requests].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

function matches(request: Invoice, term: string, pipe: PipeTransform) {
  // return (
  //request.companyName.toLowerCase().includes(term.toLowerCase()) //||
  //pipe.transform(request.region).includes(term) ||
  //pipe.transform(request.import).includes(term)
  //  );
}

@Injectable({
  providedIn: 'root'
})
export class RequestSortService {
  private _loading$ = new BehaviorSubject<boolean>(true);
  private _search$ = new Subject<void>();
  private _requests$ = new BehaviorSubject<Invoice[]>([]);
  private _total$ = new BehaviorSubject<number>(0);

  private _state: State = {
    page: 1,
    pageSize: 4,
    searchTerm: '',
    sortColumn: '',
    sortDirection: ''
  };

  private requestsList: any[];

  constructor(private pipe: DecimalPipe) {}

  get countries$() {
    return this._requests$.asObservable();
  }
  get total$() {
    return this._total$.asObservable();
  }
  get loading$() {
    return this._loading$.asObservable();
  }
  get page() {
    return this._state.page;
  }
  get pageSize() {
    return this._state.pageSize;
  }
  get searchTerm() {
    return this._state.searchTerm;
  }

  set page(page: number) {
    this._set({ page });
  }
  set pageSize(pageSize: number) {
    this._set({ pageSize });
  }
  set searchTerm(searchTerm: string) {
    this._set({ searchTerm });
  }
  set sortColumn(sortColumn: string) {
    this._set({ sortColumn });
  }
  set sortDirection(sortDirection: SortDirection) {
    this._set({ sortDirection });
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<SearchResult> {
    const { sortColumn, sortDirection, pageSize, page, searchTerm } = this._state;

    // 1. sort
    let requests = sort(this.requestsList, sortColumn, sortDirection);

    // 2. filter
    requests = requests.filter(request => matches(request, searchTerm, this.pipe));
    const total = requests.length;

    // 3. paginate
    requests = requests.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);
    return of({ requests, total });
  }

  setRequests(requests: any[]) {
    this.requestsList = requests;
  }

  initialize() {
    this._search$
      .pipe(
        tap(() => this._loading$.next(true)),
        debounceTime(200),
        switchMap(() => this._search()),
        delay(200),
        tap(() => this._loading$.next(false))
      )
      .subscribe(result => {
        this._requests$.next(result.requests);
        this._total$.next(result.total);
      });

    this._search$.next();
  }
}
