import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import DataTables from 'node_modules/datatables.net';
import { DataTablesResponse } from '@app/models/DataTablesResponse.model';
import { environment } from '@env/environment.prod';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Invoice } from '@app/models/Invoice.model';
import { RequestDetailModalComponent } from '@app/home/wallet-details/request-detail-modal/request-detail-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Wallet } from '@app/models/Wallet.model';
import { Utilities } from '@app/utilities/Utilities';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  message = '';

  // @ts-ignore
  dtOptions: DataTables.Settings = {};

  private wallet: Wallet;
  private backupWallet: Wallet;

  private uuidInvestorSPV: string = '';

  private recordsTotal: any;
  private recordsFiltered: any;
  private data: [];

  private showInProgress = true;
  private showAttendPay = false;
  private showClosed = false;

  constructor(
    private router: Router,
    private http: HttpClient,
    private firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private backendAuthService: BackendAuthService
  ) {
    this.dtOptions = 0;
    this.wallet = new Wallet();

    this.uuidInvestorSPV = this.firebase.loggedUser.id;

    if (this.firebase.loggedUser.roles.indexOf('servicerSPV') !== -1) {
      console.log('Servicer*****');
      this.getDtOptionsOfServicer(this.firebase.loggedUser.id);
    } else {
      this.getDtOptions(this.uuidInvestorSPV);
    }
  }

  getDtOptionsOfServicer(uuid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      autoWidth: false,
      columnDefs: [
        { width: '20%', targets: 0 },
        { width: '10%', targets: 1 },
        { width: '10%', targets: 2 },
        { width: '10%', targets: 3 },
        { width: '10%', targets: 4 },
        { width: '5%', targets: 5 },
        { width: '20%', targets: 6 }
      ],
      width: '200%',
      serverSide: false, // Set the flag
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            `${environment.laravelServer}/invoices/getInvoicesByservicerSPVId`,
            '{"id":"' + uuid + '"}',
            httpOptions
          )
          .subscribe(resp => {
            const dataFiltered = this.applyFilter(resp);
            callback({
              recordsTotal: dataFiltered.recordsTotal,
              recordsFiltered: dataFiltered.recordsFiltered,
              data: dataFiltered.data
            });
          });
      },
      columns: [
        {
          title: 'Cedente',
          data: 'cedenteName'
        },
        {
          title: 'Num Fatt',
          data: 'numFattura'
        },
        {
          title: 'Data emiss',
          data: 'dataFattura'
        },
        {
          title: 'Data scad.',
          data: 'dataScadenza'
        },
        {
          title: 'Importo',
          data: 'importoFattura'
        },
        {
          title: 'Sconto',
          data: 'scontoAccettato'
        },
        {
          title: 'Stato',
          data: 'status'
        }
      ],
      // tslint:disable-next-line:ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // Note: In newer jQuery v3 versions, `unbind` and `bind` are
        // deprecated in favor of `off` and `on`
        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.someClickHandler(data);
        });
        return row;
      },
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'columnsToggle',
        'colvis',
        'copy',
        'print',
        'excel',
        {
          text: 'Some button',
          key: '1',
          action: function(e, dt, node, config) {
            alert('Button activated');
          }
        }
      ]
    };
  }

  applyFilter(resp: DataTablesResponse) {
    let count = 0;
    if (this.showInProgress) {
      const newArr = [];
      resp.data.forEach(dataN => {
        if (
          dataN.status_index !== 0 &&
          dataN.status_index !== 3 &&
          dataN.status_index !== 36 &&
          dataN.status_index !== 37 &&
          dataN.status_index !== 55 &&
          dataN.status_index !== 60
        ) {
          count++;
          newArr.push(dataN);
        }
      });
      resp.recordsTotal = count;
      resp.recordsFiltered = count;
      resp.data = newArr;
    }

    if (this.showAttendPay) {
      const newArr = [];
      resp.data.forEach(dataN => {
        if (dataN.status_index === 55 || dataN.status_index === 36) {
          count++;
          newArr.push(dataN);
        }
      });
      resp.recordsTotal = count;
      resp.recordsFiltered = count;
      resp.data = newArr;
    }

    if (this.showClosed) {
      const newArr = [];
      resp.data.forEach(dataN => {
        if (dataN.status_index === 60 || dataN.status_index === 37 || dataN.status_index === 17) {
          count++;
          newArr.push(dataN);
        }
      });
      resp.recordsTotal = count;
      resp.recordsFiltered = count;
      resp.data = newArr;
    }

    return resp;
  }

  getDtOptions(uuid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      autoWidth: false,
      columnDefs: [
        { width: '20%', targets: 0 },
        { width: '10%', targets: 1 },
        { width: '10%', targets: 2 },
        { width: '10%', targets: 3 },
        { width: '10%', targets: 4 },
        { width: '5%', targets: 5 },
        { width: '20%', targets: 6 }
      ],
      width: '200%',
      serverSide: false, // Set the flag
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            `${environment.laravelServer}/getInvoicesByInvestorSPVId`,
            '{"id":"' + uuid + '"}',
            httpOptions
          )
          .subscribe(resp => {
            const dataFiltered = this.applyFilter(resp);

            callback({
              recordsTotal: dataFiltered.recordsTotal,
              recordsFiltered: dataFiltered.recordsFiltered,
              data: dataFiltered.data
            });
          });
      },
      columns: [
        {
          title: 'Cedente',
          data: 'cedenteName'
        },
        {
          title: 'Num Fatt',
          data: 'numFattura'
        },
        {
          title: 'Data emiss',
          data: 'dataFattura'
        },
        {
          title: 'Data scad.',
          data: 'dataScadenza'
        },
        {
          title: 'Importo',
          data: 'importoFattura'
        },
        {
          title: 'Sconto',
          data: 'scontoAccettato'
        },
        {
          title: 'Stato',
          data: 'status'
        }
      ],
      // tslint:disable-next-line:ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // Note: In newer jQuery v3 versions, `unbind` and `bind` are
        // deprecated in favor of `off` and `on`
        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.someClickHandler(data);
        });
        return row;
      },
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'columnsToggle',
        'colvis',
        'copy',
        'print',
        'excel',
        {
          text: 'Some button',
          key: '1',
          action: function(e, dt, node, config) {
            alert('Button activated');
          }
        }
      ]
    };
  }

  someClickHandler(info: any): void {
    this.message = info.uuid + ' - ' + info.cedenteName;
    console.log(this.message);

    // devo recuperare i dettagli della fattura dal suo id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/getInvoiceById`, '{"id":"' + info.uuid + '"}', httpOptions)
      .subscribe(respInv => {
        const idWallet = respInv.walletId;

        this.firebase
          .getWalletfromID(idWallet)
          .then(doc => {
            if (doc.exists) {
              this.wallet = doc.data() as Wallet;
              this.backupWallet = { ...this.wallet };
              this.wallet.id = idWallet;
              Utilities.getDaysLeft(this.wallet);

              this.showRequestDetail(respInv);
            } else {
            }
          })
          .catch(error => {});
      });
  }

  showRequestDetail(request: Invoice) {
    const modalRef = this.modalService.open(RequestDetailModalComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.invoice = request;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.result.then(
      () => {
        // Save
      },
      () => {
        // Dismiss
      }
    );
  }

  ngOnInit() {}

  changeList(value: string) {
    switch (value) {
      case 'InProgress': {
        this.showInProgress = true;
        this.showAttendPay = false;
        this.showClosed = false;

        break;
      }
      case 'AttendPay': {
        this.showInProgress = false;
        this.showAttendPay = true;
        this.showClosed = false;

        break;
      }
      case 'Closed': {
        this.showInProgress = false;
        this.showAttendPay = false;
        this.showClosed = true;

        break;
      }
    }
  }
}
