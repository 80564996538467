export class DocumentKycSPVModel {
  static tableName = 'DocumentKycSPV';
  id: string;
  tipoDocumento: string;
  nome_file: string | any;
  descrizione: string;
  dataCaricamento: any;
  user_id: string;
  url: string;
  statusSPV: string;
  modified: boolean; // campo ausiliario da cancellare quando si va su firebase

  static cleanForFirebase(document: DocumentKycSPVModel) {
    delete document.modified;
  }
  constructor() {}
}
