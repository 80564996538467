import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rating-modal',
  templateUrl: './rating-modal.component.html',
  styleUrls: ['./rating-modal.component.scss']
})
export class RatingModalComponent implements OnInit {
  @Input() ratingSystemLabel: string[];
  @Input() ratingSystemPercentage: string[];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
