import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './landing.component';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  { path: 'landing/:id1/:id2', component: LandingComponent },
  { path: 'landing/:id1', component: LandingComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LandingRoutingModule {}
