export class Log {
  static tableName: string = 'Logs';

  description: string;
  date: string;
  userId: string;
  type: string;
  ip: string;
  timestamp: firebase.firestore.FieldValue;

  constructor() {}
}
