import { Utilities } from '@app/utilities/Utilities';
import { User } from './User.model';
export class InvestorSPV extends User {
  lastName: string;
  dataAssunzione: string;
  dataTermineContratto: string;
  firstName: string;
  ragioneSociale: string;
  servicersSPV: any;
  uuid: string;
  pec: string;
  city: string;
  province: string;
  cap: string;
  piva: string;
  rea: string;
  iban: string;

  constructor() {
    super();
  }
}
