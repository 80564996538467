import { environment } from '@env/environment.prod';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { MailText } from '@app/utilities/MailText';
import { ProductNode, Wallet, WalletFromDb } from '@app/models/Wallet.model';
import { any } from 'codelyzer/util/function';

export class Utilities {
  static regions: string[] = [
    "Valle D'Aosta",
    'Piemonte',
    'Liguria',
    'Lombardia',
    'Veneto',
    'Trentino-Alto Adige',
    'Friuli-Venezia Giulia',
    'Emilia-Romagna',
    'Toscana',
    'Umbria',
    'Marche',
    'Abruzzo',
    'Molise',
    'Lazio',
    'Campania',
    'Basilicata',
    'Puglia',
    'Calabria',
    'Sicilia',
    'Sardegna'
  ];

  static statoLemon: string[] = ['DAVALIDARE', 'VALIDAZIONE', 'VALIDATO', 'DACARICARE'];
  static statusSPV: string[] = ['PENDING', 'ACTIVE', 'DISACTIVE', 'ERROR'];

  static statoDocumento: string[] = [
    'Document put on hold, waiting for another document',
    'Received, need manual validation',
    'Accepted',
    'Rejected',
    'Rejected. Unreadable by human (Cropped, blur, glare…)',
    'Rejected. Expired (Expiration Date is passed)',
    'Rejected. Wrong Type (Document not accepted)',
    'Rejected. Wrong Name (Name not matching user information)',
    'Rejected. Duplicated Document'
  ];
  static tipoDocumento: string[] = [
    'ID card (both sides in one file)',
    'Proof of address',
    'Scan of a proof of IBAN',
    'Passport (European Union)',
    'Passport (outside the European Union)',
    'Residence permit (both sides in one file)', // 5
    '',
    'Official company registration document (Kbis extract or equivalent)',
    '',
    '',
    '',
    'Driver licence (both sides in one file)', // 11
    'Status',
    'Selfie',
    'Other document type', // 14
    'Other document type',
    'Other document type',
    'Other document type',
    'Other document type',
    'Other document type',
    'Other document type',
    'SDD mandate'
  ];

  static invoiceStatus: string[] = [
    'Rifiutata',
    'In attesa di controproposta',
    'Da confermare',
    'Caricamento nota di credito',
    'Da verificare formalmente NC',
    'Da verificare ufficialmente NC',
    'Pagamento in corso', // 6
    'Da verificare formalmente BF', // 7
    'Da verificare ufficialmente BF', // 8
    'Terminata', // 9
    'Istruttoria in corso', // 10
    'Da verifica', // 11
    'Acquistata',
    'Azure - In attesa contratto del cedente',
    'Proposta rifiutata'
  ];

  // @hydra 2022-01-14 -- @author - Gaetano Bitonte - https://hydrasolutions.atlassian.net/browse/FXINPROD-87
  static invoiceStatus_Label_Stepper: string[] = [
    'Caricata', // 0
    'In attesa di approvazione', // 1
    'Approvata', // 2
    'In attesa di accettazione proposta', // 3
    'Proposta accettata', // 4
    'In attesa di documenti', // 5
    'Documenti validati', // 6
    'In attesa di pagamento', // 7
    'Pagata', // 8
    'Terminata', // 9
    'Proposta rifiutata'
  ];
  static invoiceStatus_it_IT: string[] = [
    'In attesa di prima verifica', // 0 //notifica
    'Approvata da FX12 - in attesa di valutazione controproposta',
    'Inviata controproposta - in attesa del Cedente',
    'Proposta rifiutata',
    'Fattura rigettata', // 4
    'Proposta accettata -  Istruttoria in corso', // 5  //notifica
    '-',
    '-',
    '-',
    '-',
    'Yellow - In attesa di caricamento nota di credito', // 10
    'Yellow - In attesa di verifica formale (admin) nota di credito', // 11 notifica
    'Yellow - In attesa di verifica ufficiale (leader) nota di credito',
    'Yellow - In attesa caricamento contabile bonifico',
    'Yellow - In attesa di verifica formale (admin) bonifico', // 14 //notifica
    'Yellow - In attesa di verifica ufficiale (cedente) bonifico',
    'Yellow - Bonifico accettato',
    'Yellow - Completata - Trattativa conclusa',
    '-',
    '-',
    '-', // 20
    '-',
    '-',
    '-',
    '-',
    '-',
    '-', // 26
    '-',
    '-',
    '-',
    'Blu - In attesa di caricamento contratto del cedente', // 30
    'Blu - In attesa di verifica contratto (admin)', // 31 //notifica
    'Blu - Contratto approvato - In attesa di selezione investitore',
    'Blu - Investitore associato - In attesa di approvazione transazione', // 33
    'Blu - Transazione approvata',
    'Blu - In attesa di fondi', // 35
    'Blu - Trasferimento fondi confermato',
    'Blu - Trattativa Completata', // 37
    '',
    '',
    '', // 40
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Azure - In attesa di associazione InvestorSPV e ServicerSPV', // 50
    'Azure - Richiesta erogazione fondi BankSPV', // 51 //////
    'Azure - In attesa contratto investitore', // 52
    'Azure - In attesa contratto del cedente', // 53
    'Azure - In attesa di verifica contratto', // 54
    'Azure - In attesa di contabile bonifico', // 55
    'Azure - In attesa di conferma invio PEC Debitore da Admin', // 56
    'Azure - In attesa di conferma invio PEC da Cedente a Debitore', // 57
    'Azure - In attesa di conferma ricezione PEC InvestorSPV', // 58
    'Azure - In attesa di conclusione', // 59
    'Azure - Trattativa conclusa', // 60
    'Azure - 0', // 61
    'Azure - 0', // 62
    'Azure - 0', // 63
    'Azure - 0', // 64
    'Azure - 0', // 65
    'Azure - 0', // 66
    'Azure - 0', // 67
    'Azure - 0', // 68
    'Azure - 0', // 69
    'Azure Con Cartolarizzazione- 0', // 70
    'Azure Con Cartolarizzazione- 0', // 71
    'Azure Con Cartolarizzazione- 0', // 72
    'Azure Con Cartolarizzazione- 0', // 73
    'Azure Con Cartolarizzazione- 0', // 74
    'Azure Con Cartolarizzazione- 0', // 75
    'Azure Con Cartolarizzazione- 0', // 76
    'Azure Con Cartolarizzazione- 0', // 77
    'Azure Con Cartolarizzazione- 0', // 78
    'Azure Con Cartolarizzazione- 0', // 79
    'Azure Con Cartolarizzazione- 0' // 80
  ];

  static productNames: string[] = ['Yellow Finance', 'Blue Finance', 'Azure Finance'];

  static userType: string[] = [
    'god',
    'admin',
    'originator',
    'company',
    'partner',
    'investor',
    'servicer',
    'servicerSPV',
    'investorSPV',
    'bankSPV'
  ];

  static dateFormat = 'dd/MM/yyyy';

  static sendMailUrl = `${environment.laravelServer}/sendmail`;

  /* New endpoint to send email with template and data */
  static sendEmailTemplateUrl = `${environment.laravelServer}/SendEmailType`;

  static sendPecUrl = `${environment.laravelServer}/SendPec`;

  static sendSms = `${environment.laravelServer}/sendsms`;

  static urlgenerale = `${environment.laravelServer}`;

  static body = {
    to: 'mail',
    subject: 'subject',
    text: 'plainBody',
    html: 'htmlBody'
  };

  static multiBody = {
    to: ['mail1', 'mail2'],
    subject: 'subject',
    text: 'plainBody',
    html: 'htmlBody'
  };

  static multiBodyTemplate = {
    to: '',
    firstname: '',
    lastname: '',
    subject: 'subject',
    senderCompany: 'FX12 Srl',
    from: 'support@incassaora.it',
    senderName: 'Support of Incassaora',
    templateName: ''
  };

  /* *************** LemonWay Entity ********************* */
  static newLegalAccount = {
    name: '', // mandatory
    description: '', // mandatory
    websiteUrl: '',
    identificationNumber: '',
    isUltimateBeneficialOwner: '',
    accountId: '', // mandatory
    email: '',
    title: '',
    firstName: '',
    lastName: '',
    street: '',
    postCode: '',
    city: '',
    country: 'ITA', // mandatory - ISO 3166-1 alpha-3
    date: '', // mandatory -Client Date of Birth
    cityNascita: '',
    nationality: '',
    payerOrBeneficiary: null,
    phoneNumber: '',
    mobileNumber: '',
    isDebtor: null,
    isOneTimeCustomerAccount: null,
    isTechnicalAccount: null,
    reacode: '',
    cityNa: ''
  };

  /* **************************************************** */

  static getDaysLeft(wallet: any) {
    if (wallet.endingDate) {
      const start = new Date();
      const end = new Date(wallet.endingDate);

      if (start < end) {
        const diff = Math.abs(start.getTime() - end.getTime());
        wallet.daysLeft = Math.ceil(diff / (1000 * 3600 * 24));
      } else if (start > end) {
        const startDay = start.getDay();
        const startMonth = start.getMonth();
        const startYear = start.getFullYear();

        const endDay = end.getDay();
        const endMonth = end.getMonth();
        const endYear = end.getFullYear();

        if (startDay == endDay && startMonth == endMonth && startYear == endYear) {
          wallet.daysLeft = 0;
        } else {
          wallet.daysLeft = -1;
        }
      }
    } else {
      wallet.daysLeft = -2;
    }
    return wallet;
  }

  static downloadAsPDF(DATA: HTMLElement | null, fileName: string, testo: string) {
    /*
    html2canvas(DATA).then(canvas => {
      const fileWidth = 208;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;

      const FILEURI = canvas.toDataURL('image/png');
      const PDF = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);

      PDF.save(fileName + '.pdf');
    });
  */

    html2canvas(DATA).then(canvas => {
      const FILEURI = canvas.toDataURL('image/jpeg', 0.3);
      // const PDF = new jsPDF('p', 'mm', 'a4', true);
      const PDF = new jsPDF();
      // const position = 0;
      // const fileWidth = 210;
      // const fileHeight = (canvas.height * fileWidth) / canvas.width;
      // PDF.text(testo, 10, 10);
      // PDF.addImage(FILEURI, 'JPEG', 5, position, fileWidth, fileHeight, undefined, 'FAST');

      const bodyContent = PDF.splitTextToSize(testo, 250);
      const pageHeight = PDF.internal.pageSize.getHeight();
      // PDF.setFontType("normal");
      PDF.setFontSize(12);

      let y = 15;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < bodyContent.length; i++) {
        if (y + 10 > pageHeight) {
          y = 15;
          PDF.addPage();
        }
        PDF.text(bodyContent[i], 10, y);
        y = y + 7;
      }

      PDF.save(fileName + '.pdf');
    });
  }

  static differenceBetweenDates(startDate: any, endDate: any): boolean {
    const today = new Date();
    return startDate.toDate() <= today && endDate.toDate() >= today;
  }

  static isItalianVatNumber(vatNumber: string) {
    const regex = /^(IT)?[0-9]{11}$/g;
    return vatNumber.match(regex) !== null;
  }

  static formatArray(arrayObj: any[]): string[] {
    const res: string[] = [];
    for (const item of arrayObj) {
      res.push(item.email);
    }
    return res;
  }

  static subtractArray(array1: string[], array2: any[]): string[] {
    const res: string[] = [];
    for (const item of array2) {
      if (!array1.includes(item)) {
        res.push(item);
      }
    }
    return res;
  }

  static getMailText(typology: string, walletName: string | null): string {
    switch (typology) {
      case 'getIpotesiFattura': {
        return MailText.getIpotesiFattura(walletName);
      }
      case 'getPropostaRifiutata': {
        return MailText.getPropostaRifiutata(walletName);
      }
      case 'getNotaCreditoCaricataFormalmente': {
        return MailText.getNotaCreditoCaricataFormalmente();
      }
      case 'getPropostaAccettata': {
        return MailText.getPropostaAccettata();
      }
      case 'getNotaCreditoCaricataScartataFormalmente': {
        return MailText.getNotaCreditoCaricataScartataFormalmente();
      }
      case 'getNotaCreditoCaricataScartataUfficialmente': {
        return MailText.getNotaCreditoCaricataScartataUfficialmente();
      }
      case 'getBonificoCaricatoFormalmente': {
        return MailText.getBonificoCaricatoFormalmente();
      }
      case 'getBonificoCaricatoUfficialmente': {
        return MailText.getBonificoCaricatoUfficialmente();
      }
      case 'getBonificoCaricatoScartatoFormalmente': {
        return MailText.getBonificoCaricatoScartatoFormalmente();
      }
      case 'getBonificoCaricatoScartatoUfficialmente': {
        return MailText.getBonificoCaricatoScartatoUfficialmente();
      }
      case 'getPropostaInvestitore': {
        return MailText.getPropostaInvestitore();
      }
    }
  }

  static createProductsNode(wallet: Wallet): ProductNode[] {
    const productsNode: ProductNode[] = [];

    const productNode1 = new ProductNode();
    // position 0 is Yellow
    productNode1.enabled = wallet.enabledYellow;
    productNode1.name = this.productNames[0];
    productNode1.priority = wallet.priorityYellow;
    productNode1.plafond = wallet.plafondYellow;
    productNode1.counter = 0;
    productsNode.push(productNode1);

    const productNode2 = new ProductNode();
    // position 1 is Blue
    productNode2.enabled = wallet.enabledBlue;
    productNode2.name = this.productNames[1];
    productNode2.priority = wallet.priorityBlue;
    productNode2.plafond = wallet.plafondBlue;
    productNode2.counter = 0;
    productsNode.push(productNode2);

    const productNode3 = new ProductNode();
    // position 2 is Azure
    productNode3.enabled = wallet.enabledAzure;
    productNode3.name = this.productNames[2];
    productNode3.priority = wallet.priorityAzure;
    productNode3.plafond = wallet.plafondAzure;
    productNode3.counter = 0;
    productsNode.push(productNode3);

    return productsNode;
  }
}
