import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InvestorSPV } from '@app/models/InvestorSPV.model';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalGenericComponent } from '@app/confirmation-modal-generic/confirmation-modal-generic.component';
import { InvestorSpvService } from '../investor-spv.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './investor-spv-edit.component.html',
  styleUrls: ['./investor-spv-edit.component.scss']
})
export class InvestorSpvEditComponent implements OnInit {
  entityCreationForm!: FormGroup;
  get email() {
    return this.entityCreationForm.get('email');
  }
  get pec() {
    return this.entityCreationForm.get('pec');
  }

  get firstName() {
    return this.entityCreationForm.get('firstName');
  }

  get lastName() {
    return this.entityCreationForm.get('lastName');
  }

  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;
  entity: InvestorSPV;
  id: string;
  private sub: any;

  selectedServicesSPV = [];

  /* dropdown multi select */
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  /* ********************* */
  maxToday: any;

  private showSelect: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private myService: InvestorSpvService,
    private backendAuthService: BackendAuthService
  ) {
    this.maxToday = new Date();
    console.log('Data return=>', this.myService.data);
    this.dropdownList = this.myService.data;

    this.createForm();
  }

  createForm() {
    this.entityCreationForm = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      ragioneSociale: new FormControl('', [Validators.required]),
      dataContrFinanzmnt: new FormControl('', [Validators.required]),
      pec: new FormControl(''),
      iban: new FormControl(''),
      telefono: new FormControl(''),
      indirizzo: new FormControl(''),
      cap: new FormControl(''),
      city: new FormControl(''),
      province: new FormControl(''),
      piva: new FormControl(''),
      rea: new FormControl(''),
      dataAssunzione: new FormControl(''),
      dataTermineContratto: new FormControl(''),
      servicerSPVList: new FormControl('')
    });
  }

  ngOnInit() {
    this.showSelect = false;
    this.entity = new InvestorSPV();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.sub = this.route.params.subscribe(params => {
      this.id = params.id; // (+) converts string 'id' to a number
      console.log(params.id);
      this.http
        .post<InvestorSPV>(`${environment.laravelServer}/getUserById`, '{"id":"' + params.id + '"}', httpOptions)
        .subscribe(data => {
          console.log('DATA=>', data);

          if (data.ragioneSociale) {
            this.entity.ragioneSociale = data.ragioneSociale;
          } else {
            this.entity.ragioneSociale = '';
          }
          if (data.dataContrFinanzmnt) {
            this.entity.dataContrFinanzmnt = data.dataContrFinanzmnt;
          } else {
            this.entity.dataContrFinanzmnt = '';
          }
          if (data.lastName) {
            this.entity.lastName = data.lastName;
          } else {
            this.entity.lastName = '';
          }
          if (data.email) {
            this.entity.email = data.email;
          } else {
            this.entity.email = '';
          }
          if (data.pec) {
            this.entity.pec = data.pec;
          } else {
            this.entity.pec = '';
          }
          if (data.iban) {
            this.entity.iban = data.iban;
          } else {
            this.entity.iban = ' ';
          }
          if (data.telefono) {
            this.entity.telefono = data.telefono;
          } else {
            this.entity.telefono = '';
          }
          if (data.indirizzo) {
            this.entity.indirizzo = data.indirizzo;
          } else {
            this.entity.indirizzo = '';
          }
          if (data.cap) {
            this.entity.cap = data.cap;
          } else {
            this.entity.cap = '';
          }
          if (data.city) {
            this.entity.city = data.city;
          } else {
            this.entity.city = '';
          }
          if (data.province) {
            this.entity.province = data.province;
          } else {
            this.entity.province = '';
          }
          if (data.piva) {
            this.entity.piva = data.piva;
          } else {
            this.entity.piva = '';
          }
          if (data.rea) {
            this.entity.rea = data.rea;
          } else {
            this.entity.rea = '';
          }
          if (data.name) {
            this.entity.firstName = data.name;
          } else {
            this.entity.firstName = '';
          }
          this.entity.dataAssunzione = this.datePipe.transform(data.dataAssunzione, 'yyyy-MM-dd');
          this.entity.dataTermineContratto = this.datePipe.transform(data.dataTermineContratto, 'yyyy-MM-dd');
          console.log('entity=>', this.entity);

          this.selectedItems = [];

          this.dropdownList.forEach(item => {
            console.log('item=>', item);
            if (data.servicersSPV.indexOf(item.item_id) > -1) {
              console.log('PRENSENTE=>');
              this.selectedItems.push(item);
              this.selectedServicesSPV.push(item.item_id);
            } else {
              console.log('ASSENTE=>');
            }
          });

          console.log('showSelect=>', this.showSelect);
          console.log('dropdownList=>', this.dropdownList);
          console.log('selectedItems=>', this.selectedItems);
          this.showSelect = true;

          console.log('Selected=>', this.selectedItems);

          if (!this.entity.pec) {
            this.entity.pec = '';
          }

          this.entityCreationForm.patchValue({
            firstName: this.entity.firstName,
            ragioneSociale: this.entity.ragioneSociale,
            dataContrFinanzmnt: this.entity.dataContrFinanzmnt,
            lastName: this.entity.lastName,
            email: this.entity.email,
            pec: this.entity.pec,
            iban: this.entity.iban,
            telefono: this.entity.telefono,
            indirizzo: this.entity.indirizzo,
            cap: this.entity.cap,
            city: this.entity.city,
            province: this.entity.province,
            piva: this.entity.piva,
            rea: this.entity.rea,
            dataAssunzione: this.entity.dataAssunzione,
            dataTermineContratto: this.entity.dataTermineContratto,
            servicerSPVList: this.dropdownList
          });
          console.log('se1=>', this.entityCreationForm.value);
        });
    });

    this.firebaseService.createPageLog('User Visit Page - UPDATE Servicer SPV');

    console.log('ONINIT');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true,
      allowRemoteDataSearch: true
    };
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
    this.selectedServicesSPV.push(item.item_id);
    console.log('ServersADD=>', this.selectedServicesSPV);
  }

  onItemDeSelect(item: any) {
    const index = this.selectedServicesSPV.indexOf(item.item_id);
    if (index !== -1) {
      this.selectedServicesSPV.splice(index, 1);
    }
    console.log('ServersDELETE=>', this.selectedServicesSPV);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll', items);
    items.forEach(item => {
      this.selectedServicesSPV.push(item.item_id);
    });
    console.log('ServersADD=>', this.selectedServicesSPV);
  }

  onDeSelectAll(items: any) {
    this.selectedServicesSPV = [];
    console.log('ServersADD=>', this.selectedServicesSPV);
  }

  submit() {
    if (this.verifyMandatoryField()) {
      // console.log('se=>',this.servicerSPVCreationForm);
      const modalRef = this.modalService.open(ConfirmationModalGenericComponent, {
        size: 'md',
        centered: true,
        scrollable: true
      });
      modalRef.componentInstance.title = 'Attenzione';
      modalRef.componentInstance.text = 'Confermi di voler aggiornare i dati?';
      modalRef.componentInstance.loading.subscribe((data: boolean) => {
        console.log(data);
        if (data) {
          modalRef.close();

          const objectToSave = this.firebaseService.updateInvestorSPV(
            this.entityCreationForm,
            this.selectedServicesSPV
          );
          console.log(objectToSave);
          this.firebaseService.updateUserDocAfterCreate(this.id, objectToSave);

          this.router.navigate(['/home/investor-spv/' + this.id + '/detail']);
        }
      });
    } else {
      alert(
        'Attenzione - Non tutti i campi risultano compilati correttamente o mancati. Sarete reindizizzati alla pagina precendente.'
      );
      this.router.navigate(['/home/investor-spv/list']);
    }
  }

  verifyMandatoryField() {
    let response = true;

    if (this.entityCreationForm.value.email == '') {
      response = false;
    }
    if (this.entityCreationForm.value.pec == '') {
      response = false;
    }
    if (this.entityCreationForm.value.iban == '') {
      response = false;
    }
    if (this.entityCreationForm.value.firstName == '') {
      response = false;
    }
    if (this.entityCreationForm.value.lastName == '') {
      response = false;
    }
    if (this.entityCreationForm.value.ragioneSociale == '') {
      response = false;
    }
    if (this.entityCreationForm.value.telefono == '') {
      response = false;
    }
    if (this.entityCreationForm.value.indirizzo == '') {
      response = false;
    }

    return response;
  }
  goBack() {
    this.router.navigate(['/home/investor-spv/' + this.id + '/detail']);
  }
}
