import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { UsuryInfo } from '@app/models/UsuryRates.model';

@Component({
  selector: 'app-create-usury-rate',
  templateUrl: './create-usury-rate.component.html',
  styleUrls: ['./create-usury-rate.component.scss']
})
export class CreateUsuryRateComponent implements OnInit {
  CreationForm!: FormGroup;
  error: boolean;
  listaFascie: UsuryInfo[];
  usuryInfo = false;
  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router
  ) {
    this.createForm();

    this.error = false;
    this.listaFascie = [];
  }

  ngOnInit() {}

  submit() {
    if (!this.CreationForm.valid) {
      this.CreationForm.get('startDate').markAsTouched();
      this.CreationForm.get('endDate').markAsTouched();
    } else {
      let startData = new Date(this.CreationForm.value.startDate);
      let endData = new Date(this.CreationForm.value.endDate);

      if (startData > endData) {
        this.error = true;
      } else {
        this.error = false;
        var arr = [];
        if (this.listaFascie.length == 0) {
          this.error = true;
          return;
        }
        for (let entry of this.listaFascie) {
          var arg = entry.da + '---' + entry.a + '---' + entry.amount;
          arr.push(arg);
        }
        const objectToSave = this.firebaseService.createUsuryRate(this.CreationForm, arr);
        this.router.navigate(['/home/usury-rates'], { replaceUrl: true });
      }
    }
  }

  onChangeType() {
    this.createForm();
  }

  createForm() {
    this.CreationForm = this.formBuilder.group({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
      stato: new FormControl(''),
      amount: new FormControl(0, [Validators.required]),
      valA: new FormControl(0, [Validators.required]),
      valDa: new FormControl(0, [Validators.required])
    });
  }

  aggiungiFascia() {
    this.usuryInfo = false;

    var newU = new UsuryInfo();
    if (this.CreationForm.value.da > this.CreationForm.value.a) {
      this.usuryInfo = true;
      return;
    }
    if (this.CreationForm.value.amount == 0 || this.CreationForm.value.a == 0) {
      this.usuryInfo = true;
      return;
    }

    newU.a = this.CreationForm.value.valA;
    newU.amount = this.CreationForm.value.amount;
    newU.da = this.CreationForm.value.valDa;
    this.CreationForm.controls['valA'].setValue(0);
    this.CreationForm.controls['amount'].setValue(0);
    this.CreationForm.controls['valDa'].setValue(0);
    this.listaFascie.push(newU);
  }

  get endDate() {
    return this.CreationForm.get('startDate');
  }
  get startDate() {
    return this.CreationForm.get('endDate');
  }
  get amount() {
    return this.CreationForm.get('amount');
  }
}
