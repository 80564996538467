import { Observable, Subscription } from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Utilities } from '@app/utilities/Utilities';
import { FormGroup, Validators, FormBuilder, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { Wallet } from '@app/models/Wallet.model';
import { Originator } from '@app/models/Originator.model';
import { TigranService } from '@app/tigran/tigran.service';
import { finalize } from 'rxjs/operators';

// @ts-ignore
import Sortable from 'sortablejs';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'app-wallet-form',
  templateUrl: './wallet-form.component.html',
  styleUrls: ['./wallet-form.component.scss']
})
export class WalletFormComponent implements OnInit, OnDestroy {
  @Input() isEditing: boolean;
  @Input() walletToModify: Wallet = new Wallet();
  @Output() walletToModifyChange = new EventEmitter<Wallet>();
  @Output() walletChanged = new EventEmitter<boolean>();

  public users: Observable<any[]>;
  usersItems: any[];
  fileToUpload: any;
  regions = Utilities.regions;
  products: string[] = Utilities.productNames;
  walletCreationForm!: FormGroup;
  uploadPercentage: string;
  isSubmitted = false;
  showLastTigranDate = false;
  plafondAlert = false;
  isLoading: boolean;
  uploading: boolean = false;

  lastTigranDate: string;
  currentUser: any;
  currentUserId: string;

  sub: Subscription;

  productsEnabled = [false, false, false];

  private backupWallet: Wallet;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private tigranService: TigranService,
    private storage: AngularFireStorage
  ) {
    this.firebaseService.createPageLog('User Visit Page Crea Portafoglio');
    this.createForm();
  }

  ngOnInit() {
    // TODO implement code snippet used for drag and drop sortable list
    const el = document.getElementById('items');
    const sortable = new Sortable(el, {
      animation: 150,
      ghostClass: 'my-ghost-class'
    });

    this.users = this.firebaseService.getUsersByRole(Utilities.userType[2]);

    this.sub = this.users.subscribe(data => {
      this.usersItems = data;
    });

    if (this.isEditing) {
      this.backupWallet = this.walletToModify;

      this.firebaseService.getUserByID(this.walletToModify.originatorUId).then(data => {
        if (data.exists) {
          const us = data.data() as Originator;
          this.productsEnabled = us.originatorProducts;
        }
      });

      this.walletCreationForm.setValue({
        walletName: this.walletToModify.walletName,
        region: this.walletToModify.region,
        sector: this.walletToModify.sector,

        openingDate: this.walletToModify.openingDate,
        endingDate: this.walletToModify.endingDate,
        minInvoice: this.walletToModify.minInvoice,
        maxInvoice: this.walletToModify.maxInvoice,
        tigranRating: '',
        tigranProb: '',

        plafondYellow: this.walletToModify.productsNode[0].plafond,
        plafondBlue: this.walletToModify.productsNode[1].plafond,
        plafondAzure: this.walletToModify.productsNode[2].plafond,

        enabledYellow: this.walletToModify.productsNode[0].enabled,
        enabledBlue: this.walletToModify.productsNode[1].enabled,
        enabledAzure: this.walletToModify.productsNode[2].enabled,

        priorityYellow: this.walletToModify.productsNode[0].priority,
        priorityBlue: this.walletToModify.productsNode[1].priority,
        priorityAzure: this.walletToModify.productsNode[2].priority,
        logo: this.walletToModify.logo,
        paymentDays: this.walletToModify.paymentDays,
        originatorName: this.walletToModify.originatorName,
        description: this.walletToModify.description
      });
    }
    // console.log(this.walletCreationForm.value);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  submit() {
    // console.log(this.uuid());

    this.isSubmitted = true;
    if (!this.walletCreationForm.valid) {
      this.walletCreationForm.get('walletName').markAsTouched();
      this.walletCreationForm.get('region').markAsTouched();
      this.walletCreationForm.get('sector').markAsTouched();

      this.walletCreationForm.get('plafondYellow').markAsTouched();
      this.walletCreationForm.get('plafondBlue').markAsTouched();
      this.walletCreationForm.get('plafondAzure').markAsTouched();

      this.walletCreationForm.get('enabledYellow').markAsTouched();
      this.walletCreationForm.get('enabledBlue').markAsTouched();
      this.walletCreationForm.get('enabledAzure').markAsTouched();

      this.walletCreationForm.get('paymentDays').markAsTouched();
      this.walletCreationForm.get('originatorName').markAsTouched();
    } else {
      const filePath =
        this.firebaseService.loggedUser.id + '-' + this.walletCreationForm.value.walletName + '-LogoPortafoglio.png';

      this.uploadFileContratto(filePath);

      if (!this.plafondConsistent()) {
        this.plafondAlert = true;
        return;
      }
      let startData = new Date(this.walletCreationForm.value.openingDate);
      let endData = new Date(this.walletCreationForm.value.endingDate);

      if (startData > endData) {
        alert('la data di apertura non può essere maggiore della data di chiusura');
        return;
      }
      this.walletCreationForm.value.logo = filePath;
      // console.log(this.walletCreationForm);

      this.firebaseService.createWallet(this.walletCreationForm);

      this.router.navigate(['/home/list-wallet']);
    }
  }
  uuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  uploadFileContratto(filePath: any) {
    // console.log('caricamento');
    const task = this.storage.upload(filePath, this.fileToUpload);

    // console.log(task);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
        })
      )
      .subscribe(data => {});
  }

  createForm() {
    this.walletCreationForm = this.formBuilder.group({
      walletName: new FormControl('', [Validators.required]),
      region: new FormControl('', [Validators.required]),
      sector: new FormControl('', [Validators.required]),
      openingDate: new FormControl(''),
      endingDate: new FormControl(''),
      tigranRating: new FormControl(''),
      tigranProb: new FormControl(''),
      minInvoice: new FormControl(0, [Validators.min(0.0)]),
      maxInvoice: new FormControl(0, [Validators.min(0.0)]),

      plafondYellow: new FormControl(0, [Validators.min(0.0)]),
      plafondBlue: new FormControl(0, [Validators.min(0.0)]),
      plafondAzure: new FormControl(0, [Validators.min(0.0)]),

      enabledYellow: new FormControl(false),
      enabledBlue: new FormControl(false),
      enabledAzure: new FormControl(false),

      priorityYellow: new FormControl(0),
      priorityBlue: new FormControl(1),
      priorityAzure: new FormControl(2),

      logo: new FormControl('', [Validators.required]),

      // FIXME requireCheckboxesToBeCheckedValidator() this validator could be useful

      paymentDays: new FormControl(0, [Validators.required, Validators.min(0.0)]),
      originatorName: new FormControl('', [Validators.required]),
      description: new FormControl('')
    });

    this.walletCreationForm.valueChanges.subscribe((item: any) => {
      this.walletToModify.walletName = item.walletName;
      this.walletToModify.region = item.region;
      this.walletToModify.sector = item.sector;
      this.walletToModify.openingDate = item.openingDate;
      this.walletToModify.endingDate = item.endingDate;
      this.walletToModify.minInvoice = item.minInvoice;
      this.walletToModify.maxInvoice = item.maxInvoice;

      this.walletToModify.productsNode[0].plafond = item.plafondYellow;
      this.walletToModify.productsNode[1].plafond = item.plafondBlue;
      this.walletToModify.productsNode[2].plafond = item.plafondAzure;

      this.walletToModify.productsNode[0].enabled = item.enabledYellow;
      this.walletToModify.productsNode[1].enabled = item.enabledBlue;
      this.walletToModify.productsNode[2].enabled = item.enabledAzure;

      this.walletToModify.productsNode[0].priority = item.priorityYellow;
      this.walletToModify.productsNode[1].priority = item.priorityBlue;
      this.walletToModify.productsNode[2].priority = item.priorityAzure;

      this.walletToModify.paymentDays = item.paymentDays;
      this.walletToModify.description = item.description;
      if (!this.isEditing) {
        this.walletToModify.originatorUId = item.originatorName.split(';', 2)[0];
        this.walletToModify.originatorName = item.originatorName.split(';', 2)[1];
      }

      this.walletToModifyChange.emit(this.walletToModify);
      this.walletChanged.emit(true);
    });
  }

  // Custom validators
  minMaxComparator(num: number, howTo: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (howTo == '>') {
        return control.value > num ? { compare: true } : null;
      } else if (howTo == '<') {
        return control.value < num ? { compare: true } : null;
      }

      return null;
    };
  }

  // getters
  get walletName() {
    return this.walletCreationForm.get('walletName');
  }

  get region() {
    return this.walletCreationForm.get('region');
  }

  get sector() {
    return this.walletCreationForm.get('sector');
  }

  get openingDate() {
    return this.walletCreationForm.get('openingDate');
  }

  get endingDate() {
    return this.walletCreationForm.get('endingDate');
  }

  get minInvoice() {
    return this.walletCreationForm.get('minInvoice');
  }

  get maxInvoice() {
    return this.walletCreationForm.get('maxInvoice');
  }

  get plafondYellow() {
    return this.walletCreationForm.get('plafondYellow');
  }

  get plafondBlue() {
    return this.walletCreationForm.get('plafondBlue');
  }

  get plafondAzure() {
    return this.walletCreationForm.get('plafondAzure');
  }

  get enabledYellow() {
    return this.walletCreationForm.get('enabledYellow');
  }

  get enabledBlue() {
    return this.walletCreationForm.get('enabledBlue');
  }

  get enabledAzure() {
    return this.walletCreationForm.get('enabledAzure');
  }

  get priorityYellow() {
    return this.walletCreationForm.get('priorityYellow');
  }

  get priorityBlue() {
    return this.walletCreationForm.get('priorityBlue');
  }

  get priorityAzure() {
    return this.walletCreationForm.get('priorityAzure');
  }

  get originatorName() {
    return this.walletCreationForm.get('originatorName');
  }

  get paymentDays() {
    return this.walletCreationForm.get('paymentDays');
  }

  changedOriginator($event: any, updating: boolean) {
    this.restoreWalletProducts();

    const originatorInfo = $event.split(';');
    this.currentUserId = originatorInfo[0];
    if (this.currentUserId !== '') {
      this.firebaseService.getUserByID(this.currentUserId).then(doc => {
        if (doc.exists) {
          this.currentUser = doc.data() as Originator;
          if (!updating && this.currentUser.originatorMoreScore && this.currentUser.originatorDefault) {
            this.showLastTigranDate = true;
            this.lastTigranDate = this.currentUser.lastTigranDate;
            this.walletCreationForm.controls.tigranRating.setValue(this.currentUser.originatorMoreScore);
            this.walletCreationForm.controls.tigranProb.setValue(this.currentUser.originatorDefault);
          } else {
            this.updateTigranValues(this.currentUser, originatorInfo[0]);
          }
        }
      });
    }
  }

  updateTigranValues(user: Originator, userId: string) {
    this.isLoading = true;
    this.showLastTigranDate = false;

    this.walletCreationForm.controls.tigranRating.setValue('');
    this.walletCreationForm.controls.tigranProb.setValue('');

    this.tigranService.entireFlow(user.originatorPIva).subscribe(data => {
      this.walletCreationForm.controls.tigranRating.setValue(data['more_score']);
      this.walletCreationForm.controls.tigranProb.setValue(data['more_probability_of_default']);
      this.lastTigranDate = new Date().toUTCString();
      this.showLastTigranDate = true;

      user.originatorMoreScore = data['more_score'];
      user.originatorDefault = data['more_probability_of_default'];
      user.lastTigranDate = new Date().toUTCString();
      if (data['more_score'] && data['more_probability_of_default']) {
        this.firebaseService.modUserById(user, userId);
      }
    });
    this.isLoading = false;
  }

  private plafondConsistent(): boolean {
    return (
      (this.walletCreationForm.get('enabledYellow') &&
        this.walletCreationForm.get('plafondYellow').value !== undefined &&
        this.walletCreationForm.get('plafondYellow').value !== 0) ||
      (this.walletCreationForm.get('enabledBlue') &&
        this.walletCreationForm.get('plafondBlue').value !== undefined &&
        this.walletCreationForm.get('plafondBlue').value !== 0) ||
      (this.walletCreationForm.get('enabledAzure') &&
        this.walletCreationForm.get('plafondAzure').value !== undefined &&
        this.walletCreationForm.get('plafondAzure').value !== 0)
    );
  }

  private restoreWalletProducts(): void {
    this.walletCreationForm.controls.plafondYellow.setValue(0);
    this.walletCreationForm.controls.plafondBlue.setValue(0);
    this.walletCreationForm.controls.plafondAzure.setValue(0);
    this.walletCreationForm.controls.enabledYellow.setValue(false);
    this.walletCreationForm.controls.enabledBlue.setValue(false);
    this.walletCreationForm.controls.enabledAzure.setValue(false);
    this.walletCreationForm.controls.priorityYellow.setValue(0);
    this.walletCreationForm.controls.priorityBlue.setValue(1);
    this.walletCreationForm.controls.priorityAzure.setValue(2);
  }
}
