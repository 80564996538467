import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { PendingService } from '@app/pending.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-home-investor',
  templateUrl: './home-investor.component.html',
  styleUrls: ['./home-investor.component.scss']
})
export class HomeInvestorComponent implements OnInit {
  isLoading = false;
  constructor(
    public firebaseService: FirebaseTranchedService,
    public pending: PendingService,
    private router: Router
  ) {}

  ngOnInit() {
    this.calculatePendingRequests();
  }

  calculatePendingRequests(): void {
    this.pending.setPendingInvestor(0);
    this.firebaseService.getInvoicesNotApprovedInvestor().subscribe(docs => {
      let count = 0;
      for (let doc of docs) {
        if (doc.listaInvestitori) {
          for (let inve of doc.listaInvestitori) {
            if (inve.split('---')[0] == this.firebaseService.loggedUser.investorPIva) {
              count = count + 1;
              break;
            }
          }
        }
      }
      this.pending.setPendingInvestor(count);
    });
  }
}
