import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './bank-spv.component.html',
  styleUrls: ['./bank-spv.component.scss']
})
export class BankSpvComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
