import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Utilities } from '@app/utilities/Utilities';
import html2canvas from 'html2canvas';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-test-pdf',
  templateUrl: './test-pdf.component.html',
  styleUrls: ['./test-pdf.component.scss']
})
export class TestPdfComponent implements OnInit {
  creationForm!: FormGroup;
  public usersList: Observable<any[]>;
  usersItems: any[];
  caricaUsers: any[] = [];
  investorUrl = 'investor';
  public tipologia: string;
  public roles: string;
  sub: Subscription;
  bottone = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private afAuth: AngularFireAuth
  ) {
    this.tipologia = 'all';
    this.roles = 'all';
    this.createForm();
    this.firebaseService.createPageLog('User Visit Page test PDF');
  }

  ngOnInit() {
    this.usersList = this.firebaseService.getUsers();
    // console.log(this.usersList);
    this.sub = this.usersList.subscribe(data => {
      this.usersItems = data;
      this.roles = 'all';
      this.loadUser();
    });
  }

  loadUser() {
    this.caricaUsers = [];
    for (const user of this.usersItems) {
      // console.log(user);
      this.caricaUsers.push(user);
      //  console.log(this.caricaUsers.length);
    }
  }

  createForm() {
    this.creationForm = this.formBuilder.group({
      type: new FormControl('')
    });
  }

  submit() {
    const DATA = document.getElementById('htmlData');

    Utilities.downloadAsPDF(DATA, 'prova', 'testo');
  }
}
