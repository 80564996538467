import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';

import { Shell } from '@app/shell/shell.service';
import { HomeOriginatorComponent } from './home-originator.component';
import { Utilities } from '@app/utilities/Utilities';
import { WalletListComponent } from '@app/home/wallet-list/wallet-list.component';
import { WalletDetailsComponent } from '@app/home/wallet-details/wallet-details.component';
import { LemonwayComponent } from '@app/home/lemonway/lemonway.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/originator/list-wallet', pathMatch: 'full' },
    {
      path: 'originator',
      component: HomeOriginatorComponent,
      children: [
        {
          path: 'list-wallet',
          component: WalletListComponent
        },
        {
          path: 'wallet-details/:id',
          component: WalletDetailsComponent
        },
        {
          path: 'lemonway/:type',
          component: LemonwayComponent
        }
      ],
      data: { title: extract('Originator'), role: Utilities.userType[2] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeOriginatorRoutingModule {}
