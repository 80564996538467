import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';

import { Utilities } from '@app/utilities/Utilities';
import { HomePartnerComponent } from './home-partner.component';
import { NewBankComponent } from '@app/home/new-originator/new-bank.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WalletDetailsComponent } from '@app/home/wallet-details/wallet-details.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/partner', pathMatch: 'full' },
    {
      path: 'partner',
      component: HomePartnerComponent,
      children: [
        {
          path: 'new-originator',
          component: NewBankComponent
        },
        {
          path: 'dashboard',
          component: DashboardComponent
        },
        {
          path: 'wallet-details/:id',
          component: WalletDetailsComponent
        }
      ],
      data: { title: extract('Partner'), role: Utilities.userType[4] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomePartnerRoutingModule {}
