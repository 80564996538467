import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { PendingService } from '@app/pending.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  statusSPV: boolean;
  statusLemonWay: boolean;
  menuHidden: any;
  isLoading = false;

  constructor(public firebaseService: FirebaseTranchedService, public pending: PendingService, private router: Router) {
    console.log('====', this.firebaseService.loggedUser.statoLemonway);
    this.statusSPV = false;
    this.statusLemonWay = false;
    try {
      if (this.firebaseService.loggedUser.investorSPV.length > 0) {
        this.statusSPV = true;
      }

      if (this.firebaseService.loggedUser.lemonwayAccount) {
        this.statusLemonWay = true;
      }
    } catch (e) {}
  }

  ngOnInit() {
    this.calculatePendingRequests();
  }

  calculatePendingRequests(): void {
    this.pending.setPendingFornitore(0);
    this.firebaseService.getInvoicesNotApprovedFornitore().subscribe(docs => {
      var finalList = [];
      //console.log(docs);
      for (var invoice of docs) {
        //console.log(wallet.id);
        if (invoice.uid == this.firebaseService.loggedUser.id) {
          //console.log(wallet);
          finalList.push(invoice);
        }
      }

      this.pending.setPendingFornitore(finalList.length);
    });
  }
}
