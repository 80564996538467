import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';

@Component({
  selector: 'app-home-servicer-spv',
  templateUrl: './home-servicer-spv.component.html',
  styleUrls: ['./home-servicer-spv.component.scss']
})
export class HomeServicerSPVComponent implements OnInit {
  isLoading = false;

  constructor(public firebase: FirebaseTranchedService) {}

  ngOnInit() {}
}
