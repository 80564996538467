import { Utilities } from '@app/utilities/Utilities';
import { User } from './User.model';
export class BankSPV extends User {
  lastName: string;
  firstName: string;
  ragioneSociale: string;
  investorsSPV: any;
  uuid: string;
  pec: string;
  city: string;
  state: string;
  cap: string;

  constructor() {
    super();
  }
}
