import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { PendingService } from '@app/pending.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public requests: Observable<any[]>;

  isLoading = false;
  navHidden = true;
  menuActive: number;
  requestsItems: any[] = [];

  constructor(private firebase: FirebaseTranchedService, public pending: PendingService) {
    // this.pendingRequests=this.pending.getPending();
  }

  ngOnInit() {
    this.calculatePendingRequests();
  }

  toggleNav() {
    this.navHidden = !this.navHidden;
  }

  activeNav(i: number) {
    this.menuActive = i;
  }

  calculatePendingRequests(): void {
    this.pending.setPending(0);
    this.firebase.getInvoicesNotApproved().subscribe(docs => {
      if (docs.length > 0) {
        // this.pendingRequests += docs.length;
        this.pending.add(docs.length);
      }
      this.firebase.getUsersByVerified(false).subscribe(users => {
        // this.pendingRequests += users.length;
        this.pending.setPending(docs.length + users.length);
      });
    });
  }
}
