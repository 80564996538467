import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeBankSpvComponent } from './home-bank-spv.component';
import { HomeBankSpvRoutingModule } from '@app/home-bank-spv/home-bank-spv-routing.module';
import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { InvoiceListBankComponent } from '@app/home-bank-spv/invoice-list/invoice-list.component';
import { FundsRequestComponent } from './funds-request/funds-request.component';

@NgModule({
  declarations: [HomeBankSpvComponent, InvoiceListBankComponent, FundsRequestComponent],
  imports: [CommonModule, HomeBankSpvRoutingModule, SharedModule, FormsModule, DataTablesModule]
})
export class HomeBankSpvModule {}
