import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeServicerSPVRoutingModule } from './home-servicer-spv-routing.module';

import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { HomeServicerSPVComponent } from './home-servicer-spv.component';
import { CompanySpvListComponent } from './company-spv-list/company-spv-list.component';

import { DataTablesModule } from 'angular-datatables';
import { CompanySpvDetailComponent } from './company-spv-detail/company-spv-detail.component';

@NgModule({
  declarations: [HomeServicerSPVComponent, CompanySpvListComponent, CompanySpvDetailComponent],
  imports: [CommonModule, HomeServicerSPVRoutingModule, SharedModule, FormsModule, DataTablesModule]
})
export class HomeServicerSPVModule {}
