import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Wallet } from '@app/models/Wallet.model';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-wallet-edit-modal',
  templateUrl: './wallet-edit-modal.component.html',
  styleUrls: ['./wallet-edit-modal.component.scss']
})
export class WalletEditModalComponent implements OnInit {
  @Input() walletToModify: Wallet;
  isChanged: boolean;
  fileToUpload: any;
  uploadPercentage: string;
  uploading: boolean = false;
  constructor(
    public activeModal: NgbActiveModal,
    private firebase: FirebaseTranchedService,
    private storage: AngularFireStorage
  ) {
    this.isChanged = false;
  }

  ngOnInit() {
    // console.log(this.walletToModify);
    this.walletToModify.logo = !this.walletToModify.logo ? '' : this.walletToModify.logo;
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];
    const filePath = this.firebase.loggedUser.id + '-' + this.walletToModify.walletName + '-LogoPortafoglio.png';
    this.uploadFileContratto(filePath);
    this.walletToModify.logo = filePath;
    this.isChanged = true;
  }

  onCloseAction() {
    //console.log(this.walletToModify);
    if (this.isChanged) {
      this.firebase.modWallet(this.walletToModify);
    }
    this.activeModal.close();
  }

  uploadFileContratto(filePath: any) {
    //  console.log('caricamento');
    const task = this.storage.upload(filePath, this.fileToUpload);

    // console.log(task);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
        })
      )
      .subscribe(data => {});
  }
}
