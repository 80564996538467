import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTablesResponse } from '@app/models/DataTablesResponse.model';
import { environment } from '@env/environment.prod';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './bank-spv-list.component.html',
  styleUrls: ['./bank-spv-list.component.scss']
})
export class BankSpvListComponent implements OnInit {
  message = '';

  // @ts-ignore
  dtOptions: DataTables.Settings = {};

  constructor(private router: Router, private http: HttpClient, private backendAuthService: BackendAuthService) {}

  someClickHandler(info: any): void {
    this.message = info.uuid + ' - ' + info.name;
    this.router.navigate(['/home/bank-spv/' + info.uuid + '/detail']);
  }

  ngOnInit() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      autoWidth: false,
      columnDefs: [
        { width: '5%', targets: 0 },
        { width: '30%', targets: 1 },
        { width: '20%', targets: 2 },
        { width: '20%', targets: 3 },
        { width: '20%', targets: 4 }
      ],
      width: '200%',
      serverSide: false, // Set the flag
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(`${environment.laravelServer}/getAllUserByRole`, '{"role":"bankSPV"}', httpOptions)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
          });
      },
      columns: [
        {
          title: 'ID',
          data: 'id'
        },
        {
          title: 'Ragione sociale',
          data: 'ragioneSociale'
        },
        {
          title: 'Nome',
          data: 'name'
        },
        {
          title: 'Cognome',
          data: 'lastName'
        },
        {
          title: 'Email',
          data: 'email'
        }
      ],
      // tslint:disable-next-line:ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // Note: In newer jQuery v3 versions, `unbind` and `bind` are
        // deprecated in favor of `off` and `on`
        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.someClickHandler(data);
        });
        return row;
      },
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'columnsToggle',
        'colvis',
        'copy',
        'print',
        'excel',
        {
          text: 'Some button',
          key: '1',
          action: function(e, dt, node, config) {
            alert('Button activated');
          }
        }
      ]
    };
  }

  goToAddEntity() {
    this.router.navigate(['/home/bank-spv/create']);
  }
}
