import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import DataTables from 'node_modules/datatables.net';
import { DataTablesResponse } from '@app/models/DataTablesResponse.model';
import { environment } from '@env/environment.prod';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Wallet } from '@app/models/Wallet.model';
import { Subject } from 'rxjs';
import { Utilities } from '@app/utilities/Utilities';
import { Invoice } from '@app/models/Invoice.model';
import { RequestDetailModalComponent } from '@app/home/wallet-details/request-detail-modal/request-detail-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-investor-term-list',
  templateUrl: './investor-term-list.component.html',
  styleUrls: ['./investor-term-list.component.scss']
})
export class InvestorTermListComponent implements OnInit {
  message = '';

  // @ts-ignore
  dtOptions: DataTables.Settings = {};
  allCoupons = [];

  private wallet: Wallet;
  private backupWallet: Wallet;

  constructor(
    private router: Router,
    private http: HttpClient,
    private firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private backendAuthService: BackendAuthService
  ) {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      autoWidth: false,
      width: '200%',
      serverSide: false, // Set the flag
      // tslint:disable-next-line:ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // Note: In newer jQuery v3 versions, `unbind` and `bind` are
        // deprecated in favor of `off` and `on`
        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.someClickHandler(data);
        });
        return row;
      },
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'columnsToggle',
        'colvis',
        'copy',
        'print',
        'excel',
        {
          text: 'Some button',
          key: '1',
          action: function(e, dt, node, config) {
            alert('Button activated');
          }
        }
      ]
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<DataTablesResponse>(
        `${environment.laravelServer}/calendar/getTermsOfInvestor`,
        '{"id":"' + this.firebase.loggedUser.id + '"}',
        httpOptions
      )
      .subscribe(resp => {
        this.allCoupons = resp.data;
      });

    // this.getDtOptions(this.firebase.loggedUser.id);
  }

  getDtOptions(uuid) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      autoWidth: false,
      columnDefs: [
        { width: '20%', targets: 0 },
        { width: '10%', targets: 1 },
        { width: '10%', targets: 2 },
        { width: '10%', targets: 3 },
        { width: '10%', targets: 4 },
        { width: '5%', targets: 5 },
        { width: '20%', targets: 6 }
      ],
      width: '200%',
      serverSide: false, // Set the flag
      ajax: (dataTablesParameters: any, callback) => {
        this.http
          .post<DataTablesResponse>(
            `${environment.laravelServer}/calendar/getTermsOfInvestor`,
            '{"id":"' + uuid + '"}',
            httpOptions
          )
          .subscribe(resp => {
            this.allCoupons = resp.data;
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
          });
      },
      columns: [
        {
          title: 'Data',
          data: 'date_event',
          type: 'date'
        },
        {
          title: 'Oggetto',
          data: 'subject'
        },
        {
          title: 'Fattura',
          data: 'num_fatt'
        },
        {
          title: 'Cedente',
          data: 'cedente'
        },
        {
          title: 'Wallet',
          data: 'walletName'
        },
        {
          title: 'Descrizione',
          data: 'description'
        },
        {
          title: 'Tipo',
          data: 'type'
        }
      ],
      // tslint:disable-next-line:ban-types
      rowCallback: (row: Node, data: any[] | Object, index: number) => {
        const self = this;
        // Unbind first in order to avoid any duplicate handler
        // (see https://github.com/l-lin/angular-datatables/issues/87)
        // Note: In newer jQuery v3 versions, `unbind` and `bind` are
        // deprecated in favor of `off` and `on`
        $('td', row).off('click');
        $('td', row).on('click', () => {
          self.someClickHandler(data);
        });
        return row;
      },
      // Declare the use of the extension in the dom parameter
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'columnsToggle',
        'colvis',
        'copy',
        'print',
        'excel',
        {
          text: 'Some button',
          key: '1',
          action: function(e, dt, node, config) {
            alert('Button activated');
          }
        }
      ]
    };
  }

  someClickHandler(info: any): void {
    this.message = info.uuid + ' - ' + info.cedenteName;
    console.log(this.message);
  }

  ngOnInit() {}

  openRedeemModal(content: any) {
    // devo recuperare i dettagli della fattura dal suo id
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };
    this.http
      .post<any>(`${environment.laravelServer}/getInvoiceById`, '{"id":"' + content + '"}', httpOptions)
      .subscribe(respInv => {
        const idWallet = respInv.walletId;

        this.firebase
          .getWalletfromID(idWallet)
          .then(doc => {
            if (doc.exists) {
              this.wallet = doc.data() as Wallet;
              this.backupWallet = { ...this.wallet };
              this.wallet.id = idWallet;
              Utilities.getDaysLeft(this.wallet);

              this.showRequestDetail(respInv);
            } else {
            }
          })
          .catch(error => {});
      });
  }

  showRequestDetail(request: Invoice) {
    const modalRef = this.modalService.open(RequestDetailModalComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.invoice = request;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.result.then(
      () => {
        // Save
      },
      () => {
        // Dismiss
      }
    );
  }
}
