import { Component, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment.prod';
import { AngularFireStorage } from '@angular/fire/storage';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
  private filter = '';
  public invoiceItems: any = {
    status: false
  };

  public invoiceItemsCompleted: any = {
    status: false
  };

  constructor(
    private firebase: FirebaseTranchedService,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private backendAuthService: BackendAuthService
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/invoice/getInvoicesBlueOpen`, '{}', httpOptions)
      .subscribe(resp => {
        this.invoiceItems = resp;
        this.invoiceItems.status = true;
      });

    this.http
      .post<any>(`${environment.laravelServer}/invoice/getInvoicesBlueClose`, '{}', httpOptions)
      .subscribe(resp => {
        this.invoiceItemsCompleted = resp;
        this.invoiceItemsCompleted.status = true;
      });
  }

  getDownloadURL(trans: string) {
    const ref = this.storage.ref(trans);
    ref.getDownloadURL().subscribe(data => {
      console.log(data);
      return data;
    });
  }

  ngOnInit() {}

  changeView(open: string) {
    this.filter = open;
  }
}
