import { ModalProfileComponent } from './modal-profile/modal-profile.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { forEach } from 'lodash';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  products: string[];
  isEditable = false;
  user: any;
  infoTrans: any;
  balance: any;
  allInfo: any;
  nessunaTrans: boolean;
  nessunConto: boolean;
  constructor(
    public firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    private backLocation: Location,
    private ls: LemonwayService,
    private datepipe: DatePipe
  ) {
    // console.log('Profile=>' + this.firebase.isInvestor());
    this.products = this.firebase.loggedUser.originatorProducts;
    this.user = this.firebase.loggedUser;
    this.nessunConto = false;
    if (
      this.firebase.isCompany() &&
      this.firebase.loggedUser.isPrivato == true &&
      this.firebase.loggedUser.lemonwayAccount == true
    ) {
      this.ls.infoListofPayment(this.firebase.loggedUser.fiscalCode).subscribe(data => {
        //    console.log(data.transactions.value);
        this.infoTrans = data.transactions.value;
        //     console.log('trans=>' + this.infoTrans.length);
        if (this.infoTrans.length == 0) {
          this.nessunaTrans = true;
        } else {
          this.nessunaTrans = false;
        }
      });
      this.ls.infoAccount(this.firebase.loggedUser.pIva).subscribe(data => {
        console.log('profile data=>', data);
        this.allInfo = data;
        this.balance = data.account.balance / 100;
        if (this.balance == null) {
          this.nessunConto = true;
        }
      });
    }

    if (
      this.firebase.isCompany() &&
      this.firebase.loggedUser.isPrivato == false &&
      this.firebase.loggedUser.lemonwayAccount == true
    ) {
      this.ls.infoListofPayment(this.firebase.loggedUser.pIva).subscribe(data => {
        console.log(data);
        this.infoTrans = data.transactions.value;
        const array2 = new Array();
        forEach(data.transactions.value, function(value2, key) {
          //  console.log(value2);
          const obj = {};

          if (value2.transactionP2P) {
            obj[0] = value2.transactionP2P.date * 1000; // date
            obj[1] = value2.transactionP2P.executionDate * 1000; // sender
            obj[2] = 'P2P'; // tipo
            obj[3] = 'Trasferimento'; // creditAmount

            obj[4] = '';
            if (value2.transactionP2P.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionP2P.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionP2P.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionP2P.commissionAmount; //
            obj[7] = value2.transactionP2P.status; //
            if (obj[7] == 3) {
              obj[7] = 'Successo - P2P (3)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionP2P.senderAccountId;
            obj[10] = value2.transactionP2P.receiverAccountId;

            array2.push(obj);
          }
          if (value2.transactionIn) {
            obj[0] = value2.transactionIn.date * 1000; // date
            obj[1] = value2.transactionIn.executionDate * 1000; // sender
            obj[2] = 'In Entrata'; // tipo
            obj[3] = 'MoneyIn'; // creditAmount
            obj[4] = value2.transactionIn.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionIn.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionIn.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionIn.creditAmount / 100; // creditAmount
            obj[6] = value2.transactionIn.commissionAmount; //
            obj[7] = value2.transactionIn.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionIn.senderAccountId;
            obj[10] = value2.transactionIn.receiverAccountId;
            array2.push(obj);
          }
          if (value2.transactionOut) {
            obj[0] = value2.transactionOut.date * 1000; // date
            obj[1] = value2.transactionOut.executionDate * 1000; // sender
            obj[2] = 'In Uscita'; // tipo
            obj[3] = 'MoneyOut'; // creditAmount
            obj[4] = value2.transactionOut.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionOut.method == 3) {
              obj[4] = 'Bonifico bancario in uscita';
            }
            if (value2.transactionOut.method == 1) {
              obj[4] = 'MoneyOut(1)';
            }

            obj[5] = value2.transactionOut.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionOut.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionOut.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionOut.senderAccountId;
            obj[10] = value2.transactionOut.receiverAccountId;
            array2.push(obj);
          }
        });
        this.infoTrans = array2;
        //     console.log(this.infoTrans.length);
        if (this.infoTrans.length == 0) {
          this.nessunaTrans = true;
        } else {
          this.nessunaTrans = false;
        }
        //   console.log(this.nessunaTrans);
      });

      this.ls.infoAccount(this.firebase.loggedUser.pIva).subscribe(data => {
        console.log(data);
        this.allInfo = data;
        this.balance = data.account.balance / 100;
        if (this.balance == null) {
          this.nessunConto = true;
        }
      });
    }
    // console.log('cerco lemon' + this.firebase.isInvestor());
    // console.log('cerco lemon' + this.firebase.loggedUser.isPrivato);
    // console.log('cerco lemon' + this.firebase.loggedUser.lemonwayAccount);

    if (
      this.firebase.isInvestor() &&
      // tslint:disable-next-line:triple-equals
      (this.firebase.loggedUser.isPrivato == false || this.firebase.loggedUser.isPrivato == undefined) &&
      // tslint:disable-next-line:triple-equals
      this.firebase.loggedUser.lemonwayAccount == true
    ) {
      // console.log('cerco lemon');
      this.ls.infoListofPayment(this.firebase.loggedUser.investorPIva).subscribe(data => {
        console.log('data=>', data);
        // tslint:disable-next-line:only-arrow-functions typedef

        // tslint:disable-next-line:typedef only-arrow-functions
        const array2 = new Array();
        forEach(data.transactions.value, function(value2, key) {
          //  console.log(value2);
          const obj = {};

          if (value2.transactionP2P) {
            obj[0] = value2.transactionP2P.date * 1000; // date
            obj[1] = value2.transactionP2P.executionDate * 1000; // sender
            obj[2] = 'P2P'; // tipo
            obj[3] = 'Trasferimento'; // creditAmount

            obj[4] = '';
            if (value2.transactionP2P.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionP2P.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionP2P.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionP2P.commissionAmount; //
            obj[7] = value2.transactionP2P.status; //
            if (obj[7] == 3) {
              obj[7] = 'Successo - P2P (3)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionP2P.senderAccountId;
            obj[10] = value2.transactionP2P.receiverAccountId;

            array2.push(obj);
          }
          if (value2.transactionIn) {
            obj[0] = value2.transactionIn.date * 1000; // date
            obj[1] = value2.transactionIn.executionDate * 1000; // sender
            obj[2] = 'In Entrata'; // tipo
            obj[3] = 'MoneyIn'; // creditAmount
            obj[4] = value2.transactionIn.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionIn.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionIn.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionIn.creditAmount / 100; // creditAmount
            obj[6] = value2.transactionIn.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionIn.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionIn.senderAccountId;
            obj[10] = value2.transactionIn.receiverAccountId;
            array2.push(obj);
          }
          if (value2.transactionOut) {
            obj[0] = value2.transactionOut.date * 1000; // date
            obj[1] = value2.transactionOut.executionDate * 1000; // sender
            obj[2] = 'In Uscita'; // tipo
            obj[3] = 'MoneyOut'; // creditAmount
            obj[4] = value2.transactionOut.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionOut.method == 3) {
              obj[4] = 'Bonifico bancario in uscita';
            }
            if (value2.transactionOut.method == 1) {
              obj[4] = 'MoneyOut(1)';
            }

            obj[5] = value2.transactionOut.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionOut.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionOut.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionOut.senderAccountId;
            obj[10] = value2.transactionOut.receiverAccountId;
            array2.push(obj);
          }
        });
        this.infoTrans = array2;
        //console.log(this.infoTrans);
        //  console.log(this.infoTrans.length);
        if (this.infoTrans.length == 0) {
          this.nessunaTrans = true;
        } else {
          this.nessunaTrans = false;
        }
      });
      this.ls.infoAccount(this.firebase.loggedUser.investorPIva).subscribe(data => {
        //   console.log(data.account.balance);
        this.allInfo = data;
        this.balance = data.account.balance / 100;
        if (this.balance == null) {
          this.nessunConto = true;
        }
      });
    }

    if (
      this.firebase.isInvestor() &&
      // tslint:disable-next-line:triple-equals
      this.firebase.loggedUser.isPrivato == true &&
      // tslint:disable-next-line:triple-equals
      this.firebase.loggedUser.lemonwayAccount == true
    ) {
      // console.log('cerco lemon');
      this.ls.infoListofPayment(this.firebase.loggedUser.fiscalCode).subscribe(data => {
        // console.log(data);
        // tslint:disable-next-line:only-arrow-functions typedef

        // tslint:disable-next-line:typedef only-arrow-functions
        const array2 = new Array();
        forEach(data.transactions.value, function(value2, key) {
          //  console.log(value2);
          const obj = {};

          if (value2.transactionP2P) {
            obj[0] = value2.transactionP2P.date * 1000; // date
            obj[1] = value2.transactionP2P.executionDate * 1000; // sender
            obj[2] = 'P2P'; // tipo
            obj[3] = 'Trasferimento'; // creditAmount

            obj[4] = '';
            if (value2.transactionP2P.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionP2P.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionP2P.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionP2P.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionP2P.status; //
            if (obj[7] == 3) {
              obj[7] = 'Successo - P2P (3)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionP2P.senderAccountId;
            obj[10] = value2.transactionP2P.receiverAccountId;

            array2.push(obj);
          }
          if (value2.transactionIn) {
            obj[0] = value2.transactionIn.date * 1000; // date
            obj[1] = value2.transactionIn.executionDate * 1000; // sender
            obj[2] = 'In Entrata'; // tipo
            obj[3] = 'MoneyIn'; // creditAmount
            obj[4] = value2.transactionIn.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionIn.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionIn.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionIn.creditAmount / 100; // creditAmount
            obj[6] = value2.transactionIn.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionIn.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionIn.senderAccountId;
            obj[10] = value2.transactionIn.receiverAccountId;
            array2.push(obj);
          }
          if (value2.transactionOut) {
            obj[0] = value2.transactionOut.date * 1000; // date
            obj[1] = value2.transactionOut.executionDate * 1000; // sender
            obj[2] = 'In Uscita'; // tipo
            obj[3] = 'MoneyOut'; // creditAmount
            obj[4] = value2.transactionOut.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionOut.method == 3) {
              obj[4] = 'Bonifico bancario in uscita';
            }
            if (value2.transactionOut.method == 1) {
              obj[4] = 'MoneyOut(1)';
            }

            obj[5] = value2.transactionOut.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionOut.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionOut.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionOut.senderAccountId;
            obj[10] = value2.transactionOut.receiverAccountId;
            array2.push(obj);
          }
        });
        this.infoTrans = array2;
        //console.log(this.infoTrans);
        //  console.log(this.infoTrans.length);
        if (this.infoTrans.length == 0) {
          this.nessunaTrans = true;
        } else {
          this.nessunaTrans = false;
        }
      });
      this.ls.infoAccount(this.firebase.loggedUser.fiscalCode).subscribe(data => {
        //console.log(data.account.balance);
        this.allInfo = data;
        this.balance = data.account.balance / 100;
        if (this.balance == null) {
          this.nessunConto = true;
        }
      });
    }

    if (this.firebase.isOriginator()) {
      this.ls.infoListofPayment(this.firebase.loggedUser.originatorPIva).subscribe(data => {
        console.log('Originator Trans=>', data);
        //this.infoTrans = data.transactions.value;

        const array2 = new Array();
        forEach(data.transactions.value, function(value2, key) {
          console.log(value2.transactionP2P);
          const obj = {};

          if (value2.transactionP2P) {
            obj[0] = value2.transactionP2P.date * 1000; // date
            obj[1] = value2.transactionP2P.executionDate * 1000; // sender
            obj[2] = 'P2P'; // tipo
            obj[3] = 'Trasferimento'; // creditAmount

            obj[4] = '';
            if (value2.transactionP2P.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionP2P.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionP2P.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionP2P.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionP2P.status; //
            if (obj[7] == 3) {
              obj[7] = 'Successo - P2P (3)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionP2P.senderAccountId;
            obj[10] = value2.transactionP2P.receiverAccountId;

            array2.push(obj);
          }
          if (value2.transactionIn) {
            obj[0] = value2.transactionIn.date * 1000; // date
            obj[1] = value2.transactionIn.executionDate * 1000; // sender
            obj[2] = 'In Entrata'; // tipo
            obj[3] = 'MoneyIn'; // creditAmount
            obj[4] = value2.transactionIn.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionIn.method == 4) {
              obj[4] = 'Interno(4)';
            }
            if (value2.transactionIn.method == 1) {
              obj[4] = 'MoneyIn(1)';
            }

            obj[5] = value2.transactionIn.creditAmount / 100; // creditAmount
            obj[6] = value2.transactionIn.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionIn.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionIn.senderAccountId;
            obj[10] = value2.transactionIn.receiverAccountId;
            array2.push(obj);
          }
          if (value2.transactionOut) {
            obj[0] = value2.transactionOut.date * 1000; // date
            obj[1] = value2.transactionOut.executionDate * 1000; // sender
            obj[2] = 'In Uscita'; // tipo
            obj[3] = 'MoneyOut'; // creditAmount
            obj[4] = value2.transactionOut.method; // metodo // todo: da convertire nel valore lettereale

            obj[4] = '';
            if (value2.transactionOut.method == 3) {
              obj[4] = 'Bonifico bancario in uscita';
            }
            if (value2.transactionOut.method == 1) {
              obj[4] = 'MoneyOut(1)';
            }

            obj[5] = value2.transactionOut.debitAmount / 100; // creditAmount
            obj[6] = value2.transactionOut.lemonWayCommission.amount / 100; //
            obj[7] = value2.transactionOut.status; //
            if (obj[7] == 0) {
              obj[7] = 'Successo (0)';
            }
            obj[8] = ''; //
            obj[9] = value2.transactionOut.senderAccountId;
            obj[10] = value2.transactionOut.receiverAccountId;
            array2.push(obj);
          }
        });
        this.infoTrans = array2;

        console.log('Originator Info trans=>', this.infoTrans);

        if (this.infoTrans.length == 0) {
          this.nessunaTrans = true;
        } else {
          this.nessunaTrans = false;
        }
      });

      console.log('Originator PIVA=>', this.firebase.loggedUser.originatorPIva);
      this.ls.infoAccount(this.firebase.loggedUser.originatorPIva).subscribe(data => {
        console.log('Originator Balance=>', data);
        this.allInfo = data;
        this.balance = data.account.balance / 100;
        if (this.balance == null) {
          this.nessunConto = true;
        }
      });
    }
  }

  ngOnInit() {}

  showModalProfile() {
    const modalRef = this.modalService.open(ModalProfileComponent, {
      size: 'lg',
      centered: true,
      scrollable: true
    });
  }
  goBack() {
    this.backLocation.back();
  }

  stampa() {
    const data = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHms');
    const doc = new jsPDF('l', 'mm', [297, 210]);
    (doc as any).autoTable({ html: '#lemonTransaction' });
    doc.save('transaction' + data + '.pdf');
  }
}
