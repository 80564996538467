import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './anagrafiche.component.html',
  styleUrls: ['./anagrafiche.component.scss']
})
export class AnagraficheComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
