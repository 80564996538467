import { FirebaseTranchedService } from './../core/firebase-tranched.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as bcrypt from 'bcryptjs';
import { environment } from '@env/environment.prod';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetForm!: FormGroup;
  isLoading = false;
  oobCode = '';
  accountEmail: string;
  expired = false;
  passwordVerify: string;
  success = false;

  oldPassword = [];

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public afAuth: AngularFireAuth,
    public firebaseService: FirebaseTranchedService,
    private route: ActivatedRoute
  ) {
    this.resetForm = this.formBuilder.group({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      confirmPassword: new FormControl('', [Validators.required, this.verifyPassword()])
    });
    this.firebaseService.logout();
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.oobCode = params.oobCode;
      if (this.oobCode == '' || this.oobCode == undefined) {
        this.expired = true;
      }
      this.afAuth.auth
        .verifyPasswordResetCode(this.oobCode)
        .then(email => {
          this.accountEmail = email;

          // devo recuperare i dati dell'utente via email
          // in modo da salvare l'hash della nuova password
          this.firebaseService.getUserByRoleAndStringField('', 'email', email).subscribe(data => {
            // console.log(data);
            const idUser = data[0].id;
            // recupero tutte le password inserite precedentemente
            this.firebaseService.getUserPasswordLogById(idUser).then(dataP => {
              // console.log('Datap=>', dataP.data());
              try {
                this.oldPassword = dataP.data().hash;
              } catch (e) {
                console.log('pass empty');
              }
            });
          });

          // the new password.
        })
        .catch((error: any) => {
          this.expired = true;

          // Invalid or expired action code. Ask user to try to reset the password
          // again.
        });
    });
  }

  reset() {
    this.isLoading = true;
    if (!this.resetForm.valid) {
      this.resetForm.get('password').markAsTouched();
      this.resetForm.get('confirmPassword').markAsTouched();
    } else {
      this.isLoading = true;
      // Save the new password.
      this.afAuth.auth
        .confirmPasswordReset(this.oobCode, this.resetForm.value.password)
        .then((resp: any) => {
          this.afAuth.auth
            .signInWithEmailAndPassword(this.accountEmail, this.resetForm.value.password)
            .then(user => {
              this.firebaseService
                .setPasswordReset(user.user.uid)
                .then(doc => {
                  // aggiungo alla collection il nuovo valore di hash password
                  const newPass = bcrypt.hashSync(this.passwordVerify, environment.saltPass);
                  this.oldPassword.push(newPass);
                  if (this.oldPassword.length > 1) {
                    this.firebaseService.setPasswordNewHash(user.user.uid, this.oldPassword);
                  } else {
                    this.firebaseService.createPasswordNewHash(user.user.uid, [newPass]);
                  }
                  // *********************************************************

                  this.success = true;
                  this.expired = false;
                  this.firebaseService.updateUser(user.user.uid).then(() => {
                    setTimeout(() => {
                      this.router.navigate(['']);
                    }, 4000);

                    this.isLoading = false;
                  });
                })
                .catch(error => {
                  this.isLoading = false;
                  this.expired = true;
                  this.success = false;
                })
                .catch(error => {
                  this.isLoading = false;
                  this.expired = true;
                  this.success = false;
                });
            })
            .catch(error => {
              this.isLoading = false;
              this.expired = true;
              this.success = false;
            });
        })
        .catch((error: any) => {
          this.expired = true;
          this.isLoading = false;
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
        });
    }
  }

  goLogin() {
    this.router.navigate(['/login']);
  }

  // confronto password
  verifyPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value != this.passwordVerify ? { verifyPassword: true } : null;
    };
  }

  onChangePassword(event: string) {
    // console.log('event',event);
    const pass = bcrypt.hashSync(event, environment.saltPass);

    // console.log('compare=>',this.oldPassword.some(s => s.includes(pass)));
    if (this.oldPassword.some(s => s.includes(pass))) {
      alert('La password deve essere differente da una usata in precedenza');
      this.passwordVerify = '';
    }

    if (event == '') {
      this.passwordVerify = '';
    } else {
      this.passwordVerify = event;
    }
  }

  get password() {
    return this.resetForm.get('password');
  }

  get confirmPassword() {
    return this.resetForm.get('confirmPassword');
  }

  verifyPass() {
    /*
    console.log(this.resetForm.get('password').value);
    console.log(this.accountEmail);

     */

    if (this.resetForm.get('password').value == this.accountEmail) {
      // console.log('Email and password is same');
      alert("La password non può essere uguale all' email");
      return false;
    } else {
      // console.log('Email and password is different');
      return true;
    }
  }

  test() {
    const newPass = bcrypt.hashSync(this.passwordVerify, environment.saltPass);
    this.oldPassword.push(newPass);
    this.firebaseService.createPasswordNewHash('bOCVoMAoLHVY0ZygGZwtMVbwHzq1', this.oldPassword);
  }
}
