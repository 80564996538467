import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BankSpvRoutingModule } from './bank-spv-routing.module';
import { BankSpvComponent } from './bank-spv.component';
import { BankSpvCreateComponent } from './bank-spv-create/bank-spv-create.component';
import { BankSpvDetailComponent } from './bank-spv-detail/bank-spv-detail.component';
import { BankSpvEditComponent } from './bank-spv-edit/bank-spv-edit.component';
import { BankSpvListComponent } from './bank-spv-list/bank-spv-list.component';
import { DataTablesModule } from 'angular-datatables';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@app/shared';
import { ClipboardModule } from 'ngx-clipboard';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [
    BankSpvComponent,
    BankSpvCreateComponent,
    BankSpvDetailComponent,
    BankSpvEditComponent,
    BankSpvListComponent
  ],
  imports: [
    CommonModule,
    BankSpvRoutingModule,
    NgbModule,
    SharedModule,
    ClipboardModule,
    ReactiveFormsModule,
    DataTablesModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BankSpvModule {}
