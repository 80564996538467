import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BankSpvListComponent } from '@app/home/bank-spv/bank-spv-list/bank-spv-list.component';
import { BankSpvCreateComponent } from '@app/home/bank-spv/bank-spv-create/bank-spv-create.component';
import { BankSpvEditComponent } from '@app/home/bank-spv/bank-spv-edit/bank-spv-edit.component';
import { BankSpvDetailComponent } from '@app/home/bank-spv/bank-spv-detail/bank-spv-detail.component';

const routes: Routes = [
  {
    path: 'list',
    component: BankSpvListComponent
  },
  {
    path: 'create',
    component: BankSpvCreateComponent
  },
  {
    path: ':id/detail',
    component: BankSpvDetailComponent
  },
  {
    path: ':id/edit',
    component: BankSpvEditComponent
  },
  { path: '', redirectTo: 'list', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BankSpvRoutingModule {}
