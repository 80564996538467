import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  menuHidden = true;
  userRoles: any[] = [];
  private statusSPVLocal: boolean;

  private username = '';

  constructor(private router: Router, public afAuth: AngularFireAuth, public firebase: FirebaseTranchedService) {
    if (this.firebase.loggedUser.statusSPV) {
      this.statusSPVLocal = true;
    }
  }

  ngOnInit() {
    this.username = this.getUsername;
    this.userRoles = this.firebase.loggedUser.roles;
    const sess = this.firebase.retrieveSession();
    if (sess) {
      this.firebase.loggedUser.roles[0] = JSON.parse(sess).roles[0];
    }
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  /*
  setLanguage(language: string) {
    this.i18nService.language = language;
  }
*/
  logout() {
    this.firebase.logout().then(() => {
      this.router.navigate(['/login'], { replaceUrl: true });
    });
  }

  profile() {
    this.router.navigate(['/profile']);
  }

  home() {
    this.router.navigate(['']);
  }
  profilo_personale() {
    this.router.navigate(['/profiloPersonale']);
  }

  /*
  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }
*/

  get getUsername(): string | null {
    return this.afAuth.auth.currentUser.email;
    // const credentials = this.credentialsService.credentials;
    // return credentials ? credentials.username : null;
  }

  get firstRole(): string | null {
    return this.firebase.loggedUser.roles[0];
  }

  changeRole(i: number) {
    this.firebase.loggedUser.roles = [
      ...this.userRoles.filter(x => x === this.userRoles[i]),
      ...this.userRoles.filter(x => x !== this.userRoles[i])
    ];
    const newUser = this.firebase.loggedUser;
    newUser.roles = this.firebase.loggedUser.roles;
    this.firebase.modUser(newUser);
    location.reload();
  }
}
