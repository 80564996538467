import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-investor-spv',
  templateUrl: './investor-spv.component.html',
  styleUrls: ['./investor-spv.component.css']
})
export class InvestorSpvComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
