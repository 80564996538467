import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment.prod';
import { param } from 'jquery';
import { ServicerSPV } from '@app/models/ServicerSPV.model';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './servicer-spv-detail.component.html',
  styleUrls: ['./servicer-spv-detail.component.scss']
})
export class ServicerSpvDetailComponent implements OnInit {
  id: number;

  entity: any;
  private sub: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private backendAuthService: BackendAuthService
  ) {
    this.entity = new ServicerSPV();
  }

  ngOnInit() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.sub = this.route.params.subscribe(params => {
      this.id = params.id; // (+) converts string 'id' to a number
      console.log(params.id);
      this.http
        .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + params.id + '"}', httpOptions)
        .subscribe(data => {
          console.log(data);
          this.entity = data;
        });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  edit() {
    this.router.navigate(['/home/servicer-spv/' + this.id + '/edit']);
    // this.router.navigate(['./edit']);
  }

  goBack() {
    this.router.navigate(['/home/servicer-spv/']);
  }
}
