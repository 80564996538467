import { FaqModule } from './faq/faq.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { HomeModule } from './home/home.module';
import { ShellModule } from './shell/shell.module';
import { AboutModule } from './about/about.module';
import { LoginModule } from './login/login.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LandingModule } from './landing/landing.module';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { CompanyModule } from './home-company/company.module';
import { HomeOriginatorModule } from './home-originator/home-originator.module';
import { FirebaseTranchedService } from './core/firebase-tranched.service';
import { ProfileModule } from './profile/profile.module';
import 'firebase/storage';
import { HomePartnerModule } from './home-partner/home-partner.module';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';
import { HomeInvestorModule } from '@app/home-investor/home-investor.module';
import { DeleteUsuryRateModalComponent } from './home/usury-rates/delete-usury-rate/delete-usury-rate-modal.component';
import { ConfirmationModalComponent } from './tigran/confirmation-modal/confirmation-modal.component';
import { HomeServicerModule } from '@app/home-servicer/home-servicer.module';
import { RegistrationModule } from './registration/registration.module';
import { TestPdfComponent } from './test/test-pdf/test-pdf.component';
import { ResponeLemonwayModule } from './lemon-way-response/lemonresponse.module';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SendPecModule } from '@app/send-pec/send-pec.module';
import { ServicerSPVModule } from './home/servicer-spv/servicer-spv.module';
import { ConfirmationModalGenericComponent } from '@app/confirmation-modal-generic/confirmation-modal-generic.component';
import { InvestorSpvModule } from './home/investor-spv/investor-spv.module';
import { CompanyAllowSPVComponent } from './company-allow-spv/company-allow-spv.component';
import { HomeServicerSPVModule } from './home-servicer-spv/home-servicer-spv.module';
import { HomeInvestorSpvModule } from './home-investor-spv/home-investor-spv.module';
import { BankSpvModule } from '@app/home/bank-spv/bank-spv.module';
import { HomeBankSpvModule } from '@app/home-bank-spv/home-bank-spv.module';
import { AnagraficheComponent } from './anagrafiche/anagrafiche.component';
import { ProfiloPersonaleModule } from './profilo-personale/profilo-personale.module';

registerLocaleData(localeIt);

@NgModule({
  imports: [
    BrowserModule,
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    NgbModule,
    CoreModule,
    SharedModule,
    ShellModule,
    HomeModule,
    CompanyModule,
    HomeOriginatorModule,
    ProfileModule,
    ProfiloPersonaleModule,
    RegistrationModule,
    ResponeLemonwayModule,
    HomePartnerModule,
    HomeServicerModule,
    HomeInvestorModule,
    HomeServicerSPVModule,
    HomeInvestorSpvModule,
    HomeBankSpvModule,
    AboutModule,
    LoginModule,
    FaqModule,
    ResetPasswordModule,
    LandingModule,
    ChartsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAnalyticsModule, // dynamically imports firebase/analytics
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features
    AppRoutingModule,
    ReactiveFormsModule,
    NgxSliderModule,
    SendPecModule,
    ServicerSPVModule,
    InvestorSpvModule,
    BankSpvModule

    // must be imported as the last module as it contains the fallback route
  ],
  declarations: [
    AppComponent,
    DeleteUsuryRateModalComponent,
    ConfirmationModalComponent,
    TestPdfComponent,
    ConfirmationModalGenericComponent,
    CompanyAllowSPVComponent,
    AnagraficheComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'it' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    FirebaseTranchedService,
    ThemeService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
