import { Utilities } from '@app/utilities/Utilities';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import { Invoice } from '@app/models/Invoice.model';
import * as _ from 'lodash';
import { Wallet } from '@app/models/Wallet.model';
import { count } from 'console';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { BackendAuthService } from '@app/core/backend-auth.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-contratto-investitore',
  templateUrl: './contratto-investitore.component.html',
  styleUrls: ['./contratto-investitore.component.scss']
})
export class ContrattoInvestitoreComponent implements OnInit {
  data: string;

  constructor(
    public activeModal: NgbActiveModal,
    public firebase: FirebaseTranchedService,
    private backendAuthService: BackendAuthService
  ) {}
  ngOnInit() {
    var date = new Date();
    this.data = date.toLocaleDateString();
  }

  scarica() {
    var path = document.getElementById('contenuto');
    /*
    path.style.fontSize='12px'
    path.style.width='600px'
    //'p', 'pt', 'letter'
    //'p', 'mm', [290, 210]
    var doc = new jsPDF('p', 'pt', 'letter');
    //doc.setFontSize(1);

    var pdfjs = document.querySelector('contenuto');

    // Convert HTML to PDF in JavaScript
    doc.html(path, {
      callback: function(doc) {
        doc.save("output.pdf");
      },

    });
    */

    let docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [40, 60, 40, 60],
      header: [
        {
          image: 'logo',
          width: 120,
          height: 100,
          alignment: 'center'
        },
        { canvas: [{ type: 'rect', x: 170, y: 32, h: 100 }] }
      ],

      footer: [
        {
          text: 'FX12 s.r.l iscritta nell’albo speciale delle Start up innovative '
        },
        {
          text:
            'Capitale sociale Euro 10.0000 i.v., Registro Imprese Napoli e Codice Fiscale N. 09528731210, R.E.A. Napoli N. 1039449, Part. IVA 09528731210 '
        },
        {
          text: 'Sede legale: Via Caracciolo, 17, 80122 Napoli.'
        },
        {
          text:
            'Sede operativa: Via Coroglio, 57/104, 80124 Napoli c/o Campania NewSteel | Tel: +39 081 7352503 Email: desk@fx12.it PEC fx12@pec.it'
        }
      ],

      content: [
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: 'You can put images at any position'
        },
        {
          text: 'You can put images at any position'
        }
      ],
      images: {
        logo: 'https://panel.incassaora.it/assets/logoPDF.png'
      },

      styles: {}
    };

    pdfMake.createPdf(docDefinition).open();
  }
}
