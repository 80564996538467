import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Router } from '@angular/router';
import { InvestorSpvService } from '@app/home/investor-spv/investor-spv.service';
import { MailerService } from '@app/mailer/mailer.service';
import { environment } from '@env/environment.prod';

import * as bcrypt from 'bcryptjs';
import { BankSpvServiceService } from '@app/home/bank-spv/bank-spv-service.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './bank-spv-create.component.html',
  styleUrls: ['./bank-spv-create.component.scss']
})
export class BankSpvCreateComponent implements OnInit {
  entityCreationForm!: FormGroup;

  isSubmitted = false;
  errorMessage: string = undefined;
  autohide = true;

  allServicerSPV = [];
  selectedInvestorsSPV = [];

  /* dropdown multi select */
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  /* ********************* */

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private router: Router,
    private myService: BankSpvServiceService,
    private mailerService: MailerService,
    private backendAuthService: BackendAuthService
  ) {
    console.log(this.myService.data);
    this.dropdownList = this.myService.data;

    console.log('CONSTRUCT');

    this.firebaseService.createPageLog('User Visit Page - Create Servicer SPV');

    /* reupero tutti i servicers */
  }

  get email() {
    return this.entityCreationForm.get('email');
  }
  get pec() {
    return this.entityCreationForm.get('pec');
  }

  get password() {
    return this.entityCreationForm.get('password');
  }

  get firstName() {
    return this.entityCreationForm.get('firstName');
  }
  get ragioneSociale() {
    return this.entityCreationForm.get('ragioneSociale');
  }

  get lastName() {
    return this.entityCreationForm.get('lastName');
  }

  ngOnInit() {
    console.log('ONINIT');
    this.createForm();

    /*
    this.selectedItems = [
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' }
    ];
    */
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log('onItemSelect', item);
    this.selectedInvestorsSPV.push(item.item_id);
    console.log('ServersADD=>', this.selectedInvestorsSPV);
  }

  onItemDeSelect(item: any) {
    const index = this.selectedInvestorsSPV.indexOf(item.item_id);
    if (index !== -1) {
      this.selectedInvestorsSPV.splice(index, 1);
    }
    console.log('ServersADD=>', this.selectedInvestorsSPV);
  }

  onSelectAll(items: any) {
    console.log('onSelectAll', items);
    items.forEach(item => {
      this.selectedInvestorsSPV.push(item.item_id);
    });
    console.log('ServersADD=>', this.selectedInvestorsSPV);
  }

  onDeSelectAll(items: any) {
    this.selectedInvestorsSPV = [];
    console.log('ServersADD=>', this.selectedInvestorsSPV);
  }

  createForm() {
    this.entityCreationForm = this.formBuilder.group({
      ragioneSociale: new FormControl('', [Validators.required]),
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      pec: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      telefono: new FormControl(''),
      indirizzo: new FormControl(''),
      city: new FormControl(''),
      state: new FormControl(''),
      cap: new FormControl(''),
      investorSPVList: new FormControl('', [Validators.required])
    });
  }

  submit() {
    if (this.verifyMandatoryField()) {
      console.log('email=', this.entityCreationForm.value.email);
      this.isSubmitted = true;
      if (!this.entityCreationForm.valid && !this.verifyPass()) {
        console.log('Errore form');
        this.entityCreationForm.get('ragioneSociale').markAsTouched();
        this.entityCreationForm.get('firstName').markAsTouched();
        this.entityCreationForm.get('lastName').markAsTouched();
        this.entityCreationForm.get('email').markAsTouched();
        this.entityCreationForm.get('pec').markAsTouched();
        this.entityCreationForm.get('password').markAsTouched();
      } else {
        const objectToSave = this.firebaseService.createBankSPV(this.entityCreationForm, this.selectedInvestorsSPV);
        this.firebaseService
          .createUser(objectToSave.email, objectToSave.password)
          .then(data => {
            this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);

            // @@ hydra - 2023-05-01 - SEND EMAIL WITH TEMPALTE
            const params = {
              firstname: undefined,
              lastname: undefined,
              subject: undefined,
              templateName: undefined,
              to: undefined
            };

            params.to = objectToSave.email;
            params.firstname = objectToSave.name;
            params.lastname = objectToSave.lastName;
            params.subject = 'Registrazione IncassaOra';
            params.templateName = 'registrationSuccessFull';

            this.mailerService.sendEmailTemplate(params);

            // @@ hydra - 2023-01-31 - PASSWORD HASH
            try {
              const newPass = bcrypt.hashSync(objectToSave.password, environment.saltPass);
              this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
            } catch (e) {}
            // *********************************************************************************************

            this.router.navigate(['/home/bank-spv']);
          })
          .catch(error => {
            // Handle Errors here.
            const errorCode = error.code;
            this.errorMessage = error.message;
          });
      }
    } else {
      alert(
        'Attenzione - Non tutti i campi risultano compilati correttanemte o mancati. Se il problema persiste contattare il supporto tecnico!'
      );
    }
  }

  verifyMandatoryField() {
    let response = true;

    if (this.entityCreationForm.value.email == '') {
      response = false;
    }
    if (this.entityCreationForm.value.pec == '') {
      response = false;
    }
    if (this.entityCreationForm.value.firstName == '') {
      response = false;
    }
    if (this.entityCreationForm.value.lastName == '') {
      response = false;
    }
    if (this.entityCreationForm.value.ragioneSociale == '') {
      response = false;
    }
    if (this.entityCreationForm.value.telefono == '') {
      response = false;
    }
    if (this.entityCreationForm.value.indirizzo == '') {
      response = false;
    }
    if (this.entityCreationForm.value.city == '') {
      response = false;
    }
    if (this.entityCreationForm.value.state == '') {
      response = false;
    }
    if (this.entityCreationForm.value.cap == '') {
      response = false;
    }

    return response;
  }

  verifyPass() {
    console.log(this.entityCreationForm.get('password').value);
    console.log(this.entityCreationForm.get('email').value);

    if (
      this.entityCreationForm.get('password').value === this.entityCreationForm.get('email').value ||
      this.entityCreationForm.get('password').value === this.entityCreationForm.get('firstName').value ||
      this.entityCreationForm.get('password').value === this.entityCreationForm.get('lastName').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
