import { Component, Input, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Investor } from '@app/models/Investor.model';
import { Invoice } from '@app/models/Invoice.model';
import { Originator } from '@app/models/Originator.model';
import { Wallet } from '@app/models/Wallet.model';
import { Utilities } from '@app/utilities/Utilities';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-lista-cedenti',
  templateUrl: './modal-lista-cedenti.component.html',
  styleUrls: ['./modal-lista-cedenti.component.scss']
})
export class ModalListaCedentiComponent implements OnInit {
  @Input() walletToModify: Wallet;
  @Input() invoice: Invoice;
  listaCedenti: string[];
  listaGenerale: Investor[];
  InvestitorOnWallet: string[];
  constructor(public activeModal: NgbActiveModal, private firebase: FirebaseTranchedService) {
    firebase.getUsersByRole(Utilities.userType[3]).subscribe(data => {
      this.listaGenerale = data;
      this.listaCedenti = [];
      for (let entry of data) {
        if (entry.listWallet.includes(this.walletToModify.id)) {
          this.listaCedenti.push(entry.name);
        }
      }
      this.InvestitorOnWallet = this.walletToModify.listInvestitori ? this.walletToModify.listInvestitori : [];
    });
  }

  ngOnInit() {}
}
