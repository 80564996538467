import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShellModule } from '@app/shell/shell.module';
import { LemonWayRoutingModule } from './lemon-routing.module';
import { LemonWayResponseComponent } from './lemon-way-response.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    LemonWayRoutingModule,
    ShellModule,
    FormsModule
  ],
  declarations: [LemonWayResponseComponent]
})
export class ResponeLemonwayModule {}
