import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeOriginatorComponent } from './home-originator.component';
import { HomeOriginatorRoutingModule } from './home-originator-routing.module';

@NgModule({
  declarations: [HomeOriginatorComponent],
  imports: [CommonModule, HomeOriginatorRoutingModule]
})
export class HomeOriginatorModule {}
