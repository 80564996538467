import { Utilities } from '@app/utilities/Utilities';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Investor } from '@app/models/Investor.model';
import { Invoice } from '@app/models/Invoice.model';

@Component({
  selector: 'app-report-component',
  templateUrl: './report-component.component.html',
  styleUrls: ['./report-component.component.scss']
})
export class ReportComponentComponent implements OnInit {
  saleData = [
    { name: 'Mobiles', value: 105000 },
    { name: 'Laptop', value: 55000 },
    { name: 'AC', value: 15000 },
    { name: 'Headset', value: 150000 },
    { name: 'Fridge', value: 20000 }
  ];
  userSelected: string;
  dataI: string;
  dataF: string;
  CreationForm!: FormGroup;
  lineChartLabels = [];
  timeData = [];
  cessioneData = [];
  importoTotaleFattureData = [];
  importoTotaleFattureCeduteData = [];
  listaGenerale = [];
  constructor(
    private firebaseService: FirebaseTranchedService,
    private http: HttpClient,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  submit() {
    let pipe = new DatePipe('en-US');
    if (!this.CreationForm.valid) {
      this.CreationForm.get('startDate').markAsTouched();
      this.CreationForm.get('endDate').markAsTouched();
    } else {
      if (this.userSelected == '0') {
        alert('seleziona un utente');
      }
      let startData = new Date(this.CreationForm.value.startDate);
      let endData = new Date(this.CreationForm.value.endDate);
      if (new Date(this.CreationForm.value.startDate) > new Date(this.CreationForm.value.endDate)) {
        alert('data inizio maggiore di quella fine');
      } else {
        var id_user = this.userSelected.split('---')[0];
        var roles = this.userSelected.split('---')[1];
        if (roles == 'originator') {
          this.firebaseService.getWalletsByIdOriginator(id_user).subscribe(data => {
            if (data.length == 0) {
              alert("L'utente non ha wallet associati");
              return;
            } else {
              this.firebaseService.getInvoicesByID(data[0].id).subscribe(data2 => {
                this.generaGrafico(data2, startData, endData);
              });
            }
          });
        }
        if (roles == 'investor') {
          this.timeData = [];
          this.lineChartLabels = [];
          this.firebaseService.getAllInvoices().subscribe(data => {
            if (data.length == 0) {
              alert("L'utente non ha invoice associati");
              return;
            } else {
              //console.log(data);
              this.firebaseService
                .getUserByID(id_user)
                .then(doc2 => {
                  if (doc2.exists) {
                    var currentUser = doc2.data() as Investor;
                    this.generaGraficoIDInvestitore(data, startData, endData, currentUser.investorPIva);
                  } else {
                  }
                })
                .catch(error => {});
            }
          });
        }
        if (roles == 'company') {
          this.timeData = [];
          this.lineChartLabels = [];
          this.firebaseService.getInvoicesByIDUser(id_user).subscribe(data => {
            if (data.length == 0) {
              alert("L'utente non ha invoice associati");
              return;
            } else {
              // console.log(data);
              this.generaGraficoIDCompany(data, startData, endData);
            }
          });
        }
      } //else
    }
  }

  ngOnInit() {
    this.setMonths();
    this.userSelected = '0';
    this.firebaseService.getUsersByRole(Utilities.userType[5]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
    this.firebaseService.getUsersByRole(Utilities.userType[2]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
    this.firebaseService.getUsersByRole(Utilities.userType[3]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
  }

  onChange(event: any) {}

  createForm() {
    this.CreationForm = this.formBuilder.group({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required])
    });
  }

  generaGrafico(list, start: Date, end: Date) {
    let pipe = new DatePipe('en-US');
    /*
    let itarazioni = end.getFullYear() - start.getFullYear();
    console.log(itarazioni);
    let ite = itarazioni * 12;
    if (ite == 0) {
      ite = end.getMonth() - start.getMonth();
      if(ite==0){
        ite=1;
      }
    }
    */
    this.timeData = [];
    this.lineChartLabels = [];
    this.cessioneData = [];
    this.importoTotaleFattureData = [];
    this.importoTotaleFattureCeduteData = [];
    start.setMonth(start.getMonth() - 1);
    for (let i = 0; start <= end; i++) {
      start.setMonth(start.getMonth() + 1);
      var meseLimite = new Date(start);
      meseLimite.setMonth(meseLimite.getMonth() + 1);
      // console.log('mese attuale=' + pipe.transform(start, 'MMM-yy'));
      // console.log('mese limite=' + pipe.transform(meseLimite, 'MMM-yy'));
      var count = 0;
      var countCessioni = 0;
      var countImportoFattura = 0;
      var countImportoFatturaCeduto = 0;
      for (var inv of list) {
        var dinv = new Date(inv.dataFattura);
        if (dinv > start && dinv < meseLimite) {
          //  console.log('mese fattura=' + pipe.transform(dinv, 'MMM-yy'));
          count = count + 1;
          countImportoFattura = countImportoFattura + inv.importoFattura;
        }
        if (dinv > start && dinv < meseLimite && inv.status_index == 36) {
          // console.log('mese fattura=' + pipe.transform(dinv, 'MMM-yy'));
          countCessioni = countCessioni + 1;
          countImportoFatturaCeduto = countImportoFatturaCeduto + inv.importoFattura;
        }
      }
      // console.log('------');
      this.lineChartLabels.push(pipe.transform(start, 'MMM-yy'));
      this.timeData.push({ name: pipe.transform(start, 'MMM-yy'), value: count });
      this.cessioneData.push({ name: pipe.transform(start, 'MMM-yy'), value: countCessioni });
      this.importoTotaleFattureData.push({ name: pipe.transform(start, 'MMM-yy'), value: countImportoFattura });
      this.importoTotaleFattureCeduteData.push({
        name: pipe.transform(start, 'MMM-yy'),
        value: countImportoFatturaCeduto
      });
    }
    //  console.log(this.lineChartLabels);
    //  console.log(this.timeData);
  }

  generaGraficoIDCompany(list, start: Date, end: Date) {
    let pipe = new DatePipe('en-US');
    /*
    let itarazioni = end.getFullYear() - start.getFullYear();
   // console.log(itarazioni);
    let ite = itarazioni * 12;
    if (ite == 0) {
      ite = end.getMonth() - start.getMonth();
      if(ite==0){
        ite=1;
      }
    }
    */
    this.timeData = [];
    this.lineChartLabels = [];

    this.timeData = [];
    this.lineChartLabels = [];
    this.cessioneData = [];
    this.importoTotaleFattureData = [];
    this.importoTotaleFattureCeduteData = [];
    start.setMonth(start.getMonth() - 1);
    for (let i = 0; start <= end; i++) {
      start.setMonth(start.getMonth() + 1);
      var meseLimite = new Date(start);
      meseLimite.setMonth(meseLimite.getMonth() + 1);
      // console.log('mese attuale=' + pipe.transform(start, 'MMM-yy'));
      // console.log('mese limite=' + pipe.transform(meseLimite, 'MMM-yy'));
      var count = 0;
      var countCessioni = 0;
      var countImportoFattura = 0;
      var countImportoFatturaCeduto = 0;
      for (var inv of list) {
        var dinv = new Date(inv.dataFattura);
        if (dinv > start && dinv < meseLimite) {
          //  console.log('mese fattura=' + pipe.transform(dinv, 'MMM-yy'));
          count = count + 1;
          countImportoFattura = countImportoFattura + inv.importoFattura;
        }
        if (dinv > start && dinv < meseLimite && inv.status_index == 36) {
          //  console.log('mese fattura=' + pipe.transform(dinv, 'MMM-yy'));
          countCessioni = countCessioni + 1;
          countImportoFatturaCeduto = countImportoFatturaCeduto + inv.importoFattura;
        }
      }
      //  console.log('------');
      this.lineChartLabels.push(pipe.transform(start, 'MMM-yy'));
      this.timeData.push({ name: pipe.transform(start, 'MMM-yy'), value: count });
      this.cessioneData.push({ name: pipe.transform(start, 'MMM-yy'), value: countCessioni });
      this.importoTotaleFattureData.push({ name: pipe.transform(start, 'MMM-yy'), value: countImportoFattura });
      this.importoTotaleFattureCeduteData.push({
        name: pipe.transform(start, 'MMM-yy'),
        value: countImportoFatturaCeduto
      });
    }
    //  console.log(this.lineChartLabels);
    //  console.log(this.timeData);
  }

  generaGraficoIDInvestitore(list, start: Date, end: Date, id: string) {
    let pipe = new DatePipe('en-US');
    /*
    let itarazioni = end.getFullYear() - start.getFullYear();
    console.log(itarazioni);
    let ite = itarazioni * 12;
    if (ite == 0) {
      ite = end.getMonth() - start.getMonth();
      if(ite==0){
        ite=1;
      }
    }
    */

    this.timeData = [];
    this.lineChartLabels = [];
    this.cessioneData = [];
    this.importoTotaleFattureData = [];
    this.importoTotaleFattureCeduteData = [];
    start.setMonth(start.getMonth() - 1);
    for (let i = 0; start <= end; i++) {
      start.setMonth(start.getMonth() + 1);
      var meseLimite = new Date(start);
      meseLimite.setMonth(meseLimite.getMonth() + 1);
      //  console.log('mese attuale=' + pipe.transform(start, 'MMM-yy'));
      //  console.log('mese limite=' + pipe.transform(meseLimite, 'MMM-yy'));
      var count = 0;
      var countCessioni = 0;
      var countImportoFattura = 0;
      var countImportoFatturaCeduto = 0;
      for (var inv of list) {
        var dinv = new Date(inv.dataFattura);
        if (dinv > start && dinv < meseLimite) {
          //  console.log('mese fattura=' + pipe.transform(dinv, 'MMM-yy'));
          if (inv.listaInvestitoriPagamento) {
            var arr: any[] = inv.listaInvestitoriPagamento;
            for (var investitore of arr) {
              if (investitore.split('---')[0] == id) {
                count = count + 1;
                countImportoFattura = countImportoFattura + inv.importoFattura;
                if (inv.status_index == 36) {
                  countCessioni = countCessioni + 1;
                  countImportoFatturaCeduto = countImportoFatturaCeduto + inv.importoFattura;
                }
              }
            }
          }
        }
      }
      //('------');
      this.lineChartLabels.push(pipe.transform(start, 'MMM-yy'));
      this.timeData.push({ name: pipe.transform(start, 'MMM-yy'), value: count });
      this.cessioneData.push({ name: pipe.transform(start, 'MMM-yy'), value: countCessioni });
      this.importoTotaleFattureData.push({ name: pipe.transform(start, 'MMM-yy'), value: countImportoFattura });
      this.importoTotaleFattureCeduteData.push({
        name: pipe.transform(start, 'MMM-yy'),
        value: countImportoFatturaCeduto
      });
    }
    //console.log(this.lineChartLabels);
    //console.log(this.timeData);
  }

  setMonths() {
    let pipe = new DatePipe('en-US');

    for (let i = 0; i < 5; i++) {
      // ciclo mensile
      let dataF = new Date();
      let dataI = new Date();

      dataF.setMonth(dataF.getMonth() - i);
      dataI.setMonth(dataI.getMonth() - 1);
      dataI.setMonth(dataI.getMonth() - i);
      this.lineChartLabels.push(pipe.transform(dataF, 'MMM-yy'));

      for (var data of this.lineChartLabels) {
        this.timeData.push({ name: data, value: 1000 });
        this.cessioneData.push({ name: data, value: 1000 });
        this.importoTotaleFattureCeduteData.push({ name: data, value: 1000 });
        this.importoTotaleFattureData.push({ name: data, value: 1000 });
      }
    }
  }
}
