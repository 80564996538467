import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation-modal-generic',
  templateUrl: './confirmation-modal-generic.component.html',
  styleUrls: ['./confirmation-modal-generic.component.scss']
})
export class ConfirmationModalGenericComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;

  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    console.log('');
  }
}
