import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-lemon-way-response',
  templateUrl: './lemon-way-response.component.html',
  styleUrls: ['./lemon-way-response.component.scss']
})
export class LemonWayResponseComponent implements OnInit {
  response: any;
  constructor(private route: ActivatedRoute, private router: Router) {
    //console.log(this.route.queryParams["value"].prova); Per prendere i parametri
    this.response = this.route.snapshot.paramMap.get('response');
    // console.log(this.response);
  }

  ngOnInit() {}
}
