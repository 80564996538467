import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { WalletFormComponent } from './wallet-form/wallet-form.component';
import { WalletListComponent } from './wallet-list/wallet-list.component';
import { StatsComponent } from './stats/stats.component';
import { NewBankComponent } from './new-originator/new-bank.component';
import { WalletDetailsComponent } from './wallet-details/wallet-details.component';
import { UsersCredentialComponent } from './users-credential/users-credential.component';
import { Utilities } from '@app/utilities/Utilities';
import { NewPartnerComponent } from './new-partner/new-partner.component';
import { NewInvestorComponent } from './new-investor/new-investor.component';
import { UsuryRatesComponent } from '@app/home/usury-rates/usury-rates.component';
import { CreateUsuryRateComponent } from '@app/home/usury-rates/create-usury-rate/create-usury-rate.component';
import { TigranComponent } from '@app/home/tigran/tigran.component';
import { NewServicerComponent } from '@app/home/new-servicer/new-servicer.component';
import { PendingRequestListComponent } from '@app/home/pending-request-list/pending-request-list.component';
import { LemonwayComponent } from './lemonway/lemonway.component';
import { NewcompanyComponent } from './new-company/newcompany/newcompany.component';
import { ReportComponentComponent } from './report-component/report-component.component';
import { VirtualibanUserComponent } from './virtualiban-user/virtualiban-user.component';
import { TigranReportComponent } from './tigran-report/tigran-report.component';
import { DocumentForInvestorComponent } from './document-for-investor/document-for-investor.component';
import { ServicerSpvListComponent } from './servicer-spv/servicer-spv-list/servicer-spv-list.component';
import { ServicerSpvCreateComponent } from './servicer-spv/servicer-spv-create/servicer-spv-create.component';
import { ServicerSpvDetailComponent } from './servicer-spv/servicer-spv-detail/servicer-spv-detail.component';
import { ServicerSpvEditComponent } from '@app/home/servicer-spv/servicer-spv-edit/servicer-spv-edit.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/home/list-wallet', pathMatch: 'full' },
    {
      path: 'home',
      component: HomeComponent,
      children: [
        {
          path: 'create-wallet',
          component: WalletFormComponent
        },
        {
          path: 'pending-requests',
          component: PendingRequestListComponent
        },
        {
          path: 'users-credential',
          component: UsersCredentialComponent
        },
        {
          path: 'list-wallet',
          component: WalletListComponent
        },
        {
          path: 'stats',
          component: StatsComponent
        },
        {
          path: 'new-servicer',
          component: NewServicerComponent
        },
        {
          path: 'servicer-spv',
          component: ServicerSpvListComponent
        },
        {
          path: 'servicer-spv/create',
          component: ServicerSpvCreateComponent
        },
        {
          path: 'servicer-spv/:id/detail',
          component: ServicerSpvDetailComponent
        },
        {
          path: 'servicer-spv/:id/edit',
          component: ServicerSpvEditComponent
        },
        {
          path: 'investor-spv',
          loadChildren: () => import('./investor-spv/investor-spv.module').then(m => m.InvestorSpvModule)
        },
        {
          path: 'bank-spv',
          loadChildren: () => import('./bank-spv/bank-spv.module').then(m => m.BankSpvModule)
        },
        {
          path: 'anagrafiche',
          loadChildren: () => import('../anagrafiche/anagrafiche.module').then(m => m.AnagraficheModule)
        },
        {
          path: 'leads',
          loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule)
        },
        {
          path: 'wallets',
          loadChildren: () => import('../wallets/wallets.module').then(m => m.WalletsModule)
        },
        {
          path: 'reports',
          loadChildren: () => import('../report/report.module').then(m => m.ReportModule)
        },
        {
          path: 'users',
          loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
        },
        {
          path: 'bank-require',
          loadChildren: () => import('./bank-require/bank-require.module').then(m => m.BankRequireModule)
        },
        {
          path: 'new-company',
          component: NewcompanyComponent
        },
        {
          path: 'new-originator',
          component: NewBankComponent
        },
        {
          path: 'new-partner',
          component: NewPartnerComponent
        },
        {
          path: 'new-investor',
          component: NewInvestorComponent
        },
        {
          path: 'wallet-details/:id',
          component: WalletDetailsComponent
        },
        {
          path: 'usury-rates',
          component: UsuryRatesComponent
        },
        {
          path: 'usury-rates/create',
          component: CreateUsuryRateComponent
        },
        {
          path: 'tigran',
          component: TigranComponent
        },
        {
          path: 'lemonway',
          component: LemonwayComponent
        },
        {
          path: 'lemonway/:type',
          component: LemonwayComponent
        },
        {
          path: 'report',
          component: ReportComponentComponent
        },
        {
          path: 'reportTigran',
          component: TigranReportComponent
        },
        {
          path: 'iban',
          component: VirtualibanUserComponent
        },
        {
          path: 'docForInvestor',
          component: DocumentForInvestorComponent
        },
        {
          path: 'anagrafiche',
          loadChildren: () => import('../anagrafiche/anagrafiche.module').then(m => m.AnagraficheModule)
        }
      ],
      data: { title: extract('Home'), role: Utilities.userType[1] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class HomeRoutingModule {}
