import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment.prod';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { MailerService } from '@app/mailer/mailer.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-company-spv-detail',
  templateUrl: './company-spv-detail.component.html',
  styleUrls: ['./company-spv-detail.component.scss']
})
export class CompanySpvDetailComponent implements OnInit {
  id: number;

  entity: {
    denominazione: string;
    firstName: string;
    lastName: string;
    email: string;
    pIva: string;
    fiscalCode: string;
    statusSPV: string;
    expirationSPVDate: string;
  };

  documentKycSPV: [
    {
      tipoDocumento: string;
      nome_file: string;
      descrizione: string;
      dataCaricamento: any;
      url: string;
      user_id: string;
      statusSPV: string;
      uuid: string;
    }
  ];

  private sub: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private firebase: FirebaseTranchedService,
    private sendMail: MailerService,
    private backendAuthService: BackendAuthService
  ) {
    this.entity = {
      denominazione: '',
      firstName: '',
      lastName: '',
      email: '',
      pIva: '',
      fiscalCode: '',
      statusSPV: '',
      expirationSPVDate: ''
    };
  }

  ngOnInit() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.sub = this.route.params.subscribe(params => {
      this.id = params.id; // (+) converts string 'id' to a number
      console.log(params.id);
      this.http
        .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + params.id + '"}', httpOptions)
        .subscribe(data => {
          console.log(data);
          // this.entity = data;
          this.entity.denominazione = data.denominazione;
          this.entity.firstName = data.name;
          this.entity.lastName = data.surname;
          this.entity.email = data.email;
          this.entity.pIva = data.pIva;
          this.entity.fiscalCode = data.fiscalCode;
          this.entity.statusSPV = data.statusSPV;
          this.entity.expirationSPVDate = data.expirationSPVDate;
        });
    });

    this.sub = this.route.params.subscribe(params => {
      this.id = params.id; // (+) converts string 'id' to a number
      console.log(params.id);
      this.http
        .post<any>(
          `${environment.laravelServer}/getAllDocumentByUserId`,
          '{"user_id":"' + params.id + '"}',
          httpOptions
        )
        .subscribe(data => {
          console.log(data);
          // this.entity = data;
          this.documentKycSPV = data.data;
          console.log(this.documentKycSPV);
        });
    });
  }

  goBack() {
    this.router.navigate(['/servicerSPV/companyList']);
  }

  getDownloadURL(trans: string) {
    // console.log(trans);
    const ref = this.storage.ref(trans);
    // console.log(ref);

    ref.getDownloadURL().subscribe(data => {
      // console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', trans);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  changeStatusDoc(value: string, uuid: string) {
    const object = {
      statusSPV: value
    };

    this.firebase.updateEntity(uuid, 'DocumentKycSPV', object);
    this.ngOnInit();
  }

  changeStatusCompany(value: string) {
    const expiration = new Date();

    expiration.setMonth(expiration.getMonth() + 12);

    const object = {
      statusSPV: value,
      expirationSPVDate: expiration
    };

    this.firebase.updateEntity(this.id.toString(), 'Users', object);

    if (value == 'APPROVED') {
      // invio email di notifica al cedente per avvenuta abilitazione KYC
      const body =
        '{\n' +
        '    "to":"' +
        this.entity.email +
        '",\n' +
        '    "firstname":"' +
        this.entity.firstName +
        '",\n' +
        '    "lastname":"' +
        this.entity.lastName +
        '",\n' +
        '    "subject":"Abilitazione KYC",\n' +
        '    "senderCompany":"FX12 Srl",\n' +
        '    "from":"support@incassaora.it",\n' +
        '    "senderName":"Support of Incassaora",\n' +
        '    "templateName":"allowedKYCSPV",\n' +
        '    "denominazioneCedente":"' +
        this.entity.denominazione +
        '",\n' +
        '    "importoFattura":"0' +
        '",\n' +
        '    "dataFattura":"0' +
        '",\n' +
        '    "numeroFattura":"0' +
        '",\n' +
        '    "maturity":"0' +
        '",\n' +
        '    "scontoProposto":"0' +
        '"\n' +
        '}';

      // console.log('body=>', body);

      this.sendMail.sendEmailTemplateByBody(body);
    }

    this.ngOnInit();
  }
}
