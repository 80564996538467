import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-investor-spv',
  templateUrl: './home-investor-spv.component.html',
  styleUrls: ['./home-investor-spv.component.css']
})
export class HomeInvestorSpvComponent implements OnInit {
  isLoading = false;

  constructor() {}

  ngOnInit() {}
}
