import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { DocumentKycSPVModel } from '@app/models/document/DocumentKycSPV.model';
import { environment } from '@env/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as firebase from 'firebase';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  selector: 'app-document-spv',
  templateUrl: './document-spv.component.html',
  styleUrls: ['./document-spv.component.scss']
})
export class DocumentSpvComponent implements OnInit {
  entityCreationForm!: FormGroup;

  uploading: boolean;
  fileToUpload: any;
  uploadPercentage: string;
  visualizza: boolean = true;

  listDocument: any;
  nessunaTrans: boolean;
  data;
  pdfTable: any;

  document: DocumentKycSPVModel;
  documentType: string;
  userSelected: string;

  private modelloKYC = 0;
  private visuraCam = 0;
  private fronIBAN = 0;
  private docIdent = 0;
  private bilancio = 0;
  private attoCost = 0;

  documentKycSPV: [
    {
      tipoDocumento: string;
      nome_file: string;
      descrizione: string;
      dataCaricamento: any;
      url: string;
      user_id: string;
      statusSPV: string;
      uuid: string;
    }
  ];
  percentualeCompletamento: any = 0;

  constructor(
    private storage: AngularFireStorage,
    private firebase: FirebaseTranchedService,
    private afAuth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private http: HttpClient,
    private backendAuthService: BackendAuthService
  ) {
    this.createForm();
    this.document = new DocumentKycSPVModel();
  }

  createForm() {
    this.entityCreationForm = this.formBuilder.group({
      nome: new FormControl('', [Validators.required]),
      descrizione: new FormControl('', [Validators.required])
    });
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  open(name: string, fileToUpload: number) {
    // console.log('carico')
    if (this.entityCreationForm.value.nome != '' && this.entityCreationForm.value.descrizione != '') {
      console.log(this.entityCreationForm.value.nome);
      this.entityCreationForm.get('nome').markAsTouched();
      this.entityCreationForm.get('descrizione').markAsTouched();
      this.document.nome_file = this.entityCreationForm.value.nome;
      this.document.descrizione = this.entityCreationForm.value.descrizione;
      this.document.dataCaricamento = firebase.firestore.FieldValue.serverTimestamp();
      this.document.user_id = this.firebase.loggedUser.id;
      this.document.modified = false;
      this.document.tipoDocumento = this.documentType;
      this.document.statusSPV = 'PENDING';

      const extensionFile = this.fileToUpload.name.split('.').pop();

      /*const today = new Date();
      const pipe = new DatePipe('en-US');
      this.contract.dataCaricamento = pipe.transform(today, 'yyyy-MM-dd');*/
      this.firebase.createDocumentKycSPV(this.document).then(data => {
        // console.log('RESULT=>');
        // console.log(data.id);
        if (data.id) {
          this.document.id = data.id;
          const filePath = this.firebase.loggedUser.id + '-' + data.id + '-DOCUMENT-SPV.' + extensionFile;
          // console.log(filePath);
          this.document.url = filePath;
          if (fileToUpload === 0) {
            this.uploadFileContratto(filePath);
          }
        }
      });
    } else {
      alert('I campi nome e descrizione sono obbligatori');
    }
  }

  uploadFileContratto(filePath: any) {
    const task = this.storage.upload(filePath, this.fileToUpload);

    // console.log(task);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
        })
      )
      .subscribe(data => {
        this.firebase.updateEntity(this.document.id, DocumentKycSPVModel.tableName, { url: filePath });
        // al termine del downolad ricarico la pagina
        this.ngOnInit();
      });
  }

  ngOnInit() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.http
      .post<any>(
        `${environment.laravelServer}/getAllDocumentByUserId`,
        '{"user_id":"' + this.firebase.loggedUser.id + '"}',
        httpOptions
      )
      .subscribe(data => {
        // console.log(data);
        // this.entity = data;
        this.documentKycSPV = data.data;
        this.updatePercetableUpload();
      });
  }

  updatePercetableUpload() {
    this.documentKycSPV.forEach(document_ => {
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == "Carta d'Identità"
      ) {
        this.docIdent = 20;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Scan of a proof of Iban'
      ) {
        this.fronIBAN = 15;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'SchedaKYC'
      ) {
        this.modelloKYC = 20;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Documenti aziendali ufficiali (Visura Camerale e Statuto)'
      ) {
        this.visuraCam = 15;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Bilancio'
      ) {
        this.bilancio = 15;
      }
      if (
        (document_.statusSPV == 'APPROVED' || document_.statusSPV == 'PENDING') &&
        document_.tipoDocumento == 'Atto Costitutivo'
      ) {
        this.attoCost = 15;
      }

      const summa = this.modelloKYC + this.visuraCam + this.fronIBAN + this.docIdent + this.bilancio + this.attoCost;
      this.percentualeCompletamento = summa;
    });
  }
  getDownloadURL(trans: string) {
    // console.log(trans);
    const ref = this.storage.ref(trans);
    // console.log(ref);

    ref.getDownloadURL().subscribe(data => {
      // console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', trans);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  onChange2(value: string) {
    switch (value) {
      case '0': {
        this.documentType = "Carta d'Identità";
        break;
      }
      case '1': {
        this.documentType = 'Proof of adress';
        break;
      }
      case '2': {
        this.documentType = 'Scan of a proof of Iban';
        break;
      }
      case '3': {
        this.documentType = 'Passaporto';
        break;
      }
      case '4': {
        this.documentType = 'Passaporto (outside the European Union)';
        break;
      }
      case '5': {
        this.documentType = 'Atto Costitutivo';
        break;
      }
      case '7': {
        this.documentType = 'Documenti aziendali ufficiali (Visura Camerale e Statuto)';
        break;
      }
      case '11': {
        this.documentType = 'Patente';
        break;
      }
      case '12': {
        this.documentType = 'Status';
        break;
      }
      case '13': {
        this.documentType = 'Bilancio';
        break;
      }
      case '14': {
        this.documentType = 'Altro';
        break;
      }
      case '15': {
        this.documentType = 'SchedaKYC';
        break;
      }
    }
  }
}
