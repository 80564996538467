import { ShowMessageService } from './../core/show-message.service';
import { Assignor } from './../models/Assignor.model';
import { Component, OnInit } from '@angular/core';
import { Utilities } from '@app/utilities/Utilities';
import { ActivatedRoute } from '@angular/router';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { FormGroup, Validators, FormBuilder, ValidatorFn, AbstractControl, FormControl } from '@angular/forms';
import { Wallet } from '@app/models/Wallet.model';
import { Subject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public show: boolean = false;
  public buttonName: any = 'Mostra';
  public wallet: Wallet;
  isLoading = false;
  regions: string[];
  landingLogin!: FormGroup;
  landingForm!: FormGroup;
  isSubmitted = false;
  staticAlertClosed = false;
  errorMessage: string;
  walletId: string;
  partnerId: string;
  register: boolean;
  login: boolean = true;
  assignor: Assignor;
  access: boolean = true;
  error: string | undefined;
  passwordVerify: string;
  walletItems: any[];
  assingorItems: Observable<any[]>;
  messageStop: string = '';
  stop: boolean;
  errorLogin: boolean;
  openBox: boolean = true;
  baseUrl = environment.baseUrl;
  private _error = new Subject<string>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public Loginauth: AngularFireAuth,
    public firebase: FirebaseTranchedService,
    public showService: ShowMessageService
  ) {
    this.passwordVerify = '';
    this._error.subscribe(message => (this.errorMessage = message));

    this._error.pipe(debounceTime(5000)).subscribe(any => (this.errorMessage = null));
    this.wallet = new Wallet();
    this.walletId = this.route.snapshot.paramMap.get('id1');
    this.partnerId = this.route.snapshot.paramMap.get('id2');
    firebase
      .getWalletfromID(this.walletId)
      .then(doc => {
        if (doc.exists) {
          this.wallet = doc.data() as Wallet;
          this.checkWhatBox();
        } else {
        }
      })
      .catch(error => {});

    this.regions = Utilities.regions;
    this.createFormLogin();
  }

  checkWhatBox() {
    this.access = false;
    if (this.firebase.loggedUser) {
      if (!this.firebase.isCompany()) {
        this.stop = true;
        this.messageStop = "Solo i fornitori possono accettare l'invito al portafoglio";
        this.login = false;
        this.openBox = false;
        this.register = false;
        this.access = true;
      } else if (this.verifyWaleet()) {
        this.stop = true;
        this.messageStop = 'Hai già effettuato la richiesta per questo portafoglio';
        this.login = false;
        this.openBox = false;
        this.register = false;
        this.access = true;
      } else {
        this.stop = false;
        this.access = false;
      }
    } else {
      this.stop = false;
      this.access = false;
      this.login = true;
      this.openBox = true;
    }
  }

  openFaq() {
    window.open('#/faq');
  }
  openRegistrazione() {
    window.open('#/registration');
  }

  // function login
  loginSubmit() {
    this.isLoading = true;
    this.Loginauth.auth
      .signInWithEmailAndPassword(this.landingLogin.value.username, this.landingLogin.value.password)
      .then(user => {
        this.firebase
          .getUserByID(user.user.uid)

          .then(doc => {
            if (doc.exists) {
              const myUser = doc.data();
              delete myUser.password;
              this.firebase.loggedUser = myUser;
              this.firebase.loggedUser.id = user.user.uid;

              localStorage.setItem('sessioneYellow', JSON.stringify(myUser));
              this.checkWhatBox();
            } else {
              this.logOut();
            }
          })
          .catch(error => {
            this.logOut();
          });
      })
      .catch(error => {
        this.error = 'Username o password errati!';
        this.errorLogin = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
  private logOut() {
    this.Loginauth.auth.signOut();
    this.firebase.loggedUser = undefined;
    localStorage.removeItem('sessioneYellow');
    this.checkWhatBox();
  }
  // form login
  createFormLogin() {
    this.landingLogin = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    });
  }
  submit() {
    this.isLoading = true;
    // console.log(this.landingForm.valid);
    //console.log(this.landingForm);
    if (!this.landingForm.valid && !this.verifyPass()) {
      this.isLoading = false;
      this.landingForm.get('assignorPassword').markAsTouched();
      this.landingForm.get('assignorRPassword').markAsTouched();
      this.landingForm.get('assignorUsername').markAsTouched();
      this.landingForm.get('assignorName').markAsTouched();
      this.landingForm.get('denominazione').markAsTouched();
      this.landingForm.get('assignorPec').markAsTouched();
      this.landingForm.get('phone_number').markAsTouched();
      this.landingForm.get('pIva').markAsTouched();
      this.landingForm.get('codiceFiscale').markAsTouched();
      this.landingForm.get('assignorCognome').markAsTouched();
      this.landingForm.get('legalAddressStreet').markAsTouched();
      this.landingForm.get('legalAddressCity').markAsTouched();
      this.landingForm.get('legalAddressProvince').markAsTouched();
      this.landingForm.get('legalAddressCAP').markAsTouched();
      this.landingForm.get('cognomeReferenteAmm').markAsTouched();
      this.landingForm.get('refName').markAsTouched();
      this.landingForm.get('originatorSolicitorBirthdate').markAsTouched();
      this.landingForm.get('REA_CODE').markAsTouched();
      this.landingForm.get('capLegale').markAsTouched();
      return false;
    } else {
      if (this.landingForm.value.codiceFiscale == '' && this.landingForm.value.privato) {
        alert('inserire codice fiscale');
        return;
      }
      this.landingForm.value.assignorName = this.landingForm.value.refName;
      this.landingForm.value.cognome = this.landingForm.value.cognomeReferenteAmm;
      if (this.landingForm.value.privato) {
        this.landingForm.value.viaLeg = this.landingForm.value.legalAddressStreet;
        this.landingForm.value.comuneLeg = this.landingForm.value.legalAddressCity;
        this.landingForm.value.capLegale = this.landingForm.value.capLegale;
        this.landingForm.value.provinciaLeg = this.landingForm.value.legalAddressProvince;
        this.landingForm.value.nazioneLeg = this.landingForm.value.nazione;
      }

      if (
        this.landingForm.value.legalAddressStreet == '' ||
        this.landingForm.value.legalAddressCity == '' ||
        this.landingForm.value.legalAddressProvince == '' ||
        this.landingForm.value.legalAddressCAP == ''
      ) {
        alert('sede legale non valida');
        return;
      }

      if (this.landingForm.value.privato) {
        this.landingForm.value.name =
          this.landingForm.value.refName + '  ' + this.landingForm.value.cognomeReferenteAmm;
      } else {
        if (this.landingForm.value.denominazione) {
          this.landingForm.value.name = this.landingForm.value.denominazione;
        } else {
          alert('inserire denominazione');
          return;
        }
      }

      if (this.landingForm.value.REA_CODE == '' && !this.landingForm.value.privato) {
        alert('REA CODE non valido');
        return;
      }

      if (this.landingForm.value.pIva == '' && !this.landingForm.value.privato) {
        alert('inserire Partita iva');
        return;
      }
      if (this.landingForm.value.name == '' && !this.landingForm.value.privato) {
        alert('inserire denominazione');
        return;
      }

      if (this.landingForm.value.privato) {
        this.landingForm.value.pIva = this.landingForm.value.codiceFiscale;
      }

      if (this.landingForm.value.cognomeReferenteAmm != '' && this.landingForm.value.cognomeReferenteAmm != undefined) {
        this.landingForm.value.surname = this.landingForm.value.cognomeReferenteAmm;
      }
      if (this.landingForm.value.nazioneLeg != '' && this.landingForm.value.nazioneLeg != undefined) {
        this.landingForm.value.nazione = this.landingForm.value.nazioneLeg;
      }

      let objectToSave = this.firebase.createAssignor(this.landingForm);
      this.firebase
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.login = true;
          // this.openBox = true;
          this.register = false;
          this.isLoading = false;
          this.firebase.saveUserDocAfterCreateAndWelcome(data.user.uid, objectToSave);
        })
        .catch(error => {
          // Handle Errors here.
          this.errorMessage = error.message;
          this.isLoading = false;
          // ...
        });
    }
  }

  // confronto password
  veirifyPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return control.value != this.passwordVerify ? { verifyPassword: true } : null;
    };
  }
  onChangePassoword(event: string) {
    if (event == '') {
      this.passwordVerify = '';
    } else {
      this.passwordVerify = event;
    }
  }

  onChange(event: any) {}
  createForm() {
    this.landingForm = this.formBuilder.group({
      assignorUsername: new FormControl('', [Validators.required]),
      assignorPassword: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      assignorRPassword: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      assignorName: new FormControl(''),
      denominazione: new FormControl(''),
      assignorPec: new FormControl('', [Validators.required]),
      surname: new FormControl(''),
      phone_number: new FormControl('', [Validators.required]),
      pIva: new FormControl(''),
      privacy: new FormControl('', [Validators.requiredTrue]),
      codiceFiscale: new FormControl('', [Validators.required]),
      assignorCognome: new FormControl(''),
      legalAddressStreet: new FormControl('', [Validators.required]),
      legalAddressCity: new FormControl('', [Validators.required]),
      legalAddressProvince: new FormControl('', [Validators.required]),
      legalAddressCAP: new FormControl('', [Validators.required]),
      cognomeReferenteAmm: new FormControl('', [Validators.required]),
      refName: new FormControl('', [Validators.required]),
      refPhone: new FormControl('', [Validators.required]),
      indirizzo: new FormControl(''),
      nazione: new FormControl(''),
      refMail: new FormControl('', [Validators.required]),
      originatorSolicitorBirthdate: new FormControl('', [Validators.required]),
      REA_CODE: new FormControl(''),
      privato: new FormControl(false),
      viaLeg: new FormControl(''),
      comuneLeg: new FormControl(''),
      capLegale: new FormControl(''),
      provinciaLeg: new FormControl(''),
      nazioneLeg: new FormControl(''),
      iban: new FormControl(''),
      legalAddressDataNascita: new FormControl('')
    });
  }
  verifyWaleet() {
    if (this.firebase.loggedUser) {
      return this.firebase.loggedUser.listWallet.includes(this.walletId);
    } else {
      return false;
    }
  }
  acceptWallet() {
    this.access = false;

    this.isSubmitted = true;

    if (!this.firebase.loggedUser.listWallet) {
      this.firebase.loggedUser.listWallet = [];
    }
    this.firebase.loggedUser.listWallet.push(this.walletId);

    this.firebase.modUser(this.firebase.loggedUser);

    setTimeout(() => {
      this.router.navigate(['/cedente/list-wallet']);
    }, 2000);
  }

  ngOnInit() {
    this.createForm();
  }

  goHomepage() {
    if (this.firebase.isAdmin()) {
      this.router.navigate(['/home/list-wallet']);
    } else if (this.firebase.isOriginator()) {
      this.router.navigate(['/originator/list-wallet']);
    } else if (this.firebase.isCompany()) {
      this.router.navigate(['/cedente/list-wallet']);
    } else if (this.firebase.isPartner()) {
      this.router.navigate(['/partner/dashboard']);
    }
  }

  get assignorPassword() {
    return this.landingForm.get('assignorPassword');
  }
  get assignorRPassword() {
    return this.landingForm.get('assignorRPassword');
  }
  get assignorUsername() {
    return this.landingForm.get('assignorUsername');
  }
  get assignorName() {
    return this.landingForm.get('assignorName');
  }
  get denominazione() {
    return this.landingForm.get('denominazione');
  }

  get assignorPec() {
    return this.landingForm.get('assignorPec');
  }

  get getTelefono() {
    return this.landingForm.get('phone_number');
  }

  get pIva() {
    return this.landingForm.get('pIva');
  }
  get username() {
    return this.landingLogin.get('username');
  }
  get password() {
    return this.landingLogin.get('password');
  }
  get codiceFiscale() {
    return this.landingForm.get('codiceFiscale');
  }
  get legalAddressStreet() {
    return this.landingForm.get('legalAddressStreet');
  }

  get assignorCognome() {
    return this.landingForm.get('assignorCognome');
  }
  get legalAddressCity() {
    return this.landingForm.get('legalAddressCity');
  }
  get legalAddressProvince() {
    return this.landingForm.get('legalAddressProvince');
  }
  get legalAddressCAP() {
    return this.landingForm.get('legalAddressCAP');
  }

  get cognomeReferenteAmm() {
    return this.landingForm.get('cognomeReferenteAmm');
  }
  get refName() {
    return this.landingForm.get('refName');
  }
  get refPhone() {
    return this.landingForm.get('refPhone');
  }
  get refMail() {
    return this.landingForm.get('refMail');
  }

  get originatorSolicitorBirthdate() {
    return this.landingForm.get('originatorSolicitorBirthdate');
  }
  get REA_CODE() {
    return this.landingForm.get('REA_CODE');
  }

  verifyPass() {
    console.log(this.landingForm.get('assignorPassword').value);
    console.log(this.landingForm.get('assignorUsername').value);

    if (
      this.landingForm.get('assignorPassword').value == this.landingForm.get('assignorUsername').value ||
      this.landingForm.get('assignorPassword').value == this.landingForm.get('refName').value ||
      this.landingForm.get('assignorPassword').value == this.landingForm.get('cognomeReferenteAmm').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
