import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { MailerService } from '@app/mailer/mailer.service';
import { MailText } from '@app/utilities/MailText';
import { Utilities } from '@app/utilities/Utilities';
import { ShowMessageService } from './../core/show-message.service';
import { environment } from '@env/environment.prod';

import * as bcrypt from 'bcryptjs';

@Component({
  selector: 'app-registrazione',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  creationForm!: FormGroup;
  isSubmitted = false;
  investorSelected = false;
  adminSelected = false;
  originatorSelected = false;
  termini = false;
  partnerSelected = false;
  companySelected = false;
  servicerSelected = false;
  bottone = false;

  type: string;
  controlloType: boolean = true;
  errorMessage: string = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private sendMail: MailerService,
    public showService: ShowMessageService
  ) {
    this.type = this.route.snapshot.paramMap.get('type');
    //  console.log(this.type);

    if (this.type == 'investor') {
      this.investorSelected = true;
      this.controlloType = false;
    }
    if (this.type == 'originator') {
      this.originatorSelected = true;
      this.controlloType = false;
    }
    if (this.type == 'partner') {
      this.partnerSelected = true;
      this.controlloType = false;
    }
    if (this.type == 'company') {
      this.companySelected = true;
      this.controlloType = false;
    }
    this.termini = false;

    this.createForm();
  }

  goHomepage() {
    this.router.navigate(['']);
  }

  ngOnInit() {}

  createForm() {
    this.creationForm = this.formBuilder.group({
      type: new FormControl(''),
      servicerNome: new FormControl(''),
      servicerCognome: new FormControl(''),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
      ]),
      denominazione: new FormControl(''),
      partitaIVA: new FormControl(''),
      via: new FormControl(''),
      comune: new FormControl(''),
      provincia: new FormControl(''),
      nazione: new FormControl('---'),
      sitoWeb: new FormControl(''),
      pec: new FormControl(''),
      codiceFiscale: new FormControl(''),
      privato: new FormControl(false),
      viaSedeO: new FormControl(''),
      comuneSedeO: new FormControl(''),
      provinciaSedeO: new FormControl(''),
      cognomeLegaleR: new FormControl(''),
      nomeLegaleR: new FormControl(''),
      nascitaLegaleR: new FormControl(''),
      dataLegaleR: new FormControl(''),
      termini: new FormControl(false),
      capLegale: new FormControl(''),
      cognomeReferenteAmm: new FormControl(''),
      nomeReferenteAmm: new FormControl(''),
      telReferenteAmm: new FormControl(''),
      mailReferenteAmm: new FormControl(''),
      tel: new FormControl('', [Validators.required]),
      indirizzo: new FormControl(''),
      data_assunzione: new FormControl(''),
      data_contratto: new FormControl(''),
      originatorSolicitorBirthdate: new FormControl(''),
      legalAddressStreet: new FormControl(''),
      legalAddressCity: new FormControl(''),
      legalAddressProvince: new FormControl(''),
      legalAddressCAP: new FormControl(''),
      REA_CODE: new FormControl(''),
      viaLeg: new FormControl(''),
      comuneLeg: new FormControl(''),
      provinciaLeg: new FormControl(''),
      nazioneLeg: new FormControl(''),
      legalAddressDataNascita: new FormControl(''),
      iban: new FormControl('')
    });
  }

  submit() {
    this.isSubmitted = true;
    if (!this.termini) {
      alert('devi approvare i termini prima di poter iscriverti');
    }

    if (!this.investorSelected && !this.originatorSelected && !this.partnerSelected && !this.companySelected) {
      this.showService.showRegistazione = true;
      return;
    }
    //  console.log(this.creationForm);
    if (!this.creationForm.valid && !this.verifyPass) {
      this.creationForm.get('servicerNome').markAsTouched();
      this.creationForm.get('servicerCognome').markAsTouched();
      this.creationForm.get('email').markAsTouched();
      this.creationForm.get('password').markAsTouched();
      this.creationForm.get('denominazione').markAsTouched();
      this.creationForm.get('partitaIVA').markAsTouched();
      if (
        (this.originatorSelected || this.partnerSelected || this.companySelected) &&
        this.creationForm.valid['partitaIVA'] == '' &&
        !this.creationForm.value.privato
      ) {
        this.creationForm.get('partitaIVA').markAsTouched();
        this.showService.showRegistazione = true;
      }
      this.showService.showRegistazione = true;
    } else {
      if (this.investorSelected || this.originatorSelected) {
        this.creationForm.value.servicerNome = this.creationForm.value.nomeLegaleR;
        this.creationForm.value.servicerCognome = this.creationForm.value.cognomeLegaleR;
      }
      if (this.companySelected) {
        this.creationForm.value.servicerNome = this.creationForm.value.nomeReferenteAmm;
        this.creationForm.value.servicerCognome = this.creationForm.value.cognomeReferenteAmm;
      }
      if (this.creationForm.value.privato && this.creationForm.value.codiceFiscale == '') {
        alert('inserire un codice fiscale valido');
        return;
      }
      if (this.creationForm.value.privato && this.investorSelected) {
        this.creationForm.value.denominazione =
          this.creationForm.value.nomeLegaleR + '  ' + this.creationForm.value.cognomeLegaleR;
      }
      if (this.creationForm.value.privato && this.companySelected) {
        this.creationForm.value.denominazione =
          this.creationForm.value.nomeReferenteAmm + '  ' + this.creationForm.value.cognomeReferenteAmm;
      }

      if (this.creationForm.value.privato && this.investorSelected) {
        this.creationForm.value.viaLeg = this.creationForm.value.via;
        this.creationForm.value.comuneLeg = this.creationForm.value.comune;
        this.creationForm.value.provinciaLeg = this.creationForm.value.provincia;
        this.creationForm.value.nazioneLeg = this.creationForm.value.nazione;
      }

      if (this.creationForm.value.privato && this.companySelected) {
        this.creationForm.value.viaLeg = this.creationForm.value.legalAddressStreet;
        this.creationForm.value.comuneLeg = this.creationForm.value.legalAddressCity;
        this.creationForm.value.provinciaLeg = this.creationForm.value.legalAddressProvince;
        this.creationForm.value.nazioneLeg = this.creationForm.value.nazione;
      }

      if (!this.creationForm.value.privato && this.creationForm.value.partitaIVA == '') {
        alert('inserire un partitaIVA valida');
        return;
      }

      if (this.creationForm.value.denominazione == '') {
        alert('inserire tutti i campi obbligatori');
        return;
      }

      if (
        (this.originatorSelected || this.partnerSelected || this.companySelected) &&
        this.creationForm.valid['partitaIVA'] == '' &&
        !this.creationForm.value.privato
      ) {
        this.creationForm.get('partitaIVA').markAsTouched();
        this.showService.showRegistazione = true;
      }

      if (
        this.investorSelected &&
        (this.creationForm.value.via == '' ||
          this.creationForm.value.comune == '' ||
          this.creationForm.value.provincia == '' ||
          this.creationForm.value.cognomeLegaleR == '' ||
          this.creationForm.value.nomeLegaleR == '' ||
          this.creationForm.value.nascitaLegaleR == '' ||
          this.creationForm.value.dataLegaleR == '' ||
          this.creationForm.value.nazione == '---')
      ) {
        alert('inserire tutti i campi obbligatori');
        return;
      }

      if (this.creationForm.value.privato) {
        this.creationForm.value.partitaIVA = this.creationForm.value.codiceFiscale;
      }

      if (
        this.companySelected &&
        (this.creationForm.value.legalAddressStreet == '' ||
          this.creationForm.value.legalAddressCity == '' ||
          this.creationForm.value.legalAddressProvince == '' ||
          this.creationForm.value.legalAddressCAP == '' ||
          this.creationForm.value.nazione == '---')
      ) {
        alert('sede legale non valida');
        return;
      }
      if (!this.creationForm.value.privato && this.creationForm.value.REA_CODE == '') {
        alert('REA CODE non valido');
        return;
      }

      if (
        (this.originatorSelected || this.partnerSelected || this.companySelected) &&
        this.creationForm.valid['denominazione'] == '' &&
        !this.creationForm.value.privato
      ) {
        this.creationForm.get('denominazione').markAsTouched();
        this.showService.showRegistazione = true;
      }

      if (
        (this.originatorSelected || this.partnerSelected || this.companySelected) &&
        this.creationForm.valid['denominazione'] == '' &&
        !this.creationForm.value.privato
      ) {
        this.creationForm.get('denominazione').markAsTouched();
        this.showService.showRegistazione = true;
      }

      let arr = [];
      /*
      if(this.creationForm.value.privato){
        this.creationForm.value.partitaIVA=this.creationForm.value.codiceFiscale;
      }
      */
      if (this.companySelected) {
        this.creationForm.value['type'] = 'company';
        arr.push('company');
        //  console.log(this.creationForm);
      }
      if (this.investorSelected) {
        this.creationForm.value['type'] = 'investitore';
        arr.push('investor');
      }
      if (this.partnerSelected) {
        this.creationForm.value['type'] = 'partner';
        arr.push('partner');
      }
      if (this.originatorSelected) {
        this.creationForm.value['type'] = 'debitore';
        arr.push('originator');
      }
      this.firebaseService.returnEmailListByRole(Utilities.userType[1]).subscribe(data => {
        this.sendMail.multisendEmail(data, 'Nuovo Utente', MailText.getApprovaUtente());

        const params = {
          firstname: undefined,
          lastname: undefined,
          subject: undefined,
          templateName: undefined,
          to: undefined
        };

        params.to = this.creationForm.value.email;
        params.firstname = this.creationForm.value.nomeLegaleR;
        params.lastname = this.creationForm.value.cognomeLegaleR;
        if (this.companySelected) {
          params.firstname = this.creationForm.value.nomeReferenteAmm;
          params.lastname = this.creationForm.value.cognomeReferenteAmm;
        }
        params.subject = 'Registrazione IncassaOra';
        params.templateName = 'registrationSuccessFull';

        this.sendMail.sendEmailTemplate(params);
      });

      this.bottone = true;

      const objectToSave = this.firebaseService.createGenericUser(this.creationForm);
      objectToSave.verified = false;
      objectToSave.roles = arr;
      // console.log(objectToSave);
      this.firebaseService
        .createUser(objectToSave.email, objectToSave.password)
        .then(data => {
          this.firebaseService.saveUserDocAfterCreate(data.user.uid, objectToSave);

          // @@ hydra - 2023-01-31 - PASSWORD HASH
          try {
            const newPass = bcrypt.hashSync(objectToSave.password, environment.saltPass);
            this.firebaseService.createPasswordNewHash(data.user.uid, [newPass]);
          } catch (e) {}
          // *********************************************************************************************

          this.router.navigate(['/#/login']);
        })
        .catch(error => {
          const errorCode = error.code;
          this.errorMessage = error.message;
        });
    }
  }

  onChange(event: any) {}
  onChangeTermini(event: any) {
    //  console.log(this.termini);
  }

  termCondition() {
    window.open('https://incassaora.it/termini-e-condizioni/');
  }
  termConditionLemonWay() {
    window.open('https://www.lemonway.com/it/condizioni-generali-di-utilizzo/');
  }

  get email() {
    return this.creationForm.get('email');
  }

  get password() {
    return this.creationForm.get('password');
  }

  get servicerNome() {
    return this.creationForm.get('servicerNome');
  }

  get servicerCognome() {
    return this.creationForm.get('servicerCognome');
  }

  get partitaIVA() {
    return this.creationForm.get('partitaIVA');
  }

  get denominazione() {
    return this.creationForm.get('denominazione');
  }

  verifyPass() {
    console.log(this.creationForm.get('password').value);
    console.log(this.creationForm.get('email').value);

    if (
      this.creationForm.get('password').value == this.creationForm.get('email').value ||
      this.creationForm.get('password').value == this.creationForm.get('cognomeLegaleR').value ||
      this.creationForm.get('password').value == this.creationForm.get('nomeLegaleR').value
    ) {
      console.log('Email and password is same');
      alert("La password non può essere uguale all' email, al nome o al cognome");
      return false;
    } else {
      console.log('Email and password is different');
      return true;
    }
  }
}
