import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeInvestorSpvRoutingModule } from './home-investor-spv-routing.module';
import { HomeInvestorSpvComponent } from './home-investor-spv.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';

import { DataTablesModule } from 'angular-datatables';
import { DocumentFundsComponent } from './document-funds/document-funds.component';

@NgModule({
  declarations: [HomeInvestorSpvComponent, InvoiceListComponent, DocumentFundsComponent],
  imports: [CommonModule, HomeInvestorSpvRoutingModule, SharedModule, FormsModule, DataTablesModule]
})
export class HomeInvestorSpvModule {}
