import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pointReplacer'
})
export class PointReplacerPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value) {
      let toFind = ',';
      let reg = new RegExp(toFind, 'g');
      return value.replace(reg, '.');
    }
    return '';
  }
}
