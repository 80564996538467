import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeBankSpvComponent } from './home-bank-spv.component';
import { InvoiceListBankComponent } from './invoice-list/invoice-list.component';
import { Utilities } from '@app/utilities/Utilities';
import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { FundsRequestComponent } from './funds-request/funds-request.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/bankSPV/invoiceList', pathMatch: 'full' },
    {
      path: 'bankSPV',
      component: HomeBankSpvComponent,
      children: [
        {
          path: 'invoiceList',
          component: InvoiceListBankComponent
        },
        {
          path: 'request',
          component: FundsRequestComponent
        }
      ],
      data: { title: extract('bankSPV'), role: Utilities.userType[9] }
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeBankSpvRoutingModule {}
