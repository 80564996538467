import { Utilities } from '@app/utilities/Utilities';
import { User } from './User.model';
export class ServicerSPV extends User {
  lastName: string;
  data_assunzione: string;
  data_contratto: string;
  firstName: string;
  city: string;
  province: string;
  fiscal_code: string;
  data_nascita: any;
  ragioneSociale: any;
  cap: any;
  country: any;
  pec: any;
  abi: any;
  rea: any;
  piva: any;

  constructor() {
    super();
  }
}
