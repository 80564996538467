import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestorSPV } from '@app/models/InvestorSPV.model';

import { environment } from '@env/environment.prod';
import { InvestorSpvService } from '../investor-spv.service';
import { BackendAuthService } from '@app/core/backend-auth.service';

@Component({
  templateUrl: './investor-spv-detail.component.html',
  styleUrls: ['./investor-spv-detail.component.scss']
})
export class InvestorSpvDetailComponent implements OnInit {
  id: number;

  entity: InvestorSPV;
  private sub: any;
  dropdownList: any[];

  private servicersAssoc: any[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private myService: InvestorSpvService,
    private backendAuthService: BackendAuthService
  ) {
    this.entity = new InvestorSPV();
    this.servicersAssoc = [];

    this.dropdownList = this.myService.data;
  }

  ngOnInit() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };

    this.sub = this.route.params.subscribe(params => {
      this.id = params.id; // (+) converts string 'id' to a number
      console.log(params.id);
      this.http
        .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + params.id + '"}', httpOptions)
        .subscribe(data => {
          console.log(data);
          // this.entity = data;
          this.entity.dataContrFinanzmnt = data.dataContrFinanzmnt;
          this.entity.firstName = data.name;
          this.entity.ragioneSociale = data.ragioneSociale;
          this.entity.lastName = data.lastName;
          this.entity.email = data.email;
          this.entity.pec = data.pec;
          this.entity.iban = data.iban;
          this.entity.telefono = data.telefono;
          this.entity.indirizzo = data.indirizzo;
          this.entity.city = data.city;
          this.entity.province = data.province;
          this.entity.cap = data.cap;
          this.entity.piva = data.piva;
          this.entity.rea = data.rea;
          this.entity.dataAssunzione = data.dataAssunzione;
          this.entity.dataTermineContratto = data.dataTermineContratto;
          this.entity.servicersSPV = data.servicersSPV;

          console.log('USER=>', this.entity);

          data.servicersSPV.forEach(item => {
            console.log('ITEM=>', item);
            this.http
              .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + item + '"}', httpOptions)
              .subscribe(data2 => {
                console.log(data2);
                this.servicersAssoc.push(data2.email);
              });
          });
        });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  edit() {
    this.router.navigate(['/home/investor-spv/' + this.id + '/edit']);
    // this.router.navigate(['./edit']);
  }

  goBack() {
    this.router.navigate(['/home/investor-spv/']);
  }
}
