import { Component, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { SearchForSubjectResponse } from '@app/tigran/tigran';
import { Utilities } from '@app/utilities/Utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SmsService } from '@app/sms/sms.service';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { NgbdModalConfirmComponent } from '../wallet-details/request-detail-modal/request-detail-modal.component';
import { ShowMessageService } from '@app/core/show-message.service';

@Component({
  selector: 'app-virtualiban-user',
  templateUrl: './virtualiban-user.component.html',
  styleUrls: ['./virtualiban-user.component.scss']
})
export class VirtualibanUserComponent implements OnInit {
  vatNumberForm!: FormGroup;
  currentVatNumber: string;
  showWarningMessage = false;
  isLoading = false;
  morescore: string;
  probdef: string;
  listaGenerale: any[];
  listaDocumenti: any[];
  userSelected: string;
  country: string;
  documentType: number;
  fileToUpload: any;
  uploading: boolean;
  uploadPercentage: string;
  base64: any;
  privato: boolean;
  tipoDocumento = Utilities.tipoDocumento;
  statoDocumento = Utilities.statoDocumento;
  error: string;

  virtualIban: string;
  idIban: string;

  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private http: HttpClient,
    private sms: SmsService,
    private ls: LemonwayService,
    private _modalService: NgbModal,
    public showService: ShowMessageService
  ) {
    this.createForm();
    this.country = 'IT';
    this.listaGenerale = [];
    this.userSelected = '0';
    this.documentType = 100;
    firebaseService.getUsersByRole(Utilities.userType[5]).subscribe(data => {
      for (let entry of data) {
        if (entry.lemonwayAccount == true && entry.statoLemonway == Utilities.statoLemon[2]) {
          this.listaGenerale.push(entry);
        }
      }
    });
    firebaseService.getUsersByRole(Utilities.userType[2]).subscribe(data => {
      for (let entry of data) {
        if (entry.lemonwayAccount == true && entry.statoLemonway == Utilities.statoLemon[2]) {
          this.listaGenerale.push(entry);
        }
      }
    });
    firebaseService.getUsersByRole(Utilities.userType[3]).subscribe(data => {
      for (let entry of data) {
        if (entry.lemonwayAccount == true && entry.statoLemonway == Utilities.statoLemon[2]) {
          this.listaGenerale.push(entry);
        }
      }
    });
    // console.log(this.listaGenerale);
  }
  ngOnInit() {}

  open() {
    this.isLoading = true;
    if (this.userSelected == '0') {
      alert("seleziona l'utente");
      return;
    }

    this.firebaseService.getUserByID(this.userSelected).then(doc => {
      //INTERNAL SERVER ERROR COMPRENDERE LA NATURA DELL ERRORE
      if (doc.exists) {
        const user = doc.data();
        if (user.isPrivato == true) {
          this.ls.createDedicateVirtualIban(user.fiscalCode, this.country).subscribe(data => {
            // console.log(data);

            if (data.id == 0 || data.iban == null) {
              if (user.virtualIban) {
                this.ls.infoAccount(user.fiscalCode).subscribe(data2 => {
                  //   console.log(data2);
                });
                this.virtualIban = user.virtualIban;
                this.idIban = user.idvirtualIban;
                alert(
                  "L'iban di questo utente è IBAN:" +
                    user.virtualIban +
                    "   L'id del virtual iban è ID:" +
                    user.idvirtualIban
                );
              } else {
                alert('Ci sono stati dei problemi nel caricamento dei dati' + data.error.message);
              }
            } else {
              user.virtualIban = data.iban;
              user.idvirtualIban = data.id;
              this.virtualIban = data.iban;
              this.idIban = data.id;
              this.firebaseService.modUserById(user, user.id);
              alert("L'iban di questo utente è IBAN:" + data.iban + "   L'id del virtual iban è ID:" + data.id);
              this.showService.showIbanCreato = true;
            }
          });
        } else {
          if (user.roles[0] == 'company') {
            this.ls.createDedicateVirtualIban(user.pIva, this.country).subscribe(data => {
              // console.log(data);
              this.ls.infoAccount(user.pIva).subscribe(data2 => {
                // console.log(data2);
              });
              if (data.id == 0 || data.iban == null) {
                if (user.virtualIban) {
                  this.virtualIban = user.virtualIban;
                  this.idIban = user.idvirtualIban;
                  alert(
                    "L'iban di questo utente è IBAN:" +
                      user.virtualIban +
                      "   L'id del virtual iban è ID:" +
                      user.idvirtualIban
                  );
                } else {
                  alert('Ci sono stati dei problemi nel caricamento dei dati' + data.error.message);
                }
              } else {
                user.virtualIban = data.iban;
                user.idvirtualIban = data.id;
                this.virtualIban = data.iban;
                this.idIban = data.id;
                this.firebaseService.modUserById(user, user.id);
                alert("L'iban di questo utente è IBAN:" + data.iban + "   L'id del virtual iban è ID:" + data.id);
                this.showService.showIbanCreato = true;
              }
            });
          }
          if (user.roles[0] == 'investor') {
            this.ls.createDedicateVirtualIban(user.investorPIva, this.country).subscribe(data => {
              // console.log(data);
              this.ls.infoAccount(user.investorPIva).subscribe(data2 => {
                //  console.log(data2);
              });
              if (data.id == 0 || data.iban == null) {
                if (user.virtualIban) {
                  this.virtualIban = user.virtualIban;
                  this.idIban = user.idvirtualIban;
                  alert(
                    "L'iban di questo utente è IBAN:" +
                      user.virtualIban +
                      "   L'id del virtual iban è ID:" +
                      user.idvirtualIban
                  );
                } else {
                  alert('Ci sono stati dei problemi nel caricamento dei dati' + data.error.message);
                }
              } else {
                user.virtualIban = data.iban;
                user.idvirtualIban = data.id;
                this.virtualIban = data.iban;
                this.idIban = data.id;
                this.firebaseService.modUserById(user, user.id);
                alert("L'iban di questo utente è IBAN:" + data.iban + "   L'id del virtual iban è ID:" + data.id);
                this.showService.showIbanCreato = true;
              }
            });
          }
          if (user.roles[0] == 'originator') {
            this.ls.createDedicateVirtualIban(user.originatorPIva, this.country).subscribe(data => {
              //console.log(data);
              this.ls.infoAccount(user.originatorPIva).subscribe(data2 => {
                // console.log(data2);
              });
              if (data.id == 0 || data.iban == null) {
                if (user.virtualIban) {
                  this.virtualIban = user.virtualIban;
                  this.idIban = user.idvirtualIban;
                  alert(
                    "L'iban di questo utente è IBAN:" +
                      user.virtualIban +
                      "   L'id del virtual iban è ID:" +
                      user.idvirtualIban
                  );
                } else {
                  alert('Ci sono stati dei problemi nel caricamento dei dati' + data.error.message);
                }
              } else {
                user.virtualIban = data.iban;
                user.idvirtualIban = data.id;
                this.virtualIban = data.iban;
                this.idIban = data.id;
                this.firebaseService.modUserById(user, user.id);
                alert("L'iban di questo utente è IBAN:" + data.iban + "   L'id del virtual iban è ID:" + data.id);
                this.showService.showIbanCreato = true;
              }
            });
          }
        }
      }
    });
    this.isLoading = false;
  }

  onChange(event: any) {
    //console.log(event);
    this.firebaseService.getUserByID(this.userSelected).then(data => {
      if (data.exists) {
        const us = data.data();
        //console.log(us);
        if (us.roles[0] == 'investor' && !us.isPrivato) {
          this.privato = false;
          this.ls.infoDocument(us.investorPIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'company' && !us.isPrivato) {
          this.privato = false;
          this.ls.infoDocument(us.pIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'investor' && us.isPrivato) {
          this.privato = true;
          this.ls.infoDocument(us.fiscalCode).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'company' && us.isPrivato) {
          this.privato = true;
          this.ls.infoDocument(us.fiscalCode).subscribe(data => {
            //(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
        if (us.roles[0] == 'originator') {
          this.privato = true;
          this.ls.infoDocument(us.originatorPIva).subscribe(data => {
            //console.log(data.documents);
            this.listaDocumenti = data.documents;
          });
        }
      }
    });
  }
  onChange2(event: any) {
    // console.log(this.country);
  }

  createForm() {
    this.vatNumberForm = this.formBuilder.group({
      vatNumber: new FormControl('', Validators.required),
      user: new FormControl('')
    });
  }

  get vatNumber() {
    return this.vatNumberForm.get('vatNumber');
  }
}

const MODALS: { [name: string]: Type<any> } = {
  focusFirst: NgbdModalConfirmComponent
};
