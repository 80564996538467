import { Component, OnInit, Input, Type, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Invoice } from '@app/models/Invoice.model';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { Utilities } from '@app/utilities/Utilities';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Wallet } from '@app/models/Wallet.model';
import { AngularFireStorage } from '@angular/fire/storage';
import 'firebase/storage';
import { finalize } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { MailerService } from '@app/mailer/mailer.service';
import { Investor } from '@app/models/Investor.model';
import { ModalAddOriginatorComponent } from '../modal-add-originator/modal-add-originator/modal-add-originator.component';
import { Observable, Subscription } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SmsService } from '@app/sms/sms.service';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { InvestitorePercentuale } from '@app/models/InvestitorePercentuale';
import { Company } from '@app/models/Company.model';
import { Assignor } from '@app/models/Assignor.model';
import { Originator } from '@app/models/Originator.model';
import { ChartModalComponent } from '../chart-modal/chart-modal.component';
import { GeneraContrattoComponent } from '@app/home/genera-contratto/genera-contratto.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { iteratee } from 'lodash';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { Options } from '@angular-slider/ngx-slider';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { formatCurrency } from '@angular/common';

import { Inject, LOCALE_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment.prod';
import { ConfirmationModalGenericComponent } from '@app/confirmation-modal-generic/confirmation-modal-generic.component';
import { BackendAuthService } from '@app/core/backend-auth.service';
import { InvestorSPV } from '@app/models/InvestorSPV.model';
import { ServicerSPV } from '@app/models/ServicerSPV.model';

@Component({
  selector: 'app-request-detail-modal',
  templateUrl: './request-detail-modal.component.html',
  styleUrls: ['./request-detail-modal.component.scss']
})
export class RequestDetailModalComponent implements OnInit {
  @Input() invoice: Invoice;
  @Input() invoice_id: string;
  @Input() wallet: Wallet;
  @Input() Investor: Investor;

  @ViewChild('OTPSMS', { static: false }) myNameElem: ElementRef;

  loadingGeneral = false;

  //  invoice: Invoice;
  statusSubmitOTP = true;

  show = true;
  isLoading = false;
  associaInvestitore!: FormGroup;
  data;
  verificaOTP!: FormGroup;

  invoiceStatus: string[] = Utilities.invoiceStatus;
  invoiceStatus_it_IT: string[] = Utilities.invoiceStatus_it_IT;
  invoiceStatus_Label_Stepper: string[] = Utilities.invoiceStatus_Label_Stepper;
  maturity = 0;
  controproposta: number;
  sconto: number;
  uploadPercentage: string;
  uploading: boolean;
  fileToUpload: any;
  notaUrl: any;
  bonificoUrl: any;
  contrattoURl: any;
  contrattoSPVURl: any;
  accettazioneSPVURl: any;

  private allegato_a: any;
  private allegato_b: any;
  private allegato_c: any;
  private contrattoSPV_first: any;
  private contrattoSPV_last: any;

  yellowValidate: boolean;
  blueValidate: boolean;
  azureValidate: boolean;

  usersInvestor: any;

  investitore: string;
  listInvestorOfWallet: any[];
  listInvestorOfWalletNonPaganti: any[];
  listInvestorOfWalletObj: any[];

  public usersList: Observable<any[]>;
  usersItems: any[];
  caricaUsers: any[] = [];
  sub: Subscription;
  loading = false;

  listInvestitori: string[];
  listaInvestitoriPagamento: any = [];
  private listaInvestitoriNonPaganti: any = [];
  private listaInvestitoriPagato: any[];
  private listaProposteInvestimento: any = [];
  private percentualeCoperta = 0;
  private percentualeScoperta = 0;

  private infoCurrentInvestor: any = [];

  controlloPercentuale = false;
  Listotp: string[];
  percentualeSalvata = 0;
  investitoreCorrentePercentuale = 0;
  mat = Math;

  investitorePagato: boolean;

  cedente: Assignor;
  debitore: Originator;

  listaInvestitoriDaPagare: string[];

  value = 0;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  highValue = 100;
  valoreFinale: number = 0;
  private filename: string;
  private file64Encode: string;

  private investorSPV: any;
  private servicerSPV: any;
  private listInvestorSPV: any[];
  private listServicerSPV: any[];
  private listInvestoSPVtoInvoice: any[];
  private listServicerSPVtoInvoice: any[];

  private subjectPecDebt = '';
  private bodyPecDebt = '';

  private investorIsSelected: boolean;

  private btnInvc: boolean;
  private btnOrdr: boolean;
  private btnIban: boolean;
  private btnOthr: boolean;
  private docInvc: string;
  private docOrdr: string;
  private docIban: string;
  private docOthr: string;
  private searched: boolean;
  private requestedDocUploaded: boolean = false;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    public datepipe: DatePipe,
    public activeModal: NgbActiveModal,
    public firebase: FirebaseTranchedService,
    private storage: AngularFireStorage,
    private mailerService: MailerService,
    private _modalService: NgbModal,
    private afAuth: AngularFireAuth,
    private formBuilder: FormBuilder,
    private sms: SmsService,
    private lemonway: LemonwayService,
    private sendMail: MailerService,
    private http: HttpClient,
    private modalService: NgbModal,
    private backendAuthService: BackendAuthService
  ) {
    this.yellowValidate = false;
    this.blueValidate = false;
    this.azureValidate = false;
    this.investorIsSelected = false;

    this.debitore = new Originator();
    this.cedente = new Assignor();
    this.listInvestorSPV = [];
    this.listInvestoSPVtoInvoice = [];
    this.listServicerSPVtoInvoice = [];

    this.createFormInvestor();
    // this.createFormOTP();
  }

  check(_invId: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    this.http
      .post<any>(`${environment.laravelServer}/invoice/checkDocuments`, { invId: _invId }, httpOptions)
      .subscribe(data => {
        if (data.docs[0][0]) {
          this.btnInvc = true;
          this.docInvc = data.docs[0][1];
        } else {
          this.btnInvc = false;
        }
        if (data.docs[1][0]) {
          this.btnOrdr = true;
          this.docOrdr = data.docs[1][1];
        } else {
          this.btnOrdr = false;
        }
        if (data.docs[2][0]) {
          this.btnIban = true;
          this.docIban = data.docs[2][1];
        } else {
          this.btnIban = false;
        }
        if (data.docs[3][0]) {
          this.btnOthr = true;
          this.docOthr = data.docs[3][1];
        } else {
          this.btnOthr = false;
        }
        this.searched = true;

        if (this.btnInvc && this.btnOrdr && this.btnIban) {
          this.requestedDocUploaded = true;
        }
      });
  }

  downldDoc(type: string) {
    let path: string;
    if (type == 'INVC') {
      path = '/documenti_fatture/' + this.docInvc;
    }
    if (type == 'ORDR') {
      path = '/documenti_fatture/' + this.docOrdr;
    }
    if (type == 'IBAN') {
      path = '/documenti_fatture/' + this.docIban;
    }
    if (type == 'OTHR') {
      path = '/documenti_fatture/' + this.docOthr;
    }

    const ref = this.storage.ref(path);

    ref.getDownloadURL().subscribe(data => {
      //console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', path);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  getInvestorSPV(uuid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };
    this.http
      .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + uuid + '"}', httpOptions)
      .subscribe(resp => {
        // console.log('INV2=>',resp);
        resp.uuid = uuid;
        this.listInvestorSPV.push(resp);
        console.log('INV3=>', this.listInvestorSPV);
      });
  }

  getInvestorSPVInvoice(uuid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };
    this.http
      .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + uuid + '"}', httpOptions)
      .subscribe(resp => {
        // console.log('INV2=>',resp);
        resp.uuid = uuid;
        this.investorSPV = resp;
        console.log('Investor ass invoce =>', this.investorSPV);
      });
  }

  getServicerSPVInvoice(uuid: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
      })
    };
    this.http
      .post<any>(`${environment.laravelServer}/getUserById`, '{"id":"' + uuid + '"}', httpOptions)
      .subscribe(resp => {
        resp.uuid = uuid;
        this.servicerSPV = resp;
        console.log('Servicer ass invoce =>', this.servicerSPV);
      });
  }

  ngOnInit() {
    this.check(this.invoice.id);

    console.log('ID==>', this.invoice);

    console.log('Abilitazioni=>', this.wallet.productsNode);
    if (this.invoice.status_index > 50 && this.invoice.investorSPV) {
      this.getInvestorSPVInvoice(this.invoice.investorSPV[0]);
      this.getServicerSPVInvoice(this.invoice.servicerSPV[0]);
    }

    console.log('logged=>', this.firebase.loggedUser);
    const date = new Date();
    this.data = date.toLocaleDateString();
    // recupero la company a partire dall'id della fattura => uid
    const uuid_company = this.invoice.uid;
    // console.log(uuid_company);
    this.firebase.getUserByID(uuid_company).then(async value => {
      if (value.exists) {
        // modificare qui
        this.cedente = value.data() as Assignor;

        if (this.cedente.investorSPV) {
          for (const data1 of this.cedente.investorSPV) {
            // console.log('INV1=>', data1);
            this.getInvestorSPV(data1);
          }
        }
      }
    });

    this.firebase.getUserByID(this.wallet.originatorUId).then(value => {
      if (value.exists) {
        // modificare qui
        this.debitore = value.data() as Originator;
        // console.log('debitore=>', this.debitore);
      }
    });

    // **********************************************************

    const firstDate = new Date(this.invoice.dataScadenza);
    const secondDate = new Date();
    const diff = firstDate.getTime() - secondDate.getTime();
    this.maturity = Math.ceil(diff / (1000 * 3600 * 24));

    if (this.invoice.scontoAccettato) {
      this.sconto = this.invoice.scontoAccettato;
    } else {
      this.sconto = this.invoice.scontoProposto;
    }

    if (
      this.invoice.status == this.invoiceStatus[6] ||
      this.invoice.status == this.invoiceStatus[7] ||
      this.invoice.status == this.invoiceStatus[8]
    ) {
      const ref = this.storage.ref(this.invoice.id + '-NC.pdf');
      this.notaUrl = ref.getDownloadURL();
    }

    if (this.invoice.status == this.invoiceStatus[7] || this.invoice.status == this.invoiceStatus[8]) {
      const ref = this.storage.ref(this.invoice.id + '-BON.pdf');
      this.bonificoUrl = ref.getDownloadURL();
    }

    if (this.invoice.status_index > 30 && this.invoice.status_index < 50) {
      const ref = this.storage.ref(this.invoice.id + '-CONTRATTOFIRMATO.pdf');
      this.contrattoURl = ref.getDownloadURL();
    }

    if (this.invoice.status_index === 53) {
      this.setUrlDownloadDoc('-CONTRATTO_SPV_FIRMATO_INVESTITORE.pdf');
    }

    if (this.invoice.status_index === 54) {
      this.setUrlDownloadDoc('-CONTRATTO_SPV_FIRMATO_CEDENTE_INVESTORSPV.pdf');
    }

    if (this.invoice.status_index === 58) {
      this.setUrlDownloadDoc('-CONTABILE-INVESTORSPV.pdf');
    }

    /* modifica mario 10 10 23 */
    if (this.invoice.status_index > 52) {
      const refContr = this.storage.ref(this.invoice.id + '-CONTRATTO_SPV_FIRMATO_INVESTITORE.pdf');
      this.contrattoSPVURl = refContr.getDownloadURL();

      console.log('contratto rul inve ===> ' + this.contrattoSPVURl);
    }
    /***************************/

    if (this.invoice.status_index === 59 || this.invoice.status_index === 60) {
      const ref = this.storage.ref(this.invoice.id + '-CONTABILE-INVESTORSPV.pdf');
      this.contrattoURl = ref.getDownloadURL();

      const refContr = this.storage.ref(this.invoice.id + '-CONTRATTO_SPV_FIRMATO_INVESTITORE.pdf');
      this.contrattoSPVURl = refContr.getDownloadURL();

      const refContrAcc = this.storage.ref(this.invoice.id + '-CONTRATTO_SPV_FIRMATO_CEDENTE_INVESTORSPV.pdf');
      this.accettazioneSPVURl = refContrAcc.getDownloadURL();
    }

    this.listaProposteInvestimento = this.invoice.listaProposteInvestimento
      ? this.invoice.listaProposteInvestimento
      : [];
    this.listaInvestitoriNonPaganti = [];
    this.investitore = this.invoice.investor ? this.invoice.investor : null;
    this.listInvestitori = this.invoice.listaInvestitori ? this.invoice.listaInvestitori : [];
    this.listaInvestitoriPagamento = this.invoice.listaInvestitoriPagamento
      ? this.invoice.listaInvestitoriPagamento
      : [];
    this.listaInvestitoriPagamento.forEach(element => {
      // console.log(element);
      const inv = element.split('---')[0];
      const per: number = +element.split('---')[1];
      const bool = element.split('---')[2];
      this.percentualeSalvata = this.percentualeSalvata + per;
      // if (bool == 'F') {
      //   this.listaInvestitoriNonPaganti.push([inv,per.toFixed(16)]);
      // }
    });

    this.Listotp = this.invoice.otp ? this.invoice.otp : [];

    // this.listInvestorOfWallet = this.wallet.listInvestitori;
    this.listInvestorOfWallet = [];
    // Il metodo seleziona gli investitori sulla base della associazione al wallet
    /*
    this.wallet.listInvestitori.forEach(investor => {
      this.firebase.getUserByID(investor).then(value => {
        if (value.exists) {
          const us = value.data() as Investor;
          this.listInvestorOfWallet.push(us.investorPIva);
          // console.log(investor);
        }
      });
    });
    */
    // Questo metodo permette di selezionare tutti gli investitori in fase di selezione
    this.firebase.getUsersByRole(Utilities.userType[5]).subscribe(data => {
      data.forEach(investor => {
        if (investor.investorPIva && investor.investorPIva != '') {
          this.listInvestorOfWallet.push([investor.investorPIva, investor.email]);
        }
      });
      // console.log('investor=>',this.listInvestorOfWallet);
    });

    this.listInvestorOfWalletNonPaganti = [];

    if (this.invoice.status_index != 36 && this.invoice.status_index != 37) {
      this.listaInvestitoriDaPagare = this.listaInvestitoriPagamento;
      // TODO: verificare funzione in altri step al momento non sembra necessario
      // this.firebase.modInvoice(this.invoice);
    }
    this.listaInvestitoriDaPagare = this.invoice.listaInvestitoriDaPagare ? this.invoice.listaInvestitoriDaPagare : [];

    this.valoreFinale =
      this.invoice.importoFattura - (this.invoice.importoFattura * this.invoice.scontoAccettato) / 100;

    // if (this.invoice.status_index == 32 && this.invoice.listaInvestitori) {
    //   this.invoice.listaInvestitori = [];
    //   this.firebase.modInvoice(this.invoice);
    // }

    if (this.invoice.status_index == 36) {
      this.verificaPagamentoInvestitori();
    }

    if (this.invoice.status_index > 31) {
      this.recalculateCopertura();
      if (this.firebase.isInvestor()) {
        this.investitorePagato = this.verificaPagamenti(this.firebase.loggedUser.investorPIva);
      }
    }
  }

  createFormInvestor() {
    this.associaInvestitore = this.formBuilder.group({
      pivaInvestitore: new FormControl('', [Validators.required]),
      percentuale: new FormControl('', [Validators.required]),
      rate: new FormControl(false)
    });
  }

  // @hydra 2022-01-14 -- @author - Gaetano Bitonte - https://hydrasolutions.atlassian.net/browse/FXINPROD-87

  verificaPagamenti(piva: string): boolean {
    // ritorna false se non ha pagato
    try {
      if (this.listaInvestitoriNonPaganti[0].includes(piva)) {
        return false;
      }
    } catch (e) {}

    for (const investor of this.listaInvestitoriPagamento) {
      const invArr = investor.split('---');
      if (invArr[0] == piva) {
        this.infoCurrentInvestor = invArr;
      }
      if (invArr[0] == piva && invArr[2] == 'V') {
        return true;
      }
    }
    return false;
  }

  generaContrattoPDF() {
    const modalRef = this._modalService.open(GeneraContrattoComponent, {
      size: 'xl',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.invoice = this.invoice;
    modalRef.componentInstance.wallet = this.wallet;
    modalRef.componentInstance.cedente = this.cedente;
    modalRef.result.then(
      () => {
        // fulfilled
      },
      () => {
        // rejected
      }
    );
  }

  submitInvestiore23() {
    const obj = {
      status_index: 33
    };
    this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);
    this.activeModal.close('');
  }

  submitInvestioreDaInvestitore() {
    // console.log(this.associaInvestitore.value.percentuale);
    if (this.associaInvestitore.value.percentuale <= 0 || this.associaInvestitore.value.percentuale > 100) {
      this.controlloPercentuale = true;
      return;
    }

    let controllo = false;
    var inv = this.firebase.loggedUser.investorPIva;
    var perc = this.associaInvestitore.value.percentuale;

    for (let i = 0; i < this.listaProposteInvestimento.length; i++) {
      var element2 = this.listaProposteInvestimento[i];
      const inv2 = element2.split('---')[0];
      if (inv == inv2) {
        this.listaProposteInvestimento[i] = inv + '---' + perc + '---' + 'S';
        controllo = true;
      }
    }

    if (controllo == false) {
      this.listaProposteInvestimento.push(inv + '---' + perc + '---' + 'S');
    }

    this.invoice.listaProposteInvestimento = this.listaProposteInvestimento;

    // console.log(this.invoice);
    this.firebase.modInvoice(this.invoice);
    this.activeModal.close('');
  }

  submitInvestioreVer2() {
    this.isLoading = true;
    const arr = [];
    const listaInv = [];
    const listaDaPagare = [];
    // this.percentualeCoperta=this.invoice.percentualeCoperta?this.invoice.percentualeCoperta:0;
    // Se ha inserito due volte lo stesso con percentuali differenti somma le due percentuali
    this.listInvestitori.forEach(element => {
      const inv = element.split('---')[0];
      let percentuale = 0;
      if (!arr.includes(inv)) {
        this.listInvestitori.forEach(element2 => {
          const inv2 = element2.split('---')[0];
          if (inv == inv2) {
            const perc: number = +element2.split('---')[1];
            percentuale = percentuale + perc;
          }
        });
        listaInv.push(inv + '---' + percentuale);
        if (this.listaInvestitoriNonPaganti[0].includes(inv)) {
          listaDaPagare.push(inv + '---' + percentuale + '---' + 'F');
        } else {
          listaDaPagare.push(inv + '---' + percentuale + '---' + 'V');
        }
        arr.push(inv);
      }

      /* invio email di notifica agli investitori associati */

      this.firebase.getInvestorByPIva(inv).subscribe(data => {
        console.log('Investor=>', data);

        // invio email di notifica
        // creo il body dell'email

        const body =
          '{\n' +
          '    "to":"' +
          data[0].email +
          '",\n' +
          '    "subject":"Hai una nuova operazione",\n' +
          '    "senderCompany":"FX12 Srl",\n' +
          '    "from":"support@incassaora.it",\n' +
          '    "senderName":"Support of Incassaora",\n' +
          '    "templateName":"newOperation",\n' +
          '    "denominazioneCedente":"' +
          this.cedente.name +
          '",\n' +
          '    "denominiazioneDebitore":"' +
          this.debitore.name +
          '",\n' +
          '    "morescoreDebitore":"' +
          this.debitore.originatorMoreScore +
          '",\n' +
          '    "pdDebitore":"' +
          this.debitore.originatorDefault +
          '",\n' +
          '    "morescoreCedente":"' +
          this.cedente.assignorMoreScore +
          '",\n' +
          '    "pdCedente":"' +
          this.cedente.assignorDefault +
          '"\n' +
          '}';

        // console.log('body=>',body);
        this.sendMail.sendEmailTemplateByBody(body);
      });
    });

    this.listInvestitori = listaInv;
    this.listaInvestitoriPagamento = listaDaPagare;
    this.invoice.listaInvestitori = listaInv;
    this.invoice.listaInvestitoriPagamento = listaDaPagare;
    this.invoice.listaInvestitoriDaPagare = listaDaPagare;
    // console.log(this.invoice);
    this.firebase.modInvoice(this.invoice);
    this.percentualeSalvata = this.percentualeCoperta;
    this.adminChangeStatusIndex(33);
    this.isLoading = false;

    this.activeModal.close('');
  }

  adminChangeStatusIndex(value: number) {
    if (value) {
      this.invoice.status_index = value;
      this.firebase.modInvoice(this.invoice).then(resp => {
        this.activeModal.close('');
        //window.location.reload();
      });
    }
  }

  accettaFattura(proposta: boolean) {
    if (proposta) {
      this.invoice.status = Utilities.invoiceStatus[1];
      this.firebase.modInvoice(this.invoice);
    } else {
      this.invoice.status = Utilities.invoiceStatus[0];
      this.firebase.modInvoice(this.invoice);
    }
  }

  controproponi(contropropost: boolean) {
    if (contropropost) {
      // Fa controproposta
      this.invoice.status = Utilities.invoiceStatus[2];
      this.invoice.controproposta = this.controproposta;
      this.firebase.modInvoice(this.invoice);
      this.activeModal.dismiss();
      // this.sendMailToOriginator('getIpotesiFattura', 'Controproposta');
      this.adminChangeStatusIndex(2);

      // invio email di notifica
      // creo il body dell'email

      const body =
        '{\n' +
        '    "to":"' +
        this.cedente.email +
        '",\n' +
        '    "firstname":"' +
        this.debitore.originatorSolicitorName +
        '",\n' +
        '    "lastname":"' +
        this.debitore.originatorSolicitorSurname +
        '",\n' +
        '    "subject":"Controproposta ricevuta",\n' +
        '    "senderCompany":"FX12 Srl",\n' +
        '    "from":"support@incassaora.it",\n' +
        '    "senderName":"Support of Incassaora",\n' +
        '    "templateName":"newCounterOffer",\n' +
        '    "denominazioneCedente":"' +
        this.cedente.assignorName +
        '",\n' +
        '    "denominiazioneDebitore":"' +
        this.debitore.name +
        '",\n' +
        '    "importoFattura":"' +
        this.invoice.importoFattura +
        '",\n' +
        '    "dataFattura":"' +
        this.invoice.dataFattura +
        '",\n' +
        '    "numeroFattura":"' +
        this.invoice.numFattura +
        '",\n' +
        '    "maturity":"' +
        this.invoice.dataScadenza +
        '",\n' +
        '    "controProposta":"' +
        this.controproposta +
        '"\n' +
        '}';

      // console.log('body=>', body);

      this.sendMail.sendEmailTemplateByBody(body);

      // ///

      /* ******************************* */
    } else {
      this.invoice.status = Utilities.invoiceStatus[3];
      this.invoice.controproposta = this.invoice.scontoProposto;
      this.invoice.scontoAccettato = this.invoice.controproposta;
      this.firebase.modInvoice(this.invoice);
      this.activeModal.dismiss();
      // this.sendMailToFornitore('getPropostaAccettata', 'Controproposta Accettata', this.invoice.uid);
      this.adminChangeStatusIndex(5);

      const body =
        '{\n' +
        '    "to":"' +
        this.cedente.email +
        '",\n' +
        '    "firstname":"' +
        this.debitore.originatorSolicitorName +
        '",\n' +
        '    "lastname":"' +
        this.debitore.originatorSolicitorSurname +
        '",\n' +
        '    "subject":"Proposta accettata",\n' +
        '    "senderCompany":"FX12 Srl",\n' +
        '    "from":"support@incassaora.it",\n' +
        '    "senderName":"Support of Incassaora",\n' +
        '    "templateName":"proposalAccepted",\n' +
        '    "denominazioneCedente":"' +
        this.cedente.assignorName +
        '",\n' +
        '    "denominiazioneDebitore":"' +
        this.debitore.name +
        '",\n' +
        '    "importoFattura":"' +
        this.invoice.importoFattura +
        '",\n' +
        '    "dataFattura":"' +
        this.invoice.dataFattura +
        '",\n' +
        '    "numeroFattura":"' +
        this.invoice.numFattura +
        '",\n' +
        '    "maturity":"' +
        this.invoice.dataScadenza +
        '",\n' +
        '    "controProposta":"' +
        this.controproposta +
        '"\n' +
        '}';

      // console.log('body=>', body);

      this.sendMail.sendEmailTemplateByBody(body);
    }
  }

  accept(accepted: boolean) {
    if (accepted) {
      this.sendMailToFornitore('getPropostaAccettata', 'Controproposta Accettata', this.invoice.uid);

      const obj = {
        status_index: 5,
        status: Utilities.invoiceStatus[3],
        scontoAccettato: this.invoice.controproposta
      };
      this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {});

      this.activeModal.dismiss();
    } else {
      this.sendMailToOriginator('getPropostaRifiutata', 'Rifiutata');

      const obj = {
        status_index: 3,
        status: Utilities.invoiceStatus[0]
      };
      this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {});

      this.activeModal.dismiss();
    }
  }

  chiudi() {
    const obj = {
      status_index: 3,
      status: Utilities.invoiceStatus[0]
    };
    this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {});
    this.activeModal.dismiss();
  }

  yellow() {
    let index_veicolo = 0;
    this.wallet.productsNode.forEach(veicolo => {
      if (veicolo.name === 'Yellow Finance') {
        const rimanenti = veicolo.plafond - veicolo.counter;
        const importoFattura = this.invoice.importoFattura - (this.invoice.importoFattura * 3) / 100;
        if (importoFattura > rimanenti) {
          this.yellowValidate = true;
        } else {
          this.yellowValidate = false;
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          // tslint:disable-next-line:max-line-length
          this.wallet.productsNode[index_veicolo].counter =
            this.wallet.productsNode[index_veicolo].counter + importoFattura;
          this.firebase.modWallet(this.wallet);
          // this.activeModal.dismiss();
          this.adminChangeStatusIndex(10);

          // invia mail a cedente
          this.sendMail.sendGenericEmail(
            this.cedente.email,
            this.cedente.name,
            this.cedente.surname,
            this.invoice.numFattura
          );
        }
      }
      index_veicolo++;
    });
  }

  blue() {
    let index_veicolo = 0;
    this.wallet.productsNode.forEach(veicolo => {
      if (veicolo.name === 'Blue Finance') {
        // console.log('Name veicolo=>', veicolo);
        // console.log('index_veicolo =>', index_veicolo);

        const rimanenti = veicolo.plafond - veicolo.counter;
        const importoFattura = this.invoice.importoFattura - (this.invoice.importoFattura * 3) / 100;
        if (importoFattura > rimanenti) {
          this.blueValidate = true;
        } else {
          this.blueValidate = false;
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          // tslint:disable-next-line:max-line-length
          this.wallet.productsNode[index_veicolo].counter =
            this.wallet.productsNode[index_veicolo].counter + importoFattura;
          this.firebase.modWallet(this.wallet);
          // this.activeModal.dismiss();
          this.adminChangeStatusIndex(30);

          // (30) Blu - In attesa di caricamento contratto del cedente
          // invia mail a cedente
          this.sendMail.sendGenericEmail(
            this.cedente.email,
            this.cedente.name,
            this.cedente.surname,
            this.invoice.numFattura
          );
        }
      }
      index_veicolo++;
    });
  }

  azure() {
    let index_veicolo = 0;
    this.wallet.productsNode.forEach(veicolo => {
      if (veicolo.name === 'Azure Finance') {
        // console.log('Name veicolo=>', veicolo);
        // console.log('index_veicolo =>', index_veicolo);

        const rimanenti = veicolo.plafond - veicolo.counter;
        const importoFattura = this.invoice.importoFattura - (this.invoice.importoFattura * 3) / 100;
        if (importoFattura > rimanenti) {
          this.azureValidate = true;
        } else {
          this.azureValidate = false;
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          // tslint:disable-next-line:max-line-length
          this.wallet.productsNode[index_veicolo].counter =
            this.wallet.productsNode[index_veicolo].counter + importoFattura;
          this.firebase.modWallet(this.wallet);
          // this.activeModal.dismiss();
          this.adminChangeStatusIndex(50);

          // (50) Azure - In attesa di associazione InvestorSPV e ServicerSPV
          // invio mail ad admin
          this.sendMail.sendGenericEmail('desk@fx12.it', 'Amministratore', 'FX12', this.invoice.numFattura);
        }
      }
      index_veicolo++;
    });
  }

  addInvestiore() {
    const arr: InvestitorePercentuale = new InvestitorePercentuale();
    this.controlloPercentuale = false;
    if (this.associaInvestitore.value.pivaInvestitore == '---') {
      this.controlloPercentuale = true;
      return;
    }
    if (this.associaInvestitore.value.percentuale <= 0) {
      this.controlloPercentuale = true;
      return;
    }
    arr.investorPiva = this.associaInvestitore.value.pivaInvestitore;
    arr.percentuale = this.associaInvestitore.value.percentuale;
    var a = this.percentualeCoperta.toFixed(2);
    this.percentualeCoperta = parseFloat(a);
    const new_perc = (this.percentualeCoperta + this.associaInvestitore.value.percentuale).toFixed(2);
    if (new_perc > 100) {
      this.controlloPercentuale = true;
      this.associaInvestitore.reset();
      this.associaInvestitore.reset();
      return;
    }
    this.percentualeCoperta = this.percentualeCoperta + this.associaInvestitore.value.percentuale;
    var a = this.percentualeCoperta.toFixed(2);
    this.percentualeCoperta = parseFloat(a);
    this.associaInvestitore.reset();
    this.associaInvestitore.reset();
    this.listInvestitori.push(arr.investorPiva + '---' + arr.percentuale);
    /* to split the string do so
    var prova=arr.investorPiva+"---"+arr.percentuale;
    let toArray = prova.split("---");
    console.log(toArray);
    */
    // console.log(this.listInvestitori);
  }

  addInvestiore2(selezione: string, inv: string) {
    if (selezione == 'A') {
      const arr: InvestitorePercentuale = new InvestitorePercentuale();
      this.controlloPercentuale = false;
      arr.investorPiva = inv.split('---')[0];
      const perc: number = +inv.split('---')[1];
      arr.percentuale = parseFloat(perc.toFixed(2));
      const new_perc = parseFloat((this.percentualeCoperta + perc).toFixed(2));
      if (new_perc > 100) {
        this.controlloPercentuale = true;
        return;
      }
      this.percentualeCoperta = this.percentualeCoperta + perc;
      var a = this.percentualeCoperta.toFixed(2);
      this.percentualeCoperta = parseFloat(a);

      this.listInvestitori.push(arr.investorPiva + '---' + arr.percentuale);
    }
    if (selezione == 'R') {
      //invia email
    }
  }

  controllo(inv: string) {
    for (let i = 0; i < this.listInvestitori.length; i++) {
      var elem = this.listInvestitori[i];
      if (elem.split('---')[0] == inv.split('---')[0]) {
        return false;
      }
    }
    return true;
  }

  addInvestioreVer2() {
    const arr: InvestitorePercentuale = new InvestitorePercentuale();
    this.controlloPercentuale = false;
    if (this.associaInvestitore.value.pivaInvestitore == '---') {
      this.controlloPercentuale = true;
      return;
    }
    if (this.associaInvestitore.value.percentuale <= 0) {
      this.controlloPercentuale = true;
      return;
    }
    arr.investorPiva = this.associaInvestitore.value.pivaInvestitore;
    arr.percentuale = this.associaInvestitore.value.percentuale;
    var a = this.percentualeCoperta.toFixed(2);
    this.percentualeCoperta = parseFloat(a);
    const new_perc = (this.percentualeCoperta + this.associaInvestitore.value.percentuale).toFixed(2);
    if (new_perc > 100) {
      this.controlloPercentuale = true;
      this.associaInvestitore.reset();
      this.associaInvestitore.reset();
      return;
    }
    this.percentualeCoperta = this.percentualeCoperta + this.associaInvestitore.value.percentuale;
    var a = this.percentualeCoperta.toFixed(2);
    this.percentualeCoperta = parseFloat(a);
    this.associaInvestitore.reset();
    this.associaInvestitore.reset();
    this.listInvestitori.push(arr.investorPiva + '---' + arr.percentuale);
    this.listaInvestitoriNonPaganti.push(arr.investorPiva);
    /* to split the string do so
    var prova=arr.investorPiva+"---"+arr.percentuale;
    let toArray = prova.split("---");
    console.log(toArray);
    */
    // console.log(this.listInvestitori);
  }

  approvaTransazione(value: boolean) {
    // const verifica = document.getElementById('verificaOTP');
    console.log('APPTOVAA');
    let avvio = true;
    if (value) {
      // attivo textbox per inserimento otp

      this.firebase.getInvoiceByID(this.invoice.id).subscribe(resp => {
        this.show = true;

        // genero OTP SMS e apro campo per inserimento e conferma
        // TODO: chiamata verso piattaforma laravel per generazione SMS
        // Chiamata verso piattaforma laravel per generazione sms
        // console.log('Genera OTP');

        let investorPhone = '';

        investorPhone = this.firebase.loggedUser.telefono;
        // console.log('phone1:' + investorPhone);

        // tslint:disable-next-line:triple-equals
        console.log('avvio=>', avvio);
        if (investorPhone != '' && avvio) {
          avvio = false;
          console.log('avvio=>', avvio);
          console.log('INVIOOOOO');
          this.sms.sendSms(investorPhone).subscribe(data2 => {
            // console.log(data2);
            const otp = data2.otp;
            const response_status = data2.response_status;
            if (otp !== '' && response_status == 'OK') {
              this.firebase.createSmsLog(otp, this.firebase.loggedUser.investorPIva);
              // let date = new Date().toUTCString();
              const date = new Date().getTime();

              this.Listotp = [];
              try {
                if (resp[0].otp) {
                  this.Listotp = resp[0].otp;
                }
              } catch (err) {
                this.Listotp = [];
              }

              this.Listotp.push(otp + '---' + this.firebase.loggedUser.investorPIva + '---' + date);

              const obj = {
                otp: this.Listotp
              };

              console.log('Oggetto=>', obj);
              this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj).then(rest => {
                // this.activeModal.close('');
                // window.location.reload();
              });

              // tslint:disable-next-line:max-line-length
              alert(
                'Abbiamo inviato un codice al tuo numero: ' +
                  investorPhone +
                  '\n Il codice sarà valido solo per 10 minuti!'
              );
            } else {
              alert(
                "Si è verificato un errore durante l'invio del SMS. Si prega di riprovare oppure contattare l'assistenza tecnica!"
              );
              this.activeModal.close('');
              window.location.reload();
            }
          });
        }
      });
    } else {
      this.show = false;
    }
  }

  // createFormOTP() {
  //   this.verificaOTP = this.formBuilder.group({
  //     OTPSMS: new FormControl('', [Validators.required]),
  //     rate: new FormControl(false)
  //   });
  // }

  ritarda() {
    this.statusSubmitOTP = false;
    setTimeout(() => {
      // <<<---using ()=> syntax
      this.submitOTP();
    }, Math.floor(Math.random() * 6000));
  }

  async submitOTP() {
    /* ricarico la fattura prima di procedere ad una sua modifica */
    this.statusSubmitOTP = false;
    console.log('this.investitorePagato=>', this.investitorePagato);
    if (!this.investitorePagato) {
      this.firebase.getInvoiceByID(this.invoice.id).subscribe(resp => {
        this.isLoading = false;
        const value = this.myNameElem.nativeElement.value;
        console.log('OTP INS =>', value);
        if (value) {
          let verifica = false;
          // il tempo massimo di attesa prima dell'inserimento dell'otp è 10 minuti calcolato in millisecondi
          const timeDelay = 10 * 60 * 1000;

          this.Listotp = [];
          try {
            if (resp[0].otp) {
              this.Listotp = resp[0].otp;
            }
          } catch (err) {
            this.Listotp = [];
          }

          this.Listotp.forEach(element => {
            console.log(element);
            // console.log(this.firebase.loggedUser.investorPIva);
            // splitto gli elementi nell'array
            const arr = element.split('---');
            // tslint:disable-next-line:triple-equals
            const dateNow = new Date().getTime();
            const dateDelay = dateNow - timeDelay;
            //  console.log(dateNow);
            if (arr[1] == this.firebase.loggedUser.investorPIva) {
              //   console.log('Investitore presente');
              if (arr[0] == value) {
                // verific a delle date
                // @ts-ignore
                if (dateDelay < arr[2]) {
                  verifica = true;

                  // console.log('OTP corretto');
                }
              }
            }
          });

          console.log('Finito 1 foreach');
          console.log('verifica=>', verifica);

          if (verifica) {
            // Sostituire con l'otp che è salvato attualmente in this.otp
            // TODO: se il codice è corretto allora verificare se vi sono fondi ed avviare il trasferimento di fondi
            const fondi = 10000000;

            // TODO: recuperare il valore dei fondi da Laravel tramite API a LemonWay
            // La partita IVA dell'investiotre è attualmente associata

            // verifica che i findi siano maggiori di quanto bisogna versare per chiudere la trattativa
            // tslint:disable-next-line:max-line-length
            let prezzofinale =
              this.invoice.importoFattura - (this.invoice.importoFattura * this.invoice.scontoAccettato) / 100;

            if (fondi > prezzofinale) {
              // chiamate alle API Laravel e verso LemonWay per il trasferimento

              // tslint:disable-next-line:no-shadowed-variable
              this.firebase.getUserByID(this.invoice.uid).then(value => {
                // console.log('PIVA Forniotore:' + value.data().pIva);
                this.listaInvestitoriPagamento.forEach(elemINV => {
                  const investitoreArr = elemINV.split('---');
                  if (investitoreArr[0] === this.firebase.loggedUser.investorPIva) {
                    const per: number = +investitoreArr[1];
                    prezzofinale = (prezzofinale * per) / 100;
                  }
                });

                console.log('Finito 2 foreach');

                // console.log(prezzofinale);
                prezzofinale = prezzofinale * 100;
                const valoreForLemonway: number = Math.trunc(prezzofinale);
                console.log('valoreForLemonway=>', valoreForLemonway);

                const comment = 'Fatt. ' + this.invoice.numFattura + '|' + this.invoice.id;

                let countCall = 0;

                if (countCall === 0) {
                  verifica = false;
                  countCall = 1;
                  console.log(countCall);
                  this.lemonway
                    .transaction(valoreForLemonway, value.data().pIva, this.firebase.loggedUser.investorPIva, comment)
                    .subscribe(data => {
                      const test = true;
                      let pass = 0;

                      try {
                        try {
                          pass = data.transaction.status;
                        } catch (e) {
                          pass = 0;
                        }

                        if (pass === 3) {
                          this.investitorePagato = true;

                          // Modifichiamo il suo valore nell array
                          const arr = [];
                          let num = 0;

                          this.listaInvestitoriPagamento = [];
                          try {
                            if (resp[0].listaInvestitoriPagamento) {
                              this.listaInvestitoriPagamento = resp[0].listaInvestitoriPagamento;
                            }
                          } catch (err) {
                            this.listaInvestitoriPagamento = [];
                          }

                          this.listaInvestitoriPagamento.forEach(elem => {
                            if (elem.split('---')[0] != this.firebase.loggedUser.investorPIva) {
                              arr.push(elem);
                            } else {
                              arr.push(
                                this.firebase.loggedUser.investorPIva + '---' + elem.split('---')[1] + '---' + 'V'
                              );
                            }
                          });

                          this.listaInvestitoriPagamento = arr;
                          this.invoice.listaInvestitoriPagamento = arr;
                          this.invoice.listaInvestitoriDaPagare = arr;

                          this.listaInvestitoriPagamento.forEach(elem => {
                            if (elem.split('---')[2] == 'F') {
                              num = num + 1;
                            }
                          });

                          console.log('Finito 3 foreach');

                          console.log('Pagamenti 1 =>', this.listaInvestitoriPagamento);

                          const obj = {
                            listaInvestitoriPagamento: arr,
                            listaInvestitoriDaPagare: arr
                          };
                          this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj).then(resp => {
                            if (num > 0) {
                              // this.adminChangeStatusIndex(33);
                              this.isLoading = true;
                              this.activeModal.close('');
                              window.location.reload();
                            } else {
                              console.log('Pagamenti 2 =>', this.listaInvestitoriPagamento);
                              const obj = {
                                status: Utilities.invoiceStatus[9],
                                status_index: 36
                              };
                              this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj).then(resp => {
                                this.isLoading = true;
                                this.activeModal.close();
                                window.location.reload();
                              });
                            }
                            alert('ALERT: Trasferimento in corso');
                            alert('Transazione Eseguita');
                          });
                        } else {
                          alert("Errore nella transazione! Se il problema persiste contattare l'amministratore");
                          this.isLoading = true;
                          this.activeModal.close('');
                        }
                      } catch (e) {
                        alert('Catch=>' + data.error.message);
                        this.isLoading = true;
                        this.activeModal.close('');
                      }
                    });
                }
              });
            } else {
              // in attesa di fondi
              alert(
                // tslint:disable-next-line:max-line-length
                "ALERT: Fondi non sufficienti a coprire l'importo richiesto.\n Effettuare una ricarica oppure contattare l'amministrazione"
              );
              // this.adminChangeStatusIndex(35);
              this.isLoading = true;
              this.activeModal.close('');
              window.location.reload();
            }
          } else {
            // TODO: messaggio di errore
            alert('ERRORE: Codice non valido!');
            this.isLoading = true;
            this.activeModal.close('');
            window.location.reload();
          }
        } else {
          alert('ERRORE: Codice non valido. Inserire un valore');
          this.isLoading = true;
          this.activeModal.close('');
        }
      });
    }

    /* ********************************************************** */
  } // end Submit

  clearOTP() {
    // svuota il campo otp e nasconde il div relativo
  }

  validateInvestorOTP_SMS(value: string) {}

  generaContratto() {
    //  console.log(this.invoice.uid);
    // la funzione getUserByID restituisce un oggetto di tipo Promise
    // la funzione then assegna alla variabile value quanto restituito
    // nel caso sotto tutta la collection specificata da cui è possibile
    // estrarre il documento
    this.firebase.getUserByID(this.invoice.uid).then(value => {
      // console.log("Legal:" + value.data().legalName);
      this.usersItems = [value.data()];
      // qui va generato tutto il pdf

      // genero un html element
      let contratto: HTMLElement;
      let contrattoText: string;
      contratto = document.createElement('div');
      // setto attributi come stile etc
      contratto.setAttribute('style', 'padding: 20px; font-size: 15px; color: red; align: center;');
      // inserisco testo con formattazione html
      contratto.innerHTML = '<h1>questo è un contratto tra fx12 e ' + value.data().legalName + '</h1>';
      let ibanValidato;
      let pecValidato;
      if (this.cedente.virtualIban) {
        ibanValidato = this.cedente.virtualIban;
      } else {
        ibanValidato = '_____________________________________';
      }
      if (this.cedente.assignorPec) {
        pecValidato = this.cedente.assignorPec;
      } else {
        pecValidato = '_____________________________________';
      }

      //sostituire qua
      contrattoText =
        this.cedente.denominazione +
        ' \n' +
        'Indirizzo sede legale\n' +
        '\n' +
        '\n' +
        'Alla cortese attenzione\n' +
        this.invoice.cedenteName +
        '\n' +
        myDataConversion(this.invoice.dataFattura) +
        '\n' +
        'Egregi Signori, \n' +
        'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di cessione del credito.\n' +
        '\n' +
        'Oggetto: Contratto di cessione del credito \n' +
        '\n' +
        'Egregi Signori:\n' +
        '\n' +
        'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue:\n' +
        '\n' +
        '\n' +
        '\n' +
        'ACCORDO\n' +
        '\n' +
        'Tra\n' +
        '\n' +
        '- FX12 s.r.l., con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 in persona della Dott.ssa Rita Capitelli, che sottoscrive il presente accordo in qualità di amministratore e legale rappresentante (di seguito, “FX12” o il “Cessionario”)\n' +
        '- da una parte - \n' +
        'e\n' +
        '\n' +
        '-' +
        this.invoice.cedenteName +
        ', con sede legale in [n] (n), CF. ' +
        this.cedente.fiscalCode +
        ' e P.Iva ' +
        this.cedente.pIva +
        ', numero di iscrizione al Registro delle Imprese di NAPOLI n. [n], in persona del Sig. [Legale rappresentante], che sottoscrive il presente accordo in qualità di Legale Rappresentante (di seguito, “' +
        this.invoice.cedenteName +
        '” o il “Cedente”); \n' +
        '- dall’altra parte –\n' +
        '\n' +
        'FX12 e ' +
        this.invoice.cedenteName +
        ', d’ora in avanti congiuntamente denominate le "Parti", e ciascuna singolarmente una "Parte");\n' +
        '\n' +
        'PREMESSO CHE\n' +
        '\n' +
        'FX12 è una start up innovativa che ha sviluppato la piattaforma fintech Incassa Ora® (di seguito, la “Piattaforma”) finalizzata alla negoziazione di crediti; \n' +
        '\n' +
        this.wallet.originatorName +
        ' è un’azienda dotata di un elevato merito creditizio, interessata ad accelerare i pagamenti ai propri fornitori, e che a tal fine ha aderito alla piattaforma Incassa Ora® di FX12 (di seguito anche il “Debitore Ceduto”); \n' +
        '\n' +
        this.invoice.cedenteName +
        ' è il fornitore di ' +
        this.wallet.originatorName +
        ', ed è interessato ad ottenere il pagamento anticipato dei crediti vantati nei confronti di ' +
        this.wallet.originatorName +
        '; \n' +
        '\n' +
        this.wallet.originatorName +
        ', in particolare, si è impegnata a corrispondere a ' +
        this.invoice.cedenteName +
        ' un importo, da liquidarsi entro ' +
        this.wallet.daysLeft +
        ' giorni dall’emissione della fattura;\n' +
        '\n' +
        this.invoice.cedenteName +
        ' ha manifestato l’intenzione di cedere e trasferire a FX12 attraverso la piattaforma Incassa Ora® il singolo credito risultante dalla fattura n. ' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        '\n' +
        'FX12 intende acquistare il credito secondo le modalità e termini di cui al presente contratto.\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        'TUTTO CIò PREMESSO, in virtù dei reciproci accordi qui contenuti, e secondo i termini e le condizioni del presente accordo (l\'"Accordo"), le Parti convengono quanto segue:\n' +
        '\n' +
        '1. Premesse \n' +
        '1.1. Le Premesse e gli Allegati formano parte integrante e sostanziale del presente Accordo.\n' +
        '\n' +
        '2. Oggetto\n' +
        '2.1 In forza della sottoscrizione del presente Accordo, il Cedente cede a titolo oneroso pro-soluto al Cessionario, che accetta, il credito risultante dalla fattura n° ' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        ' di ' +
        this.invoice.importoFattura +
        '€ (di seguito, il “Credito”) che vanta nei confronti di ' +
        this.wallet.originatorName +
        '. \n' +
        '2.2 Il Cedente consegna al Cessionario tutta la documentazione comprovante il Credito e, nello specifico: (i) fattura n°' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        ' (All. 1).\n' +
        '2.3 Dalla data di sottoscrizione del presente Accordo, quindi, FX12 diventa a tutti gli effetti di legge titolare del Credito ceduto. Il Credito viene trasferito al Cessionario con tutti i privilegi, garanzie personali, garanzie reali e con gli altri accessori ivi comprese eventuali pattuizioni contrattuali ai sensi dell’1263, comma 1, c.c..\n' +
        '\n' +
        '3. Prezzo\n' +
        '3.1 A fronte della cessione del Credito, il Cessionario si obbliga a pagare in favore del Cedente:\n' +
        '3.1.1 un importo pari a € ' +
        this.invoice.importoFattura +
        ' ([Prezzo d’acquisto]), entro ' +
        this.wallet.daysLeft +
        ' giorni lavorativi dalla sottoscrizione del presente Accordo.\n' +
        '3.2 I pagamenti saranno effettuati sul conto corrente n. IBAN ' +
        ibanValidato +
        ' intestato a ' +
        this.invoice.cedenteName +
        '(All. 2). \n' +
        '\n' +
        '\n' +
        '\n' +
        '4. Garanzie e obblighi delle Parti\n' +
        '4.1 Il Cedente garantisce che:\n' +
        '\n' +
        'non è in stato di insolvenza, non esistono fatti o circostanze che potrebbero esporla a eventuali procedure concorsuali e non ha depositato né deliberato il deposito di una domanda di concordato;\n' +
        '\n' +
        'il Credito è certo, liquido ed esigibile rispetto alla scadenza riportata sui documenti comprovanti la sua esistenza e non contestato da parte del Debitore Ceduto;\n' +
        '\n' +
        'ha adempiuto puntualmente agli obblighi di cui all’ordine/Contratto (All. 3) in base al quale il Credito è sorto;\n' +
        '\n' +
        'è unico legittimo e assoluto titolare del Credito oggetto di cessione, il quale è legittimamente trasferibile, non soggetto a sequestro, pignoramento, né ad altri vincoli a favore di terzi, di non aver liberato alcuno dei debitori ceduti e di non aver rinunciato ad alcun diritto nei confronti di essi;\n' +
        '\n' +
        'all’atto della cessione il Debitore Ceduto non ha crediti che possano essere imputati a compensazione, sia pure parziale, del Credito;\n' +
        '\n' +
        'i contratti da cui derivano i crediti ceduti con il presente atto sono stati validamente stipulati nell’osservanza delle disposizioni di legge vigenti ad essi applicabili, e non contengono clausole o disposizioni che impediscono o anche limitino il trasferimento o la cessione, in tutto o in parte, dei crediti che ne derivano;\n' +
        '\n' +
        '\n' +
        '\n' +
        "4.2 Le Parti convengono che la cessione del Credito viene effettuata pro-soluto e, per l'effetto, il Cedente viene liberato da ogni responsabilità in ordine alla solvibilità del Debitore Ceduto, rispetto a cui non presta alcuna garanzia.\n" +
        "4.3 Il Cedente si obbliga a manlevare e a tenere totalmente indenne il Cessionario da tutte le eventuali richieste per pretese oneri, danni e/o quant'altro avanzate da terzi a qualsiasi titolo ed in qualsiasi momento in ordine al Credito ceduto e si impegna in caso di procedimenti giudiziali e/o arbitrali conseguenti a tali richieste ovvero al fine di transigere tali pretese, a non compiere atti di remissione o rinuncia che possano pregiudicare la recuperabilità del Credito ceduto. Le Parti concordano che gli eventuali giudizi passivi relativi ai rapporti contrattuali da cui originano i crediti o le garanzie accessoria e finalizzate all’accertamento di responsabilità restitutorie o risarcitorie saranno gestiti a cura e spese del Cedente, fermo restando che il Cedente dovrà prontamente informare il Cessionario di tali azioni e di ogni altra azione passiva che possa comunque interessare il Cessionario.\n" +
        "4.4 Il Cedente dichiara che i documenti allegati al presente Accordo, che il Cessionario dichiara di avere esaminato e conoscere, costituiscono tutti i documenti probatori del Credito ceduto ai sensi dell'art. 1262 c.c., e della cui consegna da parte del Cedente il Cessionario dà atto con la sottoscrizione del presente Accordo; il Cedente si impegna inoltre, a partire dalla data della cessione, a trasmettere prontamente al Cessionario tutta la corrispondenza relativa al Credito oggetto di cessione che dovesse ricevere.\n" +
        '\n' +
        '5. Notifica al Debitore Ceduto \n' +
        '5.1 Contestualmente alla sottoscrizione del presente Accordo, ' +
        this.invoice.cedenteName +
        " si obbliga a provvedere a propria cura e spese alla notifica della comunicazione di cui all'art. 1264 c.c. al Debitore Ceduto nonché ai suoi eventuali avallanti, garanti e/o obbligati in solido ceduti, impegnandosi a porre in essere quant'altro fosse necessario affinché i destinatari prendano debita nota dell'intervenuta cessione del Credito ed a consegnare prontamente al Cessionario tutta la documentazione relativa alla predetta notifica non appena perfezionata.\n" +
        '\n' +
        '6. Risoluzione\n' +
        "6.1 Il Cessionario potrà risolvere il presente Accordo, ai sensi dell'art. 1456 c.c., dandone comunicazione scritta al Cedente a mezzo lettera raccomandata in caso di violazione di uno qualsiasi degli obblighi previsti dal presente Accordo.\n" +
        '6.1 Lo scioglimento dell’Accordo ai sensi del presente articolo legittima il Cessionario a richiedere al Cedente la restituzione delle somme pagate quale corrispettivo dei crediti ceduti e non ancora incassati, con obbligo del Cedente di indennizzare e manlevare il Cessionario, altresì, di tutte le eventuali spese sostenute.\n' +
        '\n' +
        '7. Comunicazioni\n' +
        '7.1 Se non diversamente convenuto per iscritto dalle Parti, qualsiasi comunicazione riguardante l’oggetto del presente Accordo dovrà essere effettuata mediante lettera raccomandata A/R ovvero posta elettronica certificata ai recapiti di seguito riportati:\n' +
        '\n' +
        'Se a FX12 \n' +
        'c.a. Rita Capitelli\n' +
        'Via Francesco Caracciolo 17 Napoli (NA)\n' +
        'PEC: fx12@pec.it \n' +
        '\n' +
        '\n' +
        '\n' +
        'Se a ' +
        this.invoice.cedenteName +
        '\n' +
        'c.a. ' +
        this.cedente.refName +
        '\n' +
        'Via ' +
        this.cedente.legalAddressStreet +
        '_' +
        this.cedente.legalAddressCity +
        '_' +
        this.cedente.legalAddressProvince +
        '_' +
        this.cedente.legalAddressCAP +
        '\n' +
        'PEC: ' +
        pecValidato +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '8. Miscellanea\n' +
        '8.1 Il presente Accordo è regolato dalla legge italiana.\n' +
        "8.2 Tutte le controversie che dovessero insorgere in ordine all'interpretazione, alla validità ed alla esecuzione del presente Accordo saranno devolute alla competenza esclusiva del Foro di [-], intendendosi consensualmente derogata ogni diversa norma di competenza giudiziaria.\n" +
        '8.3 Le Parti dichiarano che, avendo negoziato singolarmente e congiuntamente, le clausole del presente Accordo, tutte concordate fra le stesse, non sussistono i presupposti per l’approvazione specifica di cui all’art. 1341 del Codice Civile\n' +
        '\n' +
        'Allegati:\n' +
        '\n' +
        'fattura n° ' +
        this.invoice.numFattura +
        ' del ' +
        myDataConversion(this.invoice.dataFattura) +
        '\n' +
        '\n' +
        'Frontespizio IBAN\n' +
        '\n' +
        'Ordine/Contratto d’acquisto\n' +
        '\n' +
        '\n' +
        '\n' +
        'Napoli, \n' +
        '\n' +
        'Letto, confermato e sottoscritto\n' +
        '\n' +
        '__________________________ __________________________\n' +
        'FX12 srl ' +
        this.invoice.cedenteName +
        ' \n' +
        'Rita Capitelli ' +
        this.cedente.refName +
        '\n' +
        '(Amministratore Unico) (Amministratore Unico)\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n' +
        '\n';
      // tslint:disable-next-line:max-line-length
      // perchè venga rendeìrizzato dal canvas, htmlelement deve essere appeso come child al body (sarà successivamente rimosso)
      document.body.appendChild(contratto);
      const DATA = document.getElementById('htmlData');

      Utilities.downloadAsPDF(contratto, 'contratto', contrattoText);
      // rimuovo html element
      document.body.removeChild(contratto);
    });
  }

  aggiungiInvestitore() {
    const modalRef = this._modalService.open(ModalAddOriginatorComponent, {
      size: 'l',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.walletToModify = this.wallet;
    modalRef.componentInstance.invoice = this.invoice;
  }

  eliminaInvestitoreDaPercentuale(inv: string) {
    // console.log(inv);
    //  console.log(this.listaInvestitoriPagamento);
    //  console.log(this.listInvestitori);
    //  console.log(this.listaInvestitoriNonPaganti);
    const newListaInvestitoriPagamento = [];
    const newListaInvestitori = [];
    const newlistaInvestitoriNonPaganti = [];
    this.listaInvestitoriPagamento.forEach(element => {
      const inv2 = element.split('---')[0];
      if (inv != inv2) {
        newListaInvestitoriPagamento.push(element);
      } else {
        const per: number = +element.split('---')[1];
        this.percentualeSalvata = this.percentualeSalvata - per;
      }
    });
    this.listInvestitori.forEach(element => {
      const inv2 = element.split('---')[0];
      if (inv != inv2) {
        newListaInvestitori.push(element);
      }
    });
    this.listaInvestitoriNonPaganti.forEach(element => {
      if (inv != element) {
        newlistaInvestitoriNonPaganti.push(element);
      }
    });
    this.listaInvestitoriPagamento = newListaInvestitoriPagamento;
    this.listInvestitori = newListaInvestitori;
    this.listaInvestitoriNonPaganti = newlistaInvestitoriNonPaganti;

    // console.log(this.listaInvestitoriPagamento);
    // console.log(this.listInvestitori);
    //  console.log(this.listaInvestitoriNonPaganti);
    //  console.log(this.percentualeSalvata);

    this.percentualeCoperta = this.percentualeSalvata;
    /*
    this.invoice.listaInvestitori = this.listInvestitori;
    this.invoice.listaInvestitoriPagamento = this.listaInvestitoriPagamento;
    console.log(this.invoice);
    this.firebase.modInvoice(this.invoice);
    */
  }

  corrente() {
    if (this.investitore == null || this.investitore == '') {
      return false;
    }
    if (this.firebase.loggedUser.id == this.investitore) {
      return true;
    }
    return false;
  }

  uploadFileNota() {
    const filePath = this.invoice.id + '-NC.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          this.invoice.status = Utilities.invoiceStatus[4];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.adminChangeStatusIndex(11);
          // this.sendMailToAdminAndServicer('getNotaCreditoCaricataFormalmente', 'Nota Di Credito Caricata ');

          // invio email di notifica
          // creo il body dell'email

          //
          const body =
            '{\n' +
            '    "to":"' +
            this.debitore.email +
            '",\n' +
            '    "firstname":"' +
            this.debitore.originatorSolicitorName +
            '",\n' +
            '    "lastname":"' +
            this.debitore.originatorSolicitorSurname +
            '",\n' +
            '    "subject":"Nuova nota di credito",\n' +
            '    "senderCompany":"FX12 Srl",\n' +
            '    "from":"support@incassaora.it",\n' +
            '    "senderName":"Support of Incassaora",\n' +
            '    "templateName":"newCreditNote",\n' +
            '    "denominazioneCedente":"' +
            this.cedente.denominazione +
            '",\n' +
            '    "denominiazioneDebitore":"' +
            this.debitore.name +
            '"\n' +
            '}';

          // console.log('body=>', body);

          this.sendMail.sendEmailTemplateByBody(body);
        })
      )
      .subscribe();
  }

  notaCreditoVerified(verified: boolean, type: string) {
    switch (type) {
      case 'formal': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[5];
          this.firebase.modInvoice(this.invoice);
          this.sendMailToOriginator('getNotaCreditoCaricataFormalmente', 'DA APPROVARE');
          this.adminChangeStatusIndex(12);
        } else {
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToFornitore('getNotaCreditoCaricataScartataFormalmente', 'Nota Credito', this.invoice.uid);
          this.adminChangeStatusIndex(10);
        }
        break;
      }
      case 'official': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[6];
          this.firebase.modInvoice(this.invoice);
          this.adminChangeStatusIndex(13);
        } else {
          this.invoice.status = Utilities.invoiceStatus[3];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToFornitore('getNotaCreditoCaricataScartataUfficialmente', 'Nota Credito', this.invoice.uid);
          this.adminChangeStatusIndex(10);
        }
        break;
      }
    }
  }

  bonificoVerified(verified: boolean, type: string) {
    switch (type) {
      case 'formal': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[8];
          this.firebase.modInvoice(this.invoice);
          this.sendMailToAdminAndServicer('getBonificoCaricatoFormalmente', 'DA APPROVARE');
          this.adminChangeStatusIndex(15);
        } else {
          this.invoice.status = Utilities.invoiceStatus[6];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToOriginator('getBonificoCaricatoScartatoFormalmente', 'Bonfifico');
          this.adminChangeStatusIndex(13);
        }
        break;
      }
      case 'official': {
        if (verified) {
          this.invoice.status = Utilities.invoiceStatus[9];
          this.firebase.modInvoice(this.invoice);
          this.sendMailToFornitore('getBonificoCaricatoUfficialmente', 'Bonfifico', this.invoice.uid);
          this.adminChangeStatusIndex(16);
        } else {
          this.invoice.status = Utilities.invoiceStatus[6];
          this.firebase.modInvoice(this.invoice);
          this.activeModal.dismiss();
          this.sendMailToOriginator('getBonificoCaricatoScartatoUfficialmente', 'Bonfifico');
          this.adminChangeStatusIndex(13);
        }
        break;
      }
    }
  }

  verificaPagamentoInvestitori() {
    // Inserire controllo se tutti hanno pagato
    const array = this.listaInvestitoriDaPagare;

    // console.log('Investitori=>', array);

    let count = 0;

    for (let i = 0; i < array.length; i++) {
      const elem = array[i];

      const b = elem.split('---')[2];
      if (b == 'V' || b == 'F') {
        count = count + 1;
      }
    }
    // console.log('COUNT=>', count);
    if (count == 0) {
      console.log("Tutti gli investitori sono stati pagati o fattura precedente all'aggiornamento");
      this.adminChangeStatusIndex(37);
    }
  }
  PagamentoDaDebitoreAInvestor() {
    // console.log(this.invoice.listaInvestitoriDaPagare);
    // console.log(this.debitore);
    this.isLoading = true;

    if (!this.invoice.listaInvestitoriDaPagare) {
      this.listaInvestitoriDaPagare = this.invoice.listaInvestitoriPagamento;
      this.invoice.listaInvestitoriDaPagare = this.listaInvestitoriDaPagare;
      this.firebase.modInvoice(this.invoice);
    }

    // Inserire controllo se tutti hanno pagato
    const array = this.listaInvestitoriDaPagare;
    var count = 0;
    for (let i = 0; i < array.length; i++) {
      var elem = array[i];

      var b = elem.split('---')[2];
      if (b == 'V' || b == 'F') {
        count = count + 1;
      }
    }
    if (count == 0) {
      alert("Tutti gli investitori sono stati pagati o fattura precedente all'aggiornamento");
      this.adminChangeStatusIndex(37);

      //

      this.isLoading = false;
      return;
    }

    const fondi = 100000000;
    // Importo finale senza sconto
    const prezzofinale = this.invoice.importoFattura;
    var prezzoCalcolo = this.invoice.importoFattura;
    // console.log('AMOUNT:' + prezzofinale);

    if (fondi > prezzofinale) {
      for (let i = 0; i < array.length; i++) {
        var elem2 = array[i];
        const inv = elem2.split('---')[0];
        const per: number = +elem2.split('---')[1];
        const bool = elem2.split('---')[2];

        prezzoCalcolo = (prezzofinale * per) / 100;
        // console.log(prezzoCalcolo);
        prezzoCalcolo = prezzoCalcolo * 100;
        // console.log(prezzoCalcolo);
        const valoreForLemonway: number = Math.trunc(prezzoCalcolo);
        // console.log(valoreForLemonway);

        if (bool == 'V' || bool == 'F') {
          // "3322116655"
          const comment = 'Refund Investor';
          this.lemonway.transaction(valoreForLemonway, inv, this.debitore.originatorPIva, comment).subscribe(data => {
            try {
              if (data.transaction.status == 3) {
                const newBool = 'R';
                elem2 = inv + '---' + per + '---' + newBool;
                array[i] = elem2;
                this.listaInvestitoriDaPagare = array;
                this.invoice.listaInvestitoriDaPagare = this.listaInvestitoriDaPagare;
                this.firebase.modInvoice(this.invoice);
                alert(
                  'Transazione verso: ' +
                    inv +
                    ' eseguita, se ci sono altri investitori procedo con la prossima operazione'
                );

                // recupero info su investitore
                const httpOptions = {
                  headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
                  })
                };
                this.http
                  .post<any>(
                    `${environment.laravelServer}/users/getInvestorByPIva`,
                    '{"piva":"' + inv + '"}',
                    httpOptions
                  )
                  .subscribe(resp => {
                    if (resp) {
                      if (resp.data.length > 0) {
                        // invio email
                        // invio email ad investitore per operazione rientrata
                        // creo il body dell'email
                        const body =
                          '{\n' +
                          '    "to":"' +
                          resp.data[0].email +
                          '",\n' +
                          '    "firstname":"' +
                          resp.data[0].originatorSolicitorName +
                          '",\n' +
                          '    "lastname":"' +
                          resp.data[0].originatorSolicitorSurname +
                          '",\n' +
                          '    "subject":"Operazione Rientrata",\n' +
                          '    "senderCompany":"FX12 Srl",\n' +
                          '    "from":"support@incassaora.it",\n' +
                          '    "senderName":"Support of Incassaora",\n' +
                          '    "templateName":"operationReturned",\n' +
                          '    "denominazioneInvestitore":"' +
                          resp.data[0].originatorSolicitorName +
                          ' ' +
                          resp.data[0].originatorSolicitorSurname +
                          '",\n' +
                          '    "denominazionePortafoglio":"' +
                          this.wallet.walletName +
                          '"\n' +
                          '}';

                        console.log('body=>', body);
                        this.sendMail.sendEmailTemplateByBody(body);
                      }
                    }
                  });
              } else {
                alert('Errore nella transazione verso: ' + inv);
              }
            } catch (e) {
              alert('Errore nella transazione verso: ' + inv + ' Errore: ' + data.error.message);
            }
          });
        }
      }

      this.activeModal.close();
    } else {
      alert(
        "ALERT: Fondi non sufficienti a coprire l'importo richiesto.\n Effettuare una ricarica oppure contattare l'amministrazione"
      );
    }
  }

  contrattoVerified(verified: boolean) {
    if (verified) {
      this.firebase.modInvoice(this.invoice);
      // this.sendMailToAdminAndServicer('getContrattoCaricatoFormalmente', 'Contratto Approvato');

      // invio email di notifica
      // creo il body dell'email
      const body =
        '{\n' +
        '    "to":"' +
        this.cedente.email +
        '",\n' +
        '    "subject":"Nuovo contratto",\n' +
        '    "senderCompany":"FX12 Srl",\n' +
        '    "from":"support@incassaora.it",\n' +
        '    "senderName":"Support of Incassaora",\n' +
        '    "templateName":"newContract",\n' +
        '    "denominazioneCedente":"' +
        this.cedente.name +
        '",\n' +
        '    "denominiazioneDebitore":"' +
        this.debitore.name +
        '"\n' +
        '}';

      // console.log('body=>',body);
      this.sendMail.sendEmailTemplateByBody(body);

      // ************** INVIO PEC CON CONTRATTO *************************

      // creo il body dell'email
      const bodyPEC =
        '{\n' +
        '    "to":"' +
        this.cedente.assignorPec +
        '",\n' +
        '    "to_name":"' +
        this.cedente.name +
        '",\n' +
        '    "subject":"Nuovo contratto",\n' +
        '    "body":"Spett.le ' +
        this.cedente.name +
        ',<br> In allegato trova il documento di cessione controfirmato.<br><br>Team of IncassaOra",\n' +
        '    "filename":"' +
        this.filename +
        '",\n' +
        '    "attachmentBase64":"' +
        this.file64Encode +
        '"\n' +
        '}';

      // console.log('bodyPEC=>', bodyPEC);
      this.sendMail.sendPecByBody(bodyPEC);

      // ****************************************************************

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };

      console.log('Invio');

      this.http
        .post<any>(
          `${environment.laravelServer}/investorpreferences/sendMailToInvestor`,
          '{"id":"' + this.invoice.id + '"}',
          httpOptions
        )
        .subscribe(resp => {
          console.log(resp);
        });

      this.adminChangeStatusIndex(32);
    } else {
      this.firebase.modInvoice(this.invoice);
      this.activeModal.dismiss();
      this.sendMailToOriginator('getContrattoCaricatoScartatoFormalmente', 'Contratto  Dismesso');
      this.adminChangeStatusIndex(30);
    }
  }

  chiudiFattura() {
    this.sendMailToFornitore('getBonificoCaricataFormalmente', 'Bonfico Caricato', this.invoice.uid);
    this.invoice.status = Utilities.invoiceStatus[8];
    this.firebase.modInvoice(this.invoice);
    this.activeModal.dismiss();
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];

    const input = event.target as HTMLInputElement;
    const file = input.files[0];
    this.fileToUpload = file;
    // console.log(file);

    this.filename = file.name;
    const typeFile = file.type;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // console.log(reader.result);
      this.file64Encode = reader.result as string;
      const strReplace = 'data:' + typeFile + ';base64,';
      this.file64Encode = this.file64Encode.replace(strReplace, '');
      // console.log(this.file64Encode);
    };
  }

  scaricaContratto() {
    // metodo che si basa sulla generazione del pdf dobbiamo vederlo con gaetano cosi da farlo
    // in maniera rapida
  }

  uploadFileBonifico() {
    const filePath = this.invoice.id + '-BON.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          this.invoice.status = Utilities.invoiceStatus[7];
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaBonifico = pipe.transform(today, 'yyyy-MM-dd');
          this.firebase.modInvoice(this.invoice);
          this.firebase.chiudiFattura(this.wallet.id, this.invoice.importoFattura, this.invoice.scontoAccettato);
          this.adminChangeStatusIndex(14);

          // (14) Yellow - In attesa di verifica formale (admin) bonifico
          this.sendMail.sendGenericEmail('desk@fx12.it', 'Amministratore', 'FX12', this.invoice.numFattura);

          this.activeModal.dismiss();
        })
      )

      .subscribe();
  }

  uploadFileContratto() {
    const filePath = this.invoice.id + '-CONTRATTOFIRMATO.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaContratto = pipe.transform(today, 'yyyy-MM-dd');
          this.firebase.modInvoice(this.invoice);
          if (this.invoice.status_index == 31) {
            this.uploading = true;
            return;
          }
          this.adminChangeStatusIndex(31);

          // (31) Blu - In attesa di verifica contratto (admin)
          this.sendMail.sendGenericEmail('desk@fx12.it', 'Amministratore', 'FX12', this.invoice.numFattura);

          this.activeModal.dismiss();
        })
      )

      .subscribe();
  }

  setUrlDownloadDoc(value: string) {
    const ref = this.storage.ref(this.invoice.id + value);
    this.contrattoURl = ref.getDownloadURL();
  }

  uploadFileContrattoSPVCedente() {
    const filePath = this.invoice.id + '-CONTRATTO_SPV_FIRMATO_INVESTITORE.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaContrattoSPV = pipe.transform(today, 'yyyy-MM-dd');

          const obj = {
            status_index: 53,
            dataCaricaContrattoSPV: this.invoice.dataCaricaContrattoSPV,
            status: Utilities.invoiceStatus_it_IT[53]
          };

          this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(data => {
            this.sendPecWithDocument(
              this.cedente.assignorPec,
              this.cedente.name,
              this.filename,
              this.file64Encode,
              'Proposta di contratto - copia cedente'
            );

            // investitore
            this.sendPecWithDocument(
              this.listInvestorSPV[0].pec,
              this.listInvestorSPV[0].ragioneSociale,
              this.filename,
              this.file64Encode,
              'Proposta di contratto - copia investitore'
            );
          });

          this.activeModal.dismiss();
        })
      )

      .subscribe();
  }

  uploadFileContrattoSPVInvestor() {
    const filePath = this.invoice.id + '-CONTRATTO_SPV_FIRMATO_CEDENTE_INVESTORSPV.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaContrattoSPV = pipe.transform(today, 'yyyy-MM-dd');

          const obj = {
            status_index: 54,
            dataCaricaContrattoSPVInvestor: this.invoice.dataCaricaContrattoSPV,
            status: Utilities.invoiceStatus_it_IT[54]
          };

          this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
            // invio pec a cedente
            this.sendPecWithDocument(
              this.cedente.assignorPec,
              this.cedente.name,
              this.filename,
              this.file64Encode,
              'Accettazione contratto - copia cedente'
            );

            // investitore
            this.sendPecWithDocument(
              this.listInvestorSPV[0].pec,
              this.listInvestorSPV[0].ragioneSociale,
              this.filename,
              this.file64Encode,
              'Accettazione contratto - copia investitore'
            );

            this.activeModal.dismiss();
          });
        })
      )
      .subscribe(data => {});
  }

  sendPecWithDocument(to: string, to_name: string, filename: string, file: any, subject_: string) {
    // creo il body dell'email
    const bodyPEC =
      '{\n' +
      '    "to":"' +
      to +
      '",\n' +
      '    "to_name":"' +
      to_name +
      '",\n' +
      '    "subject":"' +
      subject_ +
      '",\n' +
      '    "body":"Spett.le ' +
      to_name +
      ',<br> In allegato trova il documento di cessione controfirmato.<br><br>Team of IncassaOra",\n' +
      '    "filename":"' +
      filename +
      '",\n' +
      '    "attachmentBase64":"' +
      file +
      '"\n' +
      '}';

    // console.log('bodyPEC=>', bodyPEC);
    this.sendMail.sendPecByBody(bodyPEC);
  }

  open(name: string, fileToUpload: number) {
    const modalRef = this._modalService.open(MODALS[name]);
    modalRef.result.then(
      result => {
        if (fileToUpload == 0) {
          this.uploadFileNota();
        }
        if (fileToUpload == 1) {
          this.uploadFileBonifico();
        }
        if (fileToUpload == 2) {
          this.uploadFileContratto();
        }
        if (fileToUpload == 3) {
          this.uploadFileContrattoSPVCedente();
        }
        if (fileToUpload == 4) {
          this.uploadFileContrattoSPVInvestor();
        }
        if (fileToUpload == 5) {
          this.uploadFileContabileBonificoSPVInvestor();
        }
        if (fileToUpload == 6) {
          this.uploadFileNotificationToDebit();
        }
      },
      reason => {}
    );
  }

  private uploadFileNotificationToDebit() {
    const filePath = this.invoice.id + '-NOTIFICATION-TO-DEBITOR.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));

    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaContrattoSPV = pipe.transform(today, 'yyyy-MM-dd');
          const obj = {
            status_index: 58,
            status: Utilities.invoiceStatus_it_IT[58]
          };
          this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
            console.log('body => ', respEnt);
            // invio pec a investitore e cedente
            // TODO: Inviare notifica ad investitore spv per confermare di aver ricevuto la PEC
            this.activeModal.dismiss();
          });
        })
      )
      .subscribe(data => {});
  }

  scaricaContrattoPDF() {
    var iban = '___________________________';
    if (this.cedente.iban) {
      iban = this.cedente.iban;
    }

    let docDefinition = {
      pageSize: 'A4',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [60, 60, 60, 60],
      header: [
        {
          image: 'logo',
          width: 120,
          height: 100,
          alignment: 'center',
          margin: [5, 10]
        },

        { canvas: [{ type: 'rect', x: 170, y: 32, h: 100 }] }
      ],

      footer: (currentPage, pageCount) => [
        {
          text: 'FX12 s.r.l iscritta nell’albo speciale delle Start up innovative ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2],
          bold: true
        },
        {
          text:
            'Capitale sociale Euro 10.0000 i.v., Registro Imprese Napoli e Codice Fiscale N. 09528731210, R.E.A. Napoli N. 1039449, Part. IVA 09528731210 ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text: 'Sede legale: Via Caracciolo, 17, 80122 Napoli.',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text:
            'Sede operativa: Via Coroglio, 57/104, 80124 Napoli c/o Campania NewSteel | Tel: +39 081 7352503 Email: desk@fx12.it PEC fx12@pec.it',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text: '\nPagina  ' + currentPage.toString() + ' di ' + pageCount,
          fontSize: 8,
          color: '#9C9C9C',
          alignment: 'center'
        },
        {
          text: '_',
          fontSize: 8,
          color: '#9C9C9C',
          alignment: 'center'
        }
      ],

      content: [
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: ' '
        },
        {
          text: this.cedente.name,
          margin: [0, 5]
        },
        {
          text:
            this.cedente.legalAddressStreet +
            ' ' +
            this.cedente.legalAddressCity +
            ' ' +
            this.cedente.legalAddressProvince,
          margin: [0, 10]
        },
        {
          text: 'Alla cortese attenzione',
          margin: [0, 10],
          decoration: 'underline',
          italics: true
        },
        {
          text:
            this.cedente.refName +
            ' ' +
            (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname),
          margin: [0, 5]
        },
        {
          text: this.data,
          alignment: 'right',
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori, ',
          italics: true,
          margin: [0, 10]
        },
        {
          text:
            'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di cessione del credito.',
          italics: true,
          margin: [0, 10]
        },
        {
          text: 'Oggetto: Contratto di cessione del credito ',
          bold: true,
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori: ',
          margin: [0, 5]
        },
        {
          text: 'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue: '
        },
        {
          text: 'ACCORDO',
          bold: true,
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text: 'Tra',
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text:
            'FX12 s.r.l., con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 in persona della Dott.ssa Rita Capitelli, che sottoscrive il presente accordo in qualità di amministratore e legale rappresentante (di seguito, “FX12” o il “Cessionario”) ',
          margin: [0, 2],
          alignment: 'justify'
        },
        {
          text: '- da una parte -',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text:
            this.cedente.legalName +
            ', con sede legale in ' +
            this.cedente.legalAddressCity +
            ' (' +
            this.cedente.legalAddressProvince +
            ')' +
            ', ' +
            this.cedente.legalAddressStreet +
            ' CF. ' +
            this.cedente.fiscalCode +
            ' e P.Iva ' +
            this.cedente.pIva +
            ', numero di iscrizione al Registro delle Imprese di ' +
            this.cedente.legalAddressProvince +
            ' n. ' +
            this.cedente.REA_CODE +
            ', in persona del Sig. ' +
            this.cedente.refName +
            ' ' +
            (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname) +
            ' ' +
            ', che sottoscrive il presente accordo in qualità di Legale Rappresentante (di seguito, “ ' +
            this.cedente.denominazione +
            '” o il “Cedente”);',
          margin: [0, 5]
        },
        {
          text: '- dall’altra parte - ',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text:
            'FX12 e ' +
            this.cedente.denominazione +
            ', d’ora in avanti congiuntamente denominate le "Parti", e ciascuna singolarmente una "Parte");',
          margin: [0, 2],
          alignment: 'justify',
          pageBreak: 'after'
        },

        {
          margin: [0, 20, 0, 0],
          text: 'PREMESSO CHE',
          alignment: 'center',
          bold: true
        },
        //PROSSIMO FOGLIO
        {
          text: ' '
        },
        {
          type: 'upper-alpha',
          ol: [
            {
              text:
                'FX12 è una start up innovativa che progetta e realizza piattaforme web finalizzate allo sviluppo di servizi di finanza digitale dedicati all’accelerazione dei pagamenti ed alla cessione di fatture commerciali; ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.wallet.originatorName +
                ' è un’azienda dotata di un elevato merito creditizio, interessata ad accelerare i pagamenti ai propri fornitori, e che a tal fine ha aderito alla piattaforma Incassa Ora® di FX12 (di seguito anche il “Debitore Ceduto”);',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.cedente.denominazione +
                ' è il fornitore di ' +
                this.wallet.originatorName +
                ', ed è interessato ad ottenere il pagamento anticipato dei crediti vantati nei confronti di ' +
                this.wallet.originatorName,
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.wallet.originatorName +
                ', in particolare, si è impegnata a corrispondere a ' +
                this.cedente.denominazione +
                ' un importo, da liquidarsi entro ' +
                this.wallet.paymentDays +
                ' giorni dall’emissione della fattura; ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.cedente.denominazione +
                ' ha manifestato l’intenzione di cedere e trasferire a FX12 attraverso la piattaforma Incassa Ora® il singolo credito risultante dalla fattura n. ' +
                this.invoice.numFattura +
                ' del ' +
                myDataConversion(this.invoice.dataFattura) +
                '; ',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text: 'FX12 intende acquistare il credito secondo le modalità e termini di cui al presente contratto. ',
              alignment: 'justify',
              margin: [0, 2]
            }
          ]
        },
        {
          text:
            "TUTTO CIÒ PREMESSO, in virtù dei reciproci accordi qui contenuti, e secondo i termini e le condizioni del presente accordo (l'Accordo), le Parti convengono quanto segue:",
          margin: [0, 20],
          alignment: 'justify'
        },
        {
          ol: [
            [
              { text: 'Premesse ', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text: 'Le Premesse e gli Allegati formano parte integrante e sostanziale del presente Accordo',
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Oggetto', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      'In forza della sottoscrizione del presente Accordo, il Cedente cede a titolo oneroso pro-soluto al Cessionario, che accetta, il credito risultante dalla fattura n° ' +
                      this.invoice.numFattura +
                      ' del ' +
                      myDataConversion(this.invoice.dataFattura) +
                      ' di ' +
                      formatCurrency(this.invoice.importoFattura, this.locale, '€') +
                      ' (di seguito, il “Credito”) che vanta nei confronti di ' +
                      this.wallet.originatorName,
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Il Cedente consegna al Cessionario tutta la documentazione comprovante il Credito e, nello specifico: (i) fattura n° ' +
                      this.invoice.numFattura +
                      ' del  ' +
                      myDataConversion(this.invoice.dataFattura) +
                      ' (All. 1).',
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Dalla data di sottoscrizione del presente Accordo, quindi, FX12 diventa a tutti gli effetti di legge titolare del Credito ceduto. Il Credito viene trasferito al Cessionario con tutti i privilegi, garanzie personali, garanzie reali e con gli altri accessori ivi comprese eventuali pattuizioni contrattuali ai sensi dell’1263, comma 1, c.c..',
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Prezzo', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      'A fronte della cessione del Credito, il Cessionario si obbliga a pagare in favore del Cedente:un importo pari a ' +
                      formatCurrency(
                        myRound(
                          this.invoice.importoFattura -
                            (this.invoice.importoFattura * this.invoice.scontoAccettato) / 100
                        ),
                        this.locale,
                        '€'
                      ) +
                      ' , entro ' +
                      this.wallet.paymentDays +
                      ' giorni lavorativi dalla sottoscrizione del presente Accordo.',
                    margin: [0, 2],
                    pageBreak: 'after',
                    alignment: 'justify'
                  },
                  {
                    margin: [0, 22, 0, 2],
                    text:
                      'I pagamenti saranno effettuati sul conto corrente n. IBAN ' +
                      iban +
                      ' intestato a ' +
                      this.cedente.denominazione +
                      ' (All. 2).'
                  }
                ]
              }
            ],
            [
              { text: 'Garanzie e obblighi delle Parti', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  [
                    { text: 'Il Cedente garantisce che', margin: [0, 5] },
                    {
                      ul: [
                        {
                          text:
                            'non è in stato di insolvenza, non esistono fatti o circostanze che potrebbero esporla a eventuali procedure concorsuali e non ha depositato né deliberato il deposito di una domanda di concordato;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'il Credito è certo, liquido ed esigibile rispetto alla scadenza riportata sui documenti comprovanti la sua esistenza e non contestato da parte del Debitore Ceduto;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'ha adempiuto puntualmente agli obblighi di cui all’ordine/Contratto (All. 3) in base al quale il Credito è sorto;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'è unico legittimo e assoluto titolare del Credito oggetto di cessione, il quale è legittimamente trasferibile, non soggetto a sequestro, pignoramento, né ad altri vincoli a favore di terzi, di non aver liberato alcuno dei debitori ceduti e di non aver rinunciato ad alcun diritto nei confronti di essi;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'all’atto della cessione il Debitore Ceduto non ha crediti che possano essere imputati a compensazione, sia pure parziale, del Credito;',
                          margin: [0, 2],
                          alignment: 'justify'
                        },
                        {
                          text:
                            'i contratti da cui derivano i crediti ceduti con il presente atto sono stati validamente stipulati nell’osservanza delle disposizioni di legge vigenti ad essi applicabili, e non contengono clausole o disposizioni che impediscono o anche limitino il trasferimento o la cessione, in tutto o in parte, dei crediti che ne derivano;',
                          margin: [0, 2],
                          alignment: 'justify'
                        }
                      ]
                    }
                  ],
                  {
                    text:
                      "Le Parti convengono che la cessione del Credito viene effettuata pro-soluto e, per l'effetto, il Cedente viene liberato da ogni responsabilità in ordine alla solvibilità del Debitore Ceduto, rispetto a cui non presta alcuna garanzia.",
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      "Il Cedente si obbliga a manlevare e a tenere totalmente indenne il Cessionario da tutte le eventuali richieste per pretese oneri, danni e/o quant'altro avanzate da terzi a qualsiasi titolo ed in qualsiasi momento in ordine al Credito ceduto e si impegna in caso di procedimenti giudiziali e/o arbitrali conseguenti a tali richieste ovvero al fine di transigere tali pretese, a non compiere atti di remissione o rinuncia che possano pregiudicare la recuperabilità del Credito ceduto. Le Parti concordano che gli eventuali giudizi passivi relativi ai rapporti contrattuali da cui originano i crediti o le garanzie accessoria e finalizzate all’accertamento di responsabilità restitutorie o risarcitorie saranno gestiti a cura e spese del Cedente, fermo restando che il Cedente dovrà prontamente informare il Cessionario di tali azioni e di ogni altra azione passiva che possa comunque interessare il Cessionario.",
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      "Il Cedente dichiara che i documenti allegati al presente Accordo, che il Cessionario dichiara di avere esaminato e conoscere, costituiscono tutti i documenti probatori del Credito ceduto ai sensi dell'art. 1262 c.c., e della cui consegna da parte del Cedente il Cessionario dà atto con la sottoscrizione del presente Accordo; il Cedente si impegna inoltre, a partire dalla data della cessione, a trasmettere prontamente al Cessionario tutta la corrispondenza relativa al Credito oggetto di cessione che dovesse ricevere.",
                    margin: [0, 2],
                    alignment: 'justify',
                    pageBreak: 'after'
                  }
                ]
              }
            ],

            //PROSSIMO BLOCCO

            [
              { text: 'Notifica al Debitore Ceduto', bold: true, decoration: 'underline', margin: [0, 22, 0, 2] },
              {
                ol: [
                  {
                    text:
                      'Contestualmente alla sottoscrizione del presente Accordo, ' +
                      this.cedente.denominazione +
                      " si obbliga a provvedere a propria cura e spese alla notifica della comunicazione di cui all'art. 1264 c.c. al Debitore Ceduto nonché ai suoi eventuali avallanti, garanti e/o obbligati in solido ceduti, impegnandosi a porre in essere quant'altro fosse necessario affinché i destinatari prendano debita nota dell'intervenuta cessione del Credito ed a consegnare prontamente al Cessionario tutta la documentazione relativa alla predetta notifica non appena perfezionata.",
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Risoluzione', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      "Il Cessionario potrà risolvere il presente Accordo, ai sensi dell'art. 1456 c.c., dandone comunicazione scritta al Cedente a mezzo lettera raccomandata in caso di violazione di uno qualsiasi degli obblighi previsti dal presente Accordo.",
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      'Lo scioglimento dell’Accordo ai sensi del presente articolo legittima il Cessionario a richiedere al Cedente la restituzione delle somme pagate quale corrispettivo dei crediti ceduti e non ancora incassati, con obbligo del Cedente di indennizzare e manlevare il Cessionario, altresì, di tutte le eventuali spese sostenute.',
                    margin: [0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ],
            [
              { text: 'Comunicazioni', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text:
                      'Se non diversamente convenuto per iscritto dalle Parti, qualsiasi comunicazione riguardante l’oggetto del presente Accordo dovrà essere effettuata mediante lettera raccomandata A/R ovvero posta elettronica certificata ai recapiti di seguito riportati:',
                    margin: [0, 5, 0, 15],
                    alignment: 'justify'
                  }
                ]
              },
              {
                text: [
                  { text: 'Se a FX12', bold: true, alignment: 'left' },
                  {
                    text:
                      '                                                            Se a ' + this.cedente.denominazione,
                    bold: true,
                    margin: [0, 0, 20, 0]
                  }
                ]
              },
              {
                text: [
                  { text: 'c.a. Rita Capitelli', alignment: 'left' },
                  {
                    text:
                      '                                                 c.a. ' +
                      this.cedente.refName +
                      ' ' +
                      (this.cedente.refCogn && this.cedente.refCogn != ''
                        ? this.cedente.refCogn
                        : this.cedente.surname),
                    margin: [0, 0, 20, 0]
                  }
                ]
              },
              {
                text: [
                  { text: 'Via Francesco Caracciolo 17 Napoli (NA) ', alignment: 'left' },
                  {
                    text:
                      '     ' +
                      this.cedente.legalAddressStreet +
                      '  ' +
                      this.cedente.legalAddressCity +
                      ' (' +
                      this.cedente.legalAddressProvince +
                      ')',
                    margin: [0, 0, 20, 0]
                  }
                ]
              },
              {
                text: [
                  { text: 'PEC: fx12@pec.it', alignment: 'left' },
                  {
                    text: '                                                 PEC: ' + this.cedente.assignorPec,
                    margin: [0, 0, 20, 0]
                  }
                ]
              }
            ],
            [
              { text: 'Miscellanea', bold: true, decoration: 'underline', margin: [0, 10, 0, 10] },
              {
                ol: [
                  {
                    text: 'Il presente Accordo è regolato dalla legge italiana.',
                    margin: [0, 2],
                    alignment: 'justify'
                  },
                  {
                    text:
                      "Tutte le controversie che dovessero insorgere in ordine all'interpretazione, alla validità ed alla esecuzione del presente Accordo saranno devolute alla competenza esclusiva del Foro di Napoli, intendendosi consensualmente derogata ogni diversa norma di competenza giudiziaria.",
                    margin: [0, 2],
                    alignment: 'justify',
                    pageBreak: 'after'
                  },
                  {
                    text:
                      'Le Parti dichiarano che, avendo negoziato singolarmente e congiuntamente, le clausole del presente Accordo, tutte concordate fra le stesse, non sussistono i presupposti per l’approvazione specifica di cui all’art. 1341 del Codice Civile',
                    margin: [0, 22, 0, 2],
                    alignment: 'justify'
                  }
                ]
              }
            ]
          ]
        },
        [
          { text: 'Allegati', bold: true, decoration: 'underline', margin: [0, 20, 0, 0] },
          {
            ol: [
              {
                text: 'fattura n° ' + this.invoice.numFattura + ' del ' + myDataConversion(this.invoice.dataFattura),
                margin: [0, 2],
                alignment: 'justify'
              },
              {
                text: 'Frontespizio IBAN',
                margin: [0, 2],
                alignment: 'justify'
              },
              {
                text: 'Ordine/Contratto d’acquisto',
                margin: [0, 2],
                alignment: 'justify'
              }
            ]
          }
        ],

        {
          text: 'Napoli,   ',
          margin: [0, 20, 0, 0]
        },
        {
          text: 'Letto, confermato e sottoscritto',
          margin: [0, 10, 0, 10]
        },
        {
          columns: [
            {
              // auto-sized columns have their widths based on their content
              width: '50%',
              text: '\n\n\n__________________________________\nFX12\nRita Capitelli\n(Amministratore Unico)'
            },
            {
              // star-sized columns fill the remaining space
              // if there's more than one star-column, available width is divided equally
              width: '50%',
              text:
                '\n\n\n__________________________________\n' +
                this.cedente.denominazione +
                '\n' +
                this.cedente.refName +
                ' ' +
                (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname) +
                '\n(Legale rappresentante)'
            }
          ],
          // optional space between columns
          columnGap: 10
        }
      ],
      images: {
        logo: 'https://panel.incassaora.it/assets/logoPDF.png'
      },

      styles: {}
    };

    pdfMake.createPdf(docDefinition).open();
  }

  sendB64PdfSpv() {
    //console.log(this.invoice.id);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const body =
      '{\n"inv_id": "' +
      this.invoice.id +
      '", ' +
      '\n"first": "' +
      this.contrattoSPV_first +
      '", ' +
      '\n"last": "' +
      this.contrattoSPV_last +
      '", ' +
      '\n"a_a": "' +
      this.allegato_a +
      '", ' +
      '\n"a_b": "' +
      this.allegato_b +
      '", ' +
      '\n"a_c": "' +
      this.allegato_c +
      '"\n}';

    //console.log(body);

    // resp.file
    this.http.post<any>(`${environment.laravelServer}/merged/downDoc`, body, httpOptions).subscribe(resp => {
      console.log(resp);

      const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
      };

      const contentType = 'application/pdf';

      const blob = b64toBlob(resp.file, contentType);
      const blobUrl = URL.createObjectURL(blob);

      console.log('blob: ', blob);
      console.log('blobUrl: ', blobUrl);

      window.open(blobUrl);
    });
  }

  generaAllegatoA() {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [60, 60, 60, 60],
      header: [],
      footer: (currentPage, pageCount) => [],
      content: [
        {
          text: 'ALLEGATO A',
          style: {
            fontSize: 16,
            bold: true,
            alignment: 'center'
          }
        },
        {
          text: 'FATTURE',
          style: {
            fontSize: 14,
            bold: true,
            alignment: 'center'
          }
        }
      ],
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      },
      styles: {
        header: {
          fontSize: 14,
          alignment: 'right'
        }
      }
    };

    const pdf_gen = pdfMake.createPdf(docDefinition);
    pdf_gen.getBase64(data => {
      this.allegato_a = data;
    });
  }

  generaAllegatoB() {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [60, 60, 60, 60],
      header: [],
      footer: (currentPage, pageCount) => [],
      content: [
        {
          text: 'ALLEGATO B',
          style: {
            fontSize: 16,
            bold: true,
            alignment: 'center'
          }
        },
        {
          text: 'FRONTESPIZIO IBAN',
          style: {
            fontSize: 14,
            bold: true,
            alignment: 'center'
          }
        }
      ],
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      },
      styles: {
        header: {
          fontSize: 14,
          alignment: 'right'
        }
      }
    };

    const pdf_gen = pdfMake.createPdf(docDefinition);
    pdf_gen.getBase64(data => {
      this.allegato_b = data;
    });
  }

  generaAllegatoC() {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [60, 60, 60, 60],
      header: [],
      footer: (currentPage, pageCount) => [],
      content: [
        {
          text: 'ALLEGATO C',
          style: {
            fontSize: 16,
            bold: true,
            alignment: 'center'
          }
        },
        {
          text: 'ORDINE',
          style: {
            fontSize: 14,
            bold: true,
            alignment: 'center'
          }
        }
      ],
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      },
      styles: {
        header: {
          fontSize: 14,
          alignment: 'right'
        }
      }
    };

    const pdf_gen = pdfMake.createPdf(docDefinition);
    pdf_gen.getBase64(data => {
      this.allegato_c = data;
    });
  }

  generaDocContrattoSPV() {
    this.scaricaContrattoPDFSPV();
    this.generaGDPR_firma();
    this.generaAllegatoA();
    this.generaAllegatoB();
    this.generaAllegatoC();

    setTimeout(() => {
      /*
      console.log('len_first: ' + this.contrattoSPV_first.length);
      console.log('len_last: ' + this.contrattoSPV_last.length);
      console.log('passed 1 sec');
      console.log("len_allegatoA: " + this.allegato_a.length);
      console.log("len_allegatoB: " + this.allegato_b.length);
      console.log("len_allegatoC: " + this.allegato_c.length);
      */
      this.sendB64PdfSpv();
    }, 1000);
  }

  scaricaContrattoPDFSPV() {
    var iban = '___________________________';
    if (this.cedente.iban) {
      iban = this.cedente.iban;
    }

    let docDefinition = {
      pageSize: 'A4',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [60, 60, 60, 60],
      header: [
        /*
        {
          image: 'logo',
          width: 120,
          height: 100,
          alignment: 'center',
          margin: [5, 10]
        },

        { canvas: [{ type: 'rect', x: 170, y: 32, h: 100 }] }
        */
      ],

      footer: (currentPage, pageCount) => [],

      content: [
        {
          text: '',
          headlineLevel: 1
        },

        {
          text: ' '
        },
        {
          text: 'Spett.le:' + this.cedente.denominazione,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        {
          text: this.cedente.legalAddressCity + ' (' + this.cedente.legalAddressProvince + ')',
          margin: [0, 5, 0, 0]
        },
        {
          text: this.cedente.legalAddressStreet,
          margin: [0, 5, 0, 0]
        },
        {
          text: 'CAP ' + this.cedente.legalAddressCAP,
          margin: [0, 5, 0, 0]
        },
        {
          text:
            'Alla cortese attenzione ' +
            this.cedente.refName +
            ' ' +
            (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname),
          margin: [0, 5, 0, 0]
        },
        {
          // CHECK DATA
          text: 'Milano, ' + this.data,
          alignment: 'right',
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori, ',
          italics: true
          //margin: [0, 10, 0, 0]
        },
        {
          text:
            'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di cessione del credito.',
          italics: true
          //margin: [0, 10]
        },
        {
          text: 'Oggetto: Proposta contratto di cessione del credito ',
          bold: true,
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori: ',
          italics: true
          //margin: [0, 0, 0, 10]
        },
        {
          text: 'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue:',
          italics: true,
          margin: [0, 0, 0, 5]
        },

        {
          text: 'ACCORDO DI CESSIONE DEL CREDITO',
          bold: true,
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text: 'Tra',
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text: [
            '- 	',
            {
              text: this.firebase.loggedUser.ragioneSociale,
              bold: true
            },
            ', una società a responsabilità limitata con socio unico avente sede legale in Milano (20122), Corso Vittorio Emanuele 24/28, capitale sociale pari ad Euro 10.000,00 (dieci mila/00), i.v., avente numero di iscrizione al Registro delle Imprese di Milano Codice fiscale e n. iscr. al Registro Imprese ' +
              this.firebase.loggedUser.pIva +
              ', R.E.A. ' +
              this.firebase.loggedUser.province +
              ' - ' +
              this.firebase.loggedUser.rea +
              ', costituita ai sensi della legge sulla cartolarizzazione, n. 130, del 30 aprile 1999 (“',
            { text: 'Legge 130', bold: true },
            '”) in corso di iscrizione nell’ “',
            { text: 'elenco delle società veicolo', italics: true },
            '” tenuto da Banca d’Italia avente quale oggetto sociale la realizzazione di una o più operazioni di cartolarizzazione dei crediti  (di seguito, il “',
            { text: 'Cessionario', bold: true },
            '”)'
          ],
          margin: [0, 2],
          alignment: 'justify'
        },
        {
          text: '- da una parte -',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 0]
        },
        {
          text: 'e',
          alignment: 'center',
          margin: [0, 10]
        },
        {
          text: [
            {
              text: '-  ',
              margin: [0, 5],
              alignment: 'justify'
            },
            {
              text: '' + this.cedente.denominazione,
              bold: true
            },
            {
              text:
                ', con sede legale in ' +
                this.cedente.legalAddressCity +
                ' (' +
                this.cedente.legalAddressProvince +
                ')' +
                ' ' +
                this.cedente.legalAddressStreet +
                ' CAP ' +
                this.cedente.legalAddressCAP +
                ', CF. ' +
                this.cedente.fiscalCode +
                ' e P.Iva ' +
                this.cedente.pIva +
                ', numero di iscrizione al Registro delle Imprese di ' +
                this.cedente.legalAddressProvince +
                ' n. ' +
                this.cedente.REA_CODE +
                ', in persona del Sig. ' +
                this.debitore.originatorSolicitorName +
                ' ' +
                (this.debitore.originatorSolicitorSurname && this.debitore.originatorSolicitorSurname != ''
                  ? this.debitore.originatorSolicitorSurname
                  : this.debitore.surname) +
                ' che sottoscrive il presente accordo in qualità di Legale Rappresentante (di seguito, “'
            },
            {
              text: this.cedente.denominazione,
              bold: true
            },
            {
              text: '” o il “'
            },
            {
              text: 'Cedente',
              bold: true
            },
            {
              text: '”);'
            }
          ]
        },
        {
          text: '- dall’altra parte - ',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text: [
            {
              text: 'il Cessionario e '
            },
            {
              text: this.cedente.denominazione,
              bold: true
            },
            {
              text: ', d’ora in avanti congiuntamente denominate le "'
            },
            {
              text: 'Parti',
              bold: true
            },
            {
              text: '", e ciascuna singolarmente una "'
            },
            {
              text: 'Parte',
              bold: true
            },
            {
              text: '");',
              margin: [0, 2],
              alignment: 'justify',
              pageBreak: 'after'
            }
          ]
        },
        {
          margin: [0, 20, 0, 0],
          text: 'PREMESSO CHE',
          alignment: 'center',
          bold: true
        },
        //PROSSIMO FOGLIO
        {
          type: 'upper-alpha',
          ol: [
            {
              text: [
                {
                  text:
                    this.firebase.loggedUser.ragioneSociale +
                    ' è una società di cartolarizzazione che intende acquistare crediti commerciali al fine di realizzare un’operazione di cartolarizzazione ai sensi dell’articolo 1, comma 1, della Legge 130 (la “',
                  alignment: 'justify',
                  margin: [0, 2]
                },
                {
                  text: 'Cartolarizzazione',
                  bold: true
                },
                { text: '”).;', alignment: 'justify', margin: [0, 2] }
              ]
            },
            {
              text: [
                this.cedente.denominazione +
                  ' è il fornitore di ' +
                  this.debitore.originatorSolicitorName +
                  ' ' +
                  (this.debitore.originatorSolicitorSurname && this.debitore.originatorSolicitorSurname != ''
                    ? this.debitore.originatorSolicitorSurname
                    : this.debitore.surname) +
                  ', ed è interessato ad ottenere il pagamento anticipato dei crediti vantati nei confronti di  ' +
                  this.debitore.originatorSolicitorName +
                  ' ' +
                  (this.debitore.originatorSolicitorSurname && this.debitore.originatorSolicitorSurname != ''
                    ? this.debitore.originatorSolicitorSurname
                    : this.debitore.surname) +
                  ' (di seguito il "',
                { text: 'Debitore Ceduto', bold: true },
                '");'
              ],
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.cedente.denominazione +
                ' ha manifestato l’intenzione di cedere e trasferire al Cessionario, e il Cessionario intende acquistare dal Cedente, ai sensi degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130, tutti i diritti e le pretese di qualsiasi tipo e natura relative al credito, come di seguito definito, risultante dalla fattura n° ' +
                this.invoice.numFattura +
                ' del ' +
                this.invoice.dataFattura +
                ' di Euro ' +
                this.invoice.importoFattura +
                ';',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text: [
                'Le Parti intendono concordare la cessione del Credito secondo le modalità e termini di cui al presente accordo di cessione dei crediti (l\'"',
                { text: 'Accordo', bold: true },
                '").'
              ],
              alignment: 'justify',
              margin: [0, 2]
            }
          ]
        },
        {
          text:
            'TUTTO CIÒ PREMESSO, in virtù dei reciproci accordi qui contenuti, e secondo i termini e le condizioni del presente Accordo, le Parti convengono quanto segue:',
          margin: [0, 20, 0, 10],
          alignment: 'justify'
        },
        {
          text: [
            {
              text: '1.  ',
              bold: true
            },
            {
              text: 'Premesse e definizioni',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: '1.1. Le Premesse e gli Allegati formano parte integrante e sostanziale del presente Accordo.'
        },
        {
          text:
            '1.2. I titoli delle Clausole e sotto-clausole sono qui indicati per mera convenienza e non condizionano l’interpretazione del presente Accordo.'
        },
        {
          text:
            '1.3. Qualsiasi riferimento contenuto nel presente Accordo ad una legge, uno statuto, qualsiasi previsione dei medesimi o qualsiasi atto, ordinanza o regolamento statutario emesso ai sensi dei medesimi sarà interpretato come un riferimento a tale legge, statuto, previsione e strumento, ordinanza o regolamento, come di volta in volta modificato, integrato o riemesso.'
        },
        {
          text:
            '1.4. Salvo ove diversamente definiti i termini utilizzati in maiuscolo nel presente Accordo hanno il seguente significato:'
        },
        {
          text: [
            { text: '(i)    “' },
            { text: 'Cartolarizzazione', bold: true },
            { text: ',” ha il significato indicato nella premessa della A;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(ii)   “' },
            { text: 'Codice della Crisi e dell’Insolvenza', bold: true },
            { text: '” o “' },
            { text: 'CCII', bold: true },
            { text: '”: D. Lgs. del 12 gennaio 2019, n. 14;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(iii)  “' },
            { text: 'Credito', bold: true },
            { text: '” ha il significato di cui all’articolo 2.1;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(iv)  “' },
            { text: 'Debitore Ceduto', bold: true },
            { text: '” ha il significato indicato nella premessa B;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(v)   “' },
            { text: 'Evento di Insolvenza', bold: true },
            {
              text:
                '” indica, con riferimento a un soggetto, che tale soggetto sia stato sottoposto ad una procedura di risanamento o liquidazione, ivi incluse:'
            }
          ],
          margin: [5, 5]
        },
        {
          text:
            'a)	la messa in liquidazione volontaria o giudiziale o, comunque, la convocazione di un’assemblea per la messa in liquidazione (o la richiesta di messa in liquidazione) o l’approvazione di tale delibera, o comunque il verificarsi di una causa di scioglimento;',
          margin: [5, 5]
        },
        {
          text:
            'b)	la “composizione negoziata per la soluzione della crisi d’impresa”, di cui agli artt. 12ss. del CCII, e ciascuna delle soluzioni percorribili alla conclusione delle trattative, come previste dall’art. 23 del CCII, ivi incluse: il “concordato semplificato per la liquidazione del patrimonio” di cui all’art. 25-sexies del CCII, il contratto con uno o più creditori di cui all’art. 23, co. 1, lett. a) del CCII e l’accordo sottoscritto dall’imprenditore, dai creditori e dall’esperto di cui all’art. 23, co. 1, lett. c) del CCII;',
          margin: [5, 5]
        },
        {
          text:
            'c)	gli strumenti di regolazione della crisi e dell’insolvenza (inclusi, senza limitazione alcuna, i “piani di risanamento attestati ed i relativi accordi in esecuzione”, ai sensi dell’art. 56; gli “accordi di ristrutturazione dei debiti”, ai sensi degli artt. 57 e seguenti (“accordi di ristrutturazione dei debiti”), art. 60 (“accordi di ristrutturazione agevolati”) e art. 61 (“accordi di ristrutturazione ad efficacia estesa”); la “convenzione di moratoria” ai sensi dell’art. 62; il “piano di ristrutturazione soggetto ad omologazione”, ai sensi dell’art. 64-bis; il “concordato preventivo”, ai sensi degli artt. 84 e seguenti; la “liquidazione giudiziale”, di cui agli artt. 121 e seguenti; la “liquidazione coatta amministrativa”, di cui agli artt. 239 e seguenti; le “procedure di composizione delle crisi da sovraindebitamento”, di cui alla parte I, titolo IV capo II del CCII, ivi incluse, la “procedura di ristrutturazione dei debiti del consumatore”, di cui agli artt. 67 e ss. Del CCII, il “concordato minore”, di cui agli artt. 74 e ss. del CCII e la “liquidazione controllata del sovraindebitato”, di cui agli artt. 268 e ss. del CCII);',
          margin: [5, 5]
        },
        {
          text:
            'd)	la circostanza che l’intera impresa o parte sostanziale del patrimonio del relativo soggetto è oggetto di una procedura di pignoramento, sequestro o altra procedura con effetti analoghi:',
          margin: [5, 5]
        },
        {
          text:
            'e)	sono state fatte delle segnalazioni per l’anticipata emersione della crisi, la composizione negoziata e le soluzioni percorribili alla conclusione delle relative trattative, le misure cautelari e protettive, gli strumenti di regolazione della crisi e dell’insolvenza;',
          margin: [5, 5]
        },
        {
          text:
            'f)	è stata fatta la richiesta di misure protettive o cautelari ai sensi del CCII, ivi incluso ai sensi degli artt. 18, 19, 54 e 55 del medesimo CCII, nonché di ogni altra misura protettiva e/o cautelare avente analoghe finalità;',
          margin: [5, 5]
        },
        {
          text:
            'g)	è stata proposta un’istanza, ricorso e/o domanda finalizzata all’accesso ad una procedura di risanamento o liquidazione di cui alla lettera (a) che precede con riserva di deposito di documentazione e/o all’ottenimento di una misura cautelare e protettiva ai sensi del CCII, delle leggi speciali, ovvero delle disposizioni previgenti rispetto all’entrata in vigore del CCII, come pro tempore applicabili; o un’istanza, ricorso e/o domanda finalizzati all’accesso ad una delle predette procedure con riserva di deposito della documentazione, comunque previsti dalle disposizioni previgenti rispetto all’entrata in vigore del CCII, come pro tempore applicabili;',
          margin: [5, 5]
        },
        {
          text:
            'h)	nelle procedure di cui al CCII è stato nominato o ne è stata richiesta la nomina da parte dei soggetti a ciò legittimati, di un curatore, amministratore straordinario, commissario, liquidatore, professionista indipendente, esperto nella composizione negoziata della crisi, soggetto incaricato dall’autorità giudiziaria delle funzioni di gestione e di controllo, o altro funzionario e/o professionista avente natura o funzioni simili nell’ambito delle suddette procedure;',
          margin: [5, 5]
        },
        {
          text:
            'i)	è stata pronunciata un’ordinanza o è diventata effettiva una delibera per la liquidazione o lo scioglimento del relativo soggetto in qualunque forma;',
          margin: [5, 5]
        },
        {
          text: ['(vi)  “', { text: 'Prezzo di Acquisto', bold: true }, '” ha il significato di cui all’articolo 3.1.'],
          margin: [5, 1]
        },
        {
          text: [
            {
              text: '2.  ',
              bold: true
            },
            {
              text: 'Oggetto',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: [
            {
              text:
                '2.1.	In forza della sottoscrizione del presente Accordo, il Cedente cede, ai sensi e per gli effetti degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130 e della legge n. 52 del 21 febbraio 1991 (“'
            },
            {
              text: 'Legge Factoring',
              bold: true
            },
            {
              text:
                '”), a titolo oneroso pro-soluto al Cessionario, che accetta, il credito commerciale risultante dalla fattura n° ' +
                this.invoice.numFattura +
                ' del ' +
                this.invoice.dataFattura +
                ' di Euro ' +
                this.invoice.importoFattura +
                '(di seguito, il “'
            },
            {
              text: 'Credito',
              bold: true
            },
            {
              text: '”) con efficacia legale ed economica alla data di stipula del presente Accordo (inclusa).'
            }
          ]
        },
        {
          text:
            '2.2.	Il Cedente consegna al Cessionario tutta la documentazione comprovante il Credito e, nello specifico: (i) fattura n° ' +
            this.invoice.numFattura +
            ' del ' +
            this.invoice.dataFattura +
            ' di cui all’Allegato. A.'
        },
        {
          text:
            '2.3.	Il Credito viene trasferito al Cessionario con tutti i privilegi, garanzie personali, garanzie reali e con gli altri accessori ivi comprese eventuali pattuizioni contrattuali ai sensi dell’1263, comma 1, c.c., inclusi, a titolo esemplificativo, tutte le relative somme per capitale, interessi, interessi di mora, le penali e qualsiasi altra commissione, accessorio o qualsiasi altro importo esigibile e pagabile in qualsiasi momento.'
        },
        {
          text: [
            {
              text: '3.  ',
              bold: true
            },
            {
              text: 'Prezzo',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: [
            {
              text:
                '3.1.	 Salvo quanto previsto dall’articolo 6 (Risoluzione e Condizione Risolutiva), a fronte della cessione del Credito, il Cessionario si obbliga a pagare in favore del Cedente: un importo pari a € ' +
                this.invoice.importoFattura +
                ', relativamente alla fattura ' +
                this.invoice.numFattura +
                ' del ' +
                this.invoice.dataFattura +
                ', entro 3 giorni lavorativi dalla sottoscrizione del presente Accordo (il “'
            },
            {
              text: 'Prezzo di Acquisto',
              bold: true
            },
            {
              text: '”).'
            }
          ]
        },
        {
          text:
            '3.2.	I pagamenti saranno effettuati sul conto corrente n. IBAN ' +
            this.cedente.iban +
            ' intestato a ' +
            this.cedente.denominazione +
            ' di cui all’Allegato B.'
        },
        {
          text:
            '3.3.	Il Cedente si impegna a rilasciare quietanza al Cessionario dell’effettivo pagamento non appena avvenuto.'
        },
        {
          text: [
            {
              text: '4.  ',
              bold: true
            },
            {
              text: 'Garanzie e obblighi delle Parti',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '4.1. Il Cedente prende atto e accetta che il Cessionario stipuli il presente Accordo e decida di acquistare il Credito ai sensi del, e in conformità al, presente Accordo, sulla base di e facendo affidamento su, le dichiarazioni e le garanzie fornite dal Cedente ai sensi della presente Clausola. '
        },
        {
          text: '4.2.	Il Cedente garantisce che:'
        },
        {
          text:
            '- è una società per azioni debitamente organizzata e validamente esistente ai sensi della legge italiana, ed è debitamente autorizzata ad operare in ogni giurisdizione in cui data la natura dei suoi affari si richieda una tale autorizzazione;',
          margin: [5, 2]
        },
        {
          text: '- non è soggetto ad alcun Evento di Insolvenza;',
          margin: [5, 2]
        },
        {
          text:
            '- il Credito è certo, liquido ed esigibile rispetto alla scadenza riportata sui documenti comprovanti la sua esistenza e non contestato da parte del Debitore Ceduto;',
          margin: [5, 2]
        },
        {
          text:
            '- il contratto da cui deriva il Credito ceduto con il presente atto è stato validamente stipulato nell’osservanza delle disposizioni di legge vigenti ad esso applicabile, e non contiene clausole o disposizioni che impediscano o anche limitino il trasferimento o la cessione, in tutto o in parte, del credito che ne derivi;',
          margin: [5, 2]
        },
        {
          text:
            '- ha adempiuto ed adempirà puntualmente agli obblighi di cui all’ordine/Contratto di cui all’Allegato C in base al quale il Credito è sorto;',
          margin: [5, 2]
        },
        {
          text:
            '- è unico legittimo e assoluto titolare del Credito oggetto di cessione, il quale è legittimamente trasferibile, non soggetto a sequestro, pignoramento, né ad altri vincoli a favore di terzi, di non aver liberato alcuno dei debitori ceduti e di non aver rinunciato ad alcun diritto nei confronti di essi;',
          margin: [5, 2]
        },
        {
          text:
            '- all’atto della cessione il Debitore Ceduto non ha crediti che possano essere imputati a compensazione, sia pure parziale, del Credito.',
          margin: [5, 2]
        },
        {
          text: '4.3. Il Cedente si impegna:'
        },
        {
          text: '- non appena ne venga a conoscenza, a comunicare al Cessionario, entro 15 (quindici) giorni:',
          margin: [5, 2]
        },
        {
          text:
            '(i)   ogni inaccuratezza o errore nelle dichiarazioni e garanzie rese dal Cedente nel presente Accordo; ',
          margin: [10, 2]
        },
        {
          text: '(ii)  pretese di terze parti sul Credito; e',
          margin: [10, 2]
        },
        {
          text: '(iii) pignoramento o azione di terzi sul Credito;',
          margin: [10, 2]
        },
        {
          text:
            '- porre in essere, nei tempi tecnici necessari e comunque con un preavviso di almeno 15 (quindici) giorni, a cura e spese esclusivamente a carico del Cessionario, ogni attività e/o azione, stipulare ogni documento e adempiere a qualsiasi formalità che il Cessionario (fatto salvo il trasferimento del Credito e dei relativi accessori) possa ragionevolmente ritenere necessari o opportuni per esercitare i propri diritti, azioni, pretese, privilegi in relazione ai Crediti, inclusi quelli necessari a rendere opponibile a terzi la cessione del Credito;',
          margin: [5, 2]
        },
        {
          text:
            '- fornire al Cessionario le informazioni necessarie alla redazione del prospetto informativo previsto dalla Legge 130;',
          margin: [5, 2]
        },
        {
          text: '- a non: ',
          margin: [5, 2]
        },
        {
          text: '(i) cedere o altrimenti trasferire il Credito a terze parti;',
          margin: [10, 2]
        },
        {
          text: '(ii) creare garanzie, vincoli, pesi o altri diritti in favore di terzi sul Credito;',
          margin: [10, 2]
        },
        {
          text: '(iii) sciogliere con effetto retroattivo il contratto dal quale deriva il Credito;',
          margin: [10, 2]
        },
        {
          text:
            '(iv) modificare i termini e le condizioni del Credito, compensarlo, rimetterlo o concordare che, o agire in modo che il Credito sia inefficace o che ne sia diminuito il valore, in tutto od in parte;',
          margin: [10, 2]
        },
        {
          text:
            '(v) dare istruzioni al Debitore Ceduto di effettuare pagamenti od intraprendere ogni azione nei suoi confronti;',
          margin: [10, 2]
        },
        {
          text:
            '- a versare al Cessionario entro 2 (due) giorni dall’incasso, qualsiasi importo incassato sul Credito successivamente alla data di stipula del presente Accordo.',
          margin: [10, 2]
        },
        {
          text:
            '4.4.	Le Parti concordano che il Cedente, previo accordo con il Cessionario, dovrà gestire a proprie spese e cura gli eventuali giudizi passivi relativi ai rapporti contrattuali da cui originano il Credito o le garanzie accessorie o finalizzate all’accertamento di responsabilità restitutorie o risarcitorie.'
        },
        {
          text:
            "4.5.	Il Cedente dichiara che i documenti allegati al presente Accordo, che il Cessionario dichiara di avere esaminato e conoscere, costituiscono tutti i documenti probatori del Credito ceduto ai sensi dell'art. 1262 c.c., e della cui consegna da parte del Cedente il Cessionario dà atto con la sottoscrizione del presente Accordo; il Cedente si impegna inoltre, a partire dalla data della cessione, a trasmettere prontamente al Cessionario tutta la corrispondenza relativa al Credito oggetto di cessione che dovesse ricevere."
        },
        {
          text: [
            {
              text: '5.  ',
              bold: true
            },
            {
              text: 'Opponibilità a terzi',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            "5.1.	Le Parti espressamente concordano nell’applicare il disposto dell'articolo 5, commi 1 e 1-bis della legge 21 febbraio 1991, n. 52 e pertanto la cessione del Credito sarà opponibile nei confronti dei terzi mediante pagamento del Prezzo di Acquisto con data certa. "
        },
        {
          text:
            '5.2.	A partire dalla data che cade 3 (tre) mesi dopo la data del primo acquisto dei Crediti, il Beneficiario si impegna, con cadenza trimestrale, ad effettuare la pubblicazione in Gazzetta Ufficiale di tutte le cessioni dei Crediti avvenute nel semestre precedente, sulla base del modello di cui all’Allegato D (Modello di avviso di cessione).'
        },
        {
          text:
            '5.3.	Contestualmente alla sottoscrizione del presente Accordo, ' +
            this.cedente.denominazione +
            'si obbliga a provvedere a propria cura e spese alla notifica della comunicazione di cui all’art. 1264 c.c. al Debitore Ceduto nonché ai suoi eventuali avallanti, garanti e/o obbligati in solido ceduti, impegnandosi a porre in essere quant’altro fosse necessario affinché i destinatari prendano debita nota dell’intervenuta cessione del Credito ed a consegnare prontamente al Cessionario tutta la documentazione relativa alla predetta notifica non appena perfezionata. Qualora il Cedente non abbia provveduto entro tre giorni lavorativi, il Cessionario potrà effettuare la suddetta notifica a spese del Cessionario.'
        },
        {
          text: [
            {
              text: '6.  ',
              bold: true
            },
            {
              text: 'Risoluzione e Condizione Risolutiva',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '6.1.	Il Cessionario potrà risolvere il presente Accordo, ai sensi dell’art. 1456 c.c., dandone comunicazione scritta al Cedente a mezzo lettera raccomandata in caso di violazione di uno qualsiasi degli obblighi previsti dall’articolo 4.2 del presente Accordo.'
        },
        {
          text:
            '6.2.	Lo scioglimento dell’Accordo ai sensi del presente articolo legittima il Cessionario a richiedere al Cedente la restituzione delle somme pagate quale corrispettivo del Credito ceduti e non ancora incassati, con obbligo del Cedente di indennizzare e manlevare il Cessionario, altresì, di tutte le eventuali spese sostenute. L’efficacia del presente Accordo è risolutivamente condizionata ai sensi dell’articolo 1353 del Codice Civile, al mancato pagamento del Prezzo di Acquisto entro [30] giorni dalla data di stipula del Contratto.'
        },
        {
          text:
            '6.3.	In caso di risoluzione del presente Accordo ai sensi dell’articolo 6.1 che precede, il Credito ed i diritti trasferiti con lo stesso si considereranno come mai trasferiti e pertanto ogni incasso ricevuto dal Cessionario dalla data di stipula del presente Accordo dovrà essere restituito al Cedente entro trenta giorni di calendario.'
        },
        {
          text: [
            {
              text: '7.  ',
              bold: true
            },
            {
              text: 'Comunicazioni',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '7.1.	Se non diversamente convenuto per iscritto dalle Parti, qualsiasi comunicazione riguardante l’oggetto del presente Accordo dovrà essere effettuata mediante lettera raccomandata A/R ovvero posta elettronica certificata ai recapiti di seguito riportati:'
        },
        {
          alignment: 'center',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['Se a ', { text: 'SPV PROJECT 2218 S.r.l.', bold: true }]
            },
            {
              text: ['Se a ', { text: this.cedente.denominazione, bold: true }]
            }
          ]
        },
        {
          alignment: 'center',
          columns: [
            {
              text: 'c.a. Amministratore Unico'
            },
            {
              text: 'c.a ' + this.cedente.legalName
            }
          ]
        },
        {
          alignment: 'center',
          columns: [
            {
              text: 'Corso Vittorio Emanuele II, 24/28 '
            },
            {
              text: 'Via ' + this.cedente.legalAddressStreet
            }
          ]
        },
        {
          alignment: 'center',
          columns: [
            {
              text: '20122 Milano'
            },
            {
              text: this.cedente.legalAddressCAP + ' ' + this.cedente.legalAddressCity
            }
          ]
        },
        {
          alignment: 'center',
          margin: [0, 0, 0, 10],
          columns: [
            {
              text: 'PEC: ' + this.firebase.loggedUser.pec
            },
            {
              text: 'PEC: ' + this.cedente.assignorPec
            }
          ]
        },
        {
          text: [
            {
              text: '8.  ',
              bold: true
            },
            {
              text: 'Miscellanea',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '8.1.	Le Parti dichiarano che, avendo negoziato singolarmente e congiuntamente, le clausole del presente Accordo, tutte concordate fra le stesse, non sussistono i presupposti per l’approvazione specifica di cui all’art. 1341 del Codice Civile.'
        },
        {
          text:
            '8.2.	Il mancato o parziale esercizio o ritardo nell’esercizio da parte di una delle Parti di qualsiasi diritto o rimedio ai sensi del presente Accordo non ne costituirà rinuncia, fatta salva l’intervenuta prescrizione. Ogni deroga o rinuncia, se concesse, sarà ritenuta riferita al solo caso specifico.'
        },
        {
          text:
            '8.3.	Il presente Accordo potrà essere modificato esclusivamente, col previo consenso scritto delle Parti.'
        },
        {
          text:
            '8.4.	Se uno o più patti del presente Accordo dovessero risultare invalidi, inefficaci od inapplicabili, tale invalidità, inefficacia od inapplicabilità non comporterà l’inefficacia delle rimanenti previsioni nei limiti di quanto consentito dall’articolo 1419 del Codice Civile. Se praticabile e se consentito dalle previsioni di legge applicabili, le previsioni che dovessero rilevarsi invalide, inefficaci o inapplicabili saranno interpretate e sostituite, se necessario, in maniera da riflettere la volontà delle Parti alla data di stipula del presente Accordo.'
        },
        {
          text:
            '8.5.	Il Cedente rinuncia irrevocabilmente a compensare ogni importo dovuto al Cessionario contro ogni altro eventuale debito dello stesso nei suoi confronti.'
        },
        {
          text: [
            {
              text: '9.  ',
              bold: true
            },
            {
              text: 'Impegni in relazione alla Legge sulla Privacy e la normativa Anti-Money Laundering',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: [
            {
              text:
                '9.1.	Il Cedente con il presente Accordo si impegna a collaborare pienamente con il Cessionario per l’espletamento di qualsiasi procedura e per il perfezionamento di qualsiasi formalità che dovesse rendersi necessaria o opportuna per il trasferimento di tutti i dati, incluse le procedure mirate all’adozione di qualsiasi misura di sicurezza in relazione a tali dati, e le informazioni relative al Credito e al Debitore Ceduto al fine di adempiere a qualsiasi legge e regolamento sulla privacy e la protezione dei dati incluse, a titolo esemplificativo, le previsioni della Legge sulla Privacy e del Regolamento UE n. 679 del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati (il “'
            },
            {
              text: 'GDPR',
              bold: true
            },
            {
              text: '”), e di qualsiasi regolamento ad essa relativo.'
            }
          ]
        },
        {
          text:
            'Il Cedente con il presente Accordo si impegna a fornire al Cessionario le informazioni in suo possesso necessarie in relazione alla profilazione del Cedente ai fini antiriciclaggio (inclusa qualsiasi informazione richiesta per la corretta identificazione del c.d. “titolare effettivo” e del c.d. “esecutore”), ove applicabile al Cessionario.'
        },
        {
          text: [
            {
              text: '10.  ',
              bold: true
            },
            {
              text: 'Ricorso Limitato e non petition',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '10.1	Il Cedente riconosce ed accetta che le obbligazioni di pagamento del Cessionario saranno a ricorso limitato, pari al minore tra '
        },

        {
          text: '(i) il nominale del relativo debito, comprensivo di capitale ed accessori, ed i',
          margin: [5, 5]
        },
        {
          text:
            "(ii) fondi eventualmente disponibili al Cessionario per tale pagamento, ai sensi dell'Ordine di Priorità applicabile. Il Cedente riconosce che le obbligazioni del Cessionario ai sensi del presente Contratto saranno limitate a tale importo massimo e che non avrà altro ricorso verso il Cessionario per le obbligazioni da questo assunte.",
          margin: [5, 5]
        },
        {
          text: [
            {
              text:
                '10.2	In considerazione dell’Articolo 10.1 che precede, il Cedente si impegna a non attivare alcuna procedura concorsuale contro il Cessionario o di prendere parte o comunque agire per la dichiarazione di insolvenza del Cessionario iniziata o attivata da terze parti, non agire, o prendere parte ad azioni di qualsiasi genere contro il Cessionario o i suoi beni, prima di due anni ed un giorno dalla data in cui i crediti del Cessionario nei confronti della banca finanziatrice ai sensi del Contratto di Finanziamento sottoscritto in data '
            },
            {
              // CHECK DATA
              text: this.investorSPV.dataContrFinanzmnt
            },
            {
              text: ' sono stati rimessi o rimborsati.'
            }
          ]
        },
        {
          text:
            '10.3	Le previsioni di cui al presente Articolo 10 resteranno valide e vincolanti anche qualora il presente Contratto dovesse essere risolto o comunque cessare di avere efficacia per qualunque motivo.'
        },
        {
          text: [
            {
              text: '11.  ',
              bold: true
            },
            {
              text: 'Legge regolatrice e foro competente',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: '11.1  Il presente Accordo è regolato dalla legge italiana.'
        },
        {
          text:
            "11.2 Tutte le controversie che dovessero insorgere in ordine all'interpretazione, alla validità ed alla esecuzione del presente Accordo saranno devolute alla competenza esclusiva del Foro di Milano, intendendosi consensualmente derogata ogni diversa norma di competenza giudiziaria."
        }
      ],
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      },
      styles: {
        header: {
          fontSize: 14,
          alignment: 'right'
        }
      }
    };

    //pdfMake.createPdf(docDefinition).open();

    const pdf_gen = pdfMake.createPdf(docDefinition);
    pdf_gen.getBase64(data => {
      this.contrattoSPV_first = data;
    });
  }

  generaGDPR_firma() {
    let docDefinition = {
      pageSize: 'A4',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [60, 60, 60, 60],
      header: [],
      footer: (currentPage, pageCount) => [
        {
          /*
          text: '\n' + currentPage.toString() + ' / ' + pageCount,
          fontSize: 8,
          color: '#9C9C9C',
          alignment: 'center'
        */
        }
      ],
      content: [
        {
          text: 'ALLEGATO D',
          headlineLevel: 1,
          bold: true,
          alignment: 'center'
        },
        {
          text: 'Modello di avviso di cessione ',
          bold: true,
          alignment: 'center'
        },
        {
          text: this.firebase.loggedUser.ragioneSociale + ', ',
          bold: true,
          alignment: 'center'
        },
        {
          text:
            'Iscritta nell’elenco delle società veicolo per la cartolarizzazione dei crediti tenuto dalla Banca d’Italia ai sensi ',
          alignment: 'center'
        },
        {
          text: ' del Provvedimento della Banca d’Italia del 7 giugno 2017',
          alignment: 'center'
        },
        {
          text:
            'Sede legale: ' +
            this.firebase.loggedUser.indirizzo +
            ' - ' +
            this.firebase.loggedUser.cap +
            ' - ' +
            this.firebase.loggedUser.city,
          alignment: 'center'
        },
        {
          text: 'Registro delle Imprese: ' + this.firebase.loggedUser.rea,
          alignment: 'center'
        },
        {
          text: 'Codice Fiscale: ' + this.firebase.loggedUser.piva,
          alignment: 'center'
        },
        {
          text: [
            {
              text:
                'Avviso di cessione di crediti pro soluto ai sensi del combinato disposto degli articoli 1 e 4 comma 1, secondo ',
              italics: true
            },
            {
              text: 'periodo, della legge 30 aprile 1999, n. 130 in materia di cartolarizzazione di crediti, (la “',
              italics: true
            },
            {
              text: 'Legge 130',
              bold: true,
              italics: true
            },
            {
              text:
                '”) e del,corredato dall’informativa ai sensi degli articoli 13 e 14 del Regolamento UE 679/2016 (il “',
              italics: true
            },
            {
              text: 'GDPR',
              bold: true,
              italics: true
            },
            {
              text:
                '”) e del provvedimento dell’Autorità Garante per la Protezione dei Dati Personali del 18 gennaio 2007',
              italics: true
            }
          ],
          margin: [10, 10]
        },
        {
          text: [
            {
              text: 'La società ' + this.firebase.loggedUser.ragioneSociale + ' (il “'
            },
            {
              text: 'Cessionario',
              bold: true
            },
            {
              text:
                '”), con sede legale in ' +
                +this.firebase.loggedUser.indirizzo +
                ' - ' +
                this.firebase.loggedUser.cap +
                ' - ' +
                this.firebase.loggedUser.city +
                ', comunica di aver sottoscritto:'
            }
          ],
          margin: [1, 1]
        },
        {
          text: [
            {
              text: '(i)	in data'
            },
            {
              text: '[' + this.invoice.dataCaricaContrattoSPV + ']',
              bold: true
            },
            {
              text:
                'un contratto di cessione di crediti pecuniari, con efficacia giuridica ed efficacia economica in pari data, con'
            },
            {
              text: '(il “'
            },
            {
              text: 'Cedente [ ' + this.invoice.cedenteName + ']',
              bold: true
            },
            {
              text: '”e il “'
            },
            {
              text: 'Contratto di Cessione [' + this.invoice.numFattura + ']',
              bold: true
            },
            {
              text:
                "”), ai sensi del quale ha acquistato pro soluto, ai sensi e per gli effetti di cui al combinato disposto degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130 nonché dal disposto dell'articolo 5, commi 1, 1-bis e 2 della legge 21 febbraio 1991, n. 52 secondo quanto previsto dall'articolo 4 della Legge130, taluni crediti commerciali derivanti dall'esercizio dell'attività di impresa esercitata dal Cedente e (ivi inclusi, senza limitazione, capitale interessi corrispettivi, interessi di mora, penali e ogni altro importo, commissione e accessorio), come meglio descritti nel Contratto di Cessione;"
            }
          ],
          margin: [5, 5]
        },
        {
          text: [
            {
              text: '(ii)	in data'
            },
            {
              text: ' [' + this.invoice.dataCaricaContrattoSPV + ']',
              bold: true
            },
            {
              text:
                'un contratto di cessione di crediti pecuniari, con efficacia giuridica ed efficacia economica in pari data, con'
            },
            {
              text: '(il “'
            },
            {
              text: 'Cedente [' + this.invoice.cedenteName + ']',
              bold: true
            },
            {
              text: '” e il “'
            },
            {
              text: 'Contratto di Cessione  [' + this.invoice.numFattura + ']',
              bold: true
            },
            {
              text:
                "”), ai sensi del quale ha acquistato pro soluto, ai sensi e per gli effetti di cui al combinato disposto degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130 nonché dal disposto dell'articolo 5, commi 1, 1-bis e 2 della legge 21 febbraio 1991, n. 52 secondo quanto previsto dall'articolo 4 della Legge130, taluni crediti commerciali derivanti dall'esercizio dell'attività di impresa esercitata dal Cedente e (ivi inclusi, senza limitazione, capitale interessi corrispettivi, interessi di mora, penali e ogni altro importo, commissione e accessorio), come meglio descritti nel Contratto di Cessione;"
            }
          ],
          margin: [5, 5]
        },
        {
          text: [
            {
              text:
                this.servicerSPV.ragioneSociale +
                ' con sede legale in ' +
                this.servicerSPV.city +
                ', ' +
                this.servicerSPV.indirzzio +
                ', C.F., P. IVA e iscrizione al Registro delle Imprese di Milano Monza Brianza Lodi n. ' +
                this.servicerSPV.piva +
                ', iscritta all’Albo di cui all’art. 106 del Testo Unico Bancario tenuto dalla Banca d’Italia – Cod. ABI '
            },
            {
              text: this.servicerSPV.abi,
              bold: true
            },
            {
              text:
                ') è stata incaricata dal Cessionario di svolgere, in relazione ai Crediti, il ruolo di soggetto incaricato responsabile della verifica della conformità delle operazioni alla legge e al prospetto informativo ai sensi dell’articolo 2, comma 3, lettera (c), comma 6 e comma 6-bis della Legge 130.'
            }
          ]
        },
        {
          text:
            'Per effetto della cessione del Credito, il debitore ceduto e gli eventuali suoi garanti, successori o aventi causa dovranno pagare ogni somma dovuta in relazione al Credito al Cessionario nelle forme nelle quali il pagamento di tali somme era consentito per contratto o in forza di legge anteriormente alla suddetta cessione, salvo specifiche indicazioni in senso diverso che potranno essere tempo per tempo comunicate ai debitori ceduti.'
        },
        {
          text:
            'Il debitore ceduto e gli eventuali suoi garanti, successori o aventi causa per ogni ulteriore informazione potranno rivolgersi al Cessionario o a ' +
            this.servicerSPV.ragioneSociale +
            ' nelle ore di ufficio di ogni giorno lavorativo.'
        },
        {
          text: 'Informativa ai sensi del Regolamento Generale sulla Protezione dei Dati (“GDPR”) ',
          bold: true
        },
        {
          text: [
            {
              text:
                'A seguito della cessione dei Crediti alla Cessionaria sopra descritta, la Cessionaria è divenuta titolare di tali Crediti e, di conseguenza, ulteriore “Titolare” del trattamento  dei dati personali relativi a tale Crediti. '
            }
          ],
          margin: [3, 3]
        },
        {
          text:
            "Tanto premesso, la Cessionaria, al fine della gestione e dell'incasso dei Crediti, ha " +
            this.servicerSPV.ragioneSociale +
            ' con sede legale in ' +
            this.servicerSPV.city +
            ', ' +
            this.servicerSPV.indirizzo +
            ', C.F., P. IVA e iscrizione al Registro delle Imprese di Milano Monza Brianza Lodi n. ' +
            this.servicerSPV.piva +
            ', iscritta all’Albo di cui all’art. 106 del Testo Unico Bancario tenuto dalla Banca d’Italia '
        },
        {
          text: [
            {
              text: '– Cod. ' + this.servicerSPV.abi + ', come proprio “Servicer” (il “'
            },
            {
              text: 'Servicer',
              bold: true
            },
            {
              text:
                "). Il Servicer è di conseguenza, divenuto “Responsabile” del trattamento dei dati personali relativi ai debitori ceduti. Inoltre, la Cessionaria, ha nominato, al fine della gestione e dell'incasso dei Crediti FX12 con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 quale proprio asset manager (l’ “"
            },
            {
              text: 'Asset Manager',
              bold: true
            },
            {
              text:
                '”). L’ Asset Manager è stato, inoltre, nominato quale ulteriore “Responsabile” del trattamento  dei  dati personali relativi ai debitori ceduti, ai sensi e per gli effetti del GDPR. '
            }
          ],
          margin: [5, 5]
        },
        {
          text:
            'Ai sensi e per gli effetti del GDPR, la Cessionaria, il Servicer e l’Asset Manager non tratteranno dati che rientrano nelle “categorie particolari di dati personali” ai sensi degli articoli 9 e 10 del Regolamento Generale sulla Protezione dei Dati, definiti dal Codice in Materia di Protezione dei Dati Personali come “sensibili”. '
        },
        {
          text:
            "I dati personali continueranno ad essere trattati con le stesse modalità e per le stesse finalità per le quali gli stessi sono stati raccolti in sede di instaurazione dei rapporti, cosi come a suo tempo illustrate. In particolare,  la Cessionaria, il Servicer e l’Asset Manager, tratteranno i dati personali per finalità connesse e strumentali alla gestione ed amministrazione dei Crediti ceduti; al recupero  dei Crediti (ad es. conferimento a legali dell'incarico professionale del recupero del credito, etc.); agli  obblighi  previsti  da  leggi,  da regolamenti e dalla normativa  comunitaria  nonché'  da  disposizioni emesse da autorità a ciò legittimate dalla legge  e  da  organi  di vigilanza e controllo. "
        },
        {
          text:
            'In relazione alle indicate finalità, il trattamento dei dati personali avviene mediante strumenti manuali, informatici e telematici con logiche strettamente correlate alle finalità stesse e, comunque, in modo da garantire la sicurezza e la riservatezza  dei dati stessi. '
        },
        {
          text:
            'Per lo svolgimento della propria attività di gestione e recupero dei Crediti, la Cessionaria ed il Servicer e il Asset Manager, potranno comunicare i dati personali per le “finalità del trattamento cui sono destinati i dati, a persone, società, associazioni o studi professionali che prestano attività di assistenza o consulenza  in materia  legale  e società di recupero crediti. '
        },
        {
          text:
            'Un elenco dettagliato di tali soggetti è disponibile presso la sede della Cessionaria come sotto indicato.'
        },
        {
          text: [
            {
              text:
                "I diritti previsti all'articolo 15 e seguenti del Capo III del GDPR potranno essere esercitati anche mediante richiesta scritta all’Asset Manager - Responsabile FX12 con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 e-mail: desk@fx12.it all'attenzione del legale rappresentante."
            }
          ]
        },
        {
          text:
            'Per ogni informazione relativa al presente avviso e ai Crediti è altresì possibile rivolgersi a SPV Project 2218 S.r.l. presso la sede sociale.',
          margin: [20, 20]
        },
        {
          text: [
            {
              text: 'Milano,'
            },
            {
              text: '[' + this.data + ']',
              bold: true
            }
          ]
        },

        {
          text: this.firebase.loggedUser.ragioneSociale,
          headlineLevel: 1,
          bold: true,
          decoration: 'underline'
        },

        {
          text: '*    *     *',
          headlineLevel: 1,
          alignment: 'center'
        },
        {
          text:
            'Qualora concordiate con la presente proposta contrattuale ,vogliate trascrivere il testo della presente e dei relativi alloegati su Vostra lettera ed inviarci tale lettera firmatain segno di piena ed incodizionata accettazione'
        },
        {
          text: 'Con i nostri migliori saluti,',
          italics: true,
          margin: [10, 10]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: this.firebase.loggedUser.ragioneSociale, bold: true }]
            }
          ]
        },

        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: '________________________', bold: true }]
            }
          ]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: [' ', { text: 'Nome' }]
            }
          ]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: 'Titolo:' }]
            }
          ]
        }

        /*
        images: { logo: 'https://panel.incassaora.it/assets/logoPDF.png' },
        */
      ],
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      },
      styles: {
        header: {
          fontSize: 14,
          alignment: 'right'
        }
      }
    };

    //pdfMake.createPdf(docDefinition).open();
    const pdf_gen = pdfMake.createPdf(docDefinition);
    pdf_gen.getBase64(data => {
      this.contrattoSPV_last = data;
    });
  }
  //INVESTITORE//

  scaricaContrattoPDFSPV1() {
    var iban = '___________________________';
    if (this.cedente.iban) {
      iban = this.cedente.iban;
    }
    const investitore = this.investorSPV;

    let docDefinition = {
      pageSize: 'A4',
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [60, 60, 60, 60],
      header: [
        /*
        {
          image: 'logo',
          width: 120,
          height: 100,
          alignment: 'center',
          margin: [5, 10]
        },

        { canvas: [{ type: 'rect', x: 170, y: 32, h: 100 }] }
        */
      ],

      footer: (currentPage, pageCount) => [
        /*{
          text: 'FX12 s.r.l iscritta nell’albo speciale delle Start up innovative ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2],
          bold: true
        },
        {
          text:
            'Capitale sociale Euro 10.0000 i.v., Registro Imprese Napoli e Codice Fiscale N. 09528731210, R.E.A. Napoli N. 1039449, Part. IVA 09528731210 ',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text: 'Sede legale: Via Caracciolo, 17, 80122 Napoli.',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },
        {
          text:
            'Sede operativa: Via Coroglio, 57/104, 80124 Napoli c/o Campania NewSteel | Tel: +39 081 7352503 Email: desk@fx12.it PEC fx12@pec.it',
          fontSize: 8,
          color: '#9C9C9C',
          margin: [20, 0.2]
        },*/
        {
          text: '\n' + currentPage.toString() + ' / ' + pageCount,
          fontSize: 8,
          color: '#9C9C9C',
          alignment: 'center'
        } /*
        {
          text: '_',
          fontSize: 8,
          color: '#9C9C9C',
          alignment: 'center'
        }*/
      ],

      content: [
        {
          text: '',
          headlineLevel: 1
        },

        {
          text: ' '
        },
        {
          text: 'Spett.le:' + this.investorSPV.ragioneSociale,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        {
          text: this.investorSPV.indirizzo,
          margin: [0, 5, 0, 0]
        },
        {
          text: this.investorSPV.cap + ' - ' + this.investorSPV.city,
          margin: [0, 5, 0, 0]
        },
        {
          text: 'E-mail: ' + this.investorSPV.email,
          margin: [0, 5, 0, 0]
        },

        {
          text: 'PEC: ' + this.investorSPV.pec,
          margin: [0, 5, 0, 0]
        },
        {
          text: 'Sottoscritta ad anticipata via PEC',
          italics: true,
          margin: [20, 20]
        },
        {
          // CHECK DATA
          text: 'Giulianova (TE), ' + this.data,
          alignment: 'right',
          margin: [0, 10]
        },
        {
          text: 'Oggetto: Accettazione di contratto di cessione del credito',
          bold: true
        },
        {
          text: 'Egregi Signori',
          italic: true,
          margin: [15, 15],
          italics: true
        },
        {
          text: [
            {
              text:
                'in data odierna abbiamo ricevuto la Vostra proposta relativa alla stipula dell’accordo di cessione del credito che qui di seguito trascriviamo integralmente in segno di nostra piena ed integrale accettazione.',
              italics: true,
              margin: [0, 20]
            }
          ]
        },
        {
          text: this.cedente.denominazione,
          bold: true,
          margin: [0, 5, 0, 0]
        },
        {
          text: this.cedente.legalAddressCity + ' (' + this.cedente.legalAddressProvince + ')',
          margin: [0, 5, 0, 0]
        },
        {
          text: this.cedente.legalAddressStreet,
          margin: [0, 5, 0, 0]
        },
        {
          text: 'CAP ' + this.cedente.legalAddressCAP,
          margin: [0, 5, 0, 0]
        },
        {
          text:
            'Alla cortese attenzione ' +
            this.cedente.refName +
            ' ' +
            (this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname),
          margin: [0, 5, 0, 0]
        },
        {
          // CHECK DATA
          text: this.investorSPV.city + ', ' + this.data,
          alignment: 'right',
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori, ',
          italics: true
          //margin: [0, 10, 0, 0]
        },
        {
          text:
            'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi la seguente proposta di accordo di cessione del credito.',
          italics: true
          //margin: [0, 10]
        },
        {
          text: 'Oggetto: Proposta contratto di cessione del credito ',
          bold: true,
          margin: [0, 10]
        },
        {
          text: 'Egregi Signori: ',
          italics: true
          //margin: [0, 0, 0, 10]
        },
        {
          text: 'facendo seguito alle nostre recenti negoziazioni, siamo a sottoporVi quanto segue:',
          italics: true,
          margin: [0, 0, 0, 5]
        },

        {
          text: 'ACCORDO DI CESSIONE DEL CREDITO',
          bold: true,
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text: 'Tra',
          alignment: 'center',
          margin: [0, 5]
        },
        {
          text: [
            '- 	',
            {
              text: this.investorSPV.ragioneSociale,
              bold: true
            },
            ', una società a responsabilità limitata con socio unico avente sede legale in ' +
              this.investorSPV.city +
              ' (' +
              this.investorSPV.cap +
              '), ' +
              this.investorSPV.indirizzo +
              ', capitale sociale pari ad Euro 10.000,00 (dieci mila/00), i.v., avente numero di iscrizione al Registro delle Imprese di Milano Codice fiscale e n. iscr. al Registro Imprese ' +
              this.investorSPV.piva +
              ', R.E.A. ' +
              this.investorSPV.province +
              ' - ' +
              this.investorSPV.rea +
              ', costituita ai sensi della legge sulla cartolarizzazione, n. 130, del 30 aprile 1999 (“',
            { text: 'Legge 130', bold: true },
            '”) in corso di iscrizione nell’ “',
            { text: 'elenco delle società veicolo', italics: true },
            '” tenuto da Banca d’Italia avente quale oggetto sociale la realizzazione di una o più operazioni di cartolarizzazione dei crediti  (di seguito, il “',
            { text: 'Cessionario', bold: true },
            '”)'
          ],
          margin: [0, 2],
          alignment: 'justify'
        },
        {
          text: '- da una parte -',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 0]
        },
        {
          text: 'e',
          alignment: 'center',
          margin: [0, 10]
        },
        {
          text: [
            {
              text: '-  ',
              margin: [0, 5],
              alignment: 'justify'
            },
            {
              text: '' + this.cedente.denominazione,
              bold: true
            },
            {
              text:
                ', con sede legale in ' +
                this.cedente.legalAddressCity +
                ' (' +
                this.cedente.legalAddressProvince +
                ')' +
                ' ' +
                this.cedente.legalAddressStreet +
                ' CAP ' +
                this.cedente.legalAddressCAP +
                ', CF. ' +
                this.cedente.fiscalCode +
                ' e P.Iva ' +
                this.cedente.pIva +
                ', numero di iscrizione al Registro delle Imprese di ' +
                this.cedente.legalAddressProvince +
                ' n. ' +
                this.cedente.REA_CODE +
                ', in persona del Sig. ' +
                this.debitore.originatorSolicitorName +
                ' ' +
                (this.debitore.originatorSolicitorSurname && this.debitore.originatorSolicitorSurname != ''
                  ? this.debitore.originatorSolicitorSurname
                  : this.debitore.surname) +
                ' che sottoscrive il presente accordo in qualità di Legale Rappresentante (di seguito, “'
            },
            {
              text: this.cedente.denominazione,
              bold: true
            },
            {
              text: '” o il “'
            },
            {
              text: 'Cedente',
              bold: true
            },
            {
              text: '”);'
            }
          ]
        },
        {
          text: '- dall’altra parte - ',
          alignment: 'right',
          bold: true,
          margin: [0, 2, 0, 10]
        },
        {
          text: [
            {
              text: 'il Cessionario e '
            },
            {
              text: this.cedente.denominazione,
              bold: true
            },
            {
              text: ', d’ora in avanti congiuntamente denominate le "'
            },
            {
              text: 'Parti',
              bold: true
            },
            {
              text: '", e ciascuna singolarmente una "'
            },
            {
              text: 'Parte',
              bold: true
            },
            {
              text: '");',
              margin: [0, 2],
              alignment: 'justify',
              pageBreak: 'after'
            }
          ]
        },
        {
          margin: [0, 20, 0, 0],
          text: 'PREMESSO CHE',
          alignment: 'center',
          bold: true
        },
        //PROSSIMO FOGLIO
        {
          type: 'upper-alpha',
          ol: [
            {
              text: [
                {
                  text:
                    this.investorSPV.ragioneSociale +
                    ' è una società di cartolarizzazione che intende acquistare crediti commerciali al fine di realizzare un’operazione di cartolarizzazione ai sensi dell’articolo 1, comma 1, della Legge 130 (la “',
                  alignment: 'justify',
                  margin: [0, 2]
                },
                {
                  text: 'Cartolarizzazione',
                  bold: true
                },
                { text: '”).;', alignment: 'justify', margin: [0, 2] }
              ]
            },
            {
              text: [
                this.cedente.denominazione +
                  ' è il fornitore di ' +
                  this.debitore.originatorSolicitorName +
                  ' ' +
                  (this.debitore.originatorSolicitorSurname && this.debitore.originatorSolicitorSurname != ''
                    ? this.debitore.originatorSolicitorSurname
                    : this.debitore.surname) +
                  ', ed è interessato ad ottenere il pagamento anticipato dei crediti vantati nei confronti di  ' +
                  this.debitore.originatorSolicitorName +
                  ' ' +
                  (this.debitore.originatorSolicitorSurname && this.debitore.originatorSolicitorSurname != ''
                    ? this.debitore.originatorSolicitorSurname
                    : this.debitore.surname) +
                  ' (di seguito il "',
                { text: 'Debitore Ceduto', bold: true },
                '");'
              ],
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text:
                this.cedente.denominazione +
                ' ha manifestato l’intenzione di cedere e trasferire al Cessionario, e il Cessionario intende acquistare dal Cedente, ai sensi degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130, tutti i diritti e le pretese di qualsiasi tipo e natura relative al credito, come di seguito definito, risultante dalla fattura n° ' +
                this.invoice.numFattura +
                ' del ' +
                this.invoice.dataFattura +
                ' di Euro ' +
                this.invoice.importoFattura +
                ';',
              alignment: 'justify',
              margin: [0, 2]
            },
            {
              text: [
                'Le Parti intendono concordare la cessione del Credito secondo le modalità e termini di cui al presente accordo di cessione dei crediti (l\'"',
                { text: 'Accordo', bold: true },
                '").'
              ],
              alignment: 'justify',
              margin: [0, 2]
            }
          ]
        },
        {
          text:
            'TUTTO CIÒ PREMESSO, in virtù dei reciproci accordi qui contenuti, e secondo i termini e le condizioni del presente Accordo, le Parti convengono quanto segue:',
          margin: [0, 20, 0, 10],
          alignment: 'justify'
        },
        {
          text: [
            {
              text: '1.  ',
              bold: true
            },
            {
              text: 'Premesse e definizioni',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: '1.1. Le Premesse e gli Allegati formano parte integrante e sostanziale del presente Accordo.'
        },
        {
          text:
            '1.2. I titoli delle Clausole e sotto-clausole sono qui indicati per mera convenienza e non condizionano l’interpretazione del presente Accordo.'
        },
        {
          text:
            '1.3. Qualsiasi riferimento contenuto nel presente Accordo ad una legge, uno statuto, qualsiasi previsione dei medesimi o qualsiasi atto, ordinanza o regolamento statutario emesso ai sensi dei medesimi sarà interpretato come un riferimento a tale legge, statuto, previsione e strumento, ordinanza o regolamento, come di volta in volta modificato, integrato o riemesso.'
        },
        {
          text:
            '1.4. Salvo ove diversamente definiti i termini utilizzati in maiuscolo nel presente Accordo hanno il seguente significato:'
        },
        {
          text: [
            { text: '(i)    “' },
            { text: 'Cartolarizzazione', bold: true },
            { text: ',” ha il significato indicato nella premessa della A;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(ii)   “' },
            { text: 'Codice della Crisi e dell’Insolvenza', bold: true },
            { text: '” o “' },
            { text: 'CCII', bold: true },
            { text: '”: D. Lgs. del 12 gennaio 2019, n. 14;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(iii)  “' },
            { text: 'Credito', bold: true },
            { text: '” ha il significato di cui all’articolo 2.1;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(iv)  “' },
            { text: 'Debitore Ceduto', bold: true },
            { text: '” ha il significato indicato nella premessa B;' }
          ],
          margin: [5, 5]
        },
        {
          text: [
            { text: '(v)   “' },
            { text: 'Evento di Insolvenza', bold: true },
            {
              text:
                '” indica, con riferimento a un soggetto, che tale soggetto sia stato sottoposto ad una procedura di risanamento o liquidazione, ivi incluse:'
            }
          ],
          margin: [5, 5]
        },
        {
          text:
            'a)	la messa in liquidazione volontaria o giudiziale o, comunque, la convocazione di un’assemblea per la messa in liquidazione (o la richiesta di messa in liquidazione) o l’approvazione di tale delibera, o comunque il verificarsi di una causa di scioglimento;',
          margin: [5, 5]
        },
        {
          text:
            'b)	la “composizione negoziata per la soluzione della crisi d’impresa”, di cui agli artt. 12ss. del CCII, e ciascuna delle soluzioni percorribili alla conclusione delle trattative, come previste dall’art. 23 del CCII, ivi incluse: il “concordato semplificato per la liquidazione del patrimonio” di cui all’art. 25-sexies del CCII, il contratto con uno o più creditori di cui all’art. 23, co. 1, lett. a) del CCII e l’accordo sottoscritto dall’imprenditore, dai creditori e dall’esperto di cui all’art. 23, co. 1, lett. c) del CCII;',
          margin: [5, 5]
        },
        {
          text:
            'c)	gli strumenti di regolazione della crisi e dell’insolvenza (inclusi, senza limitazione alcuna, i “piani di risanamento attestati ed i relativi accordi in esecuzione”, ai sensi dell’art. 56; gli “accordi di ristrutturazione dei debiti”, ai sensi degli artt. 57 e seguenti (“accordi di ristrutturazione dei debiti”), art. 60 (“accordi di ristrutturazione agevolati”) e art. 61 (“accordi di ristrutturazione ad efficacia estesa”); la “convenzione di moratoria” ai sensi dell’art. 62; il “piano di ristrutturazione soggetto ad omologazione”, ai sensi dell’art. 64-bis; il “concordato preventivo”, ai sensi degli artt. 84 e seguenti; la “liquidazione giudiziale”, di cui agli artt. 121 e seguenti; la “liquidazione coatta amministrativa”, di cui agli artt. 239 e seguenti; le “procedure di composizione delle crisi da sovraindebitamento”, di cui alla parte I, titolo IV capo II del CCII, ivi incluse, la “procedura di ristrutturazione dei debiti del consumatore”, di cui agli artt. 67 e ss. Del CCII, il “concordato minore”, di cui agli artt. 74 e ss. del CCII e la “liquidazione controllata del sovraindebitato”, di cui agli artt. 268 e ss. del CCII);',
          margin: [5, 5]
        },
        {
          text:
            'd)	la circostanza che l’intera impresa o parte sostanziale del patrimonio del relativo soggetto è oggetto di una procedura di pignoramento, sequestro o altra procedura con effetti analoghi:',
          margin: [5, 5]
        },
        {
          text:
            'e)	sono state fatte delle segnalazioni per l’anticipata emersione della crisi, la composizione negoziata e le soluzioni percorribili alla conclusione delle relative trattative, le misure cautelari e protettive, gli strumenti di regolazione della crisi e dell’insolvenza;',
          margin: [5, 5]
        },
        {
          text:
            'f)	è stata fatta la richiesta di misure protettive o cautelari ai sensi del CCII, ivi incluso ai sensi degli artt. 18, 19, 54 e 55 del medesimo CCII, nonché di ogni altra misura protettiva e/o cautelare avente analoghe finalità;',
          margin: [5, 5]
        },
        {
          text:
            'g)	è stata proposta un’istanza, ricorso e/o domanda finalizzata all’accesso ad una procedura di risanamento o liquidazione di cui alla lettera (a) che precede con riserva di deposito di documentazione e/o all’ottenimento di una misura cautelare e protettiva ai sensi del CCII, delle leggi speciali, ovvero delle disposizioni previgenti rispetto all’entrata in vigore del CCII, come pro tempore applicabili; o un’istanza, ricorso e/o domanda finalizzati all’accesso ad una delle predette procedure con riserva di deposito della documentazione, comunque previsti dalle disposizioni previgenti rispetto all’entrata in vigore del CCII, come pro tempore applicabili;',
          margin: [5, 5]
        },
        {
          text:
            'h)	nelle procedure di cui al CCII è stato nominato o ne è stata richiesta la nomina da parte dei soggetti a ciò legittimati, di un curatore, amministratore straordinario, commissario, liquidatore, professionista indipendente, esperto nella composizione negoziata della crisi, soggetto incaricato dall’autorità giudiziaria delle funzioni di gestione e di controllo, o altro funzionario e/o professionista avente natura o funzioni simili nell’ambito delle suddette procedure;',
          margin: [5, 5]
        },
        {
          text:
            'i)	è stata pronunciata un’ordinanza o è diventata effettiva una delibera per la liquidazione o lo scioglimento del relativo soggetto in qualunque forma;',
          margin: [5, 5]
        },
        {
          text: ['(vi)  “', { text: 'Prezzo di Acquisto', bold: true }, '” ha il significato di cui all’articolo 3.1.'],
          margin: [5, 1]
        },
        {
          text: [
            {
              text: '2.  ',
              bold: true
            },
            {
              text: 'Oggetto',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: [
            {
              text:
                '2.1.	In forza della sottoscrizione del presente Accordo, il Cedente cede, ai sensi e per gli effetti degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130 e della legge n. 52 del 21 febbraio 1991 (“'
            },
            {
              text: 'Legge Factoring',
              bold: true
            },
            {
              text:
                '”), a titolo oneroso pro-soluto al Cessionario, che accetta, il credito commerciale risultante dalla fattura n° ' +
                this.invoice.numFattura +
                ' del ' +
                this.invoice.dataFattura +
                ' di Euro ' +
                this.invoice.importoFattura +
                '(di seguito, il “'
            },
            {
              text: 'Credito',
              bold: true
            },
            {
              text: '”) con efficacia legale ed economica alla data di stipula del presente Accordo (inclusa).'
            }
          ]
        },
        {
          text:
            '2.2.	Il Cedente consegna al Cessionario tutta la documentazione comprovante il Credito e, nello specifico: (i) fattura n° ' +
            this.invoice.numFattura +
            ' del ' +
            this.invoice.dataFattura +
            ' di cui all’Allegato. A.'
        },
        {
          text:
            '2.3.	Il Credito viene trasferito al Cessionario con tutti i privilegi, garanzie personali, garanzie reali e con gli altri accessori ivi comprese eventuali pattuizioni contrattuali ai sensi dell’1263, comma 1, c.c., inclusi, a titolo esemplificativo, tutte le relative somme per capitale, interessi, interessi di mora, le penali e qualsiasi altra commissione, accessorio o qualsiasi altro importo esigibile e pagabile in qualsiasi momento.'
        },
        {
          text: [
            {
              text: '3.  ',
              bold: true
            },
            {
              text: 'Prezzo',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: [
            {
              text:
                '3.1.	 Salvo quanto previsto dall’articolo 6 (Risoluzione e Condizione Risolutiva), a fronte della cessione del Credito, il Cessionario si obbliga a pagare in favore del Cedente: un importo pari a € ' +
                this.invoice.importoFattura +
                ', relativamente alla fattura ' +
                this.invoice.numFattura +
                ' del ' +
                this.invoice.dataFattura +
                ', entro 3 giorni lavorativi dalla sottoscrizione del presente Accordo (il “'
            },
            {
              text: 'Prezzo di Acquisto',
              bold: true
            },
            {
              text: '”).'
            }
          ]
        },
        {
          text:
            '3.2.	I pagamenti saranno effettuati sul conto corrente n. IBAN ' +
            this.cedente.iban +
            ' intestato a ' +
            this.cedente.denominazione +
            ' di cui all’Allegato B.'
        },
        {
          text:
            '3.3.	Il Cedente si impegna a rilasciare quietanza al Cessionario dell’effettivo pagamento non appena avvenuto.'
        },
        {
          text: [
            {
              text: '4.  ',
              bold: true
            },
            {
              text: 'Garanzie e obblighi delle Parti',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '4.1. Il Cedente prende atto e accetta che il Cessionario stipuli il presente Accordo e decida di acquistare il Credito ai sensi del, e in conformità al, presente Accordo, sulla base di e facendo affidamento su, le dichiarazioni e le garanzie fornite dal Cedente ai sensi della presente Clausola. '
        },
        {
          text: '4.2.	Il Cedente garantisce che:'
        },
        {
          text:
            '- è una società per azioni debitamente organizzata e validamente esistente ai sensi della legge italiana, ed è debitamente autorizzata ad operare in ogni giurisdizione in cui data la natura dei suoi affari si richieda una tale autorizzazione;',
          margin: [5, 2]
        },
        {
          text: '- non è soggetto ad alcun Evento di Insolvenza;',
          margin: [5, 2]
        },
        {
          text:
            '- il Credito è certo, liquido ed esigibile rispetto alla scadenza riportata sui documenti comprovanti la sua esistenza e non contestato da parte del Debitore Ceduto;',
          margin: [5, 2]
        },
        {
          text:
            '- il contratto da cui deriva il Credito ceduto con il presente atto è stato validamente stipulato nell’osservanza delle disposizioni di legge vigenti ad esso applicabile, e non contiene clausole o disposizioni che impediscano o anche limitino il trasferimento o la cessione, in tutto o in parte, del credito che ne derivi;',
          margin: [5, 2]
        },
        {
          text:
            '- ha adempiuto ed adempirà puntualmente agli obblighi di cui all’ordine/Contratto di cui all’Allegato C in base al quale il Credito è sorto;',
          margin: [5, 2]
        },
        {
          text:
            '- è unico legittimo e assoluto titolare del Credito oggetto di cessione, il quale è legittimamente trasferibile, non soggetto a sequestro, pignoramento, né ad altri vincoli a favore di terzi, di non aver liberato alcuno dei debitori ceduti e di non aver rinunciato ad alcun diritto nei confronti di essi;',
          margin: [5, 2]
        },
        {
          text:
            '- all’atto della cessione il Debitore Ceduto non ha crediti che possano essere imputati a compensazione, sia pure parziale, del Credito.',
          margin: [5, 2]
        },
        {
          text: '4.3. Il Cedente si impegna:'
        },
        {
          text: '- non appena ne venga a conoscenza, a comunicare al Cessionario, entro 15 (quindici) giorni:',
          margin: [5, 2]
        },
        {
          text:
            '(i)   ogni inaccuratezza o errore nelle dichiarazioni e garanzie rese dal Cedente nel presente Accordo; ',
          margin: [10, 2]
        },
        {
          text: '(ii)  pretese di terze parti sul Credito; e',
          margin: [10, 2]
        },
        {
          text: '(iii) pignoramento o azione di terzi sul Credito;',
          margin: [10, 2]
        },
        {
          text:
            '- porre in essere, nei tempi tecnici necessari e comunque con un preavviso di almeno 15 (quindici) giorni, a cura e spese esclusivamente a carico del Cessionario, ogni attività e/o azione, stipulare ogni documento e adempiere a qualsiasi formalità che il Cessionario (fatto salvo il trasferimento del Credito e dei relativi accessori) possa ragionevolmente ritenere necessari o opportuni per esercitare i propri diritti, azioni, pretese, privilegi in relazione ai Crediti, inclusi quelli necessari a rendere opponibile a terzi la cessione del Credito;',
          margin: [5, 2]
        },
        {
          text:
            '- fornire al Cessionario le informazioni necessarie alla redazione del prospetto informativo previsto dalla Legge 130;',
          margin: [5, 2]
        },
        {
          text: '- a non: ',
          margin: [5, 2]
        },
        {
          text: '(i) cedere o altrimenti trasferire il Credito a terze parti;',
          margin: [10, 2]
        },
        {
          text: '(ii) creare garanzie, vincoli, pesi o altri diritti in favore di terzi sul Credito;',
          margin: [10, 2]
        },
        {
          text: '(iii) sciogliere con effetto retroattivo il contratto dal quale deriva il Credito;',
          margin: [10, 2]
        },
        {
          text:
            '(iv) modificare i termini e le condizioni del Credito, compensarlo, rimetterlo o concordare che, o agire in modo che il Credito sia inefficace o che ne sia diminuito il valore, in tutto od in parte;',
          margin: [10, 2]
        },
        {
          text:
            '(v) dare istruzioni al Debitore Ceduto di effettuare pagamenti od intraprendere ogni azione nei suoi confronti;',
          margin: [10, 2]
        },
        {
          text:
            '- a versare al Cessionario entro 2 (due) giorni dall’incasso, qualsiasi importo incassato sul Credito successivamente alla data di stipula del presente Accordo.',
          margin: [10, 2]
        },
        {
          text:
            '4.4.	Le Parti concordano che il Cedente, previo accordo con il Cessionario, dovrà gestire a proprie spese e cura gli eventuali giudizi passivi relativi ai rapporti contrattuali da cui originano il Credito o le garanzie accessorie o finalizzate all’accertamento di responsabilità restitutorie o risarcitorie.'
        },
        {
          text:
            "4.5.	Il Cedente dichiara che i documenti allegati al presente Accordo, che il Cessionario dichiara di avere esaminato e conoscere, costituiscono tutti i documenti probatori del Credito ceduto ai sensi dell'art. 1262 c.c., e della cui consegna da parte del Cedente il Cessionario dà atto con la sottoscrizione del presente Accordo; il Cedente si impegna inoltre, a partire dalla data della cessione, a trasmettere prontamente al Cessionario tutta la corrispondenza relativa al Credito oggetto di cessione che dovesse ricevere."
        },
        {
          text: [
            {
              text: '5.  ',
              bold: true
            },
            {
              text: 'Opponibilità a terzi',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            "5.1.	Le Parti espressamente concordano nell’applicare il disposto dell'articolo 5, commi 1 e 1-bis della legge 21 febbraio 1991, n. 52 e pertanto la cessione del Credito sarà opponibile nei confronti dei terzi mediante pagamento del Prezzo di Acquisto con data certa. "
        },
        {
          text:
            '5.2.	A partire dalla data che cade 3 (tre) mesi dopo la data del primo acquisto dei Crediti, il Beneficiario si impegna, con cadenza trimestrale, ad effettuare la pubblicazione in Gazzetta Ufficiale di tutte le cessioni dei Crediti avvenute nel semestre precedente, sulla base del modello di cui all’Allegato D (Modello di avviso di cessione).'
        },
        {
          text:
            '5.3.	Contestualmente alla sottoscrizione del presente Accordo, ' +
            this.cedente.denominazione +
            'si obbliga a provvedere a propria cura e spese alla notifica della comunicazione di cui all’art. 1264 c.c. al Debitore Ceduto nonché ai suoi eventuali avallanti, garanti e/o obbligati in solido ceduti, impegnandosi a porre in essere quant’altro fosse necessario affinché i destinatari prendano debita nota dell’intervenuta cessione del Credito ed a consegnare prontamente al Cessionario tutta la documentazione relativa alla predetta notifica non appena perfezionata. Qualora il Cedente non abbia provveduto entro tre giorni lavorativi, il Cessionario potrà effettuare la suddetta notifica a spese del Cessionario.'
        },
        {
          text: [
            {
              text: '6.  ',
              bold: true
            },
            {
              text: 'Risoluzione e Condizione Risolutiva',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '6.1.	Il Cessionario potrà risolvere il presente Accordo, ai sensi dell’art. 1456 c.c., dandone comunicazione scritta al Cedente a mezzo lettera raccomandata in caso di violazione di uno qualsiasi degli obblighi previsti dall’articolo 4.2 del presente Accordo.'
        },
        {
          text:
            '6.2.	Lo scioglimento dell’Accordo ai sensi del presente articolo legittima il Cessionario a richiedere al Cedente la restituzione delle somme pagate quale corrispettivo del Credito ceduti e non ancora incassati, con obbligo del Cedente di indennizzare e manlevare il Cessionario, altresì, di tutte le eventuali spese sostenute. L’efficacia del presente Accordo è risolutivamente condizionata ai sensi dell’articolo 1353 del Codice Civile, al mancato pagamento del Prezzo di Acquisto entro [30] giorni dalla data di stipula del Contratto.'
        },
        {
          text:
            '6.3.	In caso di risoluzione del presente Accordo ai sensi dell’articolo 6.1 che precede, il Credito ed i diritti trasferiti con lo stesso si considereranno come mai trasferiti e pertanto ogni incasso ricevuto dal Cessionario dalla data di stipula del presente Accordo dovrà essere restituito al Cedente entro trenta giorni di calendario.'
        },
        {
          text: [
            {
              text: '7.  ',
              bold: true
            },
            {
              text: 'Comunicazioni',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '7.1.	Se non diversamente convenuto per iscritto dalle Parti, qualsiasi comunicazione riguardante l’oggetto del presente Accordo dovrà essere effettuata mediante lettera raccomandata A/R ovvero posta elettronica certificata ai recapiti di seguito riportati:'
        },
        {
          alignment: 'center',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['Se a ', { text: this.investorSPV.ragioneSociale, bold: true }]
            },
            {
              text: ['Se a ', { text: this.cedente.denominazione, bold: true }]
            }
          ]
        },
        {
          alignment: 'center',
          columns: [
            {
              text: 'c.a. Amministratore Unico'
            },
            {
              text: 'c.a ' + this.cedente.legalName
            }
          ]
        },
        {
          alignment: 'center',
          columns: [
            {
              text: this.investorSPV.indirizzo
            },
            {
              text: 'Via ' + this.cedente.legalAddressStreet
            }
          ]
        },
        {
          alignment: 'center',
          columns: [
            {
              text: this.investorSPV.cap + ' ' + this.investorSPV.city
            },
            {
              text: this.cedente.legalAddressCAP + ' ' + this.cedente.legalAddressCity
            }
          ]
        },
        {
          alignment: 'center',
          margin: [0, 0, 0, 10],
          columns: [
            {
              text: 'PEC: ' + this.investorSPV.pec
            },
            {
              text: 'PEC: ' + this.cedente.assignorPec
            }
          ]
        },
        {
          text: [
            {
              text: '8.  ',
              bold: true
            },
            {
              text: 'Miscellanea',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '8.1.	Le Parti dichiarano che, avendo negoziato singolarmente e congiuntamente, le clausole del presente Accordo, tutte concordate fra le stesse, non sussistono i presupposti per l’approvazione specifica di cui all’art. 1341 del Codice Civile.'
        },
        {
          text:
            '8.2.	Il mancato o parziale esercizio o ritardo nell’esercizio da parte di una delle Parti di qualsiasi diritto o rimedio ai sensi del presente Accordo non ne costituirà rinuncia, fatta salva l’intervenuta prescrizione. Ogni deroga o rinuncia, se concesse, sarà ritenuta riferita al solo caso specifico.'
        },
        {
          text:
            '8.3.	Il presente Accordo potrà essere modificato esclusivamente, col previo consenso scritto delle Parti.'
        },
        {
          text:
            '8.4.	Se uno o più patti del presente Accordo dovessero risultare invalidi, inefficaci od inapplicabili, tale invalidità, inefficacia od inapplicabilità non comporterà l’inefficacia delle rimanenti previsioni nei limiti di quanto consentito dall’articolo 1419 del Codice Civile. Se praticabile e se consentito dalle previsioni di legge applicabili, le previsioni che dovessero rilevarsi invalide, inefficaci o inapplicabili saranno interpretate e sostituite, se necessario, in maniera da riflettere la volontà delle Parti alla data di stipula del presente Accordo.'
        },
        {
          text:
            '8.5.	Il Cedente rinuncia irrevocabilmente a compensare ogni importo dovuto al Cessionario contro ogni altro eventuale debito dello stesso nei suoi confronti.'
        },
        {
          text: [
            {
              text: '9.  ',
              bold: true
            },
            {
              text: 'Impegni in relazione alla Legge sulla Privacy e la normativa Anti-Money Laundering',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: [
            {
              text:
                '9.1.	Il Cedente con il presente Accordo si impegna a collaborare pienamente con il Cessionario per l’espletamento di qualsiasi procedura e per il perfezionamento di qualsiasi formalità che dovesse rendersi necessaria o opportuna per il trasferimento di tutti i dati, incluse le procedure mirate all’adozione di qualsiasi misura di sicurezza in relazione a tali dati, e le informazioni relative al Credito e al Debitore Ceduto al fine di adempiere a qualsiasi legge e regolamento sulla privacy e la protezione dei dati incluse, a titolo esemplificativo, le previsioni della Legge sulla Privacy e del Regolamento UE n. 679 del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati (il “'
            },
            {
              text: 'GDPR',
              bold: true
            },
            {
              text: '”), e di qualsiasi regolamento ad essa relativo.'
            }
          ]
        },
        {
          text:
            'Il Cedente con il presente Accordo si impegna a fornire al Cessionario le informazioni in suo possesso necessarie in relazione alla profilazione del Cedente ai fini antiriciclaggio (inclusa qualsiasi informazione richiesta per la corretta identificazione del c.d. “titolare effettivo” e del c.d. “esecutore”), ove applicabile al Cessionario.'
        },
        {
          text: [
            {
              text: '10.  ',
              bold: true
            },
            {
              text: 'Ricorso Limitato e non petition',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text:
            '10.1	Il Cedente riconosce ed accetta che le obbligazioni di pagamento del Cessionario saranno a ricorso limitato, pari al minore tra '
        },

        {
          text: '(i) il nominale del relativo debito, comprensivo di capitale ed accessori, ed i',
          margin: [5, 5]
        },
        {
          text:
            "(ii) fondi eventualmente disponibili al Cessionario per tale pagamento, ai sensi dell'Ordine di Priorità applicabile. Il Cedente riconosce che le obbligazioni del Cessionario ai sensi del presente Contratto saranno limitate a tale importo massimo e che non avrà altro ricorso verso il Cessionario per le obbligazioni da questo assunte.",
          margin: [5, 5]
        },
        {
          text: [
            {
              text:
                '10.2	In considerazione dell’Articolo 10.1 che precede, il Cedente si impegna a non attivare alcuna procedura concorsuale contro il Cessionario o di prendere parte o comunque agire per la dichiarazione di insolvenza del Cessionario iniziata o attivata da terze parti, non agire, o prendere parte ad azioni di qualsiasi genere contro il Cessionario o i suoi beni, prima di due anni ed un giorno dalla data in cui i crediti del Cessionario nei confronti della banca finanziatrice ai sensi del Contratto di Finanziamento sottoscritto in data '
            },
            {
              // CHECK DATA
              text: this.investorSPV.dataContrFinanzmnt
            },
            {
              text: ' sono stati rimessi o rimborsati.'
            }
          ]
        },
        {
          text:
            '10.3	Le previsioni di cui al presente Articolo 10 resteranno valide e vincolanti anche qualora il presente Contratto dovesse essere risolto o comunque cessare di avere efficacia per qualunque motivo.'
        },
        {
          text: [
            {
              text: '11.  ',
              bold: true
            },
            {
              text: 'Legge regolatrice e foro competente',
              bold: true,
              decoration: 'underline'
            }
          ],
          margin: [0, 10, 0, 2]
        },
        {
          text: '11.1  Il presente Accordo è regolato dalla legge italiana.',
          margin: [10, 10]
        },
        {
          text:
            "11.2 Tutte le controversie che dovessero insorgere in ordine all'interpretazione, alla validità ed alla esecuzione del presente Accordo saranno devolute alla competenza esclusiva del Foro di Milano, intendendosi consensualmente derogata ogni diversa norma di competenza giudiziaria."
        },
        {
          text: 'ALLEGATO D',
          headlineLevel: 1,
          bold: true,
          alignment: 'center'
        },
        {
          text: 'Modello di avviso di cessione ',
          bold: true,
          alignment: 'center'
        },
        {
          text: this.investorSPV.ragioneSociale + ', ',
          bold: true,
          alignment: 'center'
        },
        {
          text:
            'Iscritta nell’elenco delle società veicolo per la cartolarizzazione dei crediti tenuto dalla Banca d’Italia ai sensi ',
          alignment: 'center'
        },
        {
          text: ' del Provvedimento della Banca d’Italia del 7 giugno 2017',
          alignment: 'center'
        },
        {
          text:
            'Sede legale: ' + this.investorSPV.indirizzo + ' - ' + this.investorSPV.cap + ' ' + this.investorSPV.city,
          alignment: 'center'
        },
        {
          text: 'Registro delle Imprese: ' + this.investorSPV.rea,
          alignment: 'center'
        },
        {
          text: 'Codice Fiscale: ' + this.investorSPV.piva,
          alignment: 'center'
        },
        {
          text: [
            {
              text:
                'Avviso di cessione di crediti pro soluto ai sensi del combinato disposto degli articoli 1 e 4 comma 1, secondo ',
              italics: true
            },
            {
              text: 'periodo, della legge 30 aprile 1999, n. 130 in materia di cartolarizzazione di crediti, (la “',
              italics: true
            },
            {
              text: 'Legge 130',
              bold: true,
              italics: true
            },
            {
              text:
                '”) e del,corredato dall’informativa ai sensi degli articoli 13 e 14 del Regolamento UE 679/2016 (il “',
              italics: true
            },
            {
              text: 'GDPR',
              bold: true,
              italics: true
            },
            {
              text:
                '”) e del provvedimento dell’Autorità Garante per la Protezione dei Dati Personali del 18 gennaio 2007',
              italics: true
            }
          ],
          margin: [10, 10]
        },
        {
          text: [
            {
              text: this.investorSPV.ragioneSociale + ' (il “'
            },
            {
              text: 'Cessionario',
              bold: true
            },
            {
              text:
                '”), con sede legale in ' +
                this.investorSPV.indirizzo +
                ' – ' +
                this.investorSPV.cap +
                ' ' +
                this.investorSPV.city +
                ', comunica di aver sottoscritto:'
            }
          ],
          margin: [1, 1]
        },
        {
          text: [
            {
              text: '(i)	in data'
            },
            {
              text: '[' + this.invoice.dataCaricaContrattoSPV + ']',
              bold: true
            },
            {
              text:
                'un contratto di cessione di crediti pecuniari, con efficacia giuridica ed efficacia economica in pari data, con'
            },
            {
              text: '(il “'
            },
            {
              text: 'Cedente [ ' + this.invoice.cedenteName + ']',
              bold: true
            },
            {
              text: '”e il “'
            },
            {
              text: 'Contratto di Cessione [' + this.invoice.numFattura + ']',
              bold: true
            },
            {
              text:
                "”), ai sensi del quale ha acquistato pro soluto, ai sensi e per gli effetti di cui al combinato disposto degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130 nonché dal disposto dell'articolo 5, commi 1, 1-bis e 2 della legge 21 febbraio 1991, n. 52 secondo quanto previsto dall'articolo 4 della Legge130, taluni crediti commerciali derivanti dall'esercizio dell'attività di impresa esercitata dal Cedente e (ivi inclusi, senza limitazione, capitale interessi corrispettivi, interessi di mora, penali e ogni altro importo, commissione e accessorio), come meglio descritti nel Contratto di Cessione;"
            }
          ],
          margin: [5, 5]
        },
        {
          text: [
            {
              text: '(ii)	in data'
            },
            {
              text: ' [' + this.invoice.dataCaricaContrattoSPV + ']',
              bold: true
            },
            {
              text:
                'un contratto di cessione di crediti pecuniari, con efficacia giuridica ed efficacia economica in pari data, con'
            },
            {
              text: '(il “'
            },
            {
              text: 'Cedente [' + this.invoice.cedenteName + ']',
              bold: true
            },
            {
              text: '” e il “'
            },
            {
              text: 'Contratto di Cessione  [' + this.invoice.numFattura + ']',
              bold: true
            },
            {
              text:
                "”), ai sensi del quale ha acquistato pro soluto, ai sensi e per gli effetti di cui al combinato disposto degli articoli 1 e 4 comma 1, secondo periodo, della Legge 130 nonché dal disposto dell'articolo 5, commi 1, 1-bis e 2 della legge 21 febbraio 1991, n. 52 secondo quanto previsto dall'articolo 4 della Legge130, taluni crediti commerciali derivanti dall'esercizio dell'attività di impresa esercitata dal Cedente e (ivi inclusi, senza limitazione, capitale interessi corrispettivi, interessi di mora, penali e ogni altro importo, commissione e accessorio), come meglio descritti nel Contratto di Cessione;"
            }
          ],
          margin: [5, 5]
        },
        {
          text: [
            {
              text:
                this.servicerSPV.ragioneSociale +
                ' con sede legale in ' +
                this.servicerSPV.city +
                ', ' +
                this.servicerSPV.indirizzo +
                ', C.F., P. IVA e iscrizione al Registro delle Imprese di Milano Monza Brianza Lodi n. ' +
                this.servicerSPV.piva +
                ', iscritta all’Albo di cui all’art. 106 del Testo Unico Bancario tenuto dalla Banca d’Italia – Cod. ABI '
            },
            {
              text: this.servicerSPV.abi,
              bold: true
            },
            {
              text:
                ') è stata incaricata dal Cessionario di svolgere, in relazione ai Crediti, il ruolo di soggetto incaricato responsabile della verifica della conformità delle operazioni alla legge e al prospetto informativo ai sensi dell’articolo 2, comma 3, lettera (c), comma 6 e comma 6-bis della Legge 130.'
            }
          ]
        },
        {
          text:
            'Per effetto della cessione del Credito, il debitore ceduto e gli eventuali suoi garanti, successori o aventi causa dovranno pagare ogni somma dovuta in relazione al Credito al Cessionario nelle forme nelle quali il pagamento di tali somme era consentito per contratto o in forza di legge anteriormente alla suddetta cessione, salvo specifiche indicazioni in senso diverso che potranno essere tempo per tempo comunicate ai debitori ceduti.'
        },
        {
          text:
            'Il debitore ceduto e gli eventuali suoi garanti, successori o aventi causa per ogni ulteriore informazione potranno rivolgersi al Cessionario o a ' +
            this.servicerSPV.ragioneSociale +
            ' nelle ore di ufficio di ogni giorno lavorativo.'
        },
        {
          text: 'Informativa ai sensi del Regolamento Generale sulla Protezione dei Dati (“GDPR”) ',
          bold: true
        },
        {
          text: [
            {
              text:
                'A seguito della cessione dei Crediti alla Cessionaria sopra descritta, la Cessionaria è divenuta titolare di tali Crediti e, di conseguenza, ulteriore “Titolare” del trattamento  dei dati personali relativi a tale Crediti. '
            }
          ],
          margin: [3, 3]
        },
        {
          text:
            "Tanto premesso, la Cessionaria, al fine della gestione e dell'incasso dei Crediti, ha " +
            this.servicerSPV.ragioneSociale +
            ' con sede legale in ' +
            this.servicerSPV.city +
            ', ' +
            this.servicerSPV.indirizzo +
            ', C.F., P. IVA e iscrizione al Registro delle Imprese di Milano Monza Brianza Lodi n. ' +
            this.servicerSPV.piva +
            ', iscritta all’Albo di cui all’art. 106 del Testo Unico Bancario tenuto dalla Banca d’Italia '
        },
        {
          text: [
            {
              text: '– Cod. ABI ' + this.servicerSPV.abi + ', come proprio “Servicer” (il “'
            },
            {
              text: 'Servicer',
              bold: true
            },
            {
              text:
                "). Il Servicer è di conseguenza, divenuto “Responsabile” del trattamento dei dati personali relativi ai debitori ceduti. Inoltre, la Cessionaria, ha nominato, al fine della gestione e dell'incasso dei Crediti FX12 con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 quale proprio asset manager (l’ “"
            },
            {
              text: 'Asset Manager',
              bold: true
            },
            {
              text:
                '”). L’ Asset Manager è stato, inoltre, nominato quale ulteriore “Responsabile” del trattamento  dei  dati personali relativi ai debitori ceduti, ai sensi e per gli effetti del GDPR. '
            }
          ],
          margin: [5, 5]
        },
        {
          text:
            'Ai sensi e per gli effetti del GDPR, la Cessionaria, il Servicer e l’Asset Manager non tratteranno dati che rientrano nelle “categorie particolari di dati personali” ai sensi degli articoli 9 e 10 del Regolamento Generale sulla Protezione dei Dati, definiti dal Codice in Materia di Protezione dei Dati Personali come “sensibili”. '
        },
        {
          text:
            "I dati personali continueranno ad essere trattati con le stesse modalità e per le stesse finalità per le quali gli stessi sono stati raccolti in sede di instaurazione dei rapporti, cosi come a suo tempo illustrate. In particolare,  la Cessionaria, il Servicer e l’Asset Manager, tratteranno i dati personali per finalità connesse e strumentali alla gestione ed amministrazione dei Crediti ceduti; al recupero  dei Crediti (ad es. conferimento a legali dell'incarico professionale del recupero del credito, etc.); agli  obblighi  previsti  da  leggi,  da regolamenti e dalla normativa  comunitaria  nonché'  da  disposizioni emesse da autorità a ciò legittimate dalla legge  e  da  organi  di vigilanza e controllo. "
        },
        {
          text:
            'In relazione alle indicate finalità, il trattamento dei dati personali avviene mediante strumenti manuali, informatici e telematici con logiche strettamente correlate alle finalità stesse e, comunque, in modo da garantire la sicurezza e la riservatezza  dei dati stessi. '
        },
        {
          text:
            'Per lo svolgimento della propria attività di gestione e recupero dei Crediti, la Cessionaria ed il Servicer e il Asset Manager, potranno comunicare i dati personali per le “finalità del trattamento cui sono destinati i dati, a persone, società, associazioni o studi professionali che prestano attività di assistenza o consulenza  in materia  legale  e società di recupero crediti. '
        },
        {
          text:
            'Un elenco dettagliato di tali soggetti è disponibile presso la sede della Cessionaria come sotto indicato.'
        },
        {
          text: [
            {
              text:
                "I diritti previsti all'articolo 15 e seguenti del Capo III del GDPR potranno essere esercitati anche mediante richiesta scritta all’Asset Manager - Responsabile FX12 con sede legale in Napoli, via Caracciolo 17, Partita IVA e C.F. 09528731210, e numero di iscrizione al Registro delle Imprese di Napoli NA-1039449 e-mail: desk@fx12.it all'attenzione del legale rappresentante."
            }
          ]
        },
        {
          text:
            'Per ogni informazione relativa al presente avviso e ai Crediti è altresì possibile rivolgersi a ' +
            this.investorSPV.ragioneSociale +
            ' presso la sede sociale.',
          margin: [20, 20]
        },
        {
          text: [
            {
              text: this.investorSPV.city + ','
            },
            {
              text: '[' + this.data + ']',
              bold: true
            }
          ]
        },

        { text: this.investorSPV.ragioneSociale + '', headlineLevel: 1, bold: true, decoration: 'underline' },

        { text: '*    *     *', headlineLevel: 1, alignment: 'center' },

        {
          text:
            'Qualora concordiate con la presente proposta contrattuale, vogliate trascrivere il testo della presente e dei relativi allegati su Vostra lettera ed inviarci tale lettera debitamente firmata in segno di piena ed incondizionata accettazione '
        },
        {
          text: 'Con i nostri migliori saluti,',
          italics: true,
          margin: [10, 10]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: this.investorSPV.ragioneSociale, bold: true }]
            }
          ]
        },

        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: '[Firmato] ', italics: true }]
            }
          ]
        },
        {
          text: '*    *     *',
          alignment: 'center'
        },
        {
          text: 'In segno di integrale ed incontizionata accettazione'
        },
        {
          text: 'Cordiali saluti,',
          margin: [10, 10]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: this.cedente.denominazione, bold: true }]
            }
          ]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: '__________________________', bold: true }]
            }
          ]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: 'Nome:' }]
            }
          ]
        },
        {
          alignment: 'left',
          margin: [0, 10, 0, 0],
          columns: [
            {
              text: ['', { text: 'Titolo:' }]
            }
          ]
        }
      ],
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      }
    };
    pdfMake.createPdf(docDefinition).open();
  }

  onSelected(value: string) {
    if (value != '' && value != '---') {
      this.listInvestoSPVtoInvoice[0] = value;
      // recupero i servicer collegati al presente investitore
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.backendAuthService.sessionStorageModel.backendToken
        })
      };
      this.http
        .post<any>(
          `${environment.laravelServer}/users/getServicerSPVByIdInvestorSPV`,
          '{"id":"' + value + '"}',
          httpOptions
        )
        .subscribe(resp => {
          this.listServicerSPV = resp.data;
          console.log('Servicers=>', this.listServicerSPV);
          this.investorIsSelected = true;
        });
    } else {
      alert('Devi selezionare almeno un valore');
      this.listInvestoSPVtoInvoice = [];
    }
  }

  onSelectedServicer(value: string) {
    console.log('Servicers sel uuid=>', value);
    if (value != '' && value != '---') {
      this.listServicerSPVtoInvoice[0] = value;
    } else {
      alert('Devi selezionare almeno un valore');
      this.listServicerSPVtoInvoice = [];
    }
    console.log('Servicers sel=>', this.listServicerSPVtoInvoice);
  }

  assegnaToInvestorSPV(email: string) {
    if (this.listInvestoSPVtoInvoice.length > 0 && this.listServicerSPVtoInvoice.length > 0) {
      // aggiorno la fattura aggiungendo l'investitore alla fattura
      const obj = {
        status_index: 51,
        investorSPV: this.listInvestoSPVtoInvoice,
        servicerSPV: this.listServicerSPVtoInvoice,
        status: Utilities.invoiceStatus_it_IT[51]
      };

      this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj);

      this.listInvestorSPV.forEach(item => {
        this.listInvestoSPVtoInvoice.forEach(item2 => {
          if (item.uuid == item2) {
            this.sendEmailToInvestorSPV(item.email.toString(), item);
          }
        });
      });

      this.listServicerSPV.forEach(item => {
        this.listServicerSPVtoInvoice.forEach(item2 => {
          if (item.uuid == item2) {
            this.sendEmailToServicerSPV(item.email.toString(), item);
          }
        });
      });

      this.activeModal.dismiss();
    } else {
      alert('Bisogna assegnare almeno un investitore e un servicer');
    }
  }

  generateNotificationToDebit() {
    let docDefinition = {
      pageSize: 'A4',
      pageMargins: [60, 60, 60, 60],
      content: [
        {
          text: 'Spettabile',
          alignment: 'right',
          margin: [0, 0, 0, 0]
        },
        {
          text: this.debitore.name,
          bold: true,
          alignment: 'right',
          margin: [0, 0, 0, 0]
        },
        {
          text: this.debitore.viaLeg,
          alignment: 'right',
          margin: [0, 0, 0, 0]
        },
        {
          text: this.debitore.comuneLeg,
          alignment: 'right',
          margin: [0, 0, 0, 0]
        },
        {
          text: this.debitore.capLegale,
          alignment: 'right',
          margin: [0, 0, 0, 0]
        },
        {
          text: 'A mezzo PEC all’indirizzo:',
          alignment: 'right',
          margin: [0, 8, 0, 0]
        },
        {
          text: this.debitore.originatorPec,
          alignment: 'right',
          margin: [0, 0, 0, 0]
        },
        {
          text: 'Oggetto: Notifica della cessione di credito',
          bold: true,
          alignment: 'left',
          margin: [0, 30, 0, 10]
        },
        {
          text:
            'Con la presente Vi comunichiamo che, con contratto di cessione del ' +
            this.invoice.dataCaricaContrattoSPV +
            ', ' +
            this.cedente.denominazione +
            '. con sede ' +
            this.cedente.legalAddressCity +
            ', Italia, C.F ' +
            this.cedente.pIva +
            ', P.IVA e numero di iscrizione al Registro delle Imprese di ' +
            this.cedente.legalAddressCity +
            ' n. ' +
            this.cedente.pIva +
            ' (il “Cedente”) ha ceduto a “' +
            this.investorSPV.ragioneSociale +
            '” (il “Cessionario”) i seguenti crediti a qualunque titolo vantati dal Cedente nei Vostri confronti da parte di:'
        },
        {
          text: '-	' + this.debitore.name + ' (il “Debitore”) per:',
          alignment: 'left',
          margin: [0, 5]
        },
        {
          text: '   o	' + this.invoice.numFattura + ' del ' + this.invoice.dataFattura,
          alignment: 'left',
          margin: [20, 0]
        },
        {
          text:
            'Per effetto della predetta cessione, unitamente ai crediti vantati nei confronti del Debitore, sono state trasferite al Cessionario tutte le garanzie da chiunque concesse in relazione ai predetti crediti ceduti.',
          margin: [0, 10, 0, 0]
        },
        {
          text:
            'Vi informiamo altresì che il Cessionario ha conferito incarico a Zenith Service S.p.A. (il “Servicer”) con sede in Corso Vittorio Emanuele II 24/28, numero di iscrizione al registro delle imprese di Milano Monza Brianza Lodi n. 02200990980, iscritta nell’Albo Unico degli Intermediari Finanziari tenuto dalla Banca d’Italia ai sensi dell’articolo 106 del Testo Unico Bancario al n. 30 - ABI Code 32590.2 - di agire in qualità di “soggetto incaricato della riscossione dei crediti ceduti e dei servizi di cassa e pagamento e responsabile della verifica della conformità delle operazioni alla legge” ai sensi dell’articolo 2, comma 3, lettera (c) e commi 6 e 6-bis della legge 30 aprile 1999, n. 130 (“Legge sulla Cartolarizzazione”), affinché proceda all’incasso e al recupero delle somme dovute in relazione ai crediti ceduti.',
          margin: [0, 10, 0, 0]
        },
        {
          text:
            'Fermo quanto sopra, precisiamo che l’intestatario del conto di accredito e l’IBAN di accredito per il pagamento di tutto quanto da Voi dovuto (fino a successiva diversa comunicazione da parte del Cessionario) sono i seguenti:',
          margin: [0, 10, 0, 0]
        },
        {
          text: '•	Intestatario: SPV PROJECT 2218 S.r.l.',
          margin: [20, 6]
        },
        {
          text: '•	IBAN: IT 64 S 05372 74370 000011039215',
          margin: [20, 0]
        },
        {
          text:
            'Siamo inoltre a comunicarVi i contatti del Cessionario per eventuali comunicazioni riguardanti la cessione del credito per la pratica in oggetto:',
          margin: [0, 15, 0, 10]
        },
        {
          text: 'Zenith Service S.p.A.',
          bold: true,
          alignement: 'left',
          margin: [0, 0]
        },
        {
          text: 'Corso Vittorio Emanuele II, 24/28 ',
          bold: false,
          alignement: 'left',
          margin: [0, 0]
        },
        {
          text: '20122 - Milano',
          bold: false,
          alignement: 'left',
          margin: [0, 0]
        },
        {
          text: 'PEC: zenith_service@legalmail.it',
          bold: false,
          alignement: 'left',
          margin: [0, 0]
        },
        {
          text: 'Email: reporting@zenithservice.it',
          bold: false,
          alignement: 'left',
          margin: [0, 0]
        },
        {
          text: 'Att.: Legale Rappresentante',
          bold: false,
          alignement: 'left',
          margin: [0, 0]
        },
        {
          text: 'Distinti saluti.',
          bold: false,
          alignement: 'right',
          margin: [0, 15]
        },
        {
          text: '________________________________________',
          alignement: 'right',
          margin: [0, 0]
        },
        {
          text: 'Il Cedente',
          alignement: 'right',
          margin: [0, 0]
        }
      ],
      pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
        return currentNode.headlineLevel === 1;
      }
    };
    pdfMake.createPdf(docDefinition).open();
  }

  createBodyForPECDebitor() {
    const body =
      '' +
      '<html>\n' +
      '\n' +
      '<head>\n' +
      '<meta http-equiv=Content-Type content="text/html; charset=windows-1252">\n' +
      '<meta name=Generator content="Microsoft Word 15 (filtered)">\n' +
      '<style>\n' +
      '<!--\n' +
      ' /* Font Definitions */\n' +
      ' @font-face\n' +
      '\t{font-family:Wingdings;\n' +
      '\tpanose-1:5 0 0 0 0 0 0 0 0 0;}\n' +
      '@font-face\n' +
      '\t{font-family:PMingLiU;\n' +
      '\tpanose-1:2 1 6 1 0 1 1 1 1 1;}\n' +
      '@font-face\n' +
      '\t{font-family:"Cambria Math";\n' +
      '\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n' +
      '@font-face\n' +
      '\t{font-family:Tahoma;\n' +
      '\tpanose-1:2 11 6 4 3 5 4 4 2 4;}\n' +
      '@font-face\n' +
      '\t{font-family:"Arial Narrow";\n' +
      '\tpanose-1:2 11 6 6 2 2 2 3 2 4;}\n' +
      '@font-face\n' +
      '\t{font-family:"\\@PMingLiU";\n' +
      '\tpanose-1:2 1 6 1 0 1 1 1 1 1;}\n' +
      ' /* Style Definitions */\n' +
      ' p.MsoNormal, li.MsoNormal, div.MsoNormal\n' +
      '\t{margin:0cm;\n' +
      '\tfont-size:11.0pt;\n' +
      '\tfont-family:"Times New Roman",serif;}\n' +
      'p.MsoHeader, li.MsoHeader, div.MsoHeader\n' +
      '\t{mso-style-link:"Intestazione Carattere";\n' +
      '\tmargin:0cm;\n' +
      '\tfont-size:11.0pt;\n' +
      '\tfont-family:"Times New Roman",serif;}\n' +
      'p.MsoFooter, li.MsoFooter, div.MsoFooter\n' +
      '\t{mso-style-link:"Piè di pagina Carattere";\n' +
      '\tmargin:0cm;\n' +
      '\tfont-size:11.0pt;\n' +
      '\tfont-family:"Times New Roman",serif;}\n' +
      'p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n' +
      '\t{margin-top:0cm;\n' +
      '\tmargin-right:0cm;\n' +
      '\tmargin-bottom:0cm;\n' +
      '\tmargin-left:36.0pt;\n' +
      '\tfont-size:11.0pt;\n' +
      '\tfont-family:"Times New Roman",serif;}\n' +
      'p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst\n' +
      '\t{margin-top:0cm;\n' +
      '\tmargin-right:0cm;\n' +
      '\tmargin-bottom:0cm;\n' +
      '\tmargin-left:36.0pt;\n' +
      '\tfont-size:11.0pt;\n' +
      '\tfont-family:"Times New Roman",serif;}\n' +
      'p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle\n' +
      '\t{margin-top:0cm;\n' +
      '\tmargin-right:0cm;\n' +
      '\tmargin-bottom:0cm;\n' +
      '\tmargin-left:36.0pt;\n' +
      '\tfont-size:11.0pt;\n' +
      '\tfont-family:"Times New Roman",serif;}\n' +
      'p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast\n' +
      '\t{margin-top:0cm;\n' +
      '\tmargin-right:0cm;\n' +
      '\tmargin-bottom:0cm;\n' +
      '\tmargin-left:36.0pt;\n' +
      '\tfont-size:11.0pt;\n' +
      '\tfont-family:"Times New Roman",serif;}\n' +
      'span.IntestazioneCarattere\n' +
      '\t{mso-style-name:"Intestazione Carattere";\n' +
      '\tmso-style-link:Intestazione;}\n' +
      'span.PidipaginaCarattere\n' +
      '\t{mso-style-name:"Piè di pagina Carattere";\n' +
      '\tmso-style-link:"Piè di pagina";}\n' +
      '.MsoChpDefault\n' +
      '\t{font-size:10.0pt;\n' +
      '\tfont-family:"Arial",sans-serif;}\n' +
      '.MsoPapDefault\n' +
      '\t{margin-bottom:12.0pt;\n' +
      '\tline-height:120%;}\n' +
      ' /* Page Definitions */\n' +
      ' @page WordSection1\n' +
      '\t{size:595.3pt 841.9pt;\n' +
      '\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\n' +
      'div.WordSection1\n' +
      '\t{page:WordSection1;}\n' +
      ' /* List Definitions */\n' +
      ' ol\n' +
      '\t{margin-bottom:0cm;}\n' +
      'ul\n' +
      '\t{margin-bottom:0cm;}\n' +
      '-->\n' +
      '</style>\n' +
      '\n' +
      '</head>\n' +
      '\n' +
      '<body lang=IT link="#0563C1" vlink="#954F72" style=\'word-wrap:break-word\'>\n' +
      '\n' +
      '<div class=WordSection1>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:.2pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:241.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><a name=\"_Hlk63325188\"><span style='color:black'>Spettabile</span></a></p>\n" +
      '\n' +
      "<p class=MsoNormal style='margin-top:.55pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:241.2pt;margin-bottom:.0001pt;line-height:12.35pt;vertical-align:\n' +
      "baseline'><b><span style='color:black'>" +
      this.debitore.name +
      '</span></b></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:.15pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:241.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><span style='color:black'>" +
      this.debitore.indirizzo +
      '</span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:241.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><span style='color:black'>" +
      this.debitore.originatorOfficeRegisteredCity +
      '</span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:12.8pt;margin-right:0cm;margin-bottom:\n" +
      '0cm;margin-left:241.2pt;margin-bottom:.0001pt;line-height:12.45pt;vertical-align:\n' +
      "baseline'><span style='color:black'>A mezzo PEC all' indirizzo: " +
      this.debitore.originatorPec +
      ' </span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:14.45pt;margin-right:0cm;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;line-height:12.35pt;vertical-align:\n' +
      "baseline'><b><span style='color:black'>Oggetto: Notifica della cessione di\n" +
      'credito </span></b></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:12.05pt;margin-right:25.2pt;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-align:justify;line-height:\n' +
      "12.65pt;vertical-align:baseline'><span style='color:black'>Con la presente Vi\n" +
      'comunichiamo che, con contratto di cessione del ' +
      this.invoice.dataCaricaContrattoSPV +
      ' , ' +
      this.cedente.denominazione +
      ' con\n' +
      'sede in ' +
      this.cedente.legalAddressCity +
      ', Italia, C.F ' +
      this.cedente.fiscalCode +
      ', P.IVA ' +
      this.cedente.pIva +
      ' e numero di iscrizione al Registro delle\n' +
      'Imprese di ' +
      this.cedente.legalAddressProvince +
      ' n. ' +
      this.cedente.REA_CODE +
      ' (il "<b>Cedente</b>") ha ceduto a "' +
      this.investorSPV.ragioneSociale +
      '"\n' +
      ' (il "<b>Cessionario</b>") i seguenti crediti a qualunque titolo vantati\n' +
      'dal Cedente nei Vostri confronti da parte di:</span></p>\n' +
      '\n' +
      "<p class=MsoListParagraphCxSpLast style='margin-top:12.05pt;margin-right:25.2pt;\n" +
      'margin-bottom:0cm;margin-left:54.0pt;margin-bottom:.0001pt;text-align:justify;\n' +
      "text-indent:-18.0pt;line-height:12.65pt;vertical-align:baseline'><span\n" +
      'style=\'font-family:"Courier New";color:black\'>o<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;\n' +
      "</span></span><span style='color:black'>" +
      this.debitore.name +
      "</span><span style='color:black'> (il\n" +
      '"<b>Debitore</b>") per:</span></p>\n' +
      '\n' +
      "<p class=MsoListParagraphCxSpLast style='margin-top:12.05pt;margin-right:25.2pt;\n" +
      'margin-bottom:0cm;margin-left:54.0pt;margin-bottom:.0001pt;text-align:justify;\n' +
      "text-indent:-18.0pt;line-height:12.65pt;vertical-align:baseline'><span\n" +
      'style=\'font-family:"Courier New";color:black\'>o<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;\n' +
      "</span></span><span style='color:black'>fattura n. " +
      this.invoice.numFattura +
      ' del ' +
      this.invoice.dataFattura +
      '\n' +
      ' </span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:11.8pt;margin-right:25.2pt;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-align:justify;line-height:\n' +
      "12.7pt;vertical-align:baseline'><span style='color:black'>Per effetto della\n" +
      'predetta cessione, unitamente ai crediti vantati nei confronti del Debitore,\n' +
      'sono state trasferite al Cessionario tutte le garanzie da chiunque concesse in\n' +
      'relazione ai predetti crediti ceduti.</span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:11.8pt;margin-right:25.2pt;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-align:justify;line-height:\n' +
      "12.7pt;vertical-align:baseline'><span style='color:black'>Vi informiamo altres&igrave;\n" +
      'che il Cessionario ha conferito incarico a ' +
      this.servicerSPV.ragioneSociale +
      ' (il "<b>Servicer</b>")\n' +
      'con sede in ' +
      this.servicerSPV.indirizzo +
      ', numero di iscrizione al registro\n' +
      'delle imprese di Milano Monza Brianza Lodi n. ' +
      this.servicerSPV.piva +
      ", iscritta nell' Albo\n" +
      "Unico degli Intermediari Finanziari tenuto dalla Banca d' Italia ai sensi\n" +
      "dell' articolo 106 del Testo Unico Bancario al n. 30 - ABI Code " +
      this.servicerSPV.abi +
      ' - di\n' +
      'agire in qualit&agrave; di "<i>soggetto incaricato della riscossione dei crediti\n' +
      'ceduti e dei servizi di cassa e pagamento e responsabile della verifica della\n' +
      'conformit&agrave; delle operazioni alla legge</i>" ai sensi dell\' articolo 2, comma 3,\n' +
      'lettera (c) e commi 6 e 6-<i>bis</i> della legge 30 aprile 1999, n. 130 ("<b>Legge\n' +
      'sulla Cartolarizzazione</b>"), affinch&egrave; proceda all\' incasso e al recupero delle\n' +
      'somme dovute in relazione ai crediti ceduti.</span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:11.85pt;margin-right:25.2pt;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-align:justify;line-height:\n' +
      "12.7pt;vertical-align:baseline'><span style='color:black'>Fermo quanto sopra,\n" +
      "precisiamo che l' intestatario del conto di accredito e l' IBAN di accredito per\n" +
      'il pagamento di tutto quanto da Voi dovuto (fino a successiva diversa\n' +
      'comunicazione da parte del Cessionario) sono i seguenti:</span></p>\n' +
      '\n' +
      "<p class=MsoListParagraphCxSpLast style='margin-top:12.05pt;margin-right:25.2pt;\n" +
      'margin-bottom:0cm;margin-left:54.0pt;margin-bottom:.0001pt;text-align:justify;\n' +
      "text-indent:-18.0pt;line-height:12.65pt;vertical-align:baseline'><span\n" +
      'style=\'font-family:"Courier New";color:black\'>o<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;\n' +
      "</span></span><span style='color:black'>Intestatario: </span><span lang=EN-US>" +
      this.investorSPV.ragioneSociale +
      '\n' +
      ' </span></p>\n' +
      '\n' +
      "<p class=MsoListParagraphCxSpLast style='margin-top:12.05pt;margin-right:25.2pt;\n" +
      'margin-bottom:0cm;margin-left:54.0pt;margin-bottom:.0001pt;text-align:justify;\n' +
      "text-indent:-18.0pt;line-height:12.65pt;vertical-align:baseline'><span\n" +
      'style=\'font-family:"Courier New";color:black\'>o<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;\n' +
      "</span></span><span style='color:black'>IBAN: </span><span lang=EN-US>" +
      this.investorSPV.iban +
      '\n' +
      '</span></p>\n' +
      '\n' +
      "<br><p class=MsoNormal style='margin-top:6.2pt;margin-right:25.2pt;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-align:justify;line-height:\n' +
      "12.5pt;vertical-align:baseline'><span style='color:black'>Siamo inoltre a\n" +
      'comunicarVi i contatti del Cessionario per eventuali comunicazioni riguardanti\n' +
      'la cessione del credito per la pratica in oggetto:</span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:6.2pt;margin-right:25.2pt;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;text-align:justify;line-height:\n' +
      "12.5pt;vertical-align:baseline'><b><span lang=EN-US style='color:black'>" +
      this.servicerSPV.ragioneSociale +
      '\n' +
      '</span></b></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:14.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><a name=\"_Hlk125457703\"><span style='color:black'>" +
      this.servicerSPV.indirizzo +
      '\n' +
      '</span></a></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:14.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><span style='color:black'>" +
      this.servicerSPV.cap +
      " </span><span style='color:black'>- " +
      this.servicerSPV.city +
      '\n' +
      '</span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:14.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><span style='color:black'>PEC: " +
      this.servicerSPV.pec +
      ' </span></p>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:14.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><span style='color:black'>Email: reporting@zenithservice.it</span></p>\n" +
      '\n' +
      "<p class=MsoNormal style='margin-top:.3pt;margin-right:0cm;margin-bottom:0cm;\n" +
      'margin-left:14.2pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><span style='color:black'>Att.: Legale Rappresentante</span></p>\n" +
      '\n' +
      "<p class=MsoNormal style='margin-top:12.0pt;margin-right:0cm;margin-bottom:\n" +
      '0cm;margin-left:14.4pt;margin-bottom:.0001pt;line-height:12.4pt;vertical-align:\n' +
      "baseline'><span style='color:black;letter-spacing:-.05pt'>Distinti saluti</span><span\n" +
      "style='color:black;letter-spacing:-.05pt'>.</span></p>\n" +
      '\n' +
      "<p class=MsoNormal style='margin-top:.75pt;margin-right:14.9pt;margin-bottom:\n" +
      "0cm;margin-left:0cm;margin-bottom:.0001pt;vertical-align:baseline'><b><i><span\n" +
      "style='color:black'>&nbsp;</span></i></b></p>\n" +
      '\n' +
      "<p class=MsoNormal style='margin-top:.75pt;margin-right:14.9pt;margin-bottom:\n" +
      "0cm;margin-left:0cm;margin-bottom:.0001pt;vertical-align:baseline'><b><i><span\n" +
      "style='color:black'>&nbsp;</span></i></b></p>\n" +
      '\n' +
      '<table class=MsoTableGrid border=0 cellspacing=0 cellpadding=0\n' +
      " style='margin-left:18.9pt;border-collapse:collapse;border:none'>\n" +
      ' <tr>\n' +
      "  <td width=307 valign=top style='width:230.6pt;padding:0cm 5.4pt 0cm 5.4pt'>\n" +
      "  <p class=MsoNormal style='margin-top:.75pt;margin-right:14.9pt;margin-bottom:\n" +
      "  0cm;margin-left:0cm;margin-bottom:.0001pt;vertical-align:baseline'>_____________________________</p>\n" +
      "  <p class=MsoNormal style='margin-top:.75pt;margin-right:14.9pt;margin-bottom:\n" +
      "  0cm;margin-left:0cm;margin-bottom:.0001pt;vertical-align:baseline'><b>Il\n" +
      '  Cedente</b></p>\n' +
      '  </td>\n' +
      "  <td width=333 valign=top style='width:249.5pt;padding:0cm 5.4pt 0cm 5.4pt'>\n" +
      "  <p class=MsoNormal style='margin-top:.75pt;margin-right:14.9pt;margin-bottom:\n" +
      "  0cm;margin-left:0cm;margin-bottom:.0001pt;vertical-align:baseline'><b></b></p>\n" +
      '  </td>\n' +
      ' </tr>\n' +
      '</table>\n' +
      '\n' +
      "<p class=MsoNormal style='margin-top:12.3pt;line-height:12.45pt;vertical-align:\n" +
      "baseline'>&nbsp;</p>\n" +
      '\n' +
      '</div>\n' +
      '\n' +
      '</body>\n' +
      '\n' +
      '</html>\n';

    return body;
  }

  sendPecToDebit() {
    /*
    this.bodyPecDebt = encodeURIComponent(this.createBodyForPECDebitor());
    const subjectPec = 'Notifica della cessione di credito ';

    if (this.debitore.originatorPec != '') {
      // creo il body dell'email
      const body =
        '{\n' +
        '    "to":"' +
        this.debitore.originatorPec +
        '",\n' +
        '    "to_name":"' +
        this.debitore.name +
        '",\n' +
        '    "subject":"' +
        subjectPec +
        '",\n' +
        '    "body":"' +
        this.bodyPecDebt +
        '",\n' +
        '    "filename":""\n' +
        '}';

      // console.log('body=>', body);
      this.sendMail.sendPecByBodyReturn(body).subscribe(data => {
        if (data.status == 'OK') {
          alert('PEC inviata correttamente');
          // cambio stato della fattura

          const obj = {
            status_index: 59,
            status: 'Acquistata'
          };

          this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
            this.activeModal.dismiss();
          });
        }
      });
    } else {
      alert('Il debitore non ha una PEC. Si prega di inserirlo nella rispettiva scheda.');
    }
    */
    const obj = {
      status_index: 57,
      status: Utilities.invoiceStatus_it_IT[57]
    };

    this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
      this.activeModal.dismiss();
    });
  }

  updateParamsPec(field: string, value: any) {
    if (field == 'subject') {
      this.subjectPecDebt = value.target.value;
    }
    if (field == 'body') {
      this.bodyPecDebt = value.target.value;
    }
    console.log('subject>', this.subjectPecDebt);
    console.log('subject>', this.bodyPecDebt);
  }

  terminaAzure() {
    const modalRef = this.modalService.open(ConfirmationModalGenericComponent, {
      size: 'md',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.title = 'Attenzione';
    modalRef.componentInstance.text = 'Confermi di voler concludere la trattativa?';
    modalRef.componentInstance.loading.subscribe((data: boolean) => {
      if (data) {
        modalRef.close();

        const obj = {
          status_index: 60,
          status: 'Terminata'
        };

        this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
          this.activeModal.dismiss();
        });
      }
    });
  }

  changeStatusIndex(accept: string) {
    const modalRef = this.modalService.open(ConfirmationModalGenericComponent, {
      size: 'md',
      centered: true,
      scrollable: true
    });
    modalRef.componentInstance.title = 'Attenzione';
    modalRef.componentInstance.text = 'Confermi di voler procedere?';
    modalRef.componentInstance.loading.subscribe((data: boolean) => {
      if (data) {
        modalRef.close();

        let obj = {};
        if (accept === 'ACCEPT') {
          obj = {
            status_index: 55,
            status: Utilities.invoiceStatus_it_IT[55]
          };
        }
        if (accept === 'DECLINE') {
          obj = {
            status_index: 53,
            status: Utilities.invoiceStatus_it_IT[53]
          };
        }

        if (accept === 'DECLINE' || accept === 'ACCEPT') {
          this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
            this.activeModal.dismiss();
          });
        }

        this.activeModal.dismiss();
      }
    });
  }

  createBodyEmail(dest: string) {
    var dati: any = {};

    dati.subject = "E' disponibile un nuovo documento";
    dati.senderCompany = 'FX12 Srl';
    dati.from = 'support@incassaora.it';
    dati.senderName = 'Support of Incassaora';
    dati.templateName = 'uploadedProofTransfer';

    if (dest == 'servicer_spv') {
      dati.recieverFirstName = this.servicerSPV.name;
      dati.recieverLastName = this.servicerSPV.lastName;
      dati.recieverEmail = this.servicerSPV.email;
    } else if (dest == 'investor_spv') {
      dati.recieverFirstName = this.investorSPV.name;
      dati.recieverLastName = this.investorSPV.lastName;
      dati.recieverEmail = this.investorSPV.email;
    } else if (dest == 'debitore') {
      dati.recieverFirstName = this.debitore.originatorSolicitorName;
      dati.recieverLastName =
        this.debitore.originatorSolicitorSurname && this.debitore.originatorSolicitorSurname != ''
          ? this.debitore.originatorSolicitorSurname
          : this.debitore.surname;
      dati.recieverEmail = this.debitore.email;
    } else if (dest == 'company') {
      dati.recieverFirstName = this.cedente.refName;
      dati.recieverLastName =
        this.cedente.refCogn && this.cedente.refCogn != '' ? this.cedente.refCogn : this.cedente.surname;
      dati.recieverEmail = this.cedente.email;
    }

    dati.numFattura = this.invoice.numFattura;

    const body =
      '{\n' +
      ' "to":"' +
      dati.recieverEmail +
      '",\n' +
      ' "subject":"' +
      dati.subject +
      '",\n' +
      ' "senderCompany":"' +
      dati.senderCompany +
      '",\n' +
      ' "from":"' +
      dati.from +
      '",\n' +
      ' "senderName":"' +
      dati.senderName +
      '",\n' +
      ' "templateName":"' +
      dati.templateName +
      '",\n' +
      ' "recieverFirstName":"' +
      dati.recieverFirstName +
      '",\n' +
      ' "recieverLastName":"' +
      dati.recieverLastName +
      '",\n' +
      ' "recieverEmail":"' +
      dati.recieverEmail +
      '",\n' +
      ' "numFattura":"' +
      dati.numFattura +
      '"' +
      '\n}';

    //console.log(body);
    return body;
  }

  addInvestorProd23() {
    console.log(this.associaInvestitore.value.pivaInvestitore);
    console.log(this.associaInvestitore.value.percentuale);
    // verifico se vi sono investitori inseriti

    const currentInv = this.associaInvestitore.value.pivaInvestitore;
    const percInserita: number = parseFloat(this.associaInvestitore.value.percentuale.toFixed(16));

    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;
      let investorPresent = false;
      let investorPercIfPresent = 0;

      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
        if (arrInv[0] == currentInv) {
          investorPresent = true;
          investorPercIfPresent = arrInv[1];
          listaInvestitoriPagamento.splice(index, 1);
          listaInvestitori.splice(index, 1);
          listaInvestitoriDaPagare.splice(index, 1);
        }
      });

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      percRimanente = percRimanente - percInserita;
      console.log('percRimanente Relativa =>', percRimanente);

      if (percRimanente < 0) {
        alert(
          'Impossibile inserire questo valore. La percentuale deve essere uguale o inferiore alla rimanenza scoperta.'
        );
        return;
      } else {
        // qualora l'investitore sia già presente bisogna aggiornare i dati
        let percAggiornata = 0;
        console.log('percInserita =>', percInserita);
        console.log('investorPercIfPresent =>', investorPercIfPresent);
        if (investorPresent) {
          percAggiornata = parseFloat(investorPercIfPresent + '') + parseFloat(percInserita + '');
        } else {
          percAggiornata = percInserita;
        }

        console.log('percAggiornata =>', percAggiornata);

        // aggiungo l'investitore all'array di cui sopra e aggiorno la fattura.
        listaInvestitoriPagamento.push(this.associaInvestitore.value.pivaInvestitore + '---' + percAggiornata + '---F');
        listaInvestitoriDaPagare.push(this.associaInvestitore.value.pivaInvestitore + '---' + percAggiornata + '---F');
        listaInvestitori.push(this.associaInvestitore.value.pivaInvestitore + '---' + percAggiornata);

        // creo l'oggetto per l'aggiornamento dello specifico campo
        const obj = {
          listaInvestitoriPagamento: listaInvestitoriPagamento,
          listaInvestitoriDaPagare: listaInvestitoriDaPagare,
          listaInvestitori: listaInvestitori
        };

        console.log('Oggetto=>', obj);
        this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);
        // aggiorno invoice
        this.percentualeScoperta = percRimanente;

        this.recalculateCopertura();
      }
    });
  }

  approvaPropInvestorProd23(investor: string) {
    const investorArr = investor.split('---');
    console.log(investorArr);
    // verifico se vi sono investitori inseriti

    const currentInv = investorArr[0];
    console.log('currentInv=>', currentInv);
    const percInserita: number = parseFloat(investorArr[1]);

    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];
    let listaProposteInvestimento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }
      if (currentInvoice.listaProposteInvestimento) {
        listaProposteInvestimento = currentInvoice.listaProposteInvestimento;
      }

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;
      let investorPresent = false;
      let investorPercIfPresent = 0;

      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
        if (arrInv[0] == currentInv) {
          investorPresent = true;
          investorPercIfPresent = arrInv[1];

          listaInvestitoriPagamento.splice(index, 1);
          listaInvestitori.splice(index, 1);
          listaInvestitoriDaPagare.splice(index, 1);
        }
      });

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      percRimanente = percRimanente - percInserita;
      console.log('percRimanente Relativa =>', percRimanente);

      if (percRimanente < 0) {
        alert(
          'Impossibile inserire questo valore. La percentuale deve essere uguale o inferiore alla rimanenza scoperta.'
        );
        return;
      } else {
        // qualora l'investitore sia già presente bisogna aggiornare i dati
        let percAggiornata = 0;
        console.log('percInserita =>', percInserita);
        console.log('investorPercIfPresent =>', investorPercIfPresent);
        if (investorPresent) {
          percAggiornata = parseFloat(investorPercIfPresent + '') + parseFloat(percInserita + '');
        } else {
          percAggiornata = percInserita;
        }

        console.log('percAggiornata =>', percAggiornata);

        // aggiungo l'investitore all'array di cui sopra e aggiorno la fattura.
        listaInvestitoriPagamento.push(currentInv + '---' + percAggiornata + '---F');
        listaInvestitoriDaPagare.push(currentInv + '---' + percAggiornata + '---F');
        listaInvestitori.push(currentInv + '---' + percAggiornata);

        listaProposteInvestimento.forEach((invProp, index) => {
          const invArr = invProp.split('---');
          if (invArr[0] == currentInv) {
            listaProposteInvestimento[index] = currentInv + '---' + percInserita + '---S';
          }
        });

        // creo l'oggetto per l'aggiornamento dello specifico campo
        const obj = {
          listaInvestitoriPagamento: listaInvestitoriPagamento,
          listaInvestitoriDaPagare: listaInvestitoriDaPagare,
          listaInvestitori: listaInvestitori,
          listaProposteInvestimento: listaProposteInvestimento
        };

        console.log('Oggetto=>', obj);
        this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);
        // aggiorno invoice
        this.percentualeScoperta = percRimanente;

        this.recalculateCopertura();
      }
    });
  }

  eliminaInvestitoreProd23(investor: any) {
    console.log(investor);
    const investorArr = investor;
    // verifico se vi sono investitori inseriti

    const currentInv = investorArr[0];
    console.log('currentInv=>', currentInv);
    const percInserita: number = parseFloat(investorArr[1]);

    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }

      // per ognuna delle liste sopra bisogna togliere l'investitore corrente e aggiornare il dato sul db
      listaInvestitoriPagamento.forEach((investitore, index) => {
        const invArr = investitore.split('---');
        if (invArr[0] == currentInv) {
          listaInvestitoriPagamento.splice(index, 1);
        }
      });

      listaInvestitori.forEach((investitore, index) => {
        const invArr = investitore.split('---');
        if (invArr[0] == currentInv) {
          listaInvestitori.splice(index, 1);
        }
      });

      listaInvestitoriDaPagare.forEach((investitore, index) => {
        const invArr = investitore.split('---');
        if (invArr[0] == currentInv) {
          listaInvestitoriDaPagare.splice(index, 1);
        }
      });

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;
      let investorPresent = false;
      let investorPercIfPresent = 0;

      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
      });

      // aggiorno fattura su db
      // creo l'oggetto per l'aggiornamento dello specifico campo
      const obj = {
        listaInvestitoriPagamento: listaInvestitoriPagamento,
        listaInvestitoriDaPagare: listaInvestitoriDaPagare,
        listaInvestitori: listaInvestitori
      };

      console.log('Oggetto=>', obj);
      this.firebase.updateEntityById(this.invoice.id, 'Invoices', obj);

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      percRimanente = percRimanente + percInserita;
      console.log('percRimanente Relativa =>', percRimanente);
      this.percentualeScoperta = percRimanente;
      this.recalculateCopertura();
    });
  }

  recalculateCopertura() {
    let listaInvestitori = [];
    let listaInvestitoriDaPagare = [];
    let listaInvestitoriPagamento = [];

    let currentInvoice: any;

    this.firebase.getInvoiceById(this.invoice.id).then(resp => {
      currentInvoice = resp.data();
      console.log('Invoice Cur=>', currentInvoice);
      if (currentInvoice.listaInvestitori) {
        listaInvestitori = currentInvoice.listaInvestitori;
      }
      if (currentInvoice.listaInvestitoriDaPagare) {
        listaInvestitoriDaPagare = currentInvoice.listaInvestitoriDaPagare;
      }
      if (currentInvoice.listaInvestitoriPagamento) {
        listaInvestitoriPagamento = currentInvoice.listaInvestitoriPagamento;
      }

      // calcolo la % rimanente di inserimenti
      // posso valutare il solo array di listaInvestitoriPagamento in quanto è quello che necessariamente dovrà esistere
      let percRimanente = 100;
      this.percentualeCoperta = 0;

      this.listaInvestitoriNonPaganti = [];
      this.listaInvestitoriPagato = [];
      listaInvestitoriPagamento.forEach((investirore, index) => {
        // splitto la stringa in array
        const arrInv = investirore.split('---');
        percRimanente = percRimanente - arrInv[1];
        this.percentualeCoperta = this.percentualeCoperta + parseFloat(arrInv[1]);
        if (arrInv[2] == 'F') {
          this.listaInvestitoriNonPaganti.push([arrInv[0], arrInv[1]]);
        }
        if (arrInv[2] == 'V') {
          this.listaInvestitoriPagato.push([arrInv[0], arrInv[1]]);
        }
      });

      this.listInvestorOfWalletNonPaganti = [];
      this.firebase.getUsersByRole(Utilities.userType[5]).subscribe(data => {
        data.forEach(investor => {
          if (this.verificaPagamenti(investor.investorPIva) == false && investor.investorPIva != '') {
            this.listInvestorOfWalletNonPaganti.push([investor.investorPIva, investor.email]);
          }
        });
        // console.log('listInvestorOfWalletNonPaganti=>',this.listInvestorOfWalletNonPaganti);
      });

      if (this.firebase.isInvestor()) {
        this.investitorePagato = this.verificaPagamenti(this.firebase.loggedUser.investorPIva);
        // console.log("l'investitore corrente " + this.firebase.loggedUser.investorPIva + '---' + this.investitorePagato);
      }
      if (this.firebase.isInvestor()) {
        this.listInvestitori.forEach(investor => {
          const inv = investor.split('---')[0];
          const per: number = +investor.split('---')[1];
          //console.log(inv);
          //console.log(per);
          const bool = investor.split('---')[2];
          if (inv == this.firebase.loggedUser.investorPIva) {
            this.investitoreCorrentePercentuale = per;
          }
        });
      }

      console.log('percRimanente Assoluta =>', percRimanente);
      this.percentualeScoperta = percRimanente;
    });
  }

  confirmPecInv() {
    const obj = {
      status_index: 59,
      status: Utilities.invoiceStatus_it_IT[59]
    };
    this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
      this.activeModal.dismiss();
    });
  }
  private sendMailToOriginator(typology: string, subject: string): void {
    this.firebase
      .getWalletfromID(this.invoice.walletId)
      .then(doc => {
        if (doc.exists) {
          const wallet = doc.data() as Wallet;
          this.firebase
            .getUserByID(wallet.originatorUId)
            .then(doc2 => {
              if (doc2.exists) {
                const walletName = doc.data().walletName;
                const email = doc2.data().email;
                const mailText = Utilities.getMailText(typology, walletName);
                this.mailerService.sendEmail(email, subject + walletName, mailText).subscribe(() => {});
                this.firebase.createEmailLog(email, typology);
              } else {
              }
            })
            .catch(error => {});
        } else {
        }
      })
      .catch(error => {});
  }
  private sendMailToFornitore(typology: string, subject: string, userId: string): void {
    this.firebase
      .getUserByID(userId)
      .then(doc2 => {
        if (doc2.exists) {
          const email = doc2.data().email;
          const mailText = Utilities.getMailText(typology, null);
          this.mailerService.sendEmail(email, subject, mailText).subscribe(() => {});
          this.firebase.createEmailLog(email, typology);
        } else {
        }
      })
      .catch(error => {});
  }
  private sendMailToInvoicer(typology: string, subject: string, userId: string): void {
    this.firebase
      .getUserByID(userId)
      .then(doc2 => {
        if (doc2.exists) {
          const email = doc2.data().email;
          const mailText = Utilities.getMailText(typology, null);
          this.mailerService.sendEmail(email, subject, mailText).subscribe(() => {});
          this.firebase.createEmailLog(email, typology);
        } else {
        }
      })
      .catch(error => {});
  }
  private sendMailToAdminAndServicer(typology: string, subject: string): void {
    const mailText = Utilities.getMailText(typology, null);
    let mailingList: string[] = [];
    this.firebase.returnEmailListByRole(Utilities.userType[1]).subscribe(data => {
      mailingList = Utilities.formatArray(data);
      if (mailingList.length > 0) {
        this.mailerService.multisendEmail(mailingList, subject, mailText).subscribe(() => {});
        this.firebase.createMultiEmailLog(mailingList, typology);
      }
    });
    this.firebase.returnEmailListByRole(Utilities.userType[6]).subscribe(data => {
      mailingList = Utilities.subtractArray(mailingList, Utilities.formatArray(data));
      if (mailingList.length > 0) {
        this.mailerService.multisendEmail(mailingList, subject, mailText).subscribe(() => {});
        this.firebase.createMultiEmailLog(mailingList, typology);
      }
    });
  }
  private sendEmailToInvestorSPV(email: string, item: InvestorSPV) {
    console.log('email to investor=>', item);
    const body =
      '{\n' +
      '    "to":"' +
      email +
      '",\n' +
      '    "subject":"Hai una nuova operazione",\n' +
      '    "senderCompany":"FX12 Srl",\n' +
      '    "from":"support@incassaora.it",\n' +
      '    "senderName":"Support of Incassaora",\n' +
      '    "templateName":"newOperation",\n' +
      '    "investorFirstName":"' +
      item.name +
      '",\n' +
      '    "investortLastName":"' +
      item.lastName +
      '",\n' +
      '    "importoFattura":"' +
      this.invoice.importoFattura +
      '",\n' +
      '    "scontoFattura":"' +
      this.invoice.scontoAccettato +
      '",\n' +
      '    "denominazioneCedente":"' +
      this.cedente.name +
      '",\n' +
      '    "denominiazioneDebitore":"' +
      this.debitore.name +
      '",\n' +
      '    "morescoreDebitore":"' +
      this.debitore.originatorMoreScore +
      '",\n' +
      '    "pdDebitore":"' +
      this.debitore.originatorDefault +
      '",\n' +
      '    "morescoreCedente":"' +
      this.cedente.assignorMoreScore +
      '",\n' +
      '    "pdCedente":"' +
      this.cedente.assignorDefault +
      '"\n' +
      '}';
    // console.log('body=>',body);
    this.sendMail.sendEmailTemplateByBody(body);
  }
  private sendEmailToServicerSPV(email: string, item: ServicerSPV) {
    console.log('email to servicer=>', item);
    const body =
      '{\n' +
      '    "to":"' +
      email +
      '",\n' +
      '    "subject":"Hai una nuova operazione",\n' +
      '    "senderCompany":"FX12 Srl",\n' +
      '    "from":"support@incassaora.it",\n' +
      '    "senderName":"Support of Incassaora",\n' +
      '    "templateName":"newOperation",\n' +
      '    "investorFirstName":"' +
      item.name +
      '",\n' +
      '    "investortLastName":"' +
      item.lastName +
      '",\n' +
      '    "importoFattura":"' +
      this.invoice.importoFattura +
      '",\n' +
      '    "scontoFattura":"' +
      this.invoice.scontoAccettato +
      '",\n' +
      '"denominazioneCedente":"' +
      this.cedente.name +
      '",\n' +
      '    "denominiazioneDebitore":"' +
      this.debitore.name +
      '",\n' +
      '    "morescoreDebitore":"' +
      this.debitore.originatorMoreScore +
      '",\n' +
      '    "pdDebitore":"' +
      this.debitore.originatorDefault +
      '",\n' +
      '    "morescoreCedente":"' +
      this.cedente.assignorMoreScore +
      '",\n' +
      '    "pdCedente":"' +
      this.cedente.assignorDefault +
      '"\n' +
      '}';
    this.sendMail.sendEmailTemplateByBody(body);
  }

  private uploadFileContabileBonificoSPVInvestor() {
    const filePath = this.invoice.id + '-CONTABILE-INVESTORSPV.pdf';
    const task = this.storage.upload(filePath, this.fileToUpload);
    // observe percentage changes
    task.percentageChanges().subscribe((value: number) => (this.uploadPercentage = value.toFixed(0)));
    this.uploading = true;
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.uploading = false;
          const today = new Date();
          const pipe = new DatePipe('en-US');
          this.invoice.dataCaricaContrattoSPV = pipe.transform(today, 'yyyy-MM-dd');
          const obj = {
            status_index: 56,
            dataCaricaContrattoSPVInvestor: this.invoice.dataCaricaContrattoSPV,
            status: Utilities.invoiceStatus_it_IT[56]
          };
          this.firebase.updateEntity(this.invoice.id, Invoice.tableName, obj).then(respEnt => {
            console.log('body => ', respEnt);
            // invio pec a investitore e cedente
            this.sendMail.sendEmailTemplateByBody(this.createBodyEmail('investor_spv'));
            this.sendMail.sendEmailTemplateByBody(this.createBodyEmail('company'));
            // debitore & servicer
            this.sendMail.sendEmailTemplateByBody(this.createBodyEmail('servicer_spv'));
            this.sendMail.sendEmailTemplateByBody(this.createBodyEmail('debitore'));
            this.activeModal.dismiss();
          });
        })
      )
      .subscribe(data => {});
  }
}

@Component({
  selector: 'ngbd-modal-confirm',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">Caricamento documento</h4>
      <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Sei sicuro di aver selezionato il file corretto? Se hai problemi con un file caricato contatta desk@fx12.it</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Indietro</button>
      <button type="button" class="btn btn-primary" (click)="modal.close('Ok click')">Carica file</button>
    </div>
  `
})
export class NgbdModalConfirmComponent {
  constructor(public modal: NgbActiveModal) {}
}

const MODALS: { [name: string]: Type<any> } = {
  focusFirst: NgbdModalConfirmComponent
};

function myRound(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
}

function myDataConversion(date) {
  try {
    date = date + 'T00:00:00';
    const newDate = new Date(date);
    const datePipe = new DatePipe('it-IT');
    const latest_date = datePipe.transform(newDate, 'dd/MM/yyyy');
    return latest_date;
  } catch (e) {
    console.warn('Errore=>', e);
    return date;
  }
}
