import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-partner',
  templateUrl: './home-partner.component.html',
  styleUrls: ['./home-partner.component.scss']
})
export class HomePartnerComponent implements OnInit {
  isLoading = false;
  constructor() {}

  ngOnInit() {}
}
