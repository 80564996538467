import { Component, Input, OnInit } from '@angular/core';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/auth';
import { Utilities } from '@app/utilities/Utilities';
import { templateJitUrl } from '@angular/compiler';

@Component({
  selector: 'app-confirm-roles-modal',
  templateUrl: './confirm-roles-modal.component.html',
  styleUrls: ['./confirm-roles-modal.component.scss']
})
export class ConfirmRolesModalComponent implements OnInit {
  @Input() userInput: any;
  showApproveButton = true;
  item1 = false; // investor
  item2 = false; // servicer
  item3 = false; // debitore
  item4 = false; // partner
  item5 = false; // company

  constructor(
    public firebase: FirebaseTranchedService,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public auth: AngularFireAuth
  ) {}

  ngOnInit() {
    for (const item of this.userInput.roles) {
      switch (item) {
        case Utilities.userType[2]: {
          this.item3 = true;
          break;
        }
        case Utilities.userType[3]: {
          this.item5 = true;
          break;
        }
        case Utilities.userType[4]: {
          this.item4 = true;
          break;
        }
        case Utilities.userType[5]: {
          this.item1 = true;
          break;
        }
        case Utilities.userType[6]: {
          this.item2 = true;
        }
      }
    }
  }

  canSubmit(): boolean {
    return this.item1 || this.item2 || this.item3 || this.item4 || this.item5;
  }

  onSubmit(): void {
    this.userInput.roles = this.createUserRoles();
    this.firebase.approveUser(this.userInput);
    this.activeModal.close();
  }

  private createUserRoles(): string[] {
    const roles: string[] = [];
    if (this.item1) {
      roles.push(Utilities.userType[5]);
    }
    if (this.item2) {
      roles.push(Utilities.userType[3]);
    }
    if (this.item3) {
      roles.push(Utilities.userType[2]);
    }
    if (this.item4) {
      roles.push(Utilities.userType[4]);
    }
    if (this.item5) {
      roles.push(Utilities.userType[1]);
    }

    return roles;
  }
}
