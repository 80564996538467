import { User } from './User.model';
export class Partner extends User {
  partnerOfficeRegisteredStreet: string; // sede legale
  partnerOfficeRegisteredCity: string;
  partnerOfficeRegisteredProvince: string;

  partnerPIva: string;
  partnerMoreScore: string;
  partnerDefault: string;
  lastTigranDate: string;

  partnerWebSite: string;
  partnerPec: string;

  constructor() {
    super();
  }
}
