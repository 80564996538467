import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  constructor(private router: Router) {}

  goHomepage() {
    this.router.navigate(['']);
  }

  ngOnInit() {}

  ngOnDestroy() {}
}
