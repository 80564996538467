import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  AcquireBeneficialOwnerResponse,
  AcquireBlackRecordsResponse,
  AcquireCompanyStructureResponse,
  AcquireInfoBrResponse,
  ClosingDateRecord,
  ClosingDateResponse,
  CreateAnalysisInsideDossierResponse,
  CreateDossierResponse,
  SearchForSubjectResponse,
  Subject
} from '@app/tigran/tigran';
import { environment } from '@env/environment';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';

@Injectable({
  providedIn: 'root'
})
export class TigranService {
  baseUrl = environment.laravelServer;

  userMail = environment.tigranUserMail;

  headers_object = new HttpHeaders({
    Authorization: 'Basic ' + btoa(`${environment.tigranUsername}:${environment.tigranPassword}`),
    secretFx12: environment.secretFx12_
    // secretFx12: 'k1gJZU0QYWVysIpzsRx3'
  });

  httpOptions = {
    headers: this.headers_object
  };

  constructor(private http: HttpClient, private firebaseService: FirebaseTranchedService) {}

  searchForSubject(field: string): Observable<SearchForSubjectResponse> {
    return this.http.get<SearchForSubjectResponse>(`${this.baseUrl}/search_f_s?piva=${field}`, this.httpOptions);
  }

  createSubject(field: string): Observable<Subject> {
    return this.http.post<Subject>(`${this.baseUrl}/api/v1/italian_companies`, { provider_id: field });
  }

  createDossierCreditoDiFiliera(subjectId: number, description: string): Observable<CreateDossierResponse> {
    return this.http.post<CreateDossierResponse>(`${this.baseUrl}/api/v1/subjects/${subjectId}/dossiers`, {
      dossier_type_name: 'Credito di Filiera',
      user_email: this.userMail,
      description
    });
  }

  // after this request i have to take CreateAnalysisInsideDossierResponseId
  // in order to proceed with step by step analysis
  createAnalisiCompletaInsideDossier(dossierId: number): Observable<CreateAnalysisInsideDossierResponse> {
    return this.http.post<CreateAnalysisInsideDossierResponse>(
      `${this.baseUrl}/api/v1/dossiers/${dossierId}/analyses`,
      { analysis_type_name: 'Analisi Completa', user_email: this.userMail }
    );
  }

  acquireCompanyStructure(analysisId: number): Observable<AcquireCompanyStructureResponse> {
    return this.http.post<AcquireCompanyStructureResponse>(
      `${this.baseUrl}/api/v1/analyses/${analysisId}/company_structure`,
      { user_email: this.userMail }
    );
  }

  acquireBeneficialOwner(analysisId: number): Observable<AcquireBeneficialOwnerResponse> {
    return this.http.post<AcquireBeneficialOwnerResponse>(
      `${this.baseUrl}/api/v1/analyses/${analysisId}/beneficial_owner`,
      { user_email: this.userMail }
    );
  }

  acquireInfoBr(analysisId: number): Observable<AcquireInfoBrResponse> {
    return this.http.post<AcquireInfoBrResponse>(`${this.baseUrl}/api/v1/analyses/${analysisId}/black_record_flags`, {
      user_email: this.userMail
    });
  }

  acquireBlackRecords(analysisId: number): Observable<AcquireBlackRecordsResponse> {
    return this.http.post<AcquireBlackRecordsResponse>(`${this.baseUrl}/api/v1/analyses/${analysisId}/black_record`, {
      user_email: this.userMail
    });
  }

  obtainingAvailableClosingDates(analysisId: number): Observable<ClosingDateResponse> {
    return this.http.get<ClosingDateResponse>(
      `${this.baseUrl}/api/v1/analyses/${analysisId}/closing_dates?info_provider=CAMERA_COMMERCIO`
    );
  }

  downloadFinancialData(analysisId: number, infoProvider: string, closingDates: ClosingDateRecord[]): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/v1/analyses/${analysisId}/steps/1/accounts`, {
      user_email: this.userMail,
      info_provider: infoProvider,
      closing_dates: closingDates
    });
  }

  // this is step 2
  step2MoreLight(analysisId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/v1/analyses/${analysisId}/steps/2`, { user_email: this.userMail });
  }

  step2Approve(analysisId: number): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/v1/analyses/${analysisId}/steps/2/approve`, {
      user_email: this.userMail
    });
  }

  // used to skip steps from 3 to 7 (7 included)
  stepSkip(analysisId: number, stepNumber: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/v1/analyses/${analysisId}/steps/${stepNumber}/skip`, {
      user_email: this.userMail
    });
  }

  approveStep8(analysisId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/v1/analyses/${analysisId}/steps/8/approve`, {
      user_email: this.userMail
    });
  }

  obtainingAnalysisJSON(analysisId: number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/api/v1/analyses/${analysisId}`);
  }

  entireFlow(field: string): Observable<SearchForSubjectResponse> {
    return this.http.get<SearchForSubjectResponse>(`${this.baseUrl}/flusso?piva=${field}`, this.httpOptions);
  }
}
