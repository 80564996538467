import { contropropostaMail } from './../models/utils/contropropostaMail';
import { Invoice } from './../models/Invoice.model';
import { ContractInvestor } from './../models/document/ContractInvestor.model';
import { ShowMessageService } from './show-message.service';
import { Assignor } from './../models/Assignor.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Wallet, WalletFromDb } from 'src/app/models/Wallet.model';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utilities } from '@app/utilities/Utilities';
import * as firebase from 'firebase/app';
import { environment } from '@env/environment';
import { FirebaseApp } from '@angular/fire';
import { User } from '@app/models/User.model';
import { Originator } from '@app/models/Originator.model';
import { Partner } from '@app/models/Partner.model';
import { Investor } from '@app/models/Investor.model';
import { DatePipe } from '@angular/common';
import { welcomeMail } from '@app/models/utils/welcomeMail';
import { UsuryRate } from '@app/models/UsuryRates.model';
import { Log } from '@app/models/Log.model';
import { LogUtils } from '@app/utilities/LogUtils';
import { Servicer } from '@app/models/Servicer.model';
import { LogTigran } from '@app/models/LogTigran.model';
import { IpServiceService } from '@app/ipService/ip-service.service';
import { LogSms } from '@app/models/LogSms';
import { DocumentoKey } from '@app/models/document/DocumentoKey.model';
import { PasswordLog } from '@app/models/PasswordModel';
import { ServicerSPV } from '@app/models/ServicerSPV.model';
import { InvestorSPV } from '@app/models/InvestorSPV.model';
import { DocumentKycSPVModel } from '@app/models/document/DocumentKycSPV.model';
import { BankSPV } from '@app/models/BankSPV.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseTranchedService {
  loggedUser: any;
  client_ip: string;
  private secondaryApp: FirebaseApp;
  private rate: number;

  constructor(
    private firestore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private datepipe: DatePipe,
    public showService: ShowMessageService,
    private ip: IpServiceService
  ) {
    if (localStorage.getItem('sessioneYellow')) {
      this.loggedUser = JSON.parse(localStorage.getItem('sessioneYellow'));
      if (!this.checkPasswordDate()) {
        this.afAuth.auth.sendPasswordResetEmail(this.loggedUser.email);
        this.showService.showPasswordReset = true;
        this.logout();
      } else {
        this.updateUser(this.loggedUser.id);
      }
    }
    this.getAttualUsuryRates().subscribe((data: any) => {
      this.rate = data['amount'];
    });

    this.checkAndSetIP();
  }

  /* ROLES CHECK */
  isAdmin() {
    return this.loggedUser.roles[0] === Utilities.userType[1];
  }

  isOriginator() {
    return this.loggedUser.roles[0] === Utilities.userType[2];
  }

  isCompany() {
    return this.loggedUser.roles[0] === Utilities.userType[3];
  }

  isPartner() {
    return this.loggedUser.roles[0] === Utilities.userType[4];
  }

  isInvestor() {
    return this.loggedUser.roles[0] === Utilities.userType[5];
  }

  isServicer() {
    return this.loggedUser.roles[0] === Utilities.userType[6];
  }

  isServicerSPV() {
    return this.loggedUser.roles[0] === Utilities.userType[7];
  }

  isInvestorSPV() {
    return this.loggedUser.roles[0] === Utilities.userType[8];
  }

  isBankSPV() {
    return this.loggedUser.roles[0] === Utilities.userType[9];
  }

  checkPasswordDate() {
    if (this.loggedUser.roles[0] === 'admin') {
      return true;
    }
    if (this.loggedUser.passwordDate === undefined) {
      return false;
    }
    const today: any = new Date();
    const passwordDate: any = new Date(this.loggedUser.passwordDate);
    return Math.floor((today - passwordDate) / (1000 * 60 * 60 * 24)) < 90;
  }

  logout() {
    return this.afAuth.auth
      .signOut()
      .then(() => {
        // Sign-out successful
        this.loggedUser = undefined;
        localStorage.removeItem('sessioneYellow');
      })
      .catch(error => {
        // An error happened.
      });
  }

  setPasswordReset(id: string) {
    return this.firestore
      .collection(User.tableName)
      .doc(id)
      .update({ passwordDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd') });
  }

  setPasswordNewHash(id: string, hash: string[]) {
    return this.firestore
      .collection(PasswordLog.tableName)
      .doc(id)
      .update({ hash: hash });
  }

  createPasswordNewHash(id: string, hash: string[]) {
    return this.firestore
      .collection(PasswordLog.tableName)
      .doc(id)
      .set({ id: id, hash: hash });
  }

  updateUser(id: string) {
    return this.getUserByID(id).then(doc => {
      if (doc.exists) {
        this.loggedUser = doc.data();
        this.loggedUser.id = id;
        localStorage.setItem('sessioneYellow', JSON.stringify(this.loggedUser));
      }
    });
  }

  createUser(mail: string, password: string) {
    if (firebase.apps.length == 1) {
      this.secondaryApp = firebase.initializeApp(environment.firebase, 'Secondary');
    }

    if (this.secondaryApp == undefined) {
      this.secondaryApp = firebase.apps[1];
    }

    return this.secondaryApp.auth().createUserWithEmailAndPassword(mail, password);
  }

  saveUserDocAfterCreate(uid: string, objectToSave: any) {
    delete objectToSave.password;
    this.secondaryApp.auth().signOut();
    return this.firestore
      .collection(User.tableName)
      .doc(uid)
      .set({ ...objectToSave });
  }

  updateUserDocAfterCreate(uid: string, objectToSave: any) {
    return this.firestore
      .collection(User.tableName)
      .doc(uid)
      .update({ ...objectToSave });
  }

  updateEntity(uid: string, collection: string, objectToSave: any) {
    return this.firestore
      .collection(collection)
      .doc(uid)
      .update({ ...objectToSave });
  }

  saveUserDocAfterCreateAndWelcome(uid: string, objectToSave: any) {
    delete objectToSave.password;
    this.secondaryApp
      .firestore()
      .collection('mail')
      .add({
        to: objectToSave.email,
        message: {
          subject: 'Benvenuto su Yellow Finance',
          html: welcomeMail.getHtmlMail(objectToSave.email)
        }
      })
      .then((data: any) => {
        this.secondaryApp.auth().signOut();
        return this.firestore
          .collection(User.tableName)
          .doc(uid)
          .set({ ...objectToSave });
      });
  }

  sendContropropostaMail(invoice: any, wallet: any) {
    this.getUserByID(invoice.uid).then(doc => {
      if (doc.exists) {
        const fornitore = doc.data();
        this.firestore.collection('mail').add({
          to: fornitore.email,
          message: {
            subject: "Hai ricevuto un'offerta su Yellow Finance",
            html: contropropostaMail.getHtmlMail(invoice, wallet)
          }
        });
      }
      // TODO esiste già la controproposta email col template bisogna solo inviarla e testare per capire che arriva
    });
  }

  // company create
  createAssignor(formaValues: FormGroup) {
    const assignor: Assignor = new Assignor();
    // inserire email
    assignor.email = formaValues.value.assignorUsername;
    assignor.name = formaValues.value.name;
    assignor.surname = formaValues.value.assignorName;
    assignor.telefono = formaValues.value.phone_number;
    assignor.phone_number = formaValues.value.phone_number;
    assignor.assignorType = formaValues.value.assignorType ? formaValues.value.assignorType : '';
    assignor.pIva = formaValues.value.pIva;
    assignor.legalName = formaValues.value.refName;
    assignor.assignorPec = formaValues.value.assignorPec;
    assignor.refName = formaValues.value.refName;
    if (formaValues.value.refName == '' || !formaValues.value.refName) {
      assignor.refName = formaValues.value.name;
    }
    assignor.refCogn = formaValues.value.cognomeReferenteAmm;
    assignor.denominazione = formaValues.value.name ? formaValues.value.name : formaValues.value.denominazione;
    assignor.refMail = formaValues.value.refMail;
    assignor.refPhone = formaValues.value.refPhone;
    assignor.isPrivato = formaValues.value.privato ? formaValues.value.privato : false;
    assignor.fiscalCode = formaValues.value.codiceFiscale ? formaValues.value.codiceFiscale : '';
    assignor.roles = [Utilities.userType[3]];
    assignor.password = formaValues.value.assignorPassword;
    assignor.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    assignor.originatorProducts = [true, false, false];
    assignor.originatorSolicitorBirthdate = formaValues.value.originatorSolicitorBirthdate
      ? formaValues.value.originatorSolicitorBirthdate
      : '';
    assignor.legalAddressStreet = formaValues.value.legalAddressStreet ? formaValues.value.legalAddressStreet : '';
    assignor.legalAddressCity = formaValues.value.legalAddressCity ? formaValues.value.legalAddressCity : '';
    assignor.legalAddressProvince = formaValues.value.legalAddressProvince
      ? formaValues.value.legalAddressProvince
      : '';
    assignor.legalAddressCAP = formaValues.value.legalAddressCAP ? formaValues.value.legalAddressCAP : '';
    assignor.REA_CODE = formaValues.value.REA_CODE ? formaValues.value.REA_CODE : '';

    assignor.stato = formaValues.value.nazione;
    assignor.viaLeg = formaValues.value.viaLeg;
    assignor.comuneLeg = formaValues.value.comuneLeg;
    assignor.provinciaLeg = formaValues.value.provinciaLeg;
    assignor.nazioneLeg = formaValues.value.nazioneLeg;
    assignor.capLegale = formaValues.value.capLegale;
    // @@ hydra
    assignor.surname = formaValues.value.cognomeReferenteAmm;
    assignor.nazione = formaValues.value.nazione;
    assignor.iban = formaValues.value.iban;
    assignor.legalAddressDataNascita = formaValues.value.legalAddressDataNascita;
    assignor.originatorSolicitorBirthplace = formaValues.value.indirizzo;

    return assignor;
  }

  createOriginator(formValues: FormGroup) {
    const other: Originator = new Originator();

    other.email = formValues.value.email;
    other.password = formValues.value.password;
    other.name = formValues.value.name;
    other.roles = ['originator'];
    other.originatorPIva = formValues.value.originatorPIva;
    other.originatorPec = formValues.value.originatorPec;
    other.originatorWebSite = formValues.value.originatorWebSite;
    other.originatorCreditsCheck = formValues.value.originatorCreditsCheck;
    other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    const products = [];
    products[0] = formValues.value.originatorProducts.originatorFintech;
    products[1] = formValues.value.originatorProducts.originatorSupply;
    products[2] = formValues.value.originatorProducts.originatorBlue;
    other.originatorProducts = products;

    other.originatorOfficeRegisteredStreet = formValues.value.originatorOfficeRegisteredStreet;
    other.originatorOfficeRegisteredCity = formValues.value.originatorOfficeRegisteredCity;
    other.originatorOfficeRegisteredProvince = formValues.value.originatorOfficeRegisteredProvince;

    other.originatorOfficeOperationalStreet = formValues.value.originatorOfficeOperationalStreet;
    other.originatorOfficeOperationalCity = formValues.value.originatorOfficeOperationalCity;
    other.originatorOfficeOperationalProvince = formValues.value.originatorOfficeOperationalProvince;

    other.originatorAdminReferentSurname = formValues.value.originatorAdminReferentSurname;
    other.originatorAdminReferentName = formValues.value.originatorAdminReferentName;
    other.originatorAdminReferentTelephone = formValues.value.originatorAdminReferentTelephone;
    other.originatorAdminReferentEmail = formValues.value.originatorAdminReferentEmail;

    other.originatorSolicitorSurname = formValues.value.originatorSolicitorSurname;
    other.originatorSolicitorName = formValues.value.originatorSolicitorName;
    other.originatorSolicitorBirthplace = formValues.value.originatorSolicitorBirthplace;
    other.originatorSolicitorBirthdate = formValues.value.originatorSolicitorBirthdate;
    other.telefono = formValues.value.tel;
    other.indirizzo = formValues.value.indirizzo;
    if (this.isPartner()) {
      other.partnerName = this.loggedUser.name;
      other.partnerUId = this.loggedUser.id;
    }

    other.REA_CODE = formValues.value.REA_CODE;
    other.stato = formValues.value.nazione;
    other.viaLeg = formValues.value.viaLeg;
    other.comuneLeg = formValues.value.comuneLeg;
    other.provinciaLeg = formValues.value.provinciaLeg;
    other.nazioneLeg = formValues.value.nazioneLeg;
    other.legalAddressCAP = formValues.value.legalAddressCAP;
    other.capLegale = formValues.value.capLegale;

    return other;
  }

  createInvestor(formValues: FormGroup) {
    const other: Investor = new Investor();
    other.email = formValues.value.email;
    other.password = formValues.value.password;
    other.name = formValues.value.name;
    other.roles = ['investor'];
    other.investorPIva = formValues.value.investorPIva;
    other.investorPec = formValues.value.investorPec;
    other.investorWebSite = formValues.value.investorWebSite;
    other.originatorProducts = [true, true, true];
    other.investorOfficeRegisteredStreet = formValues.value.investorOfficeRegisteredStreet;
    other.investorOfficeRegisteredCity = formValues.value.investorOfficeRegisteredCity;
    other.investorOfficeRegisteredProvince = formValues.value.investorOfficeRegisteredProvince;
    other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    other.telefono = formValues.value.phone_number;
    other.indirizzo = formValues.value.indirizzo;
    other.isPrivato = formValues.value.privato;
    other.fiscalCode = formValues.value.codiceFiscale;
    other.originatorSolicitorSurname = formValues.value.originatorSolicitorSurname;
    other.originatorSolicitorName = formValues.value.originatorSolicitorName;
    other.originatorSolicitorBirthplace = formValues.value.originatorSolicitorBirthplace;
    other.originatorSolicitorBirthdate = formValues.value.originatorSolicitorBirthdate;
    other.stato = formValues.value.nazione;
    other.REA_CODE = formValues.value.REA_CODE;
    other.viaLeg = formValues.value.viaLeg;
    other.comuneLeg = formValues.value.comuneLeg;
    other.provinciaLeg = formValues.value.provinciaLeg;
    other.nazioneLeg = formValues.value.nazioneLeg;
    other.legalAddressCAP = formValues.value.legalAddressCAP;
    other.capLegale = formValues.value.capLegale;
    return other;
  }

  createGenericUser(formValues: FormGroup) {
    let other;
    if (formValues.value.type == 'investitore') {
      other = new Investor();

      other.email = formValues.value.email;
      other.password = formValues.value.password;
      other.name = formValues.value.denominazione;
      other.roles = ['investor'];
      other.investorPIva = formValues.value.partitaIVA;
      other.investorPec = formValues.value.pec;
      other.investorWebSite = formValues.value.sitoWeb;
      other.originatorProducts = [true, true, true];
      other.investorOfficeRegisteredStreet = formValues.value.via;
      other.investorOfficeRegisteredCity = formValues.value.comune;
      other.investorOfficeRegisteredProvince = formValues.value.provincia;
      other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      other.telefono = formValues.value.tel;
      other.phone_number = formValues.value.tel;
      other.isPrivato = formValues.value.privato;
      other.fiscalCode = formValues.value.codiceFiscale;
      other.indirizzo = formValues.value.indirizzo;
      other.originatorSolicitorSurname = formValues.value.cognomeLegaleR;
      other.originatorSolicitorName = formValues.value.nomeLegaleR;
      other.originatorSolicitorBirthplace = formValues.value.nascitaLegaleR;
      other.originatorSolicitorBirthdate = formValues.value.dataLegaleR;
      other.stato = formValues.value.nazione;
      other.REA_CODE = formValues.value.REA_CODE;
      other.viaLeg = formValues.value.viaLeg;
      other.comuneLeg = formValues.value.comuneLeg;
      other.provinciaLeg = formValues.value.provinciaLeg;
      other.nazioneLeg = formValues.value.nazioneLeg;
      other.legalAddressCAP = formValues.value.legalAddressCAP;
      other.capLegale = formValues.value.capLegale;
      other.nazione = formValues.value.nazione;
      return other;
    }
    if (formValues.value.type == 'servicer') {
      other = new Servicer();

      other.email = formValues.value.email;
      other.password = formValues.value.password;
      other.name = formValues.value.servicerNome;
      other.lastName = formValues.value.servicerCognome;
      other.roles = ['servicer'];
      other.originatorProducts = [true, true, true];
      other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      other.telefono = formValues.value.tel;
      other.indirizzo = formValues.value.indirizzo;
      other.dataAssunzione = formValues.value.data_assunzione;
      other.dataTermineContratto = formValues.value.data_contratto;
      return other;
    }
    if (formValues.value.type == 'debitore') {
      other = new Originator();

      other.email = formValues.value.email;
      other.password = formValues.value.password;
      other.name = formValues.value.denominazione;
      other.roles = ['originator'];
      other.originatorPIva = formValues.value.partitaIVA;
      other.originatorPec = formValues.value.pec;
      other.originatorWebSite = formValues.value.sitoWeb;
      // other.originatorCreditsCheck = formValues.value.originatorCreditsCheck;
      other.originatorCreditsCheck = true;
      other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      other.telefono = formValues.value.tel;
      other.indirizzo = formValues.value.indirizzo;
      /*
      products[0] = formValues.value.originatorProducts.originatorFintech;
      products[1] = formValues.value.originatorProducts.originatorSupply;
      products[2] = formValues.value.originatorProducts.originatorBlue;
      */
      other.originatorProducts = [true, true, true];
      other.originatorOfficeRegisteredStreet = formValues.value.via;
      other.originatorOfficeRegisteredCity = formValues.value.comune;
      other.originatorOfficeRegisteredProvince = formValues.value.provincia;

      other.originatorOfficeOperationalStreet = formValues.value.viaSedeO;
      other.originatorOfficeOperationalCity = formValues.value.comuneSedeO;
      other.originatorOfficeOperationalProvince = formValues.value.provinciaSedeO;

      other.originatorAdminReferentSurname = formValues.value.cognomeReferenteAmm;
      other.originatorAdminReferentName = formValues.value.nomeReferenteAmm;
      other.originatorAdminReferentTelephone = formValues.value.telReferenteAmm;
      other.originatorAdminReferentEmail = formValues.value.mailReferenteAmm;

      other.originatorSolicitorSurname = formValues.value.cognomeLegaleR;
      other.originatorSolicitorName = formValues.value.nomeLegaleR;
      other.originatorSolicitorBirthplace = formValues.value.nascitaLegaleR;
      other.originatorSolicitorBirthdate = formValues.value.dataLegaleR;
      other.REA_CODE = formValues.value.REA_CODE;
      other.stato = formValues.value.nazione;
      other.viaLeg = formValues.value.viaLeg;
      other.comuneLeg = formValues.value.comuneLeg;
      other.provinciaLeg = formValues.value.provinciaLeg;
      other.nazioneLeg = formValues.value.nazioneLeg;
      other.legalAddressCAP = formValues.value.legalAddressCAP;
      other.capLegale = formValues.value.capLegale;
      return other;
    }
    if (formValues.value.type == 'partner') {
      other = new Partner();

      other.email = formValues.value.email;
      other.password = formValues.value.password;
      other.name = formValues.value.denominazione;
      other.roles = ['partner'];
      other.partnerPIva = formValues.value.partitaIVA;
      other.partnerPec = formValues.value.pec;
      other.partnerWebSite = formValues.value.sitoWeb;
      other.originatorProducts = [true, true, true];
      other.partnerOfficeRegisteredStreet = formValues.value.via;
      other.partnerOfficeRegisteredCity = formValues.value.comune;
      other.partnerOfficeRegisteredProvince = formValues.value.provincia;
      other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      other.telefono = formValues.value.tel;
      other.indirizzo = formValues.value.indirizzo;
      return other;
    }
    if (formValues.value.type == 'company') {
      other = new Assignor();
      // inserire email
      other.email = formValues.value.email;
      other.surname = formValues.value.servicerCognome;
      other.name = formValues.value.denominazione;
      other.assignorType = formValues.value.type;
      other.pIva = formValues.value.partitaIVA;
      other.legalName = formValues.value.nomeReferenteAmm;
      other.assignorPec = formValues.value.pec;
      other.denominazione = formValues.value.denominazione;
      other.refName = formValues.value.nomeReferenteAmm;
      other.refCogn = formValues.value.cognomeReferenteAmm;
      other.refMail = formValues.value.mailReferenteAmm;
      other.refPhone = formValues.value.telReferenteAmm;
      other.isPrivato = formValues.value.privato;
      other.fiscalCode = formValues.value.codiceFiscale;
      other.originatorSolicitorBirthdate = formValues.value.originatorSolicitorBirthdate;
      other.roles = [Utilities.userType[3]];
      other.password = formValues.value.password;
      other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
      other.telefono = formValues.value.tel;
      other.phone_number = formValues.value.tel;
      other.indirizzo = formValues.value.indirizzo;
      other.originatorProducts = [true, false, false];
      other.legalAddressStreet = formValues.value.legalAddressStreet;
      other.legalAddressCity = formValues.value.legalAddressCity;
      other.legalAddressProvince = formValues.value.legalAddressProvince;
      other.legalAddressCAP = formValues.value.legalAddressCAP;
      other.REA_CODE = formValues.value.REA_CODE;
      other.stato = formValues.value.nazione;
      other.viaLeg = formValues.value.viaLeg;
      other.comuneLeg = formValues.value.comuneLeg;
      other.provinciaLeg = formValues.value.provinciaLeg;
      other.nazioneLeg = formValues.value.nazioneLeg;
      other.capLegale = formValues.value.capLegale;
      other.legalAddressDataNascita = formValues.value.legalAddressDataNascita;
      other.iban = formValues.value.iban;
      other.originatorSolicitorBirthplace = formValues.value.indirizzo;
      return other;
    }
  }

  createAccessLog() {
    this.saveLog(LogUtils.accessLogMessage, LogUtils.accessLogType);
  }

  createOperationLog() {
    this.saveLog(LogUtils.operationLogMessage, LogUtils.operationLogType);
  }

  createMultiEmailLog(users: string[], typology: string) {
    for (const email of users) {
      this.saveLog(LogUtils.getEmailLogMessage(email, typology), LogUtils.emailLogType);
    }
  }

  createEmailLog(email: string, typology: string) {
    this.saveLog(LogUtils.getEmailLogMessage(email, typology), LogUtils.emailLogType);
  }

  createSmsLog(otp: string, id: string) {
    this.saveLogSMS(LogUtils.smsLogMessage, LogUtils.smsLogType, otp, id);
  }

  createPageLog(des: string) {
    this.saveLog(des, LogUtils.pageVisitedLogType);
  }

  saveLog(des: string, type: string) {
    const log: Log = new Log();
    log.description = des;
    log.ip = this.client_ip;
    log.userId = this.loggedUser.id;
    log.date = new Date().toUTCString();
    log.type = type;
    log.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.firestore.collection(Log.tableName).add({ ...log });
  }

  saveLogSMS(des: string, type: string, otp: string, id: string) {
    const log: LogSms = new LogSms();
    log.description = des;
    log.ip = this.client_ip;
    log.userId = this.loggedUser.id;
    log.date = new Date().toUTCString();
    log.type = type;
    log.otp = otp;
    log.invoice = id;
    log.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.firestore.collection(Log.tableName).add({ ...log });
  }

  // tslint:disable-next-line:max-line-length
  createLogTigran(
    morescore: string,
    probdef: string,
    currentVatNumber: string,
    companyName: string,
    ret: string,
    userRet: string
  ) {
    this.saveLogTigran(
      LogUtils.tigranLogMessage,
      LogUtils.tigranLogType,
      ret,
      morescore,
      probdef,
      currentVatNumber,
      companyName,
      userRet
    );
  }

  saveLogTigran(
    des: string,
    type: string,
    ret: string,
    morescore: string,
    probdef: string,
    vatNumber: string,
    companyName: string,
    userRet: string
  ) {
    const log: LogTigran = new LogTigran();
    log.description = des;
    log.userId = userRet;
    log.date = new Date().toUTCString();
    log.type = type;
    log.return = ret;
    log.pIva = vatNumber;
    log.moreScore = morescore;
    log.probDefault = probdef;
    log.companyName = companyName;
    log.timestamp = firebase.firestore.FieldValue.serverTimestamp();
    return this.firestore.collection(LogTigran.tableName).add({ ...log });
  }

  createPartner(formValues: FormGroup) {
    const other: Partner = new Partner();

    other.email = formValues.value.email;
    other.password = formValues.value.password;
    other.name = formValues.value.name;
    other.roles = ['partner'];
    other.partnerPIva = formValues.value.partnerPIva;
    other.partnerPec = formValues.value.partnerPec;
    other.partnerWebSite = formValues.value.partnerWebSite;
    other.originatorProducts = [true, true, true];
    other.partnerOfficeRegisteredStreet = formValues.value.partnerOfficeRegisteredStreet;
    other.partnerOfficeRegisteredCity = formValues.value.partnerOfficeRegisteredCity;
    other.partnerOfficeRegisteredProvince = formValues.value.partnerOfficeRegisteredProvince;
    other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    other.telefono = formValues.value.tel;
    other.indirizzo = formValues.value.indirizzo;
    return other;
  }

  createServicer(formValues: FormGroup) {
    const other: Servicer = new Servicer();
    other.email = formValues.value.email;
    other.password = formValues.value.password;
    other.name = formValues.value.firstName;
    other.lastName = formValues.value.lastName;
    other.telefono = formValues.value.tel;
    other.indirizzo = formValues.value.indirizzo;
    other.dataAssunzione = formValues.value.data_assunzione;
    other.dataTermineContratto = formValues.value.data_contratto;
    other.roles = ['servicer'];
    other.originatorProducts = [true, true, true];
    other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    return other;
  }

  createServicerSPV(formValues: FormGroup) {
    const other: ServicerSPV = new ServicerSPV();

    other.email = formValues.value.email;
    other.password = formValues.value.password;
    other.name = formValues.value.firstName;
    other.lastName = formValues.value.lastName;
    other.telefono = formValues.value.tel;
    other.data_nascita = formValues.value.data_nascita;
    other.data_assunzione = formValues.value.data_assunzione;
    other.data_contratto = formValues.value.data_contratto;
    other.fiscal_code = formValues.value.fiscal_code;

    other.ragioneSociale = formValues.value.ragioneSociale;
    other.indirizzo = formValues.value.indirizzo;
    other.cap = formValues.value.cap;
    other.city = formValues.value.city;
    other.province = formValues.value.province;
    other.country = formValues.value.country;

    other.pec = formValues.value.pec;
    other.abi = formValues.value.abi;
    other.rea = formValues.value.rea;
    other.piva = formValues.value.piva;

    other.roles = ['servicerSPV'];
    other.originatorProducts = [true, true, true];
    other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    return other;
  }

  updateServicerSPV(formValues: FormGroup) {
    const other: ServicerSPV = new ServicerSPV();
    other.name = formValues.value.firstName;
    other.lastName = formValues.value.lastName;
    other.fiscal_code = formValues.value.fiscal_code;
    other.telefono = formValues.value.telefono;
    other.data_nascita = formValues.value.data_nascita;
    other.data_assunzione = formValues.value.data_assunzione;
    other.data_contratto = formValues.value.data_contratto;

    other.ragioneSociale = formValues.value.ragioneSociale;
    other.indirizzo = formValues.value.indirizzo;
    other.cap = formValues.value.cap;
    other.city = formValues.value.city;
    other.province = formValues.value.province;
    other.country = formValues.value.country;

    other.pec = formValues.value.pec;
    other.abi = formValues.value.abi;
    other.rea = formValues.value.rea;
    other.piva = formValues.value.piva;

    return other;
  }

  createInvestorSPV(formValues: FormGroup, selectedServicesSPV) {
    const other: InvestorSPV = new InvestorSPV();
    other.email = formValues.value.email;
    other.dataContrFinanzmnt = formValues.value.dataContrFinanzmnt;
    other.pec = formValues.value.pec;
    other.iban = formValues.value.iban;
    other.password = formValues.value.password;
    other.name = formValues.value.firstName;
    other.lastName = formValues.value.lastName;
    other.ragioneSociale = formValues.value.ragioneSociale;
    other.telefono = formValues.value.telefono;
    other.indirizzo = formValues.value.indirizzo;
    other.city = formValues.value.city;
    other.province = formValues.value.province;
    other.cap = formValues.value.cap;
    other.piva = formValues.value.piva;
    other.rea = formValues.value.rea;
    other.dataAssunzione = formValues.value.data_assunzione;
    other.dataTermineContratto = formValues.value.data_contratto;
    other.roles = ['investorSPV'];
    other.servicersSPV = selectedServicesSPV;
    other.originatorProducts = [true, true, true];
    other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    return other;
  }
  updateInvestorSPV(formValues: FormGroup, selectedServicesSPV) {
    const other: InvestorSPV = new InvestorSPV();
    other.pec = formValues.value.pec;
    other.iban = formValues.value.iban;
    other.name = formValues.value.firstName;
    other.lastName = formValues.value.lastName;
    other.ragioneSociale = formValues.value.ragioneSociale;
    other.telefono = formValues.value.telefono;
    other.indirizzo = formValues.value.indirizzo;
    other.city = formValues.value.city;
    other.province = formValues.value.province;
    other.cap = formValues.value.cap;
    other.piva = formValues.value.piva;
    other.rea = formValues.value.rea;
    other.dataAssunzione = formValues.value.dataAssunzione;
    other.dataTermineContratto = formValues.value.dataTermineContratto;
    other.dataContrFinanzmnt = formValues.value.dataContrFinanzmnt;
    other.servicersSPV = selectedServicesSPV;
    return other;
  }

  createBankSPV(formValues: FormGroup, selectedInvestorsSPV) {
    const other: BankSPV = new BankSPV();
    other.email = formValues.value.email;
    other.pec = formValues.value.pec;
    other.password = formValues.value.password;
    other.name = formValues.value.firstName;
    other.lastName = formValues.value.lastName;
    other.ragioneSociale = formValues.value.ragioneSociale;
    other.telefono = formValues.value.telefono;
    other.indirizzo = formValues.value.indirizzo;
    other.city = formValues.value.city;
    other.state = formValues.value.state;
    other.cap = formValues.value.cap;
    other.roles = ['bankSPV'];
    other.investorsSPV = selectedInvestorsSPV;
    other.originatorProducts = [true, true, true];
    other.passwordDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    return other;
  }

  updateBankSPV(formValues: FormGroup, selectedInvestorsSPV: any) {
    const other: BankSPV = new BankSPV();
    other.pec = formValues.value.pec;
    other.name = formValues.value.firstName;
    other.lastName = formValues.value.lastName;
    other.ragioneSociale = formValues.value.ragioneSociale;
    other.telefono = formValues.value.telefono;
    other.indirizzo = formValues.value.indirizzo;
    other.city = formValues.value.city;
    other.state = formValues.value.state;
    other.cap = formValues.value.cap;
    other.investorsSPV = selectedInvestorsSPV;
    return other;
  }

  createWallet(formValues: FormGroup) {
    const myWallet: Wallet = new Wallet();
    // console.log(formValues.value.logo);

    myWallet.logo = formValues.value.logo;
    // console.log(myWallet.logo);
    myWallet.walletName = formValues.value.walletName;
    myWallet.region = formValues.value.region;
    myWallet.sector = formValues.value.sector;
    myWallet.openingDate = formValues.value.openingDate;
    myWallet.endingDate = formValues.value.endingDate;
    myWallet.minInvoice = formValues.value.minInvoice;
    myWallet.maxInvoice = formValues.value.maxInvoice;

    myWallet.plafondYellow = formValues.value.plafondYellow;
    myWallet.plafondBlue = formValues.value.plafondBlue;
    myWallet.plafondAzure = formValues.value.plafondAzure;

    myWallet.enabledYellow = formValues.value.enabledYellow;
    myWallet.enabledBlue = formValues.value.enabledBlue;
    myWallet.enabledAzure = formValues.value.enabledAzure;

    myWallet.priorityYellow = formValues.value.priorityYellow;
    myWallet.priorityBlue = formValues.value.priorityBlue;
    myWallet.priorityAzure = formValues.value.priorityAzure;

    myWallet.paymentDays = formValues.value.paymentDays;
    myWallet.originatorUId = formValues.value.originatorName.split(';', 2)[0];
    myWallet.originatorName = formValues.value.originatorName.split(';', 2)[1];

    myWallet.productsNode = Utilities.createProductsNode(myWallet).map(obj => {
      return Object.assign({}, obj);
    });

    myWallet.description = formValues.value.description;
    myWallet.phaseId = 0;
    myWallet.ratingSystemLabel = [];
    myWallet.ratingSystemPercentage = [];

    const walletToBeSaved: WalletFromDb = new WalletFromDb();

    const newObj = {};
    Object.keys(walletToBeSaved).forEach(key => (newObj[key] = (key in myWallet ? myWallet : walletToBeSaved)[key]));

    return this.firestore.collection(Wallet.tableName).add({ ...newObj });
  }

  createInvoice(formValues: FormGroup, walletId: string, walletName: string) {
    const invoice: Invoice = new Invoice();
    invoice.uid = this.loggedUser.id;
    invoice.cedenteName = this.loggedUser.name;
    invoice.numFattura = formValues.value.numFattura;
    invoice.dataFattura = formValues.value.dataFattura;
    invoice.dataScadenza = formValues.value.dataScadenza;
    invoice.importoFattura = parseFloat(formValues.value.importoFattura);
    invoice.scontoProposto = parseFloat(formValues.value.scontoProposto);
    invoice.status = Utilities.invoiceStatus[11];
    invoice.walletId = walletId;
    invoice.walletName = walletName;
    // invoice.usuryRespected = formValues.value.rate;
    // @hydra 2022-01-14 -- @author - Gaetano Bitonte - https://hydrasolutions.atlassian.net/browse/FXINPROD-87
    invoice.status_index = 0;
    return this.firestore.collection(Invoice.tableName).add({ ...invoice });
  }

  createUsuryRate(formValues: FormGroup, arr: string[]) {
    const usuryRate = new UsuryRate();
    usuryRate.startDate = new Date(formValues.value.startDate);
    usuryRate.endDate = new Date(formValues.value.endDate);
    usuryRate.country = formValues.value.stato;
    usuryRate.info = arr;
    return this.firestore.collection(UsuryRate.tableName).add({ ...usuryRate });
  }

  createContractInvestor(contract: ContractInvestor) {
    return this.firestore.collection(ContractInvestor.tableName).add({ ...contract });
  }

  createDocumentiKey(contract: DocumentoKey) {
    return this.firestore.collection(DocumentoKey.tableName).add({ ...contract });
  }

  createDocumentKycSPV(doc: DocumentKycSPVModel) {
    return this.firestore.collection(DocumentKycSPVModel.tableName).add({ ...doc });
  }

  updateContractInvestor(contractInvestor: ContractInvestor) {
    return this.firestore
      .collection(ContractInvestor.tableName)
      .doc(contractInvestor.id)
      .update({ ...contractInvestor });
  }

  updateDocumentiKey(contractInvestor: DocumentoKey) {
    return this.firestore
      .collection(DocumentoKey.tableName)
      .doc(contractInvestor.id)
      .update({ ...contractInvestor });
  }

  getContractsByIdInvestor(investorUUID: string) {
    let contractList: Observable<any[]>;
    contractList = this.firestore
      .collection<ContractInvestor>(ContractInvestor.tableName, ref =>
        ref.where('investorUUID', '==', investorUUID).where('url', '!=', '')
      )
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as ContractInvestor;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return contractList;
  }

  getDocument(investorUUID: string) {
    let contractList: Observable<any[]>;
    contractList = this.firestore
      .collection<DocumentoKey>(DocumentoKey.tableName, ref =>
        ref.where('investorUUID', '==', investorUUID).where('url', '!=', '')
      )
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as DocumentoKey;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return contractList;
  }

  modInvoice(invoice: Invoice) {
    return this.firestore
      .collection(Invoice.tableName)
      .doc(invoice.id)
      .update({ ...invoice });
  }

  modWallet(wallet: Wallet) {
    delete wallet.daysLeft;
    this.firestore
      .collection(Wallet.tableName)
      .doc(wallet.id)
      .update({ ...wallet });
  }

  modInvestor(user: any) {
    this.firestore
      .collection(User.tableName)
      .doc(user.id)
      .update({ ...user });
  }

  chiudiFattura(walletId: string, importo: number, percentualeFinale: number) {
    this.firestore
      .collection(Wallet.tableName)
      .doc(walletId)
      .update({
        counterImportYellow: firebase.firestore.FieldValue.increment(importo - (importo * percentualeFinale) / 100)
      });
  }

  modUser(user: any) {
    localStorage.setItem('sessioneYellow', JSON.stringify(this.loggedUser));
    const id = user.id;
    delete user.id;

    this.firestore
      .collection(User.tableName)
      .doc(id)
      .update({ ...user });

    user.id = id;
  }

  modUserById(user: any, id: string) {
    delete user.id;

    this.firestore
      .collection(User.tableName)
      .doc(id)
      .update({ ...user });

    user.id = id;
  }

  approveUser(user: any) {
    user.verified = true;
    this.firestore
      .collection(User.tableName)
      .doc(user.id)
      .update({ ...user });
  }

  disattivaUser(user: any) {
    user.stato = 'DISATTIVATO';
    this.firestore
      .collection(User.tableName)
      .doc(user.id)
      .update({ ...user });
  }

  attivaUser(user: any) {
    user.stato = 'ATTIVO';
    this.firestore
      .collection(User.tableName)
      .doc(user.id)
      .update({ ...user });
  }

  getOriginatorsForPartner() {
    let originatorCollection: AngularFirestoreCollection<User>;
    let originators: Observable<any[]>;
    originatorCollection = this.firestore.collection(User.tableName, ref =>
      ref.where('partnerUId', '==', this.loggedUser.id)
    );
    originators = originatorCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as User;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return originators;
  }

  getWallets() {
    let walletCollection: AngularFirestoreCollection<Wallet>;
    let wallets: Observable<any[]>;
    walletCollection = this.firestore.collection<Wallet>(Wallet.tableName);
    // .snapshotChanges() returns a DocumentChangeAction[], which contains
    // a lot of information about "what happened" with each change. If you want to
    // get the data and the id use the map operator.
    wallets = walletCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Wallet;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return wallets;
  }

  getWalletsByIdOriginator(id: string) {
    let walletCollection: AngularFirestoreCollection<Wallet>;
    let wallets: Observable<any[]>;
    walletCollection = this.firestore.collection<Wallet>(Wallet.tableName, ref => ref.where('originatorUId', '==', id));

    // .snapshotChanges() returns a DocumentChangeAction[], which contains
    // a lot of information about "what happened" with each change. If you want to
    // get the data and the id use the map operator.
    wallets = walletCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Wallet;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    return wallets;
  }

  getInvoiceById(id: string) {
    return this.firestore
      .collection<Invoice>(Invoice.tableName)
      .doc(id)
      .ref.get();
  }

  updateEntityById(id: string, tableName: string, obj: any) {
    return this.firestore
      .collection(tableName)
      .doc(id)
      .update(obj);
  }

  getInvoicesByID(id: string) {
    let invoices: Observable<any[]>;
    invoices = this.firestore
      .collection(Invoice.tableName, ref => ref.where('walletId', '==', id))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return invoices;
  }

  getInvoiceByID(id: string) {
    let invoices: Observable<any[]>;
    invoices = this.firestore
      .collection(Invoice.tableName, ref => ref.where('id', '==', id))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return invoices;
  }

  getInvoicesByIDUser(id: string) {
    let invoices: Observable<any[]>;
    invoices = this.firestore
      .collection(Invoice.tableName, ref => ref.where('uid', '==', id))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return invoices;
  }

  getAllInvoices() {
    let invoices: Observable<any[]>;
    invoices = this.firestore
      .collection(Invoice.tableName)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return invoices;
  }

  getInvoicesNotApproved() {
    //TODO QUI
    //inserire qua le richieste che devono essere aggiunte quindi poi da qui
    //permette di aggiungere l'operazione in uno stato di panding
    let invoices: Observable<any[]>;
    invoices = this.firestore
      //.collection(Invoice.tableName, ref => ref.where('status_index', 'in', [0, 5, 11, 14, 31]))
      .collection(Invoice.tableName, ref => ref.where('status_index', 'in', [0, 1, 5, 11, 14, 31, 32, 50, 59]))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return invoices;
  }

  getInvoicesNotApprovedInvestor() {
    //TODO QUI
    //inserire qua le richieste che devono essere aggiunte quindi poi da qui
    //permette di aggiungere l'operazione in uno stato di panding
    let invoices: Observable<any[]>;
    invoices = this.firestore
      .collection(Invoice.tableName, ref => ref.where('status_index', 'in', [33, 35]))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return invoices;
  }

  getInvoicesNotApprovedFornitore() {
    //TODO QUI
    //inserire qua le richieste che devono essere aggiunte quindi poi da qui
    //permette di aggiungere l'operazione in uno stato di panding
    let invoices: Observable<any[]>;
    invoices = this.firestore
      //.collection(Invoice.tableName, ref => ref.where('status_index', 'in', [10, 30]))
      .collection(Invoice.tableName, ref => ref.where('status_index', 'in', [10, 30, 1, 2, 8, 15, 53, 57]))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return invoices;
  }

  approveDocs(obj: any) {
    if (obj.status == Utilities.invoiceStatus[4]) {
      obj.status = Utilities.invoiceStatus[5];
    }
    if (obj.status == Utilities.invoiceStatus[7]) {
      obj.status = Utilities.invoiceStatus[8];
    }
    if (obj.status == Utilities.invoiceStatus[11]) {
      obj.status = Utilities.invoiceStatus[1];
    }
    this.firestore
      .collection(Invoice.tableName)
      .doc(obj.id)
      .update({ ...obj });
  }

  getWalletfromID(id: string) {
    return this.firestore
      .collection<Wallet[]>(Wallet.tableName)
      .doc(id)
      .ref.get();
  }

  getUsers() {
    let usersList: Observable<any[]>;
    usersList = this.firestore
      .collection<User>(User.tableName)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return usersList;
  }

  getUserByID(id: string) {
    return this.firestore
      .collection<User[]>(User.tableName)
      .doc(id)
      .ref.get();
  }

  getInvestorByPIva(pIva: string) {
    let usersList: Observable<any[]>;
    usersList = this.firestore
      .collection<User>(User.tableName, ref => ref.where('investorPIva', '==', pIva))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return usersList;
  }

  getUserPasswordLogById(id: string) {
    return this.firestore
      .collection<PasswordLog[]>(PasswordLog.tableName)
      .doc(id)
      .ref.get();
  }

  getUserByRoleAndStringField(role: string, field: string, value: any) {
    let usersList: Observable<any[]>;
    usersList = this.firestore
      .collection<User>(User.tableName, ref => ref.where(field, '==', value))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return usersList;
  }

  getUsersByRole(role: string) {
    let usersList: Observable<any[]>;
    usersList = this.firestore
      .collection<User>(User.tableName, ref => ref.where('roles', 'array-contains', role))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return usersList;
  }

  getUsersByVerified(b: boolean) {
    let usersList: Observable<any[]>;
    usersList = this.firestore
      .collection<User>(User.tableName, ref => ref.where('verified', '==', b))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return usersList;
  }

  getUsuryRates() {
    let usuryRatesList: Observable<any[]>;
    usuryRatesList = this.firestore
      .collection<UsuryRate>(UsuryRate.tableName)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as UsuryRate;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return usuryRatesList;
  }

  getAttualUsuryRates() {
    let usuryRate: any;
    const today = new Date();
    usuryRate = this.firestore
      .collection<UsuryRate>(UsuryRate.tableName, ref => ref.where('endDate', '>', today))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as UsuryRate;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return usuryRate;
  }

  deleteUsuryRate(id: string) {
    return this.firestore
      .collection<UsuryRate>(UsuryRate.tableName)
      .doc(id)
      .delete();
  }

  fatturaNumberToString() {
    let invoices: Observable<any[]>;
    let array: any[];
    invoices = this.firestore
      .collection<Invoice>(Invoice.tableName)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as Invoice;
            data.numFattura = data.numFattura + '';
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );

    invoices.subscribe(data => {
      array = data;
    });

    // this.modInvoice(data) ;

    array.forEach(element => {
      try {
        this.modInvoice(element);
      } catch (error) {
        console.error(error);
      }
    });

    return invoices;
  }

  retrieveSession(): string | null {
    return localStorage.getItem('sessioneYellow');
  }

  setSession(session: string): void {
    return localStorage.setItem('sessioneYellow', session);
  }

  svuotaLog(tipo: string) {
    this.firestore
      .collection<Log>(Log.tableName, ref => ref.where('type', '==', tipo))
      .get()
      .subscribe(data => {
        data.docs.forEach(data2 => {
          data2.ref.delete();
        });
      });
  }

  getLog(invoice: string) {
    // Bisogna creare un indice per le query con ordinamento
    // la procedura è automatizzata su firestore: in caso di errore nella console del browser viene proposto un link
    // altrimenti bisonga
    let logList: Observable<any[]>;
    logList = this.firestore
      .collection<LogSms>(Log.tableName, ref => ref.where('invoice', '==', invoice).orderBy('date', 'desc'))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as LogSms;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return logList;
  }

  getLogTigran(id: string) {
    // Bisogna creare un indice per le query con ordinamento
    // la procedura è automatizzata su firestore: in caso di errore nella console del browser viene proposto un link
    // altrimenti bisonga
    let logList: Observable<any[]>;
    logList = this.firestore
      .collection<LogTigran>(Log.tableName, ref =>
        ref
          .where('userId', '==', id)
          .where('type', '==', 'TIGRAN')
          .orderBy('date', 'desc')
      )
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
    return logList;
  }

  returnEmailListByRole(role: string): Observable<string[]> {
    let adminEmailList: Observable<any[]>;
    adminEmailList = this.firestore
      .collection<User>(User.tableName, ref => ref.where('roles', 'array-contains', role).where('verified', '==', true))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as User;
            const email = data.email;
            return { email };
          });
        })
      );
    return adminEmailList;
  }

  private checkAndSetIP() {
    if (sessionStorage.getItem('ip')) {
      this.client_ip = sessionStorage.getItem('ip');
    } else {
      this.ip.getIPAddress().subscribe((res: any) => {
        this.client_ip = res.ip;
        sessionStorage.setItem('ip', res.ip);
      });
    }
  }
}
