import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-robo-modal',
  templateUrl: './robo-modal.component.html',
  styleUrls: ['./robo-modal.component.scss']
})
export class roboModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
