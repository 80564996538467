import { Component, OnInit, Type } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseTranchedService } from '@app/core/firebase-tranched.service';
import { SearchForSubjectResponse } from '@app/tigran/tigran';
import { Utilities } from '@app/utilities/Utilities';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SmsService } from '@app/sms/sms.service';
import { LemonwayService } from '@app/lemonway/lemonway.service';
import { NgbdModalConfirmComponent } from '../wallet-details/request-detail-modal/request-detail-modal.component';
import { ShowMessageService } from '@app/core/show-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContractInvestor } from '@app/models/document/ContractInvestor.model';
import { AngularFireStorage } from '@angular/fire/storage';
@Component({
  selector: 'app-document-for-investor',
  templateUrl: './document-for-investor.component.html',
  styleUrls: ['./document-for-investor.component.scss']
})
export class DocumentForInvestorComponent implements OnInit {
  vatNumberForm!: FormGroup;
  currentVatNumber: string;
  showWarningMessage = false;
  isLoading = false;
  morescore: string;
  probdef: string;
  listaGenerale: any[];
  listaDocumenti: any[];
  userSelected: string;
  documentType: number;
  fileToUpload: any;
  uploading: boolean;
  uploadPercentage: string;
  base64: any;
  privato: boolean;
  tipoDocumento = Utilities.tipoDocumento;
  statoDocumento = Utilities.statoDocumento;
  error: string;
  type: string;
  singolUserSelected: boolean = false;
  idLoggedUser: string;
  isLoggedName: string;

  contract: ContractInvestor;

  investorCreationForm!: FormGroup;

  listDocument: any;
  nessunaTrans: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private firebaseService: FirebaseTranchedService,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private sms: SmsService,
    private route: ActivatedRoute,
    private router: Router,
    private ls: LemonwayService,
    private _modalService: NgbModal,
    public showService: ShowMessageService
  ) {
    this.createForm();
    this.listaGenerale = [];
    this.userSelected = '0';
    this.documentType = 100;
    firebaseService.getUsersByRole(Utilities.userType[5]).subscribe(data => {
      for (let entry of data) {
        this.listaGenerale.push(entry);
      }
    });
  }
  ngOnInit() {}

  open() {}

  onChange(event: any) {
    //console.log(event);
    this.firebaseService.getUserByID(this.userSelected).then(data => {
      if (data.exists) {
        const us = data.data();
        this.listDocument = [];
        // console.log(this.firebase.loggedUser.id);
        this.loadContract(this.userSelected);
      }
    });
  }

  prepareFile(event: any) {
    this.fileToUpload = event.target.files[0];
  }

  getDownloadURL2(trans: string) {
    // console.log(trans);
    const ref = this.storage.ref(trans);
    // console.log(ref);

    ref.getDownloadURL().subscribe(data => {
      // console.log(data);
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', data);
      link.setAttribute('download', trans);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  }

  loadContract(id: string) {
    this.firebaseService.getContractsByIdInvestor(id).subscribe(data => {
      data.forEach(element => {
        this.listDocument.push(element);
      });
      // console.log(this.listDocument.length);
      if (this.listDocument.length == 0) {
        this.nessunaTrans = true;
      } else {
        this.nessunaTrans = false;
      }
      // console.log(this.listDocument);
    });
  }

  createForm() {
    this.vatNumberForm = this.formBuilder.group({
      vatNumber: new FormControl('', Validators.required),
      user: new FormControl('')
    });
  }
}

const MODALS: { [name: string]: Type<any> } = {
  focusFirst: NgbdModalConfirmComponent
};
